import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import PropTypes from "prop-types";

import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./Carousel.scss";
import classNames from "classnames";

const Carousel = ({
  children,
  itemsPerPage,
  onChange,
  rows = 1,
  displayPagination = true,
  defaultSlide = 0,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [totalItems, setTotalItems] = useState(1);
  const sliderRef = useRef();
  const containerRef = useRef();

  useEffect(() => {
    if (
      activeIndex < 0 ||
      sliderRef.current?.innerSlider.state.currentSlide < 0 ||
      defaultSlide > 0
    ) {
      sliderRef.current.slickGoTo(defaultSlide);
      setActiveIndex(defaultSlide);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children, activeIndex, sliderRef.current, defaultSlide]);

  useEffect(() => {
    setTotalItems((prev) => {
      if (prev !== Math.ceil(children.length / itemsPerPage / rows)) {
        setActiveIndex(0);
      }

      return Math.ceil(children.length / itemsPerPage / rows);
    });
  }, [children, itemsPerPage, rows]);

  const afterChange = (currentSlide) => {
    onChange(currentSlide);
    const elem = containerRef?.current?.querySelector(".slick-dots");

    if (elem?.childNodes) {
      elem.childNodes.forEach((element, idx) => {
        if (element.className?.includes("slick-active")) {
          setActiveIndex(idx);
        }
      });
    }
  };

  const handlePrevClick = () => {
    if (activeIndex === 0) {
      return;
    }

    sliderRef.current.slickPrev();
  };

  const handleNextClick = () => {
    if (activeIndex + 1 === totalItems) {
      return;
    }

    sliderRef.current.slickNext();
  };

  return (
    <div ref={containerRef}>
      <Slider
        rows={rows}
        ref={sliderRef}
        infinite={false}
        dots={true}
        afterChange={afterChange}
        slidesToScroll={itemsPerPage}
        slidesToShow={itemsPerPage}
      >
        {children}
      </Slider>
      {children.length ? (
        <div className="common-carousel-pagination">
          <div>
            <KeyboardArrowLeft
              className={classNames("common-carousel-keyboard", {
                disabled: activeIndex === 0,
              })}
              onClick={handlePrevClick}
              data-testid="Carousel-prevButton"
            />
            {displayPagination ? (
              <span>
                {activeIndex + 1} / {totalItems}
              </span>
            ) : null}
            <KeyboardArrowRight
              className={classNames("common-carousel-keyboard", {
                disabled: activeIndex + 1 === totalItems,
              })}
              onClick={handleNextClick}
              data-testid="Carousel-nextButton"
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

Carousel.propTypes = {
  children: PropTypes.any,
  itemsPerPage: PropTypes.number,
  rows: PropTypes.number,
  defaultSlide: PropTypes.number,
  onChange: PropTypes.func,
  displayPagination: PropTypes.bool,
};

export default Carousel;
