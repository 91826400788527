import React from "react";
import PropTypes from "prop-types";
import { getUserData } from "../../helpers";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import avatarIcon1 from "../../assets/images/user1-avatar.png";
import avatarIcon2 from "../../assets/images/user2-avatar.png";
import avatarIcon3 from "../../assets/images/user3-avatar.png";
import "./MultipleUserIndicator.scss";

const avatarIcons = [avatarIcon1, avatarIcon2, avatarIcon3];

const MultipleUserIndicator = ({ activeUsers }) => {
  const { userDetails } = getUserData();
  const filteredActiveUsers =
    [...new Set(activeUsers)].filter((user) => user !== userDetails?.name) ||
    [];
  const visibleUsers = filteredActiveUsers.slice(
    0,
    filteredActiveUsers.length > 3 ? 2 : 3
  );
  const hiddenUsers = filteredActiveUsers.slice(
    filteredActiveUsers.length > 3 ? 2 : 3
  );

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={-1}
      data-testid="multiple-users-indicator"
    >
      {visibleUsers?.map((user, index) => (
        <Tooltip title={user} key={user} placement="bottom" arrow>
          <div
            className="multiple-users-indicator"
            data-testid={`user-avatar-visible-${index}`}
          >
            <img
              src={avatarIcons[index]}
              className={`multiple-users-indicator-avatar avatar-${index}`}
              alt={`user-${index}-avatar`}
            />
          </div>
        </Tooltip>
      ))}
      {hiddenUsers.length ? (
        <Tooltip
          title={hiddenUsers.join(", ")}
          placement="bottom"
          arrow
          sx={{ display: "inline-block" }}
        >
          <div className="multiple-users-indicator" data-testid="hidden-users">
            <div className="multiple-users-indicator-count-icon">
              <span
                className="multiple-users-indicator-count-value"
                data-testid="hidden-users-count"
              >
                +{hiddenUsers.length}
              </span>
            </div>
          </div>
        </Tooltip>
      ) : null}
    </Stack>
  );
};

MultipleUserIndicator.propTypes = {
  activeUsers: PropTypes.array.isRequired,
};

export default MultipleUserIndicator;
