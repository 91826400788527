import React from "react";
import PropTypes from "prop-types";

import { LVT_ITEM, MOS } from "./LVTDiagramSettings";
import MoS from "../../../assets/images/MoS.svg";
import FixMenu from "../../../components/Common/FixMenu";
import LVTItemMenu from "./LVTItemMenu";

const LVTMenu = ({ userEditAccess }) => {
  return (
    <div>
      <FixMenu
        userEditAccess={userEditAccess || false}
        items={[
          {
            body: (
              <div>
                <LVTItemMenu userEditAccess={userEditAccess} />
              </div>
            ),
            eventName: LVT_ITEM,
          },
          {
            icon: (
              <img
                src={MoS}
                alt="MosIcon"
                className="lvt-menu-icon big"
                data-testid="mos-icon"
              />
            ),
            eventName: MOS,
          },
        ]}
      />
    </div>
  );
};

LVTMenu.propTypes = {
  userEditAccess: PropTypes.bool.isRequired,
};

export default LVTMenu;
