import React, { useState, useRef, useEffect, useMemo } from "react";
import { Handle } from "reactflow";
import PropTypes from "prop-types";
import classNames from "classnames";
import CodeEditor from "@uiw/react-textarea-code-editor";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { COLUMN_WIDTH, sections } from "./DPIMSettings";

const DataProductItem = ({
  data,
  isConnectable,
  updateDPItem,
  id,
  setNodes,
  onNodesRemove,
  userEditAccess,
  setIsDraggable,
}) => {
  const textRef = useRef();
  const [anchorEl, setAnchorEl] = useState();
  const [text, setText] = useState(data.label);
  let isDeleteInProgress = false;

  const maxWidth = useMemo(
    () =>
      (sections.find((section) => section.id === data.step).width || 0) *
        COLUMN_WIDTH -
      50,
    [data.step]
  );

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setText(data.label);
  }, [data.label]);

  const handleOnClick = () => {
    textRef.current.focus();
    setIsDraggable(false);
  };

  const handleBlur = (event) => {
    setIsDraggable(true);
    if (isDeleteInProgress) {
      return;
    }
    setNodes((nds) => {
      return nds.map((node) => {
        if (node.id === id) {
          updateDPItem(id, {
            ...node,
            data: { ...node.data, label: event.target.value },
          });
        }
        return node;
      });
    });
  };

  const handleDelete = () => {
    isDeleteInProgress = true;
    onNodesRemove([{ id, data, type: "dpItem" }]);
  };

  return (
    <div
      style={{ maxWidth }}
      className="dataproduct-item"
      onClick={handleOnClick}
    >
      {["data-entites", "operational-consumer", "operational-dp-job"].includes(
        data.step
      ) ? (
        <Handle
          type="target"
          position="right"
          id="2"
          style={{ background: "#555", marginLeft: 10 }}
          className="dataproduct-item-handle-dot"
          isConnectable={isConnectable}
        />
      ) : null}
      <div className="dataproduct-item-container">
        <CodeEditor
          value={text}
          ref={textRef}
          disabled={!userEditAccess}
          placeholder="Start Type"
          onChange={(evn) => setText(evn.target.value)}
          onBlur={handleBlur}
          padding={15}
          style={{
            background: "transparent",
            fontSize: 12,
          }}
          data-testid="editor-input"
        />
      </div>
      <div
        className="diagram-item-delete data-product-item"
        onClick={handleMoreClick}
        data-testid="more-button"
      >
        <MoreVertIcon sx={{ fontSize: "14px" }} />
      </div>
      <Menu
        id="delete-dp-icon"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMoreClose}
      >
        <MenuItem
          onClick={userEditAccess ? handleDelete : null}
          className={classNames({
            "cursor-pointer": userEditAccess,
            "cursor-not-allowed": !userEditAccess,
          })}
          data-testid="delete-menu"
        >
          Delete <DeleteOutlinedIcon />
        </MenuItem>
      </Menu>
      {["sources", "data-entites", "operational-consumer"].includes(
        data.step
      ) ? (
        <Handle
          type="source"
          position="left"
          id="4"
          style={{ background: "#555", marginRight: 10 }}
          className="dataproduct-item-handle-dot"
          isConnectable={isConnectable}
        />
      ) : null}
    </div>
  );
};

DataProductItem.propTypes = {
  data: PropTypes.object.isRequired,
  isConnectable: PropTypes.bool,
  updateDPItem: PropTypes.func,
  id: PropTypes.string,
  setNodes: PropTypes.func,
  onNodesRemove: PropTypes.func,
  userEditAccess: PropTypes.bool,
  setIsDraggable: PropTypes.func,
};

export default DataProductItem;
