export const DATAPRODUCT_WORKSPACE_CONST = {
  ADD_DATAPRODUCT_LINK_REQUEST: "ADD_DATAPRODUCT_LINK_REQUEST",
  ADD_DATAPRODUCT_LINK_SUCCESS: "ADD_DATAPRODUCT_LINK_SUCCESS",
  ADD_DATAPRODUCT_LINK_FAILURE: "ADD_DATAPRODUCT_LINK_FAILURE",

  DELETE_DATA_PRODUCT_RELATIONSHIP_REQUEST:
    "DELETE_DATA_PRODUCT_RELATIONSHIP_REQUEST",
  DELETE_DATA_PRODUCT_RELATIONSHIP_SUCCESS:
    "DELETE_DATA_PRODUCT_RELATIONSHIP_SUCCESS",
  DELETE_DATA_PRODUCT_RELATIONSHIP_FAILURE:
    "DELETE_DATA_PRODUCT_RELATIONSHIP_FAILURE",

  DELETE_DATA_PRODUCT_REQUEST: "DELETE_DATA_PRODUCT_REQUEST",
  DELETE_DATA_PRODUCT_SUCCESS: "DELETE_DATA_PRODUCT_SUCCESS",
  DELETE_DATA_PRODUCT_FAILURE: "DELETE_DATA_PRODUCT_FAILURE",
};
