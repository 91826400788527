import React from "react";
import PropTypes from "prop-types";
import Breadcrumb from "../../components/Common/BreadCrumb";

const DataProductBreadCrumb = ({
  useCaseNames,
  useCaseHrefs,
  lvtUrls,
  lvtTypeName,
  dataProductId,
}) => {
  const arrUseCaseNames = Array.from(useCaseNames); // Typecaseting since the type of the element returns object!
  const arrUseCaseHref = Array.from(useCaseHrefs);
  const arrLvtUrls = Array.from(lvtUrls);
  const arrLvtTypeNames = Array.from(lvtTypeName);
  return (
    <Breadcrumb
      steps={[
        { name: "Home Page", href: "/home" },
        {
          name: "Data Product value realization understood by Lean Value Tree",
          href: arrLvtUrls.length === 0 ? [] : arrLvtUrls,
          LVTDropDownList:
            arrLvtTypeNames?.length === 0
              ? ["This data product does not belong to any Lean Value Tree"]
              : arrLvtTypeNames,
        },
        {
          name: "Data Product discovery by Data Product Interaction Map",
          href: arrUseCaseHref.length === 0 ? [] : arrUseCaseHref,
          dropDownList:
            arrUseCaseNames?.length === 0
              ? [
                  "This data product does not belong to any Data Interaction Map",
                ]
              : arrUseCaseNames,
        },
        {
          name: "Data Product Canvas",
          href: `dataproduct-details?dataproductId=${dataProductId}`,
          active: true,
        },
      ]}
    />
  );
};

DataProductBreadCrumb.propTypes = {
  useCaseNames: PropTypes.array.isRequired,
  useCaseHrefs: PropTypes.array.isRequired,
  lvtTypeName: PropTypes.array.isRequired,
  lvtUrls: PropTypes.array.isRequired,
  dataProductId: PropTypes.string.isRequired,
};

export default DataProductBreadCrumb;
