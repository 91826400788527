import React from "react";
import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./MultipurposeAccordion.scss";

const MultipurposeAccordion = ({
  title,
  titleBtn,
  titleBtnHandler,
  children,
  defaultOpen = false,
  handleAccordionExpand = () => {},
}) => {
  const handleAccordionChange = (expanded) => {
    handleAccordionExpand(expanded);
  };

  return (
    <Accordion
      className="custom-accordion"
      defaultExpanded={defaultOpen}
      onChange={(e, expanded) => handleAccordionChange(expanded)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className="custom-accordion-summary"
        data-testid="accordion-title"
      >
        <p className="accordion-title">
          {title}
          {titleBtn && (
            <span
              className="accordion-title-btn"
              onClick={titleBtnHandler}
              data-testid="title-btn"
            >
              {titleBtn}
            </span>
          )}
        </p>
      </AccordionSummary>
      <AccordionDetails className="custom-accordion-details">
        {children && <div data-testid="accordion-content">{children}</div>}
      </AccordionDetails>
    </Accordion>
  );
};

MultipurposeAccordion.propTypes = {
  title: PropTypes.string,
  titleBtn: PropTypes.node,
  titleBtnHandler: PropTypes.func,
  children: PropTypes.node,
  handleAccordionExpand: PropTypes.func,
  defaultOpen: PropTypes.bool,
};

export default MultipurposeAccordion;
