import React from "react";
import PropTypes from "prop-types";
import { Route, withRouter, Redirect } from "react-router-dom";
import ErrorBoundaries from "../../components/Common/ErrorBoundaries";
import useInactivityTimer from "../../helpers/CustomHooks/useInactivityTimer";

const Authmiddleware = ({ component: Component, layout: Layout }) => {
  useInactivityTimer();

  return (
    <ErrorBoundaries>
      <Route
        render={(props) => {
          // here you can apply condition
          if (!localStorage.getItem("loginInfo")) {
            return (
              <Redirect
                to={{ pathname: "/login", state: { from: props.location } }}
              />
            );
          }

          return (
            <Layout>
              <Component {...props} />
            </Layout>
          );
        }}
      />
    </ErrorBoundaries>
  );
};

Authmiddleware.propTypes = {
  location: PropTypes.object.isRequired,
  component: PropTypes.elementType,
  layout: PropTypes.object,
};

export default withRouter(Authmiddleware);
