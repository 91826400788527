import React from "react";
import PropTypes from "prop-types";

import Breadcrumb from "../Common/BreadCrumb";
import CollibraCommunityDataProducts from "./CollibraCommunityDataProducts";

import "./CollibraCommunityCard.scss";

const CollibraCommunityCard = ({ details, onClick, isSelected = false }) => {
  const clickHandler = () => {
    onClick(details?.collibraCommunityId);
  };
  return (
    <div className="collibra-community-card">
      <h3 className="community-header">
        {details?.collibraCommunityName}
        <input
          type="radio"
          className="community-radio"
          onClick={clickHandler}
          checked={isSelected}
        />
      </h3>
      {details?.path && details?.path?.length > 0 && (
        <Breadcrumb
          steps={details.path}
          styles={{ fontSize: "12px" }}
          shorten={true}
        />
      )}
      <p className="pt-2">{details?.description}</p>
      {details?.dataProducts?.length > 0 && (
        <CollibraCommunityDataProducts dataProducts={details.dataProducts} />
      )}
    </div>
  );
};

CollibraCommunityCard.propTypes = {
  details: PropTypes.object,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
};

export default CollibraCommunityCard;
