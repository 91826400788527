import { useState, useCallback } from "react";
// custom modal hook to show and hide the child component from parent component
const useModal = () => {
  const [isShowing, setIsShowing] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [deactivate, setDeactivate] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [isCreate, setCreateModal] = useState(false);
  const [addMemModalActive, setAddMemModal] = useState(false);
  const [createDpModal, setCreateDpModal] = useState(false);
  const [addMos, setAddMos] = useState(false);
  const [deleteMosModal, setDeleteMosModal] = useState(false);
  const [dpimConfimationModal, setDpimConfimationModal] = useState(false);
  const [lvtUseCasesModal, setLvtUseCasesModal] = useState(false);
  const [deleteDomainModal, setDeleteDomainModal] = useState(false);
  const [deleteTeamSpaceModal, setDeleteTeamSpaceModal] = useState(false);

  const toggle = useCallback(() => {
    setIsShowing((value) => !value);
  }, []);
  const updateToggle = useCallback(() => {
    setIsUpdate((value) => !value);
  }, []);
  const confirmToggle = useCallback(() => {
    setConfirm((value) => !value);
  }, []);
  const deactivateToggle = useCallback(() => {
    setDeactivate((value) => !value);
  }, []);
  const modalToggle = useCallback(() => {
    setIsModal((value) => !value);
  }, []);
  const createToggle = useCallback(() => {
    setCreateModal((value) => !value);
  }, []);
  const addMemModalToggle = useCallback(() => {
    setAddMemModal((value) => !value);
  }, []);
  const createDpModalToggle = useCallback(() => {
    setCreateDpModal((value) => !value);
  }, []);
  const addMosToggle = useCallback(() => {
    setAddMos((value) => !value);
  }, []);
  const deleteMosModalToggle = useCallback(() => {
    setDeleteMosModal((value) => !value);
  }, []);
  const dpimConfimationModalToggle = useCallback(() => {
    setDpimConfimationModal((value) => !value);
  }, []);
  const lvtUseCasesModalToggle = useCallback(() => {
    setLvtUseCasesModal((value) => !value);
  }, []);
  const deleteDomainModalToggle = useCallback(() => {
    setDeleteDomainModal((value) => !value);
  }, []);
  const deleteTeamSpaceModalToggle = useCallback(() => {
    setDeleteTeamSpaceModal((value) => !value);
  }, []);

  return {
    isShowing,
    isUpdate,
    deactivate,
    confirm,
    isModal,
    addMemModalActive,
    createDpModal,
    addMos,
    deleteMosModal,
    dpimConfimationModal,
    lvtUseCasesModal,
    deleteDomainModal,
    deleteTeamSpaceModal,
    toggle,
    updateToggle,
    confirmToggle,
    deactivateToggle,
    modalToggle,
    isCreate,
    createToggle,
    addMemModalToggle,
    createDpModalToggle,
    addMosToggle,
    deleteMosModalToggle,
    dpimConfimationModalToggle,
    lvtUseCasesModalToggle,
    deleteDomainModalToggle,
    deleteTeamSpaceModalToggle,
  };
};

export default useModal;
