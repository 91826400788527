import { DATAPRODUCT_INTERACTION_MAP_CONST } from "./actionTypes";

export const getAllDataProductInteractionMapItemsByUseCaseId = (request) => {
  return {
    type: DATAPRODUCT_INTERACTION_MAP_CONST.GET_DPIM_BY_USECASE_ID_REQUEST,
    payload: request,
  };
};
export const getAllDataProductInteractionMapItemsByUseCaseIdSuccess = (
  response
) => {
  return {
    type: DATAPRODUCT_INTERACTION_MAP_CONST.GET_DPIM_BY_USECASE_ID_SUCCESS,
    payload: response,
  };
};
export const getAllDataProductInteractionMapItemsByUseCaseIdFailure = (
  error
) => {
  return {
    type: DATAPRODUCT_INTERACTION_MAP_CONST.GET_DPIM_BY_USECASE_ID_FAILURE,
    payload: error,
  };
};

export const getAllItemsByUseCaseId = (request) => {
  return {
    type: DATAPRODUCT_INTERACTION_MAP_CONST.GET_ITEMS_BY_USECASE_ID_REQUEST,
    payload: request,
  };
};
export const getAllItemsByUseCaseIdSuccess = (response) => {
  return {
    type: DATAPRODUCT_INTERACTION_MAP_CONST.GET_ITEMS_BY_USECASE_ID_SUCCESS,
    payload: response,
  };
};
export const getAllItemsByUseCaseIdFailure = (error) => {
  return {
    type: DATAPRODUCT_INTERACTION_MAP_CONST.GET_ITEMS_BY_USECASE_ID_FAILURE,
    payload: error,
  };
};
