export const glossaryItems = [
  {
    title: "Aggregate Data Products",
    description:
      "Aggregate Data Products generally contain data that has been aggregated from multiple Data Products or source systems. This aggregation may have been done to create data that represents a business concept such as a customer, from which the data comes from multiple sources.",
  },
  {
    title: "Candidate",
    description:
      "Data product is initially created, registered to the Roche Data Mesh with unique Data Product Id. It provides services, eg. ‘Configure Data Product’. While in this status Data Product is usually enriched with data receiving mechanisms (input ports), delivery mechanisms (output ports). The status ends with deploying the Data Product.",
  },
  {
    title: "Consumer",
    description:
      "The main consumer (role, team, department) for whom the Data Product has been designed for.",
  },
  {
    title: "Consumer Oriented Data Products",
    description:
      "Consumer Oriented Data Products Generally aim to satisfy a particular need or use case.These data products may enrich, combine or adapt source data to address a particular use case and may include analytical data, machine learning models or reports.",
  },
  {
    title: "Data Domain",
    description:
      "A data domain is a structural set of definitions, data and data products led by a dedicated team that encompasses all types of data within an area of importance and value to the business.",
  },
  {
    title: "Data Domain Draft",
    description:
      "The Data Domain Draft signifies that it is a Data Domain that only exists within the Data Mesh Design Studio.",
  },
  {
    title: "Data Domain Diagnostics",
    description: "Official Diagnostics Data Domain",
  },
  {
    title: "Data Domain In Review",
    description:
      "The Data Domain is neither in the Official Pharma Data Domain list nor in the Official Diagnostics Data Domain List",
  },
  {
    title: "Data Entity",
    description:
      "Is a high level data asset that represents information created and used in business capabilities and processes",
  },
  {
    title: "Data Product",
    description:
      "A data product is a life-cycle managed combination of technology and data that delivers value in specific business contexts.",
  },
  {
    title: "Data Product Interaction Map",
    description:
      "Is a conceptual tool to identify and design your Data Products that you need to achieve your use case.",
  },
  {
    title: "Data Product Job",
    description:
      'What "job" will be done thanks to this Data Product. By "job" we mean a decision that has to be made or an insight generated.',
  },
  {
    title: "Data Product Owner",
    description:
      "A person who is accountable for understanding and meeting the needs of their users and the quality and scope of their data product matching demands.",
  },
  {
    title: "Draft",
    description: "Data Domain that only exists in DMDS application",
  },
  {
    title: "Deployed",
    description:
      "Data product is performing its configured code (data transformations, policy controls, etc.) and providing data via output ports. It may check its service levels and fitness. It is also shown on the RDM portal.",
  },
  {
    title: "Diagnostics",
    description: "Official Diagnostics Data Domain",
  },
  {
    title: "In Design",
    description:
      "Data Products that are under design by the Data Domain team. In this step Data Product core specifications should be defined: data product name, owner, required sources, required data products (if applicable), usage, consumers and job. The status ends with the design of Data Product including the above specifications.",
  },
  {
    title: "In Initiation",
    description:
      "Data product currently in the Data Mesh service 'Data Product Initiation'.",
  },
  {
    title: "In Review",
    description:
      'Data Products that are under review by the Data Product owner. In this step, all Data Product core specifications of the status "design" should be reviewed, corrected if necessary. The status ends with the DP specification confirmation. With that a DP will be initiated in the data mesh.',
  },
  {
    title: "Lean Value Tree",
    description:
      "Is a conceptual tool to facilitate, share, and drive a company's strategy and vision across all levels and teams of an organization. It helps us to define and realize the value that we want to create by delivering data products.",
  },
  {
    title: "Measure of Success",
    description:
      "Measures are often characterized either as leading or lagging indicators. If a leading indicator informs business leaders of how to produce desired results, a lagging indicator measures current production and performance.",
  },
  {
    title: "Operational",
    description: "Defines the usage of Data Product.",
  },
  {
    title: "Pharma",
    description: "Official Pharma Data Domain",
  },
  {
    title: "Published",
    description:
      'Additionally to "Deployed", it is shoppable for consumers via the RDM portal.',
  },
  {
    title: "Rejected",
    description: "Not Implemented in DMDS",
  },
  {
    title: "Retired",
    description: "Data Product is not active anymore",
  },
  {
    title: "Sources",
    description: "Sources in DMDS are the source of data",
  },
  {
    title: "Source Oriented Data Product",
    description:
      "Source Oriented Data Products generally contain data that is closely aligned to data in the source systems. This may include transactional data, master data or events. This data is generally less enriched than other Data Product types, but may be more general and able to be adapted to specific needs.",
  },
];
