// saga.js
import { takeEvery, fork, all, call, put } from "redux-saga/effects";
import { DATAPRODUCT_INTERACTION_MAP_CONST } from "./actionTypes";
import {
  getAllDataProductInteractionMapItemsByUseCaseIdSuccess,
  getAllDataProductInteractionMapItemsByUseCaseIdFailure,
  getAllItemsByUseCaseIdSuccess,
  getAllItemsByUseCaseIdFailure,
} from "./actions";
import { checkHttpStatus } from "../apiUtils";
import { baseMethod } from "../../services/BaseService";
import {
  AXIOS_INSTANCE,
  DATA_PRODUCT_INTERACTION_MAP_API,
} from "../apiUtils/config";

export function* getDPIMByUseCaseId(action) {
  try {
    const serviceMethod = AXIOS_INSTANCE.get(
      `${DATA_PRODUCT_INTERACTION_MAP_API}/getRelatedUsecases?domainId=${action.payload.id}`
    );
    const processedResponse = yield call(
      baseMethod,
      serviceMethod,
      "",
      null,
      null
    );
    const response = yield call(checkHttpStatus, processedResponse);
    if (
      response.status >= 200 &&
      response.status < 300 &&
      response.data?.data?.isSuccess
    ) {
      const responseData = {
        statusCode: 200,
        response: response.data,
      };
      yield put(
        getAllDataProductInteractionMapItemsByUseCaseIdSuccess(responseData)
      );
    } else {
      const responseData = {
        statusCode: response.statusCode || 400,
        data: response.data,
      };
      yield put(
        getAllDataProductInteractionMapItemsByUseCaseIdFailure(responseData)
      );
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(
      getAllDataProductInteractionMapItemsByUseCaseIdFailure(errorData)
    );
  }
}

export function* getItemsByUseCaseId(action) {
  try {
    const serviceMethod = AXIOS_INSTANCE.get(
      `${DATA_PRODUCT_INTERACTION_MAP_API}/getAllItemsByUseCaseId?useCaseId=${action.payload.id}`
    );
    const processedResponse = yield call(
      baseMethod,
      serviceMethod,
      "",
      null,
      null
    );
    const response = yield call(checkHttpStatus, processedResponse);
    if (
      response.status >= 200 &&
      response.status < 300 &&
      response.data?.data?.isSuccess
    ) {
      const responseData = {
        statusCode: 200,
        response: response.data,
      };
      yield put(getAllItemsByUseCaseIdSuccess(responseData));
    } else {
      const responseData = {
        statusCode: response.statusCode || 400,
        data: response.data,
      };
      yield put(getAllItemsByUseCaseIdFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getAllItemsByUseCaseIdFailure(errorData));
  }
}

export function* watchgetDPIMByUseCaseId() {
  yield takeEvery(
    DATAPRODUCT_INTERACTION_MAP_CONST.GET_DPIM_BY_USECASE_ID_REQUEST,
    getDPIMByUseCaseId
  );
}

export function* watchgetItemsByUseCaseId() {
  yield takeEvery(
    DATAPRODUCT_INTERACTION_MAP_CONST.GET_ITEMS_BY_USECASE_ID_REQUEST,
    getItemsByUseCaseId
  );
}

export function* DataProductInteractionMapSaga() {
  yield all([fork(watchgetDPIMByUseCaseId), fork(watchgetItemsByUseCaseId)]);
}

export default DataProductInteractionMapSaga;
