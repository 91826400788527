import { GlobalNotificationHandle } from "./NotificationHandler";
import { checkHttpStatus } from "../store/apiUtils";
import { store } from "../store";
import { startLoader, stopLoader } from "../store/layout/loader/actions";
import axios from "axios";
import { jwtToken } from "../helpers/token";
import { AXIOS_INSTANCE } from "../store/apiUtils/config";
import { GET_AXIOS_INSTANCE } from "../store/apiUtils/config";

/**
 * httpservice url
 * @returns {*}
 */
export const httpServiceUrl = axios.create({
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});
var numberOfAjaxCAllPending = 0;
httpServiceUrl.defaults.withCredentials = true;
// Add a request interceptor
AXIOS_INSTANCE.interceptors.request.use(
  async function (config) {
    if (!localStorage.getItem("hideLoader")) {
      numberOfAjaxCAllPending++;
      store.dispatch(startLoader());
    }
    try {
      const token = await jwtToken();
      if (token || localStorage.getItem("loginInfo")) {
        config.headers.common["Authorization"] = `Bearer ${token}`;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
AXIOS_INSTANCE.interceptors.response.use(
  function (response) {
    if (numberOfAjaxCAllPending > 0) {
      numberOfAjaxCAllPending--;
    }
    if (numberOfAjaxCAllPending === 0) {
      //hide loader
      store.dispatch(stopLoader());
    }
    return response;
  },
  function (error) {
    if (numberOfAjaxCAllPending > 0) {
      numberOfAjaxCAllPending--;
    }
    if (numberOfAjaxCAllPending === 0) {
      //hide loader
      store.dispatch(stopLoader());
    }
    return Promise.reject(error);
  }
);

export const baseMethod = async (
  serviceMethod,
  successMsg,
  errorMsg,
  isStopRedirectionToLogin
) => {
  const token = await jwtToken();
  GET_AXIOS_INSTANCE(token);

  return serviceMethod
    .then(checkHttpStatus)
    .then((response) => {
      if (response?.data?.data?.isSuccess) {
        GlobalNotificationHandle(
          200,
          successMsg,
          response?.data?.data?.isSuccess
        );
      } else {
        GlobalNotificationHandle(
          response.data?.error?.error,
          errorMsg ? errorMsg : response.data.error?.errorDescription,
          response?.data?.isSuccess,
          isStopRedirectionToLogin
        );
      }

      return response;
    })
    .catch((err) => {
      if (err.response) {
        GlobalNotificationHandle(
          err.response.data.status,
          err.response.data.detail,
          err.response?.data?.isSuccess
        );
      }
      return err.response;
    });
};
