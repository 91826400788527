import React from "react";
import PropTypes from "prop-types";

import Tooltip from "@mui/material/Tooltip";
import EditIcon from "../../assets/images/edit_button.png";
import "./TeamSpaceCard.scss";

const TeamSpaceList = ({ setEditTeamSpace, teamSpaceList }) => {
  const onEditDomainHandler = (item) => {
    setEditTeamSpace({ editStatus: true, teamSpaceDetails: item });
  };

  if (!teamSpaceList?.length) {
    return null;
  }
  return (
    <>
      <div className="admin-details-domain-card-container">
        {teamSpaceList?.map((item, index) => {
          return (
            <div className="p-2" key={index}>
              <div className="admin-details-domain-card">
                <Tooltip title={item?.name} placement={"top"}>
                  <span className="admin-details-domain-name">
                    {item?.name}
                  </span>
                </Tooltip>
                <div className="admin-details-domain-status-and-edit">
                  <img
                    className="admin-details-domain-edit-icon"
                    src={EditIcon}
                    alt="edit-icon"
                    onClick={() => onEditDomainHandler(item)}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

TeamSpaceList.propTypes = {
  teamSpaceList: PropTypes.array,
  setEditTeamSpace: PropTypes.func,
};

export default TeamSpaceList;
