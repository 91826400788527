import { takeEvery, fork, all, call, put } from "redux-saga/effects";
import { Amplify, Auth } from "aws-amplify";
import awsconfig from "../../../aws-exports";

import { LOGIN_CONST } from "./actionTypes";
import { loginError } from "./actions";

Amplify.configure(awsconfig);

export function* loginUser() {
  try {
    yield call([Auth, Auth.federatedSignIn]);
  } catch (error) {
    yield put(
      loginError({
        error: {
          statusText: error,
          netWorkError: true,
        },
      })
    );
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_CONST.LOGIN_REQUEST, loginUser);
}

export function* authSaga() {
  yield all([fork(watchUserLogin)]);
}

export default authSaga;
