import { Auth, Amplify } from "aws-amplify";
import awsconfig from "../aws-exports";

Amplify.configure(awsconfig);

export const jwtToken = async () => {
  let session = await Auth.currentSession();
  //console.log('Session refreshed')
  let token = session.getIdToken().getJwtToken();
  return token;
};
