import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import Divider from "@mui/material/Divider";
import CustomDataInputField from "../../components/Common/CustomDataInputField";
import CustomDataMultiSelectField from "../../components/Common/CustomDataMultiSelectField";
import RedAsterisk from "../../components/Common/RedAsterisk";
import { getUsersByDomainId } from "../../store/actions";
import "./CreateTeamSpaceCard.scss";
import { socket } from "../../services/Socket";
import { PropTypes } from "prop-types";

const CreateTeamSpaceCard = ({ domainId, domainName, isAdminPage = false }) => {
  const isEditMode = true;
  const [teamSpaceName, setTeamSpaceName] = useState("");
  const [definition, setDefinition] = useState();
  const [TeamOwner, setTeamOwner] = useState([]);
  const [members, setMembers] = useState([]);
  const [selectableUsers, setSelectableUsers] = useState([]);
  const [teamSpaceNameError, setTeamSpaceNameError] = useState(false);
  const [teamSpaceDescriptionError, setTeamSpaceDescriptionError] =
    useState(false);
  const [ownerError, setOwnerError] = useState(false);
  const dispatch = useDispatch();
  const domainUser = "domainUsers";
  const { users } = useSelector((state) => ({
    users: state.Users?.usersByDomain?.userList,
  }));

  useEffect(() => {
    dispatch(getUsersByDomainId(domainId, domainUser));
  }, [dispatch]);

  const handleCancel = () => {
    const redirectUrl = isAdminPage
      ? `/team-space/${encodeURIComponent(domainName)}?domainId=${domainId}`
      : `/datadomain-details?domainId=${domainId}`;
    window.location.href = redirectUrl;
  };

  const hasNonEmptyObject = (array) => {
    return array.some((obj) => Object.keys(obj).length > 0);
  };

  useEffect(() => {
    if (!users || !members || !TeamOwner) {
      return;
    }

    const filteredUserList = users.filter((user) => {
      const isMember = members.find((member) => user.id === member.id);
      const isOwner = TeamOwner.find((owner) => user.id === owner.id);
      return !isMember && !isOwner;
    });

    setSelectableUsers(filteredUserList);
  }, [users, members, TeamOwner]);

  const handleSave = async () => {
    setTeamSpaceDescriptionError(false);
    setTeamSpaceNameError(false);
    setOwnerError(false);
    if (!teamSpaceName || !definition || !hasNonEmptyObject(TeamOwner)) {
      if (!teamSpaceName) setTeamSpaceNameError(true);
      if (!definition) setTeamSpaceDescriptionError(true);
      if (
        !TeamOwner ||
        TeamOwner?.length === 0 ||
        !hasNonEmptyObject(TeamOwner)
      ) {
        setOwnerError(true);
      }
      return;
    }

    let filteredTeamOwner = TeamOwner.filter(
      (owner) => Object.keys(owner).length > 0
    );

    let filteredMember = members.filter(
      (member) => Object.keys(member).length > 0
    );

    let payload = {
      domainId,
      name: teamSpaceName,
      owners: filteredTeamOwner,
      description: definition,
    };

    if (filteredMember.length > 0) {
      payload.members = filteredMember;
    }

    await socket.emit("createTeamSpace", payload, socket.lastJoinedRoom);

    await socket.on("teamSpaceCreated", (id) => {
      const redirectUrl = isAdminPage
        ? `/team-space/${encodeURIComponent(domainName)}?domainId=${domainId}`
        : `/teamspace-details?teamSpaceId=${id}`;
      window.location.href = redirectUrl;
    });
  };

  return (
    <div className="team-space-details-card">
      <Typography
        className="team-space-details-card-body-title"
        variant="subtitle2"
      >
        Team Space Name: <RedAsterisk isEditMode={true} />
      </Typography>
      <div className="team-space-details-card-title">
        <CustomDataInputField
          data={teamSpaceName}
          data-testid="team-space-name-input"
          onChangeHandler={setTeamSpaceName}
          editState={isEditMode}
          textFieldClass="team-space-details-card-title-text"
          textClass="team-space-details-card-title-text"
          isMultiLine={false}
          error={teamSpaceNameError}
          errorMessage="Please enter the required information."
          handleError={setTeamSpaceNameError}
          hasError={true}
        />
      </div>
      <div className="team-space-details-card-body">
        <Typography
          variant="subtitle2"
          className="team-space-details-card-body-title"
        >
          Data Domain:
        </Typography>
        <p className="team-space-details-card-domain">{domainName}</p>
      </div>
      <div className="team-space-details-card-body">
        <Typography
          className="team-space-details-card-body-title"
          variant="subtitle2"
        >
          Description: <RedAsterisk isEditMode={true} />
        </Typography>
        <CustomDataInputField
          data={definition}
          data-testid="team-space-description-input"
          onChangeHandler={setDefinition}
          editState={isEditMode}
          isMultiLine={true}
          textVariant="body2"
          textFieldClass="team-space-details-card-body-definition"
          textClass="team-sapce-details-card-body-definition"
          inputHeight="100px"
          error={teamSpaceDescriptionError}
          errorMessage="Please enter the required information."
          handleError={setTeamSpaceDescriptionError}
          hasError={true}
          isTeamSpace={true}
        />
      </div>
      <div className="team-space-details-card-owner">
        <Divider variant="middle" />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              className="team-space-details-card-owner-title"
            >
              Team Space Owner: <RedAsterisk isEditMode={true} />
            </Typography>
            <CustomDataMultiSelectField
              customEditClass="team-space-details-card-members-edit"
              customTextClass="team-space-details-card-members"
              isEdit={isEditMode}
              options={selectableUsers}
              setOptions={setTeamOwner}
              data={TeamOwner}
              customButtonText="Add new person"
              error={ownerError}
              errorMessage="Please select the required information."
              resetError={() => setOwnerError(false)}
              resetErrorToTrue={() => setOwnerError(true)}
              isTeamSpaceOwner={true}
              isTeamSpace={true}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              className="team-space-details-card-body-title"
            >
              Members:
            </Typography>
            <CustomDataMultiSelectField
              customEditClass="team-space-details-card-members-edit"
              customTextClass="team-space-details-card-members"
              isEdit={isEditMode}
              options={selectableUsers}
              setOptions={setMembers}
              data={members}
              customButtonText="Add new person"
              isTeamSpace={true}
            />
          </Grid>
        </Grid>

        <Divider variant="middle" />
      </div>
      <div className="team-space-details-card-button-container">
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => handleCancel()}
        >
          <span>Cancel</span>
        </Button>
        <Button
          className="team-space-details-card-button"
          variant="contained"
          color="secondary"
          disabled={!isEditMode}
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </div>
  );
};
CreateTeamSpaceCard.propTypes = {
  domainId: PropTypes.string,
  domainName: PropTypes.string,
  isAdminPage: PropTypes.bool,
};
export default CreateTeamSpaceCard;
