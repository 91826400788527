/* eslint-disable max-lines */
import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import DataProductBreadCrumb from "./DataProductBreadCrumb";
import CustomDataInputField from "../../components/Common/CustomDataInputField";
import CustomDataSelectField from "../../components/Common/CustomDataSelectField";
import DpLineageCanvas from "./dpLineageCanvas";
import {
  getDataproductDetails,
  getAllDataProductTypes,
  getAllRoles,
  getUsersByDomainId,
  getRequiredDataProducts,
  getRequiredDataSources,
} from "../../store/actions";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  AXIOS_INSTANCE,
  DATA_PRODUCTS_API,
  COLLIBRA_API,
} from "../../store/apiUtils/config";
import { GlobalNotificationHandle } from "../../services/NotificationHandler";
import PaginatedTeamMembers from "./PaginatedTeamMembers";
import ImportTeamMembers from "./ImportTeamMembers";
import DataProductIsFavorite from "../../components/Common/DataProductIsFavorite";
import ActionButtons from "./ActionButtons";
import { DATAPRODUCT_STATUS_OPTIONS } from "../../constants/DataProductStatusOptions";
import FinalDecisionButtons from "./FinalDecisionButtons";
import AdditionalOptions from "./AdditionalOptions";
import { socket } from "../../services/Socket";
import useStatus from "../../helpers/CustomHooks/useStatus";
import Alert from "../../components/Common/Alert";
import DataProductStatus from "../../components/Common/DataProductStatus";
import MultipurposeAccordion from "../../components/Common/MultipurposeAccordion";
import "./index.scss";
import "./LineageFullScreen.scss";
import DataProductYAML from "./DataProductYAML";

export const pageStateOptions = {
  DISPLAY: "display",
  EDIT: "edit",
  REVIEW: "review",
  READY_FOR_IMPLEMENTATION: "readyForImplementation",
  DELETE: "delete",
  SAVE: "save",
  CANCEL: "cancel",
};
const DATAPRODUCT_ID = "id";
const DATAPRODUCT_NAME = "name";
const DATAPRODUCT_DOMAIN_ID = "domainId";
const DATAPRODUCT_DESCRIPTION = "description";
const DATAPRODUCT_VALUE_STATEMENT = "valueStatement";
const DATAPRODUCT_OWNER_NAME = "ownerName";
const DATA_PRODUCT_OWNER_ROLE = "Data Product Owner";
const DATAPRODUCT_TEAMS = "teams";
const DATAPRODUCT_TYPE = "dataProductType";
const DATAPRODUCT_TYPE_ID = "dataProductTypeId";
const DATAPRODUCT_IS_FAVORITE = "isFavorite";
const DATAPRODUCT_STATUS = "dataProductStatus";
const DPIM_SUBMITTED = "submitted";

const DataProductDetails = () => {
  const [pageCurrentState, setPageCurrentState] = useState(
    pageStateOptions.DISPLAY
  );
  const [dataProductDetails, setDataProductDetails] = useState({});
  const [isOwner, setIsOwner] = useState(false);
  const [useCaseNames, setUseCaseNames] = useState([]);
  const [useCaseHrefs, setUseCaseHrefs] = useState([]);
  const [lvtTypeName, setLvtTypeNames] = useState([]);
  const [lvtUrls, setLvtUrls] = useState([]);
  const [useCaseIds, setUseCaseIds] = useState([]);
  const [collibraDetails, setCollibraDetails] = useState({});
  const [isDPDetailsEditing, setIsDPDetailsEditing] = useState(false);
  const [isDPDetailsInReview, setIsDPDetailsInReview] = useState(false);
  const [isDataOutOfSync, setIsDataOutOfSync] = useState(false);
  const [currentDomainId, setCurrentDomainId] = useState("");

  // using another state to retain autofilled data so that the autofilled data doesn't lost in any unexceptional behaviour
  const [autofillDescription, setAutofillDescription] = useState("");
  const [autofillValueStatement, setAutofillValueStatement] = useState("");
  const [description, setDescription] = useState("");
  const [valueStatement, setValueStatement] = useState("");
  const [showError, setShowError] = useState(false);
  const [dataProductError, setDataProductError] = useState(false);
  //for data lineage
  const [lineageData, setlineageData] = useState({});

  const dispatch = useDispatch();
  const { search } = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(search);
  const searchedDataProductId = params.get("dataproductId") || "";
  const lineagefullscreen = params.get("lineagefullscreen") || false;
  const userDetails = localStorage.getItem("loginInfo")
    ? JSON.parse(localStorage.getItem("loginInfo")).data?.userDetails
    : null;
  const isAdmin = userDetails?.isAdmin;

  const { checkStatus } = useStatus(
    `STATUS-DP-${searchedDataProductId}`,
    searchedDataProductId,
    {
      update: "updateDPStatus",
      check: "checkDPStatus",
    }
  );

  useEffect(() => {
    if (!searchedDataProductId) history.push("/home");
  }, [history, searchedDataProductId]);

  useEffect(() => {
    socket.on("deleteDataProductResponse", () => {
      history.push("/home");
      GlobalNotificationHandle(
        250,
        "Since Data product is deleted, you have been redirected",
        true,
        true
      );
    });
  }, [history]);

  useEffect(() => {
    if (searchedDataProductId) {
      dispatch(getDataproductDetails(searchedDataProductId));
      dispatch(getRequiredDataProducts(searchedDataProductId));
      dispatch(getRequiredDataSources(searchedDataProductId));
    }
  }, [searchedDataProductId, dispatch]);

  useEffect(() => {
    if (searchedDataProductId && isDataOutOfSync) {
      dispatch(getDataproductDetails(searchedDataProductId));
      dispatch(getRequiredDataProducts(searchedDataProductId));
      dispatch(getRequiredDataSources(searchedDataProductId));
    }
  }, [searchedDataProductId, dispatch, isDataOutOfSync]);

  const {
    selectedDataProduct,
    requiredDataProducts,
    requiredDataSources,
    dataProductTypesList,
    allRoles,
    usersByDomain,
  } = useSelector((state) => ({
    selectedDataProduct: state.DataProducts,
    requiredDataProducts: state.DataProducts.getRequiredDataProducts,
    requiredDataSources: state.DataSource.getRequiredDataSources,
    dataProductTypesList:
      state.DataproductTypes.allDataProductTypes?.dataProductTypeList?.map(
        (dpTypeDetails) => ({
          label: dpTypeDetails.name,
          value: dpTypeDetails.id,
          isRemoved: dpTypeDetails.isRemoved,
        })
      ),
    allRoles: state.Roles.allRoles?.roleList?.filter(
      (roleDetails) => !roleDetails?.isRemoved
    ),
    usersByDomain: state.Users.usersByDomain?.userList,
  }));

  // IF DATA PRODUCT NOT FOUND / WRONG UUID IS ENTERED IN URL GO TO /page-not-found
  useEffect(() => {
    if (selectedDataProduct?.DataProductNotFound) {
      history.push("/page-not-found");
    }
  }, [selectedDataProduct, dispatch]);

  useEffect(() => {
    dispatch(getAllRoles());
    dispatch(getAllDataProductTypes());
  }, [dispatch]);

  useEffect(() => {
    const fetchedDataProductDetails = selectedDataProduct?.DataProductDetails;
    if (
      fetchedDataProductDetails &&
      fetchedDataProductDetails?.id !== dataProductDetails?.id
    ) {
      setCurrentDomainId(fetchedDataProductDetails[DATAPRODUCT_DOMAIN_ID]);
      setDataProductDetails(fetchedDataProductDetails);
      if (fetchedDataProductDetails?.isCollibra) {
        const owner = fetchedDataProductDetails?.ownerArray
          ?.map(({ firstName, lastName }) => `${firstName} ${lastName}`)
          .join(", ");
        setCollibraDetails({ ...selectedDataProduct, owner });
      } else {
        dispatch(
          getUsersByDomainId(fetchedDataProductDetails[DATAPRODUCT_DOMAIN_ID])
        );
      }
    }
    if (pageCurrentState === pageStateOptions.DISPLAY) {
      const ownerDetials = dataProductDetails?.teams?.filter((eachMemeber) => {
        return (
          eachMemeber.roleName === DATA_PRODUCT_OWNER_ROLE &&
          eachMemeber.userId === userDetails?.id
        );
      });
      setIsOwner(ownerDetials?.length > 0 || isAdmin ? true : false);
    }
  }, [
    isAdmin,
    selectedDataProduct,
    userDetails,
    dataProductDetails,
    pageCurrentState,
    dispatch,
  ]);

  useEffect(() => {
    const fetchConnectedUsecases = selectedDataProduct?.ConnectedUseCases;
    if (fetchConnectedUsecases) {
      const tempUseCaseNames = [];
      const tempUseCaseHrefs = [];
      const tempUseCaseIds = [];
      const tempLvtUrl = [];
      const tempLvtTypeNames = [];
      const dpimUrl = `/dataproduct-interaction-map?`;
      const lvtUrl = "/leanvaluetree?";
      fetchConnectedUsecases.forEach((useCase) => {
        tempUseCaseIds.push(useCase.id);
        tempUseCaseNames.push(useCase.description);
        if (useCase.teamSpaceId) {
          tempUseCaseHrefs.push(
            `${dpimUrl}useCaseId=${useCase.id}&teamSpaceId=${useCase.teamSpaceId}`
          );
          if (tempLvtTypeNames.indexOf("TeamSpace LVT") === -1) {
            tempLvtUrl.push(`${lvtUrl}teamSpaceId=${useCase.teamSpaceId}`);
            tempLvtTypeNames.push("TeamSpace LVT");
          }
        } else {
          tempUseCaseHrefs.push(
            `${dpimUrl}useCaseId=${useCase.id}&domainId=${useCase.domainId}`
          );
          if (tempLvtTypeNames.indexOf("Domain LVT") === -1) {
            tempLvtUrl.push(`${lvtUrl}domainId=${useCase.domainId}`);
            tempLvtTypeNames.push("Domain LVT");
          }
        }
      });
      setUseCaseNames(tempUseCaseNames);
      setLvtTypeNames(tempLvtTypeNames);
      setUseCaseIds(tempUseCaseIds);
      setUseCaseHrefs(tempUseCaseHrefs);
      setLvtUrls(tempLvtUrl);
    }
  }, [selectedDataProduct]);

  useEffect(() => {
    socket.emit("getLVTAndDPIMItems", {
      useCaseId: useCaseIds[0],
      domainId: currentDomainId,
    });

    return () => {
      socket.emit(
        "updateDPDetails",
        { isEditing: false, useCaseIds },
        socket.lastJoinedRoom
      );

      socket.emit(
        "reviewDPDetails",
        { isEditing: false },
        socket.lastJoinedRoom
      );

      setAutofillDescription("");
      setAutofillValueStatement("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useCaseIds, currentDomainId]);

  socket.on("receiveLVTAndDPIMItems", (response) => {
    if (response !== null && response !== undefined) {
      autofillDataProductDescription(response?.dpimItems);
      autofillDataProductValueStatement(response);
    }
  });

  useEffect(() => {
    socket.on("updateChangesInDPDetails", (response) => {
      if (
        response.update &&
        selectedDataProduct?.DataProductDetails[DATAPRODUCT_STATUS] ===
          DATAPRODUCT_STATUS_OPTIONS.IN_DESIGN
      ) {
        setAutofillDescription("");
        setAutofillValueStatement("");
        socket.emit("getLVTAndDPIMItems", {
          useCaseId: useCaseIds[0],
          domainId: currentDomainId,
        });
      }
    });

    return () => {
      socket.off("updateChangesInDPDetails");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, currentDomainId, useCaseIds]);

  useEffect(() => {
    socket.emit("getDataProductLineageDetails", {
      productId: searchedDataProductId,
    });
    socket.on("receiveDataProductLineageDetails", (response) => {
      setlineageData(response);
    });
    return function () {
      socket.off("receiveDataProductLineageDetails");
    };
  }, [searchedDataProductId]);

  useEffect(() => {
    const handleAutoFill = (value, autofillValue, setValue, fieldState) => {
      if (!value || value.trim() === "") {
        // Check for different page states
        if (pageCurrentState !== fieldState) {
          setValue(autofillValue);
        }
      } else {
        setValue(value);
      }
    };

    const dataProductDescription =
      dataProductDetails?.[DATAPRODUCT_DESCRIPTION];
    const collibraDescription =
      collibraDetails?.dataProductDetails?.description;
    const combinedDescription = collibraDescription || dataProductDescription;

    handleAutoFill(
      combinedDescription,
      autofillDescription,
      setDescription,
      pageStateOptions.EDIT
    );

    const dataProductValueStatement =
      dataProductDetails?.[DATAPRODUCT_VALUE_STATEMENT];

    handleAutoFill(
      dataProductValueStatement,
      autofillValueStatement,
      setValueStatement,
      pageStateOptions.REVIEW
    );
  }, [
    dataProductDetails,
    collibraDetails?.dataProductDetails,
    autofillDescription,
    autofillValueStatement,
    pageCurrentState,
  ]);

  const autofillDataProductDescription = (response) => {
    const dataProductInteractionMapItems = response.data;
    const dataProductsInDPIM = response.dataProducts;
    const hasDPIMItems = response.relationships;

    const currentDataProduct = dataProductsInDPIM.find(
      (dp) => dp.id === searchedDataProductId
    );
    const currentDataProductName = currentDataProduct?.data.label;
    const currentDataProductId = currentDataProduct?.id;
    const currentDataProductType = currentDataProduct?.parentNode;

    let description = "";

    // Sources connected to Data Entities connected to Data Product
    const sourcesConnectedToDataEntitiesConnectedToDataProduct =
      getSourcesConnectedToDataEntitiesConnectedToDataProduct(
        dataProductInteractionMapItems,
        hasDPIMItems,
        currentDataProductId
      );

    // Sources connected directly to Data Product
    const sourcesConnectedDirectlyToDataProduct =
      getSourcesConnectedDirectlyToDataProduct(
        dataProductInteractionMapItems,
        hasDPIMItems,
        currentDataProductId
      );

    //this should be filled in only for ADP and SODP
    if (
      (sourcesConnectedToDataEntitiesConnectedToDataProduct ||
        sourcesConnectedDirectlyToDataProduct) &&
      currentDataProductType !== "Consumer Oriented Data Product"
    ) {
      description += `${currentDataProductName} Data Product provides data around `;

      if (sourcesConnectedToDataEntitiesConnectedToDataProduct) {
        description += sourcesConnectedToDataEntitiesConnectedToDataProduct
          .map(
            ({ dataEntity, sources }) =>
              `${dataEntity.trim()} (from ${sources
                .map((s) => s.trim())
                .join(", ")})`
          )
          .join(", ");
      }

      if (sourcesConnectedDirectlyToDataProduct) {
        description +=
          ", " +
          sourcesConnectedDirectlyToDataProduct
            .map((source) => `${"_____"} (from ${source.trim()})`)
            .join(", ");
      }
      description += ". ";
    }

    // Data Product connected to Consumers connected to Data Product Job
    const dataProductsJobsConnectedToConsumersConnectedToDataProduct =
      getDataProductsJobsConnectedToConsumersConnectedToDataProduct(
        dataProductInteractionMapItems,
        hasDPIMItems,
        currentDataProductId
      );

    // Data Product connected directly to Data Product Job
    const dataProductJobsConnectedDirectlyToDataProduct =
      getDataProductJobsConnectedDirectlyToDataProduct(
        dataProductInteractionMapItems,
        hasDPIMItems,
        currentDataProductId
      );

    if (
      dataProductsJobsConnectedToConsumersConnectedToDataProduct ||
      dataProductJobsConnectedDirectlyToDataProduct
    ) {
      if (
        (sourcesConnectedToDataEntitiesConnectedToDataProduct ||
          sourcesConnectedDirectlyToDataProduct) &&
        currentDataProductType === "Source Oriented Data Product"
      )
        description += `\n`;
      description += `${currentDataProductName} Data Product was designed for: `;
      if (dataProductsJobsConnectedToConsumersConnectedToDataProduct) {
        description +=
          dataProductsJobsConnectedToConsumersConnectedToDataProduct
            .map(
              ({ dataProductJob, consumers }) =>
                `${dataProductJob.trim()} (for ${consumers
                  .map((c) => c.trim())
                  .join(", ")})`
            )
            .join(", ");
      }

      if (dataProductJobsConnectedDirectlyToDataProduct) {
        if (dataProductsJobsConnectedToConsumersConnectedToDataProduct)
          description += ", ";
        description += dataProductJobsConnectedDirectlyToDataProduct
          .map((dataProductJob) => `${dataProductJob.trim()}`)
          .join(", ");
      }

      description += ". ";
    }

    // Data Product connected to other data products
    const connectedDataProducts = getConnectedDataProducts(
      dataProductInteractionMapItems,
      dataProductsInDPIM,
      hasDPIMItems,
      currentDataProductId
    );

    if (
      connectedDataProducts &&
      currentDataProductType === "Aggregate Data Product"
    ) {
      description += `${currentDataProductName} Data Product provides aggregated view based on data around `;
      if (
        connectedDataProducts.sourcesConnectedToDataEntitiesConnectedToDataProduct
      ) {
        description +=
          connectedDataProducts.sourcesConnectedToDataEntitiesConnectedToDataProduct
            .map(
              ({ dataProduct, data }) =>
                `${data
                  .map(
                    ({ dataEntity }) =>
                      `${dataEntity.trim()} (from ${dataProduct.trim()})`
                  )
                  .join(", ")}`
            )
            .join(", ");
      }
      if (connectedDataProducts.sourcesConnectedDirectlyToDataProduct) {
        if (
          connectedDataProducts.sourcesConnectedToDataEntitiesConnectedToDataProduct
        )
          description += ", ";
        description +=
          connectedDataProducts.sourcesConnectedDirectlyToDataProduct
            .map(
              ({ dataProduct, data }) =>
                `${data
                  .map((_) => `${"_____"} (from ${dataProduct.trim()})`) // eslint-disable-line no-unused-vars
                  .join(", ")}`
            )
            .join(", ");
      }
      description += ".";
    }

    if (description === "") return null;

    setAutofillDescription(description);
    return description;
  };

  const getSourcesConnectedToDataEntitiesConnectedToDataProduct = (
    dataProductInteractionMapItems,
    relationships,
    currentDataProductId
  ) => {
    const sourceIdsFromRelationshipsConnectedToDataProduct = relationships
      .filter((rel) => rel.data.targetId === currentDataProductId)
      .map((rel) => rel.data.sourceId);

    const dataEntitiesConnectedToDataProduct = dataProductInteractionMapItems
      .filter((item) =>
        sourceIdsFromRelationshipsConnectedToDataProduct.includes(item.id)
      )
      .filter((item) => item.data.step === "data-entites");
    if (dataEntitiesConnectedToDataProduct.length === 0) return null;

    const iDsOfDataEntities = dataEntitiesConnectedToDataProduct.map(
      (de) => de.id
    );

    const relationshipsBetweenDataEntityAndSources = relationships.filter(
      (rel) => iDsOfDataEntities.includes(rel.data.targetId)
    );

    const sourceIdsFromRelationshipsConnectedToDataEntities =
      relationshipsBetweenDataEntityAndSources.map((rel) => rel.data.sourceId);
    const dataSourcesConnectedToDataEntities = dataProductInteractionMapItems
      .filter((item) =>
        sourceIdsFromRelationshipsConnectedToDataEntities.includes(item.id)
      )
      .filter((item) => item.data.step === "sources");

    if (dataSourcesConnectedToDataEntities.length === 0) return null;

    const finalData = dataEntitiesConnectedToDataProduct.map((dataEntity) => {
      const idsOfDataSourcesFromRelationships =
        relationshipsBetweenDataEntityAndSources
          .filter((rel) => rel.data.targetId === dataEntity.id)
          .map((rel) => rel.data.sourceId);
      const dataSources = dataSourcesConnectedToDataEntities
        .filter((dataSource) =>
          idsOfDataSourcesFromRelationships.includes(dataSource.id)
        )
        .map((dataSource) => dataSource.data.label);

      return {
        dataEntity: dataEntity.data.label,
        sources: dataSources,
      };
    });

    return finalData;
  };

  const getSourcesConnectedDirectlyToDataProduct = (
    dataProductInteractionMapItems,
    relationships,
    currentDataProductId
  ) => {
    const sourceIdsFromRelationshipsConnectedToDataProduct = relationships
      .filter((rel) => rel.data.targetId === currentDataProductId)
      .map((rel) => rel.data.sourceId);
    const dataSourcesConnectedToDataProduct = dataProductInteractionMapItems
      .filter((item) =>
        sourceIdsFromRelationshipsConnectedToDataProduct.includes(item.id)
      )
      .filter((item) => item.data.step === "sources")
      .map((dataSource) => dataSource.data.label);

    if (dataSourcesConnectedToDataProduct.length === 0) return null;

    return dataSourcesConnectedToDataProduct;
  };

  const getDataProductsJobsConnectedToConsumersConnectedToDataProduct = (
    dataProductInteractionMapItems,
    relationships,
    currentDataProductId
  ) => {
    const targetIdsFromRelationshipsConnectedFromDataProduct = relationships
      .filter((rel) => rel.data.sourceId === currentDataProductId)
      .map((rel) => rel.data.targetId);

    const consumersConnectedFromDataProduct = dataProductInteractionMapItems
      .filter((item) =>
        targetIdsFromRelationshipsConnectedFromDataProduct.includes(item.id)
      )
      .filter((item) => item.data.step === "operational-consumer");

    if (consumersConnectedFromDataProduct.length === 0) return null;

    const iDsOfConsumers = consumersConnectedFromDataProduct.map(
      (con) => con.id
    );

    const relationshipsBetweenConsumersAndDataProductJobs =
      relationships.filter((rel) => iDsOfConsumers.includes(rel.data.sourceId));

    const targetIdsFromRelationshipsConnectedFromDataProductJobs =
      relationshipsBetweenConsumersAndDataProductJobs.map(
        (rel) => rel.data.targetId
      );
    const dataProductJobsConnectedFromConsumers = dataProductInteractionMapItems
      .filter((item) =>
        targetIdsFromRelationshipsConnectedFromDataProductJobs.includes(item.id)
      )
      .filter((item) => item.data.step === "operational-dp-job");

    if (dataProductJobsConnectedFromConsumers.length === 0) return null;

    const finalData = dataProductJobsConnectedFromConsumers.map((dpJob) => {
      const iDsOfConsumersFromRelationships =
        relationshipsBetweenConsumersAndDataProductJobs
          .filter((rel) => rel.data.targetId === dpJob.id)
          .map((rel) => rel.data.sourceId);
      const consumers = consumersConnectedFromDataProduct
        .filter((consumer) =>
          iDsOfConsumersFromRelationships.includes(consumer.id)
        )
        .map((consumer) => consumer.data.label);

      return {
        dataProductJob: dpJob.data.label,
        consumers,
      };
    });

    return finalData;
  };

  const getDataProductJobsConnectedDirectlyToDataProduct = (
    dataProductInteractionMapItems,
    relationships,
    currentDataProductId
  ) => {
    const targetIdsFromRelationshipsConnectedFromDataProduct = relationships
      .filter((rel) => rel.data.sourceId === currentDataProductId)
      .map((rel) => rel.data.targetId);
    const dataProductJobsConnectedDirectlyToDataProduct =
      dataProductInteractionMapItems
        .filter((item) =>
          targetIdsFromRelationshipsConnectedFromDataProduct.includes(item.id)
        )
        .filter((item) => item.data.step === "operational-dp-job")
        .map((dpJob) => dpJob.data.label);

    if (dataProductJobsConnectedDirectlyToDataProduct.length === 0) return null;
    return dataProductJobsConnectedDirectlyToDataProduct;
  };

  const getConnectedDataProducts = (
    dataProductInteractionMapItems,
    dataProductsInDPIM,
    relationships,
    currentDataProductId
  ) => {
    const sourceIdsFromRelationshipsConnectedToDataProduct = relationships
      .filter((rel) => rel.data.targetId === currentDataProductId)
      .map((rel) => rel.data.sourceId);

    const dataProductsConnectedToDataProduct = dataProductsInDPIM.filter((dp) =>
      sourceIdsFromRelationshipsConnectedToDataProduct.includes(dp.id)
    );

    if (!dataProductsConnectedToDataProduct) return null;

    const sourcesConnectedToDataEntitiesConnectedToDataProduct =
      dataProductsConnectedToDataProduct
        .map((dataProduct) => ({
          dataProduct: dataProduct.data.label,
          data: getSourcesConnectedToDataEntitiesConnectedToDataProduct(
            dataProductInteractionMapItems,
            relationships,
            dataProduct.id
          ),
        }))
        .filter((data) => data.data !== null);

    const sourcesConnectedDirectlyToDataProduct =
      dataProductsConnectedToDataProduct
        .map((dataProduct) => ({
          dataProduct: dataProduct.data.label,
          data: getSourcesConnectedDirectlyToDataProduct(
            dataProductInteractionMapItems,
            relationships,
            dataProduct.id
          ),
        }))
        .filter((data) => data.data !== null);

    return {
      sourcesConnectedToDataEntitiesConnectedToDataProduct:
        sourcesConnectedToDataEntitiesConnectedToDataProduct.toString().length >
        0
          ? sourcesConnectedToDataEntitiesConnectedToDataProduct
          : null,
      sourcesConnectedDirectlyToDataProduct:
        sourcesConnectedDirectlyToDataProduct.toString().length > 0
          ? sourcesConnectedDirectlyToDataProduct
          : null,
    };
  };

  const autofillDataProductValueStatement = (response) => {
    const leanValueTrees = response?.lvtItems.lvt;
    const hasLVTConnections = response?.lvtItems.hasLVTConnections;

    const dataProductInteractionMapItems = response?.dpimItems.data;
    const dataProductsInDPIM = response?.dpimItems.dataProducts;
    const hasDPIMItems = response?.dpimItems.relationships;

    const currentDataProduct = dataProductsInDPIM.find(
      (dp) => dp.id === searchedDataProductId
    );
    const currentDataProductName = currentDataProduct?.data.label;
    const currentDataProductId = currentDataProduct?.id;
    const currentDataProductType = currentDataProduct?.parentNode;

    let valueStatement = "";
    valueStatement += `${currentDataProductName} Data Product delivers data`;

    // Fetching data product jobs connected to Data Product
    // Data Product connected to Consumers connected to Data Product Job
    const dataProductsJobsConnectedToConsumersConnectedToDataProduct =
      getDataProductsJobsConnectedToConsumersConnectedToDataProduct(
        dataProductInteractionMapItems,
        hasDPIMItems,
        currentDataProductId
      );

    // Data Product connected directly to Data Product Job
    const dataProductJobsConnectedDirectlyToDataProduct =
      getDataProductJobsConnectedDirectlyToDataProduct(
        dataProductInteractionMapItems,
        hasDPIMItems,
        currentDataProductId
      );

    // Sources connected to Data Entities connected to Data Product
    const sourcesConnectedToDataEntitiesConnectedToDataProduct =
      getSourcesConnectedToDataEntitiesConnectedToDataProduct(
        dataProductInteractionMapItems,
        hasDPIMItems,
        currentDataProductId
      );

    // Sources connected directly to Data Product
    const sourcesConnectedDirectlyToDataProduct =
      getSourcesConnectedDirectlyToDataProduct(
        dataProductInteractionMapItems,
        hasDPIMItems,
        currentDataProductId
      );

    const lvtConnectedWithUseCases = getLvtConnectedWithUseCases(
      leanValueTrees,
      hasLVTConnections
    );

    if (
      (dataProductsJobsConnectedToConsumersConnectedToDataProduct ||
        dataProductJobsConnectedDirectlyToDataProduct) &&
      (sourcesConnectedToDataEntitiesConnectedToDataProduct ||
        sourcesConnectedDirectlyToDataProduct)
    ) {
      if (currentDataProductType !== "Consumer Oriented Data Product") {
        // for ADPs and SODPs if both jobs and sources are connected
        valueStatement += ` facilitating data-driven decisions such as: `;
        if (dataProductsJobsConnectedToConsumersConnectedToDataProduct) {
          valueStatement +=
            dataProductsJobsConnectedToConsumersConnectedToDataProduct
              .map(({ dataProductJob }) => `${dataProductJob.trim()}`)
              .join(", ");
        }

        if (dataProductJobsConnectedDirectlyToDataProduct) {
          valueStatement +=
            ", " +
            dataProductJobsConnectedDirectlyToDataProduct
              .map((dataProductJob) => `${dataProductJob.trim()}`)
              .join(", ");
        }

        valueStatement += ". ";

        if (lvtConnectedWithUseCases) {
          valueStatement +=
            `This insight helps to verify hypotheses like: ` +
            lvtConnectedWithUseCases
              .map(
                ({ hypotheses, goals }) =>
                  `${hypotheses
                    .map((h) => h.trim())
                    .join(", ")}, which contribute to overarching domain ${
                    goals.length > 1 ? "goals" : "goal"
                  }: ${goals.map((g) => g.trim()).join(", ")}`
              )
              .join(", ") +
            ".";
        }
      } else {
        // for CODPs if both jobs and sources are connected
        if (lvtConnectedWithUseCases) {
          valueStatement +=
            ` which can be used to build insight: This Data Product was designed to support the ${
              lvtConnectedWithUseCases.length > 1 ? "use cases" : "use case"
            }` +
            lvtConnectedWithUseCases
              .map(
                ({ useCase, hypotheses }) =>
                  ` ${useCase.trim()} (which aims to verify the hypothesis: ${hypotheses
                    .map((h) => h.trim())
                    .join(", ")})`
              )
              .join(", ") +
            ". ";
          valueStatement +=
            `This contributes to overarching domain ` +
            lvtConnectedWithUseCases
              .map(
                ({ goals }) =>
                  `${goals.length > 1 ? "goals" : "goal"}: ${goals
                    .map((g) => g.trim())
                    .join(", ")}`
              )
              .join(", ") +
            ".";
        }
      }
    } else {
      if (
        dataProductsJobsConnectedToConsumersConnectedToDataProduct ||
        dataProductJobsConnectedDirectlyToDataProduct
      ) {
        valueStatement += ` facilitating data-driven decisions such as: `;
        if (dataProductsJobsConnectedToConsumersConnectedToDataProduct) {
          valueStatement +=
            dataProductsJobsConnectedToConsumersConnectedToDataProduct
              .map(({ dataProductJob }) => `${dataProductJob.trim()}`)
              .join(", ");
        }

        if (dataProductJobsConnectedDirectlyToDataProduct) {
          valueStatement +=
            ", " +
            dataProductJobsConnectedDirectlyToDataProduct
              .map((dataProductJob) => `${dataProductJob.trim()}`)
              .join(", ");
        }

        valueStatement += ". ";

        if (lvtConnectedWithUseCases) {
          valueStatement +=
            `This insight helps to verify hypotheses like: ` +
            lvtConnectedWithUseCases
              .map(
                ({ hypotheses, goals }) =>
                  `${hypotheses
                    .map((h) => h.trim())
                    .join(", ")}, which contribute to overarching domain ${
                    goals.length > 1 ? "goals" : "goal"
                  }: ${goals.map((g) => g.trim()).join(", ")}`
              )
              .join(", ");
        }
      }

      if (
        sourcesConnectedToDataEntitiesConnectedToDataProduct ||
        sourcesConnectedDirectlyToDataProduct
      ) {
        if (lvtConnectedWithUseCases) {
          valueStatement +=
            ` which can be used to build insight: This Data Product was designed to support the ${
              lvtConnectedWithUseCases.length > 1 ? "use cases" : "use case"
            }` +
            lvtConnectedWithUseCases
              .map(
                ({ useCase, hypotheses }) =>
                  ` ${useCase.trim()} (which aims to verify the hypothesis: ${hypotheses
                    .map((h) => h.trim())
                    .join(", ")})`
              )
              .join(", ") +
            ". ";
          valueStatement +=
            `This contributes to overarching domain ` +
            lvtConnectedWithUseCases
              .map(
                ({ goals }) =>
                  `${goals.length > 1 ? "goals" : "goal"}: ${goals
                    .map((g) => g.trim())
                    .join(", ")}`
              )
              .join(", ") +
            ".";
        }
      }
    }

    setAutofillValueStatement(valueStatement);
    return valueStatement;
  };

  const getLvtConnectedWithUseCases = (leanValueTrees, hasLVTConnections) => {
    const currentUseCase = leanValueTrees
      .filter((lvt) => lvt.id === useCaseIds[0])
      .filter((data) => data.data.step === "useCase");

    if (currentUseCase.length === 0) return null;

    const currentUseCaseId = currentUseCase.map((uc) => uc.id);

    const hasLVTConnectionWithHypothesisAndUseCase = hasLVTConnections
      .filter((con) => currentUseCaseId.includes(con.target))
      .map((con) => con.source);
    const hypothesesConnectedToUseCase = leanValueTrees
      .filter((lvt) =>
        hasLVTConnectionWithHypothesisAndUseCase.includes(lvt.id)
      )
      .filter((data) => data.data.step === "hypothesis");

    const hypothesesIds = hypothesesConnectedToUseCase.map((h) => h.id);

    const hasLVTConnectionWithGoalsAndHypothesis = hasLVTConnections
      .filter((con) => hypothesesIds.includes(con.target))
      .map((con) => con.source);
    const goalsConnectedToUseCase = leanValueTrees
      .filter((lvt) => hasLVTConnectionWithGoalsAndHypothesis.includes(lvt.id))
      .filter((data) => data.data.step === "goals");

    const finalData = currentUseCase.map((uc) => {
      return {
        useCase: uc.data.label,
        hypotheses: hypothesesConnectedToUseCase.map((data) => data.data.label),
        goals: goalsConnectedToUseCase.map((data) => data.data.label),
      };
    });

    return finalData;
  };

  const getAllDPIMStatusValidation = useCallback(() => {
    const fetchConnectedUsecases = selectedDataProduct?.ConnectedUseCases;
    const isValid = fetchConnectedUsecases?.find(
      (useCase) => useCase.dpimStatus !== DPIM_SUBMITTED
    );
    return isValid === undefined ? true : false;
  }, [selectedDataProduct]);

  const convertTeamsDataForRoles = useCallback(() => {
    if (collibraDetails?.owner) {
      return { "Data Product Owner": [{ userName: collibraDetails.owner }] };
    }

    const teamsData = dataProductDetails?.[DATAPRODUCT_TEAMS];
    const roleObject = {};
    if (teamsData !== undefined && teamsData !== null) {
      teamsData.forEach((member) => {
        if (roleObject[member.roleName] !== undefined)
          roleObject[member.roleName].push(member);
        else roleObject[member.roleName] = [member];
      });
      return roleObject;
    } else return {};
  }, [dataProductDetails, collibraDetails]);

  const onChangeHandler = (value, fieldName) => {
    const isFieldEmpty = value.trim() === "";
    const commonStates = [pageStateOptions.REVIEW, pageStateOptions.EDIT];

    setDataProductDetails((prevData) => ({ ...prevData, [fieldName]: value }));

    const fieldActions = {
      [DATAPRODUCT_DESCRIPTION]: () => {
        setDescription(value);
        if (pageCurrentState === pageStateOptions.EDIT) {
          setShowError(isFieldEmpty);
        }
      },
      [DATAPRODUCT_VALUE_STATEMENT]: () => {
        setValueStatement(value);
        if (pageCurrentState === pageStateOptions.REVIEW) {
          setShowError(isFieldEmpty);
        }
      },
      [DATAPRODUCT_NAME]: () => {
        if (commonStates.includes(pageCurrentState)) {
          setDataProductError(isFieldEmpty);
        }
      },
    };

    if (fieldActions[fieldName]) {
      fieldActions[fieldName]();
    }
  };

  const dataProductTypeHandler = (value) => {
    const selectedDPTypeDetails = dataProductTypesList?.find(
      (type) => type.value === value
    );
    setDataProductDetails((prevData) => {
      return {
        ...prevData,
        [DATAPRODUCT_TYPE]: selectedDPTypeDetails?.label,
        [DATAPRODUCT_TYPE_ID]: value,
      };
    });
  };

  const teamMembersHandler = (action, data) => {
    switch (action) {
      case "add":
        setDataProductDetails((prevData) => {
          const teamsList = prevData?.[DATAPRODUCT_TEAMS];
          const newUserName =
            usersByDomain?.find((user) => user.id === data?.userId)?.name || "";
          let newUserRoleName = "";
          if (data?.roleName !== undefined) newUserRoleName = data?.roleName;
          else
            newUserRoleName =
              allRoles.find((role) => role.id === data?.roleId)?.name || "";
          const newUserData = {
            ...data,
            userName: newUserName,
            roleName: newUserRoleName,
          };
          return {
            ...prevData,
            [DATAPRODUCT_TEAMS]: [...teamsList, newUserData],
          };
        });
        break;
      case "deleteUser":
        setDataProductDetails((prevData) => {
          const teamsList = prevData?.[DATAPRODUCT_TEAMS]?.filter(
            (member) =>
              !(
                member.userId === data?.userId && member.roleId === data?.roleId
              )
          );
          return { ...prevData, [DATAPRODUCT_TEAMS]: teamsList };
        });
        break;
      case "deleteRole":
        setDataProductDetails((prevData) => {
          const teamsList = prevData?.[DATAPRODUCT_TEAMS]?.filter(
            (member) => !(member.roleId === data?.roleId)
          );
          return { ...prevData, [DATAPRODUCT_TEAMS]: teamsList };
        });
        break;
      case "update":
        setDataProductDetails((prevData) => {
          const teamsList = prevData?.[DATAPRODUCT_TEAMS]?.map((member) => {
            if (member.userId === data?.userId) {
              const roleName =
                allRoles.find((role) => role.id === data?.roleId)?.name || "";
              return { ...member, roleId: data?.roleId, roleName };
            } else return member;
          });
          return { ...prevData, [DATAPRODUCT_TEAMS]: teamsList };
        });
        break;
      case "updateOwner":
        setDataProductDetails((prevData) => {
          const teamsList = prevData?.[DATAPRODUCT_TEAMS]?.filter(
            (member) => member.roleId !== data?.roleId
          );
          const newUserName =
            usersByDomain?.find((user) => user.id === data?.userId)?.name || "";
          let newUserRoleName = "";
          if (data?.roleName !== undefined) newUserRoleName = data?.roleName;
          else
            newUserRoleName =
              allRoles.find((role) => role.id === data?.roleId)?.name || "";
          const newUserData = {
            ...data,
            userName: newUserName,
            roleName: newUserRoleName,
          };
          return {
            ...prevData,
            [DATAPRODUCT_TEAMS]: [...teamsList, newUserData],
            [DATAPRODUCT_OWNER_NAME]: newUserName,
          };
        });
        break;
      case "import":
        setDataProductDetails((prevData) => {
          const { teamMembers, ownerMemberName } = data;
          return {
            ...prevData,
            [DATAPRODUCT_TEAMS]: teamMembers,
            [DATAPRODUCT_OWNER_NAME]: ownerMemberName,
          };
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    socket.lastJoinedRoom = `DP-${params.get("dataproductId")}`;
    socket.emit("joinRoom", socket.lastJoinedRoom);
    socket.emit("checkEditStatus", {}, socket.lastJoinedRoom);

    return () => {
      socket.emit("leaveRoom", socket.lastJoinedRoom);
      socket.lastJoinedRoom = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // update changes after edit
  socket.on("syncDPDetails", (payload) => {
    if (payload.isEditing) {
      setIsDPDetailsEditing(true);
    } else {
      setIsDPDetailsEditing(false);
    }
    if (payload.dataOutOfSync) {
      setDataProductDetails({});
      setIsDataOutOfSync(true);
    } else {
      setIsDataOutOfSync(false);
    }
  });

  //update changes after changes in review
  socket.on("syncReviewDPDetails", (payload) => {
    setIsDPDetailsInReview(payload.isEditing);
    if (payload.dataOutOfSync) {
      setDataProductDetails({});
      setIsDataOutOfSync(true);
    } else {
      setIsDataOutOfSync(false);
    }
  });

  const isInReviewState = () => {
    socket.emit(
      "reviewDPDetails",
      { isEditing: true, dataOutOfSync: true },
      socket.lastJoinedRoom
    );
  };

  //to check if any one is editing
  socket.on("checkEditResponse", () => {
    if (pageCurrentState === pageStateOptions.EDIT) {
      socket.emit(
        "updateDPDetails",
        { isEditing: true },
        socket.lastJoinedRoom
      );
    }

    if (pageCurrentState === pageStateOptions.REVIEW) {
      socket.emit(
        "reviewDPDetails",
        { isEditing: true },
        socket.lastJoinedRoom
      );
    }
  });

  const updateDataProductDetails = async () => {
    let isSuccess = true;
    try {
      if (isOwner) {
        updateTeamMembers();
      }

      const newValues = {
        dataProductId: dataProductDetails[DATAPRODUCT_ID],
        name: dataProductDetails[DATAPRODUCT_NAME],
        description: dataProductDetails[DATAPRODUCT_DESCRIPTION],
        domainId: dataProductDetails[DATAPRODUCT_DOMAIN_ID],
        dataProductTypeId: dataProductDetails[DATAPRODUCT_TYPE_ID],
        valueStatement: dataProductDetails[DATAPRODUCT_VALUE_STATEMENT],
      };
      if (pageCurrentState === pageStateOptions.REVIEW) {
        if (dataProductDetails[DATAPRODUCT_DESCRIPTION] === "")
          newValues.description = autofillDescription;
        if (dataProductDetails[DATAPRODUCT_VALUE_STATEMENT] === "")
          newValues.valueStatement = autofillValueStatement;
      }

      socket.emit(
        "updateDPDetails",
        { isEditing: false, dataOutOfSync: true, useCaseIds },
        socket.lastJoinedRoom
      );
      socket.emit(
        "reviewDPDetails",
        { isEditing: false, dataOutOfSync: true },
        socket.lastJoinedRoom
      );

      await updateDataProductAPI(newValues);
    } catch (e) {
      isSuccess = false;
    }

    return isSuccess;
  };

  const updateDataProductAPI = async (newValues) => {
    try {
      const response = await AXIOS_INSTANCE.put(
        `${DATA_PRODUCTS_API}/update/${dataProductDetails[DATAPRODUCT_ID]}`,
        newValues
      );
      GlobalNotificationHandle(
        response.status,
        response.data.data.message,
        true,
        true,
        true
      );
    } catch (e) {
      GlobalNotificationHandle(
        e.response.status,
        e.response.data,
        false,
        true,
        true
      );
      throw new Error(e.response.data);
    }
  };

  const updateTeamMembers = async () => {
    try {
      await AXIOS_INSTANCE.put(
        `${DATA_PRODUCTS_API}/updateMembers/${dataProductDetails[DATAPRODUCT_ID]}`,
        {
          members: dataProductDetails[DATAPRODUCT_TEAMS],
        }
      );
    } catch (e) {
      GlobalNotificationHandle(
        e.response.status,
        e.response.data.errorDescription,
        false,
        true
      );
    }
  };

  const pageStateHandler = async (state) => {
    switch (state) {
      case pageStateOptions.DISPLAY:
        setPageCurrentState(pageStateOptions.DISPLAY);
        break;
      case pageStateOptions.EDIT:
        if (isDPDetailsEditing) {
          GlobalNotificationHandle(
            500,
            "This Data Product is currently being edited by another user.",
            false,
            true,
            true
          );
          setPageCurrentState(pageStateOptions.DISPLAY);
          break;
        }
        socket.emit(
          "updateDPDetails",
          { isEditing: true },
          socket.lastJoinedRoom
        );
        setPageCurrentState(pageStateOptions.EDIT);
        break;
      case pageStateOptions.SAVE:
        // eslint-disable-next-line no-case-declarations
        const isSuccess = await updateDataProductDetails();
        if (isSuccess) {
          setPageCurrentState(pageStateOptions.DISPLAY);
          setShowError(false);
          setDataProductError(false);
        }
        break;
      case pageStateOptions.REVIEW:
        if (!isDPDetailsInReview) {
          socket.emit(
            "reviewDPDetails",
            { isEditing: true },
            socket.lastJoinedRoom
          );
        }
        if (
          dataProductDetails?.[DATAPRODUCT_STATUS] !==
          DATAPRODUCT_STATUS_OPTIONS.IN_REVIEW
        ) {
          updateDataProductStatus(DATAPRODUCT_STATUS_OPTIONS.IN_REVIEW);
          //Once in review set the description and valuestatement
        }

        setDataProductDetails((prev) => ({
          ...prev,
          [DATAPRODUCT_DESCRIPTION]:
            dataProductDetails?.[DATAPRODUCT_DESCRIPTION] ||
            autofillDescription,
          [DATAPRODUCT_VALUE_STATEMENT]:
            dataProductDetails?.[DATAPRODUCT_VALUE_STATEMENT] ||
            autofillValueStatement,
        }));
        setPageCurrentState(pageStateOptions.REVIEW);
        break;
      case pageStateOptions.CANCEL:
        if (selectedDataProduct?.DataProductDetails) {
          setDataProductDetails(selectedDataProduct?.DataProductDetails);
        }
        socket.emit(
          "updateDPDetails",
          { isEditing: false, useCaseIds },
          socket.lastJoinedRoom
        );
        socket.emit(
          "reviewDPDetails",
          { isEditing: false },
          socket.lastJoinedRoom
        );
        setPageCurrentState(pageStateOptions.DISPLAY);
        setShowError(false);
        setDataProductError(false);
        break;
      case pageStateOptions.DELETE:
        deleteSelectedDataProduct();
        break;
      case pageStateOptions.READY_FOR_IMPLEMENTATION:
        checkStatus(dataProductDetails.id);
        socket.emit(
          "reviewDPDetails",
          { isEditing: false, dataOutOfSync: true },
          socket.lastJoinedRoom
        );
        updateDataProductStatus(DATAPRODUCT_STATUS_OPTIONS.IN_DEVELOPMENT);
        setPageCurrentState(pageStateOptions.READY_FOR_IMPLEMENTATION);
        break;
      default:
        break;
    }
  };

  const isTeamMember = useCallback(() => {
    if (selectedDataProduct?.DataProductDetails?.isCollibra) {
      return false;
    }
    if (isOwner) return true;
    const currentUserIsHavingRole = dataProductDetails?.[
      DATAPRODUCT_TEAMS
    ]?.find((member) => member.userId === userDetails?.id);
    return currentUserIsHavingRole !== undefined ? true : false;
  }, [dataProductDetails, userDetails, isOwner, selectedDataProduct]);

  const isFieldEditable = useCallback(() => {
    return (
      pageCurrentState === pageStateOptions.EDIT ||
      pageCurrentState === pageStateOptions.REVIEW
    );
  }, [pageCurrentState]);

  const deleteSelectedDataProduct = useCallback(async () => {
    try {
      const { data } = await AXIOS_INSTANCE.delete(
        `${DATA_PRODUCTS_API}/delete/${searchedDataProductId}`
      );
      if (data?.data?.isSuccess) {
        socket.emit("deleteDataProduct", socket.lastJoinedRoom);
        history.push("/home");
        GlobalNotificationHandle(
          250,
          "Data product deleted successfully!",
          true,
          true
        );
      }
    } catch (e) {
      GlobalNotificationHandle(e.response.status, e.response.data, false, true);
    }
  }, [searchedDataProductId, history]);

  const updateDataProductStatus = useCallback(
    async (status) => {
      try {
        const { data } = await AXIOS_INSTANCE.put(
          `${DATA_PRODUCTS_API}/updateDataProductStatus/${searchedDataProductId}`,
          {
            dataProductStatus: status,
          }
        );
        if (data.isSuccess)
          dispatch(getDataproductDetails(searchedDataProductId));
      } catch (e) {
        GlobalNotificationHandle(
          e.response.status,
          e.response.data.errorDescription,
          false,
          true
        );
      }
    },
    [searchedDataProductId, dispatch]
  );

  const handleCollibraCommunityModalSubmit = async (dataProductDetails) => {
    const dataProductName = dataProductDetails.name;
    const dataProductValue = dataProductDetails.valueStatement;
    const domainId = dataProductDetails.domainId;
    const dataProductDefinition = dataProductDetails.description;
    const communityId = dataProductDetails.selectedCommunityId;
    const emailIds = dataProductDetails.teams.map((team) => {
      return team.userEmail;
    });

    try {
      const { data } = await AXIOS_INSTANCE.post(
        `${COLLIBRA_API}/workflowInstances?`,
        {
          dataProductName,
          dataProductValue,
          domainId,
          dataProductDefinition,
          communityId,
          emailIds,
        }
      );
      if (data?.data?.isSuccess) {
        GlobalNotificationHandle(200, data?.data?.message, true, true);
        await updateDataProductStatus(DATAPRODUCT_STATUS_OPTIONS.IN_INITIATION);
        await pageStateHandler(pageStateOptions.DISPLAY);
      }
    } catch (e) {
      //User cannot edit or review if postworflow failed (in initiation state)
      switch (e.response.data.message) {
        case "INVALID_USER":
          handleInvalidUserNotification(
            e.response.status,
            e.response.data.nonExistingOwners,
            dataProductDetails.teams
          );
          break;
        case "DOMAIN_DOESNT_EXIST":
          GlobalNotificationHandle(
            e.response.status,
            "Cannot proceed to next step as your Data Domain is not associated with a Data Domain within the Collibra Data catalog",
            false,
            true,
            true
          );
          break;
        default:
          GlobalNotificationHandle(
            e.response.status,
            e.response.data.message,
            false,
            true,
            true
          );
      }
      throw new Error(e.response.data.message);
    }
  };

  const handleInvalidUserNotification = (
    errorStatus,
    inValidUsers,
    dataProductTeams
  ) => {
    const matchingInvalidUsers = dataProductTeams.filter((eachUser) => {
      return inValidUsers.some((item) => eachUser.userEmail === item);
    });
    const message = `${matchingInvalidUsers
      .map((item) => item.userName)
      .join(",")} users not found`;
    return GlobalNotificationHandle(errorStatus, message, false, true, true);
  };

  const lineageSection = useRef(null);

  useEffect(() => {
    if (lineagefullscreen) {
      window.scrollTo(0, 0);
      localStorage.setItem("fullscreen", true);
    } else {
      const checkFullScreen = localStorage.getItem("fullscreen");
      if (checkFullScreen) {
        localStorage.removeItem("fullscreen");
        if (lineageSection.current) {
          lineageSection.current.scrollIntoView();
        }
      }
    }
  }, [lineagefullscreen]);

  return !lineagefullscreen ? (
    <div
      className="global-top-1"
      style={{ background: "#fff" }}
      id="data-product-details"
    >
      <div className="banner-image-container">
        <div className="banner-image-text">Data Product Canvas</div>
      </div>
      <div
        style={{
          marginLeft: 50,
          marginRight: 50,
          marginTop: 22,
          background: "#fff",
        }}
      >
        <DataProductBreadCrumb
          useCaseNames={useCaseNames}
          useCaseHrefs={useCaseHrefs}
          lvtUrls={lvtUrls}
          lvtTypeName={lvtTypeName}
          dataProductId={dataProductDetails[DATAPRODUCT_ID] || ""}
        />
        {pageCurrentState === pageStateOptions.REVIEW ? (
          <Alert>
            <span>
              Please make sure that all data included in Data Product are
              correct before proceeding to the Ready for Implementation step.{" "}
              <strong>
                Once Data Product is in the Ready for Implementation step, you
                won’t be able to add changes
              </strong>{" "}
              in Data Mesh Design Studio.
            </span>
          </Alert>
        ) : null}
        <div className="dataproduct-canvas-main-card">
          <Box sx={{ width: "100%", marginBottom: "2rem" }}>
            <Grid
              container
              rowSpacing={4}
              columnSpacing={{ xs: 2, sm: 3, md: 5 }}
            >
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <DataProductIsFavorite
                      dataProductId={dataProductDetails?.[DATAPRODUCT_ID]}
                      defaultValue={
                        dataProductDetails?.[DATAPRODUCT_IS_FAVORITE]
                      }
                      updateHandler={() =>
                        dispatch(getDataproductDetails(searchedDataProductId))
                      }
                    />
                    <CustomDataInputField
                      textClass="data-product-details-title"
                      editState={isFieldEditable()}
                      data={dataProductDetails?.[DATAPRODUCT_NAME] || ""}
                      onChangeHandler={(value) =>
                        onChangeHandler(value, DATAPRODUCT_NAME)
                      }
                      handleError={setDataProductError}
                      errorMessage="Name is required."
                      error={dataProductError}
                      isMultiLine={false}
                    />
                    <div>
                      <DataProductStatus
                        status={
                          selectedDataProduct?.DataProductDetails?.[
                            DATAPRODUCT_STATUS
                          ]
                        }
                      />
                    </div>
                    <div>
                      <DataProductYAML
                        dataProductId={
                          dataProductDetails?.[DATAPRODUCT_ID] ||
                          searchedDataProductId
                        }
                        dataProductName={
                          dataProductDetails?.[DATAPRODUCT_NAME] || ""
                        }
                        dataProductStatus={
                          selectedDataProduct?.DataProductDetails?.[
                            DATAPRODUCT_STATUS
                          ]
                        }
                      />
                    </div>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                  >
                    <ActionButtons
                      pageCurrentState={pageCurrentState}
                      pageStateHandler={pageStateHandler}
                      isOwner={isOwner}
                      isTeamMember={isTeamMember()}
                      isDPDetailsEditing={isDPDetailsEditing}
                      isDPDetailsInReview={isDPDetailsInReview}
                      isInReviewState={isInReviewState}
                      dataProductStatus={
                        selectedDataProduct?.DataProductDetails?.[
                          DATAPRODUCT_STATUS
                        ]
                      }
                      error={showError || dataProductError}
                      dataProductDescription={description}
                    />
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={4}>
                  <Stack spacing={2}>
                    <p className="data-product-details-subtitle">
                      Description:
                    </p>
                    <CustomDataInputField
                      editState={pageCurrentState === pageStateOptions.EDIT}
                      parseDataToHTML={
                        collibraDetails?.DataProductDetails?.isCollibra
                      }
                      error={showError}
                      inputHeight="15vh"
                      inputWidth="15vw"
                      handleError={setShowError}
                      errorMessage="Description is required."
                      data={description}
                      onChangeHandler={(value) =>
                        onChangeHandler(value, DATAPRODUCT_DESCRIPTION)
                      }
                      hasError={true}
                      isMultiLine={true}
                    />
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={3.5}>
                <Stack spacing={4}>
                  <Stack spacing={2}>
                    <p className="data-product-details-subtitle">
                      Value Statement:
                    </p>
                    <CustomDataInputField
                      editState={pageCurrentState === pageStateOptions.REVIEW}
                      data={valueStatement}
                      onChangeHandler={(value) =>
                        onChangeHandler(value, DATAPRODUCT_VALUE_STATEMENT)
                      }
                      error={showError}
                      hasError={true}
                      inputHeight="100px"
                      inputWidth="220px"
                      handleError={setShowError}
                      errorMessage="ValueStatement is required."
                      isMultiLine={true}
                    />
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={2}>
                <Stack spacing={4}>
                  <Stack spacing={2}>
                    <p className="data-product-details-subtitle">
                      Data Domain:
                    </p>
                    <p className="data-product-details-text">
                      {dataProductDetails?.domainName}
                    </p>
                  </Stack>
                  <Stack spacing={2}>
                    <p className="data-product-details-subtitle">
                      Data Product Type:
                    </p>
                    <CustomDataSelectField
                      editState={isFieldEditable()}
                      data={dataProductDetails?.[DATAPRODUCT_TYPE] || ""}
                      onChangeHandler={(value) => dataProductTypeHandler(value)}
                      optionsList={dataProductTypesList || []}
                      textVariant="body2"
                    />
                  </Stack>
                </Stack>
              </Grid>
              {collibraDetails.DataProductDetails?.isCollibra ? (
                <Grid item xs={2}>
                  <Stack spacing={4}>
                    <Stack spacing={2}>
                      <p className="data-product-details-subtitle">
                        Fitness summary:
                      </p>
                      {collibraDetails.DataProductDetails.fitnessSummary}
                    </Stack>
                    <Stack spacing={2}>
                      <p className="data-product-details-subtitle">
                        Certified:
                      </p>
                      {collibraDetails.DataProductDetails.certified ? (
                        <CheckCircleOutlineIcon sx={{ fill: "#007C6B" }} />
                      ) : null}
                    </Stack>
                  </Stack>
                </Grid>
              ) : (
                <Grid item xs={2.5}>
                  <Stack spacing={4}>
                    <Stack spacing={2}>
                      <p className="data-product-details-subtitle">
                        Required Data Products:
                      </p>
                      <List disablePadding>
                        {requiredDataProducts?.map((dpItem) => (
                          <ListItem
                            disablePadding
                            key={dpItem.name}
                            sx={{ marginBottom: "0.5rem" }}
                          >
                            <p className="data-product-details-text">
                              {dpItem.name}
                            </p>
                          </ListItem>
                        ))}
                      </List>
                    </Stack>
                    <Stack spacing={2}>
                      <p className="data-product-details-subtitle">
                        Required Data Sources:
                      </p>
                      <List disablePadding>
                        {requiredDataSources?.map((dsItem) => (
                          <ListItem
                            disablePadding
                            key={dsItem.description}
                            sx={{ marginBottom: "0.5rem" }}
                          >
                            <p className="data-product-details-text">
                              {dsItem.description}
                            </p>
                          </ListItem>
                        ))}
                      </List>
                    </Stack>
                  </Stack>
                </Grid>
              )}
            </Grid>
          </Box>
          <Divider variant="middle" />
          <Box sx={{ margin: "2rem 0" }}>
            <Grid container>
              {isOwner && isFieldEditable() ? (
                <Grid container sx={{ marginBottom: "2rem" }}>
                  <ImportTeamMembers
                    domainId={dataProductDetails?.[DATAPRODUCT_DOMAIN_ID] || ""}
                    teamMembersHandler={teamMembersHandler}
                  />
                </Grid>
              ) : null}
              <Grid container>
                <PaginatedTeamMembers
                  teamMembers={convertTeamsDataForRoles()}
                  teamMembersHandler={teamMembersHandler}
                  allRoles={allRoles || []}
                  usersByDomain={usersByDomain || []}
                  editAccess={isOwner}
                  editState={isFieldEditable()}
                />
              </Grid>
            </Grid>
          </Box>
          <AdditionalOptions
            pageCurrentState={pageCurrentState}
            dataProductStatus={
              selectedDataProduct?.DataProductDetails?.[DATAPRODUCT_STATUS]
            }
            isCollibra={
              selectedDataProduct?.DataProductDetails?.isCollibra || false
            }
            dataProductId={searchedDataProductId}
          />
          <FinalDecisionButtons
            pageCurrentState={pageCurrentState}
            pageStateHandler={pageStateHandler}
            isOwner={isOwner}
            dpimStatusValid={getAllDPIMStatusValidation()}
            dataProductDetails={dataProductDetails}
            handleCollibraCommunityModalSubmit={
              handleCollibraCommunityModalSubmit
            }
          />
          {/* Data lineage start  */}
          <div id="lineageContent" ref={lineageSection}>
            <MultipurposeAccordion
              defaultOpen={localStorage.getItem("fullscreen") ? true : false}
              title={`Data Lineage for ${dataProductDetails?.name || ""}`}
            >
              <DpLineageCanvas
                dataProductDetails={dataProductDetails}
                lineageData={lineageData}
                DataProductId={searchedDataProductId}
              />
            </MultipurposeAccordion>
          </div>
          {/* Data lineage end  */}
        </div>
      </div>
    </div>
  ) : (
    <div className="lineage-fullscreen">
      <div className="lineage-fullscreen-content">
        <DpLineageCanvas
          dataProductDetails={dataProductDetails}
          lineageData={lineageData}
          DataProductId={searchedDataProductId}
          fullScreenButtonEnable={false}
        />
      </div>
    </div>
  );
};
export default DataProductDetails;
