import React, { useEffect, useRef, useState, useMemo } from "react";
import { useEdgesState, useNodesState } from "reactflow";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import DPMenu from "./DPMenu";
import DPDiagram from "./DPDiagram";
import DPHeader from "./DPHeader";
import {
  AXIOS_INSTANCE,
  DATA_PRODUCT_INTERACTION_MAP_API,
} from "../../../store/apiUtils/config";
import { GlobalNotificationHandle } from "../../../services/NotificationHandler";
import { DPIM_SECTION_ORDER, generateInitialNodes } from "./DPIMSettings";
import { getEnrolledDomains } from "../../../store/actions";
import { socket } from "../../../services/Socket";

const DataProductInteractionMap = () => {
  const dispatch = useDispatch();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [maxHeight, setMaxHeight] = useState(0);
  const [status, setStatus] = useState();
  const [activeStep, setActiveStep] = useState(-1);
  const reactFlowWrapper = useRef(null);
  const { search } = useLocation();
  const history = useHistory();
  const [userEditAccess, setUserEditAccess] = useState(false);
  const userDetails = localStorage.getItem("loginInfo");
  const isAdmin = JSON.parse(userDetails).data?.userDetails?.isAdmin;
  const [TeamSpaceData, setTeamSpaceData] = useState([]);
  const params = new URLSearchParams(search);
  const domainIdParam = useMemo(() => params.get("domainId"), [params]);
  const teamSpaceId = useMemo(() => params.get("teamSpaceId"), [params]);

  useEffect(() => {
    dispatch(getEnrolledDomains());
  }, [dispatch]);

  useEffect(() => {
    if (!params.get("useCaseId") || (!domainIdParam && !teamSpaceId)) {
      history.push("/home");
    }
  }, [params, history]);

  useEffect(() => {
    return () => {
      localStorage.removeItem("hideLoader");
    };
  });

  useEffect(() => {
    socket.lastJoinedRoom = `DPIM-${params.get("useCaseId")}`;
    socket.emit("joinRoom", socket.lastJoinedRoom);
    socket.emit(
      "getAllDataProductInteractionMapItemsByUseCaseId",
      {
        useCaseId: params.get("useCaseId"),
      },
      socket.lastJoinedRoom
    );
    return () => {
      socket.emit("leaveRoom", socket.lastJoinedRoom);
      socket.lastJoinedRoom = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    socket.on(
      "reciveAllDataProductInteractionMapItemsByUseCaseId",
      (response) => {
        generateItems(response);
      }
    );
    socket.on("syncSectionSizes", (response) => {
      setNodes([...response]);
    });
    socket.on("dataProductChange", () => {
      socket.emit(
        "getAllDataProductInteractionMapItemsByUseCaseId",
        {
          useCaseId: params.get("useCaseId"),
        },
        socket.lastJoinedRoom
      );
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userEnrolledDomains = useSelector((state) => ({
    domainList: state.Domains.enrolledDomainsList,
  }));

  useEffect(() => {
    if (teamSpaceId) {
      socket.emit("getAllEnrolledTeamSpaces", { teamSpaceId });
      socket.on("receiveAllEnrolledTeamSpaces", (data) => {
        setTeamSpaceData(data?.enrolledTeamSpaceList);
      });
    }
  }, [teamSpaceId]);

  useEffect(() => {
    let userEnrolledlist;
    if (TeamSpaceData.length > 0) {
      userEnrolledlist = TeamSpaceData.find(
        (teamSpaceDetails) => teamSpaceId === teamSpaceDetails.id
      );
    } else if (userEnrolledDomains?.domainList) {
      userEnrolledlist = userEnrolledDomains.domainList.find(
        (domainDetails) => domainDetails.id === domainIdParam
      );
    }
    setUserEditAccess(userEnrolledlist || isAdmin ? true : false);
  }, [isAdmin, userEnrolledDomains, params, TeamSpaceData]);

  const syncDataProductItems = async (DPMIId) => {
    try {
      const { data } = await AXIOS_INSTANCE.get(
        `${DATA_PRODUCT_INTERACTION_MAP_API}/getAllItemsByUseCaseId?useCaseId=${DPMIId}`
      );

      generateItems(data?.data || {});
    } catch (e) {
      GlobalNotificationHandle(
        e.response.status,
        e.response.data.errorDescription,
        false,
        true
      );
    }
  };

  const generateItems = (response) => {
    const finalData =
      response.data?.map((e) => {
        const parentNode = nodes.find((node) => node.id === e.data.step);

        return {
          ...e,
          type: "dpItem",
          parentNode: e.data.step,
          draggable: true,
          selectable: true,
          extent: "parent",
          style: {
            maxWidth: parentNode?.style.width,
            zIndex: 100000,
            pointerEvents: "All",
          },
        };
      }) || [];

    const finalDataProducts = response.dataProducts?.map((dataProduct) => ({
      ...dataProduct,
      type: "hexagon",
      extent: "parent",
      draggable: true,
      selectable: true,
    }));

    setStatus(response.status || "");

    setNodes((prevNodes) => {
      let sectionsList = [];
      if (prevNodes.length) {
        sectionsList = DPIM_SECTION_ORDER.map((sectionId) => {
          return prevNodes.find((node) => node.id === sectionId);
        });
      } else {
        sectionsList = [
          ...generateInitialNodes(
            [...finalData, ...finalDataProducts],
            response.sectionWidths
          ),
        ];
      }

      return [...sectionsList, ...finalData, ...finalDataProducts];
    });

    const finalEdges = response.relationships.map((edge) => ({
      id: edge.id,
      source: edge.data.sourceId,
      target: edge.data.targetId,
      sourceHandle: edge.data.sourceHandle,
      targetHandle: edge.data.targetHandle,
      style: { color: "#000" },
    }));

    setEdges(finalEdges);
  };

  const fetchDPIMInformation = async (
    type,
    url,
    data = null,
    syncNeeded = true,
    baseUrl = DATA_PRODUCT_INTERACTION_MAP_API
  ) => {
    try {
      const result = await AXIOS_INSTANCE[type](`${baseUrl}/${url}`, data);
      if (syncNeeded) {
        await syncDataProductItems(params.get("useCaseId"));
      }
      return result;
    } catch (e) {
      GlobalNotificationHandle(
        e.response.status,
        e.response.data.errorDescription,
        false,
        true
      );
    }
  };

  return (
    <div
      className={"data-product-interaction-map global-top-1"}
      style={{ background: "#fff" }}
    >
      <DPMenu
        setNodes={setNodes}
        useCaseId={params.get("useCaseId")}
        selectedDomainId={domainIdParam}
        selectedTeamSpaceId={teamSpaceId}
        userEditAccess={userEditAccess}
        nodes={nodes}
        TeamSpaceData={TeamSpaceData}
      />
      <DPHeader
        status={status}
        nodes={nodes}
        edges={edges}
        activeStep={activeStep}
        fetchDPIMInformation={fetchDPIMInformation}
        useCaseId={params.get("useCaseId")}
        selectedDomainId={domainIdParam}
        selectedTeamSpaceId={teamSpaceId}
        userEditAccess={userEditAccess}
        setActiveStep={setActiveStep}
      />
      <div>
        <DPDiagram
          nodes={nodes}
          setNodes={setNodes}
          onNodesChange={onNodesChange}
          edges={edges}
          setEdges={setEdges}
          onEdgesChange={onEdgesChange}
          reactFlowWrapper={reactFlowWrapper}
          useCaseId={params.get("useCaseId")}
          maxHeight={maxHeight}
          setMaxHeight={setMaxHeight}
          userEditAccess={userEditAccess}
        />
      </div>
    </div>
  );
};

export default DataProductInteractionMap;
