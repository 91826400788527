// saga.js
import { takeEvery, fork, all, call, put } from "redux-saga/effects";
import { TEAMSPACE_CONST } from "./actionsTypes";
import {
  getTeamSpacesByDomainSuccess,
  getTeamSpacesByDomainFailure,
} from "./actions";
import { checkHttpStatus } from "../apiUtils";
import { baseMethod } from "../../services/BaseService";
import { AXIOS_INSTANCE, TEAM_SPACES_API } from "../apiUtils/config";

export function* getTeamSpacesByDomain(action) {
  try {
    const serviceMethod = AXIOS_INSTANCE.get(`${TEAM_SPACES_API}/getByDomain`, {
      params: {
        page: action.payload.page,
        domainId: action.payload.domainId,
        limit: action.payload.limit,
        searchValue: action.payload.searchValue,
        type: action.payload.type,
      },
    });
    const apiResponse = yield call(baseMethod, serviceMethod, "", null, null);
    const response = yield call(checkHttpStatus, apiResponse);
    if (
      response.status >= 200 &&
      response.status < 300 &&
      response.data?.data?.isSuccess
    ) {
      const responseData = {
        statusCode: 200,
        response: response.data,
      };
      yield put(getTeamSpacesByDomainSuccess(responseData));
    } else {
      const responseData = {
        statusCode: response.statusCode || 400,
        data: response.data,
      };
      yield put(getTeamSpacesByDomainFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getTeamSpacesByDomainFailure(errorData));
  }
}

export function* watchGetTeamSpacesByDomain() {
  yield takeEvery(
    TEAMSPACE_CONST.GET_TEAMSPACES_BY_DOMAIN_REQUEST,
    getTeamSpacesByDomain
  );
}

export function* TeamSpaceSaga() {
  yield all([fork(watchGetTeamSpacesByDomain)]);
}

export default TeamSpaceSaga;
