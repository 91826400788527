import React from "react";
import PropTypes from "prop-types";

import CustomModal from "../../components/Common/CustomModal";

const DeleteDomainModal = ({
  selectedDomain,
  handleDelete,
  deleteDomainModal,
  deleteDomainModalToggle,
}) => {
  return (
    <CustomModal
      data-testid="DeleteDomainModal-Container"
      displayState={deleteDomainModal}
      toggleState={() => deleteDomainModalToggle()}
      title={`Are you sure you want to delete ${selectedDomain?.name}?`}
      primaryBtn="Yes"
      primaryBtnHandler={() => handleDelete(selectedDomain)}
      secondaryBtn="Cancel"
      secondaryHandler={() => deleteDomainModalToggle()}
    />
  );
};

DeleteDomainModal.propTypes = {
  selectedDomain: PropTypes.object.isRequired,
  handleDelete: PropTypes.func.isRequired,
  deleteDomainModal: PropTypes.bool.isRequired,
  deleteDomainModalToggle: PropTypes.func.isRequired,
};

export default DeleteDomainModal;
