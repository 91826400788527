import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Input,
} from "reactstrap";
import { IoMdArrowDropdown } from "react-icons/io";

const SearchBar = ({
  setDomainList,
  setFilterDetailsBy,
  searchValue,
  setSearchValue,
}) => {
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("All");

  const optionList = ["All", "Data Domain", "Data Product"];
  const toggle = () => setDropDownOpen((prevState) => !prevState);

  /* ------handling api data---------*/
  const nextProps = useSelector((state) => ({
    allDomains: state.Domains.allDomains,
    combinedDataProducts: state.DataProducts.combinedDataProducts,
  }));

  /* ------handling select change ---------*/

  useEffect(() => {
    if (setFilterDetailsBy) setFilterDetailsBy(selectedOption);
  }, [selectedOption, setFilterDetailsBy]);

  /* ------handling search value change ---------*/

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    let searchItem = e.target.value.toString().toLowerCase();
    if (searchItem) {
      const dominaFilterted = nextProps?.allDomains?.filter((element) =>
        element?.name?.toLowerCase().includes(searchItem)
      );
      setDomainList(dominaFilterted);
    } else {
      setDomainList(nextProps.allDomains);
    }
  };

  return (
    <div className="input-group mt-3 mb-3">
      <div className="input-group-prepend">
        <Dropdown isOpen={dropDownOpen} toggle={toggle}>
          <DropdownToggle caret className="custom-btn">
            {selectedOption}
            <IoMdArrowDropdown
              style={{ marginLeft: "10px" }}
            ></IoMdArrowDropdown>
          </DropdownToggle>
          <DropdownMenu>
            {optionList.map((item, index) => {
              return (
                <DropdownItem
                  key={index}
                  onClick={() => setSelectedOption(item)}
                >
                  {item}
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </Dropdown>
      </div>
      <Input
        className="form-control global-search-bar"
        placeholder="Search for data domains and data products..."
        onChange={handleSearchChange}
        value={searchValue}
      />
    </div>
  );
};

SearchBar.propTypes = {
  setDomainList: PropTypes.func,
  setFilterDetailsBy: PropTypes.func,
  searchValue: PropTypes.any,
  setSearchValue: PropTypes.func,
};

export default SearchBar;
