// saga.js
import { takeEvery, fork, all, call, put } from "redux-saga/effects";
import { ROLES_CONST } from "./actionTypes";
import { getAllRolesSuccess, getAllRolesFailure } from "./actions";
import { checkHttpStatus } from "../apiUtils";
import { baseMethod } from "../../services/BaseService";
import { AXIOS_INSTANCE, ROLES_API } from "../apiUtils/config";

export function* getAllRoles() {
  try {
    const serviceMethod = AXIOS_INSTANCE.get(`${ROLES_API}/getAll`);
    const apiResponse = yield call(baseMethod, serviceMethod, "", null, null);
    const response = yield call(checkHttpStatus, apiResponse);

    if (
      response.status >= 200 &&
      response.status < 300 &&
      response.data?.data?.isSuccess
    ) {
      const responseData = {
        statusCode: 200,
        response: response.data,
      };
      localStorage.setItem(
        "roles",
        JSON.stringify(response.data.data?.roleList)
      );
      yield put(getAllRolesSuccess(responseData));
    } else {
      const responseData = {
        statusCode: response.statusCode || 400,
        data: response.data,
      };
      yield put(getAllRolesFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getAllRolesFailure(errorData));
  }
}

export function* watchGetAllRoles() {
  yield takeEvery(ROLES_CONST.GET_ALL_ROLES_REQUEST, getAllRoles);
}

export function* RolesSaga() {
  yield all([fork(watchGetAllRoles)]);
}

export default RolesSaga;
