import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";

import InfoCircle from "./InfoCircle";

import "./CustomModal.scss";

const CustomModal = ({
  displayState,
  toggleState,
  hideBackdrop = false,
  formId = "",
  title = "",
  tooltipText = "",
  primaryBtn = "",
  primaryBtnHandler = () => {},
  secondaryBtn = "",
  secondaryHandler = () => {},
  children,
  width = 50,
  closeButton = true,
  closeButtonClass = "",
}) => {
  return (
    <Modal
      className="custom-modal"
      hideBackdrop={hideBackdrop}
      data-testid="custom-modal"
      open={displayState}
      onClose={toggleState}
    >
      <Box className="custom-modal-box-style" style={{ width: `${width}%` }}>
        <DialogTitle>
          {title}

          {tooltipText !== "" ? (
            <InfoCircle orientation="top" text={tooltipText} />
          ) : null}

          {closeButton ? (
            <CloseIcon
              className={
                closeButtonClass ? closeButtonClass : "modal-close-icon"
              }
              onClick={toggleState}
            />
          ) : null}
        </DialogTitle>

        <DialogContent className="custom-modal-content">
          {children}
        </DialogContent>

        <DialogActions>
          {secondaryBtn && (
            <Button
              className="custom-modal-button secondary"
              onClick={secondaryHandler}
            >
              {secondaryBtn}
            </Button>
          )}
          {primaryBtn && (
            <Button
              type="submit"
              form={formId}
              className="custom-modal-button"
              onClick={primaryBtnHandler}
            >
              {primaryBtn}
            </Button>
          )}
        </DialogActions>
      </Box>
    </Modal>
  );
};

CustomModal.propTypes = {
  displayState: PropTypes.bool.isRequired,
  toggleState: PropTypes.func.isRequired,
  formId: PropTypes.any,
  title: PropTypes.any,
  tooltipText: PropTypes.string,
  primaryBtn: PropTypes.any,
  primaryBtnHandler: PropTypes.func,
  secondaryHandler: PropTypes.func,
  secondaryBtn: PropTypes.any,
  children: PropTypes.element,
  closeButton: PropTypes.bool,
  hideBackdrop: PropTypes.bool,
  width: PropTypes.number,
  closeButtonClass: PropTypes.string,
};

export default CustomModal;
