import React from "react";
import PropTypes from "prop-types";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Tooltip from "@mui/material/Tooltip";
import { saveAs } from "file-saver";
import { AXIOS_INSTANCE, DATA_PRODUCTS_API } from "../../store/apiUtils/config";
import { GlobalNotificationHandle } from "../../services/NotificationHandler";
import { COLLIBRA_DATAPRODUCT_STATUS } from "../../constants/DataProductStatusOptions";
const eligibleDataProductStatuses = [
  COLLIBRA_DATAPRODUCT_STATUS.CANDIDATE,
  COLLIBRA_DATAPRODUCT_STATUS.DEPLOYED,
  COLLIBRA_DATAPRODUCT_STATUS.PUBLISHED,
];

const DataProductYAML = ({
  dataProductId,
  dataProductName = "Data_product",
  dataProductStatus,
}) => {
  const handleClick = async () => {
    try {
      const results = await AXIOS_INSTANCE.get(
        `${DATA_PRODUCTS_API}/generateYamlFile`,
        {
          params: { dataProductId },
        }
      );
      const data = results.data;
      const dataProductYamlFile = new Blob([data], {
        type: "text/plain;charset=utf-8",
      });
      saveAs(dataProductYamlFile, `${dataProductName}.yml`);
    } catch (e) {
      GlobalNotificationHandle(
        e.response.status,
        e.response.data.errorDescription,
        false,
        true
      );
    }
  };

  const downloadFeatureAvailable =
    eligibleDataProductStatuses.includes(dataProductStatus);
  return (
    <div data-testid="Dataproduct-YAML">
      {downloadFeatureAvailable ? (
        <Tooltip
          title="Generate & download data product OAM YAML file"
          placement="bottom"
          data-testid="yaml-download-icon-tooltip"
        >
          <span>
            <FileDownloadOutlinedIcon
              color="primary"
              onClick={handleClick}
              sx={{ cursor: "pointer" }}
              data-testid="yaml-download-icon"
            />
          </span>
        </Tooltip>
      ) : null}
    </div>
  );
};

DataProductYAML.propTypes = {
  dataProductId: PropTypes.string.isRequired,
  dataProductName: PropTypes.string,
  dataProductStatus: PropTypes.string,
};

export default React.memo(DataProductYAML);
