import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Handle } from "reactflow";
import PropTypes from "prop-types";
import classNames from "classnames";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";

import { dataProductTypeAbbreviations } from "./DPIMSettings";

import { ReactComponent as Hexagon } from "../../../assets/images/Hexagon.svg";
import { ReactComponent as Details } from "../../../assets/images/Details.svg";
import collibraIcon from "../../../assets/images/collibraIcon.png";
import "./DataProductHexagon.scss";
import { DATAPRODUCT_STATUS_OPTIONS } from "../../../constants/DataProductStatusOptions";

const DataProductHexagon = ({
  data,
  isConnectable,
  setNodes,
  id,
  deleteDataProductItems,
  userEditAccess,
}) => {
  const [anchorEl, setAnchorEl] = useState();
  const history = useHistory();
  const isOwnerOrAdmin = useMemo(() => {
    const userDetails = JSON.parse(localStorage.getItem("loginInfo"))?.data
      ?.userDetails;

    return (
      userDetails?.isAdmin ||
      data?.dataProduct?.ownerArray?.some(
        (owner) => owner.id === userDetails.id
      )
    );
  }, [data]);

  const deletableStatus = useMemo(
    () =>
      [
        DATAPRODUCT_STATUS_OPTIONS.IN_DESIGN,
        DATAPRODUCT_STATUS_OPTIONS.IN_REVIEW,
      ].includes(data?.dataProduct?.dataProductStatus),
    [data]
  );

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDetails = () => {
    history.push(`/dataproduct-details?dataproductId=${id}`);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteNodeById = () => {
    setNodes((nds) => {
      return nds.map((node) => {
        if (node.id === id) {
          deleteDataProductItems(nds.filter((node) => node.id === id));
        }
        return node;
      });
    });
    handleClose();
  };

  const deleteDataProductPermanently = () => {
    setNodes((nds) => {
      return nds.map((node) => {
        if (node.id === id) {
          node.permanantlyDelete = true;
          deleteDataProductItems(nds.filter((node) => node.id === id));
        }
        return node;
      });
    });
    handleClose();
  };

  return (
    <div className="dataproduct-item">
      <Handle
        type="target"
        position="right"
        id="2"
        style={{ background: "#555", marginLeft: 10 }}
        className="dataproduct-item-handle-dot"
        isConnectable={isConnectable}
      />
      <div className="dataproduct-hexagon-container" tabIndex={0}>
        <Tooltip title={data.label}>
          <Hexagon
            className={classNames(
              "dataproduct-hexagon",
              dataProductTypeAbbreviations[data.dataProduct.dataProductTypeName]
            )}
          />
        </Tooltip>
        <div className="dataproduct-hexagon-title">{data.label}</div>
        <Details
          onClick={handleClick}
          className={classNames(
            "dataproduct-hexagon-edit",
            dataProductTypeAbbreviations[data.dataProduct.dataProductTypeName]
          )}
          data-testid="hexagon-detail-icon"
        />
        {data.dataProduct.isCollibra ? (
          <img
            className="dataproduct-hexagon-collibra"
            alt="collibra icon"
            src={collibraIcon}
            data-testid="hexagon-collibra-icon"
          />
        ) : null}
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={handleDetails} data-testid="details-button">
            Details
          </MenuItem>
          <MenuItem
            disabled={!userEditAccess}
            onClick={userEditAccess ? deleteNodeById : null}
            data-testid="deletefromdpim"
          >
            Delete from DPIM
          </MenuItem>
          <MenuItem
            disabled={!(isOwnerOrAdmin && deletableStatus)}
            onClick={
              isOwnerOrAdmin && deletableStatus
                ? deleteDataProductPermanently
                : null
            }
            data-testid="Delete-permanently"
          >
            Delete Permanently
          </MenuItem>
        </Menu>
      </div>
      <Handle
        type="source"
        position="left"
        id="4"
        style={{ background: "#555", marginRight: 10 }}
        className="dataproduct-item-handle-dot"
        isConnectable={isConnectable}
      />
    </div>
  );
};

DataProductHexagon.propTypes = {
  data: PropTypes.object.isRequired,
  isConnectable: PropTypes.bool,
  setNodes: PropTypes.func,
  id: PropTypes.string,
  deleteDataProductItems: PropTypes.func,
  userEditAccess: PropTypes.bool,
};

export default DataProductHexagon;
