import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

import NestedMenuItem from "./NestedMenuItem";

const NestedSelect = ({ values, handleChange, selectedValue, label }) => {
  const [open, setOpen] = useState(false);

  const allOptions = useMemo(
    () => values.map((value) => value.options).flat(),
    [values]
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <TextField
      select
      label={label}
      value={selectedValue}
      size="small"
      data-testid="selectField"
      SelectProps={{ open, onClose: handleClose, onOpen: handleOpen }}
    >
      {allOptions.map((value) => (
        <MenuItem
          className="menu-item-hidden-options"
          key={value.id}
          value={value.id}
        >
          {value.name}
        </MenuItem>
      ))}
      {values.map((valueObject) => (
        <NestedMenuItem
          key={valueObject.title}
          label={valueObject.title}
          disabled={valueObject.options.length === 0}
          menu={valueObject.options.map(({ id, name }) => (
            <MenuItem
              key={id}
              onClick={() => {
                handleChange(id);
                setOpen(false);
              }}
            >
              {name}
            </MenuItem>
          ))}
        />
      ))}
    </TextField>
  );
};

NestedSelect.propTypes = {
  values: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
};

export default NestedSelect;
