import { useCallback, useEffect, useState } from "react";
import { socket } from "../../services/Socket";

const useIsEdit = (room) => {
  const [editable, setIsEditable] = useState(true);

  const handleEditState = useCallback(
    (value) => {
      socket.emit("setEditStatus", value, room);
      setIsEditable(value);
    },
    [room]
  );

  useEffect(() => {
    socket.on("checkEditStatus", (response) => {
      setIsEditable(response);
    });

    socket.on("getCurrentEditStatus", () => {
      handleEditState(editable);
    });
  }, [handleEditState, room, editable]);

  useEffect(() => {
    socket.emit("syncEditStatus", {}, room);
  }, [room]);

  return { editable, handleEditState };
};

export default useIsEdit;
