import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import Tooltip from "@mui/material/Tooltip";
import Pagination from "../../../components/Common/Pagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DataProductIsFavorite from "../../../components/Common/DataProductIsFavorite";
import { MY_FAVORITES } from "./SearchDataProduct";
import { DATA_PRODUCT_SEARCH_PAGE_SIZE } from "./DPIMSettings";
import DomainType from "../../../components/Common/DomainType";
import DataProductStatus from "../../../components/Common/DataProductStatus";

const dataProductStatus = {
  "Source Oriented Data Product": "SoDP",
  "Aggregate Data Product": "ADP",
  "Consumer Oriented Data Product": "CoDP",
};

const DataProductListBody = ({
  onItemClick,
  searchable,
  filter,
  currentPage,
  refetchList,
  setCurrentPage,
  total,
}) => {
  const handleItemClick = (item) => {
    if (item.isDisabled) {
      return;
    }
    onItemClick(item);
  };

  return (
    <>
      {filter.length > 0 ? (
        <>
          <TableContainer className="mb-2">
            <Table padding="none" size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">DP Name</TableCell>
                  <TableCell align="center"> </TableCell>
                  <TableCell align="center">DP Status</TableCell>
                  <TableCell align="center">DP Type</TableCell>
                  <TableCell align="center">Data Domain</TableCell>
                  <TableCell align="center">Roche Division</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filter.map((item, idx) => (
                  <TableRow
                    key={idx}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="dp-list-item name"
                    >
                      <span>
                        <Tooltip placement="bottom-start" title={item.name}>
                          <span
                            className={classNames("dp-list-item", {
                              disabled: item.isDisabled,
                            })}
                            onClick={() => handleItemClick(item)}
                          >
                            {item.name}
                          </span>
                        </Tooltip>
                      </span>
                    </TableCell>
                    <TableCell className="dp-list-item">
                      <span>
                        <DataProductIsFavorite
                          dataProductId={item.id}
                          defaultValue={
                            searchable === MY_FAVORITES ? true : item.isFavorite
                          }
                          updateHandler={refetchList}
                        />
                      </span>
                    </TableCell>
                    <TableCell align="center" className="dp-list-item">
                      <span>
                        <DataProductStatus status={item.dataProductStatus} />
                      </span>
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classNames(
                        "dp-list-item dp-type",
                        dataProductStatus[item.dataProductTypeName]
                      )}
                    >
                      <Tooltip
                        placement="bottom-start"
                        title={item.dataProductTypeName}
                      >
                        <span>{item.dataProductTypeName}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      align="left"
                      className="dp-list-item domain-link"
                    >
                      <Tooltip placement="bottom-start" title={item.domainName}>
                        <span>
                          <Link
                            to={`/datadomain-details?domainId=${item.domainId}`}
                            target="_blank"
                          >
                            {item.domainName}
                          </Link>
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      align="left"
                      className="dp-list-item domain-type"
                    >
                      {item.domainId ? (
                        <span>
                          <DomainType
                            domainType={item.domainType}
                            tooltipPlacement="bottom-start"
                          />
                        </span>
                      ) : null}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {total > DATA_PRODUCT_SEARCH_PAGE_SIZE &&
          searchable !== MY_FAVORITES ? (
            <Pagination
              currentPage={currentPage}
              itemsPerPage={DATA_PRODUCT_SEARCH_PAGE_SIZE}
              totalItems={total}
              setCurrentPage={(number) => setCurrentPage(number - 1)}
            />
          ) : null}
        </>
      ) : (
        <span className="dp-list-item">No results found</span>
      )}
    </>
  );
};

DataProductListBody.propTypes = {
  onItemClick: PropTypes.func,
  searchable: PropTypes.any,
  filter: PropTypes.array,
  currentPage: PropTypes.number,
  refetchList: PropTypes.func,
  setCurrentPage: PropTypes.func,
  total: PropTypes.number,
};

export default DataProductListBody;
