import React from "react";
import PropTypes from "prop-types";
import { Container } from "reactstrap";
import Header from "../HorizontalLayout/Header";
class ErrorBoundaries extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  componentDidCatch(error) {
    this.setState({
      hasError: true,
      error: error.toString(),
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <Header />
          <Container className="pt-5">
            <div className="text-center mt-5">
              <a href="/home" className="h4 cursor-pointer">
                Back to Home
              </a>
              <h2 className="text-danger ">{this.state.error}</h2>
            </div>
          </Container>
        </>
      );
    }

    return this.props.children;
  }
}

ErrorBoundaries.propTypes = {
  children: PropTypes.node,
};
export default ErrorBoundaries;
