import React, {
  useRef,
  useImperativeHandle,
  useState,
  forwardRef,
} from "react";
import PropTypes from "prop-types";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowRight from "@mui/icons-material/ArrowRight";

const NestedMenuItem = forwardRef(
  (
    {
      label,
      rightIcon = <ArrowRight style={{ fontSize: 24 }} />,
      customTheme,
      className,
      ContainerProps: ContainerPropsProp = {},
      rightAnchored,
      menu,
      ...MenuItemProps
    },
    ref
  ) => {
    const { ref: containerRefProp, ...ContainerProps } = ContainerPropsProp;

    const menuItemRef = useRef(null);
    useImperativeHandle(ref, () => menuItemRef.current);

    const containerRef = useRef(null);
    useImperativeHandle(containerRefProp, () => containerRef.current);

    const menuContainerRef = useRef(null);

    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

    const handleMouseEnter = () => {
      if (menu.length > 0) {
        setIsSubMenuOpen(true);
      }
    };

    const handleMouseLeave = () => {
      setIsSubMenuOpen(false);
    };

    const handleFocus = (event) => {
      if (event.target === containerRef.current && menu.length > 0) {
        setIsSubMenuOpen(true);
      }
    };

    return (
      <div
        {...ContainerProps}
        ref={containerRef}
        onFocus={handleFocus}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        data-testid="open-menu-div"
      >
        <MenuItem
          {...MenuItemProps}
          data-open={isSubMenuOpen || undefined}
          className={className}
          ref={menuItemRef}
        >
          {label}
          <div style={{ flexGrow: 1 }} />
          {rightIcon}
        </MenuItem>
        <Menu
          hideBackdrop
          style={{ pointerEvents: "none", left: 8 }}
          anchorEl={menuItemRef.current}
          anchorOrigin={{
            vertical: "top",
            horizontal: rightAnchored ? "left" : "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: rightAnchored ? "right" : "left",
          }}
          css={customTheme}
          open={isSubMenuOpen}
          autoFocus={false}
          disableAutoFocus
          disableEnforceFocus
          onClose={() => {
            setIsSubMenuOpen(false);
          }}
        >
          <div ref={menuContainerRef} style={{ pointerEvents: "auto" }}>
            {menu}
          </div>
        </Menu>
      </div>
    );
  }
);

NestedMenuItem.propTypes = {
  className: PropTypes.string,
  customTheme: PropTypes.any,
  rightIcon: PropTypes.any,
  label: PropTypes.string,
  ContainerProps: PropTypes.any,
  rightAnchored: PropTypes.bool,
  menu: PropTypes.any,
};

export default NestedMenuItem;
