import { ROLES_CONST } from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
};

const Roles = (state, action) => {
  if (typeof state == "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case ROLES_CONST.GET_ALL_ROLES_REQUEST:
    case ROLES_CONST.GET_ALL_ROLES_FAILURE:
      state = {
        ...state,
        allRoles: null,
      };
      break;
    case ROLES_CONST.GET_ALL_ROLES_SUCCESS:
      state = {
        ...state,
        allRoles: action.payload?.response?.data,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Roles;
