import React from "react";
import PropTypes from "prop-types";

import bannerImage from "../../assets/images/lvtbanner.png";
import "./Layout.scss";

const Layout = ({ children, title }) => {
  return (
    <div>
      <div className="common-layout-header">
        <img
          className="common-banner-image"
          src={bannerImage}
          alt="banner"
          width="100%"
        />
        {title ? (
          <div className="common-banner-image-title">{title}</div>
        ) : null}
      </div>

      <div className="common-layout">{children}</div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
};

export default Layout;
