import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CustomModal from "../../../components/Common/CustomModal";

const LVTUseCaseSelectorModal = ({
  useCasesArray,
  domainId,
  teamSpaceId,
  lvtUseCasesModal,
  lvtUseCasesModalToggle,
}) => {
  const [selectedUseCase, setSelectedUseCase] = useState("");
  const history = useHistory();

  const handleChange = useCallback(
    (event) => {
      setSelectedUseCase(event.target.value);
    },
    [setSelectedUseCase]
  );

  const navigateToUseCaseDPIM = useCallback(() => {
    if (selectedUseCase !== "") {
      lvtUseCasesModalToggle();
      const urlParam = teamSpaceId
        ? `teamSpaceId=${teamSpaceId}`
        : `domainId=${domainId}`;
      history.push(
        `/dataproduct-interaction-map?useCaseId=${selectedUseCase}&${urlParam}`
      );
    }
  }, [lvtUseCasesModalToggle, history, selectedUseCase, domainId, teamSpaceId]);

  const closeModal = useCallback(() => {
    setSelectedUseCase("");
    lvtUseCasesModalToggle();
  }, [lvtUseCasesModalToggle, setSelectedUseCase]);

  return (
    <CustomModal
      displayState={lvtUseCasesModal}
      toggleState={lvtUseCasesModalToggle}
      title="Select Use Case"
      primaryBtn="Select Use Case"
      primaryBtnHandler={navigateToUseCaseDPIM}
      secondaryBtn="Cancel"
      secondaryHandler={closeModal}
      formId="lvt-usecase-selection-modal"
    >
      <div className="lvt-usecase-modal" data-testid="usecase-modal">
        <div className="lvt-usecase-modal-text">
          Select which Use Case you want to design the Data Product Interaction
          Map for. Note that you will always be able to switch to other Use
          Cases without losing progress.
        </div>
        <div className="lvt-usecase-modal-sub-heading">Use Case&#40;s&#41;</div>
        <Select
          value={selectedUseCase}
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          size="small"
          data-testid="usecase-select"
        >
          <MenuItem value="" sx={{ display: "none" }}>
            Select Use Case
          </MenuItem>
          {useCasesArray?.map((useCase) => (
            <MenuItem key={useCase.id} value={useCase.id}>
              {useCase.data.label}
            </MenuItem>
          ))}
        </Select>
      </div>
    </CustomModal>
  );
};

LVTUseCaseSelectorModal.propTypes = {
  useCasesArray: PropTypes.array,
  domainId: PropTypes.string,
  teamSpaceId: PropTypes.string,
  lvtUseCasesModal: PropTypes.bool,
  lvtUseCasesModalToggle: PropTypes.func,
};

export default LVTUseCaseSelectorModal;
