import { DOMAINS_CONST } from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
};

const Domains = (state, action) => {
  if (typeof state == "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case DOMAINS_CONST.GET_ALL_DOMAINS_REQUEST:
    case DOMAINS_CONST.GET_ALL_DOMAINS_FAILURE:
      state = {
        ...state,
        allDomains: null,
      };
      break;
    case DOMAINS_CONST.GET_ALL_DOMAINS_SUCCESS:
      state = {
        ...state,
        allDomains: action.payload?.response?.data?.domainList,
      };
      break;
    case DOMAINS_CONST.GET_ENROLLED_DOMAINS_REQUEST:
    case DOMAINS_CONST.GET_ENROLLED_DOMAINS_FAILURE:
      state = {
        ...state,
        enrolledDomainsList: null,
      };
      break;
    case DOMAINS_CONST.GET_ENROLLED_DOMAINS_SUCCESS:
      state = {
        ...state,
        enrolledDomainsList:
          action.payload?.response?.data?.enrolledDomainsList,
      };
      break;
    case DOMAINS_CONST.GET_COLLIBRA_DOMAINS_REQUEST:
    case DOMAINS_CONST.GET_COLLIBRA_DOMAINS_FAILURE:
      state = {
        ...state,
        collibraDomainList: null,
      };
      break;
    case DOMAINS_CONST.GET_COLLIBRA_DOMAINS_SUCCESS:
      state = {
        ...state,
        collibraDomainList: action.payload?.response?.data,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Domains;
