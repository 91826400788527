import { DATAPRODUCT_WORKSPACE_CONST } from "./actionTypes";

export const addDataProductLink = (payload) => {
  return {
    type: DATAPRODUCT_WORKSPACE_CONST.ADD_DATAPRODUCT_LINK_REQUEST,
    payload: payload,
  };
};
export const addDataProductLinkSuccess = (response) => {
  return {
    type: DATAPRODUCT_WORKSPACE_CONST.ADD_DATAPRODUCT_LINK_SUCCESS,
    payload: response,
  };
};
export const addDataProductLinkFailure = (error) => {
  return {
    type: DATAPRODUCT_WORKSPACE_CONST.ADD_DATAPRODUCT_LINK_FAILURE,
    payload: error,
  };
};
export const deleteDataProductRelationship = (payload) => {
  const { dataProductId, useCaseId } = payload;
  return {
    type: DATAPRODUCT_WORKSPACE_CONST.DELETE_DATA_PRODUCT_RELATIONSHIP_REQUEST,
    payload: payload,
    dataProductId,
    useCaseId,
  };
};
export const deleteDataProductRelationshipSuccess = (response) => {
  return {
    type: DATAPRODUCT_WORKSPACE_CONST.DELETE_DATA_PRODUCT_RELATIONSHIP_SUCCESS,
    payload: response,
  };
};
export const deleteDataProductRelationshipFailure = (error) => {
  return {
    type: DATAPRODUCT_WORKSPACE_CONST.DELETE_DATA_PRODUCT_RELATIONSHIP_FAILURE,
    payload: error,
  };
};

export const deleteDataProductRequest = (payload) => {
  return {
    type: DATAPRODUCT_WORKSPACE_CONST.DELETE_DATA_PRODUCT_REQUEST,
    payload: payload,
  };
};
export const deleteDataProductSuccess = (response) => {
  return {
    type: DATAPRODUCT_WORKSPACE_CONST.DELETE_DATA_PRODUCT_SUCCESS,
    payload: response,
  };
};
export const deleteDataProductFailure = (error) => {
  return {
    type: DATAPRODUCT_WORKSPACE_CONST.DELETE_DATA_PRODUCT_FAILURE,
    payload: error,
  };
};
