import { TEAMSPACE_CONST } from "./actionsTypes";

const initialState = {
  loading: false,
  addDataProductLinkData: null,
};

const TeamSpace = (state, action) => {
  if (typeof state == "undefined") {
    state = initialState;
  }

  switch (action.type) {
    case TEAMSPACE_CONST.GET_TEAMSPACES_BY_DOMAIN_REQUEST:
    case TEAMSPACE_CONST.GET_TEAMSPACES_BY_DOMAIN_FAILURE:
      return {
        ...state,
      };
    case TEAMSPACE_CONST.GET_TEAMSPACES_BY_DOMAIN_SUCCESS:
      return {
        ...state,
        teamSpacesByDomain: action.payload?.response?.data?.teamSpaceList,
        totalTeamSpacesByDomain: action.payload?.response?.data?.total,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default TeamSpace;
