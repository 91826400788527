import React, { useState, useEffect, memo } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  // DropdownItem,
} from "reactstrap";
import { t } from "react-multi-lang";
import PropTypes from "prop-types";

const ProductTypesDropdown = ({
  allDataProductTypes,
  handleProductTypes,
  dataProductTypeFilters,
  filterLable,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [lable, setLable] = useState("");
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    let string = "";
    filterLable.map((each) => {
      string += each + " ";
      return null;
    });
    setLable(string);
  }, [filterLable]);
  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      className="product-dropdown"
    >
      <DropdownToggle>
        {filterLable.length === 3 || filterLable.length === 0
          ? t("HOME.All")
          : lable}
        <i className="fa fa-angle-down"></i>
      </DropdownToggle>
      <DropdownMenu className="product-dropdown-menu">
        <div className="product-dropdown-item">
          <input
            type="checkbox"
            className="input"
            name="All"
            id="All"
            checked={dataProductTypeFilters?.length === 3}
            onChange={(e) => {
              handleProductTypes(e.target);
              toggle();
            }}
          />
          <label htmlFor="All"> {t("HOME.All")} </label>
        </div>
        {allDataProductTypes?.length
          ? allDataProductTypes.map((item) => {
              return (
                <div className="product-dropdown-item" key={item.id}>
                  <input
                    type="checkbox"
                    className="input"
                    name={item.name}
                    id={item.name}
                    checked={
                      dataProductTypeFilters?.length
                        ? dataProductTypeFilters.includes(item.id)
                        : false
                    }
                    onChange={(e) => {
                      handleProductTypes(e.target);
                      toggle();
                    }}
                  />
                  <label htmlFor={item.name} className="text-center">
                    {" "}
                    {item.name}{" "}
                  </label>
                </div>
              );
            })
          : null}
      </DropdownMenu>
    </Dropdown>
  );
};
ProductTypesDropdown.propTypes = {
  handleProductTypes: PropTypes.func.isRequired,
  dataProductTypeFilters: PropTypes.array.isRequired,
  allDataProductTypes: PropTypes.array,
  filterLable: PropTypes.array,
};
export default memo(ProductTypesDropdown);
