// saga.js
import { takeEvery, fork, all, call, put } from "redux-saga/effects";
import { DATAPRODUCT_WORKSPACE_CONST } from "./actionTypes";
import {
  addDataProductLinkSuccess,
  addDataProductLinkFailure,
  deleteDataProductRelationshipSuccess,
  deleteDataProductRelationshipFailure,
  deleteDataProductSuccess,
  deleteDataProductFailure,
} from "./actions";
import { checkHttpStatus } from "../../apiUtils";
import { baseMethod } from "../../../services/BaseService";
import { AXIOS_INSTANCE, DATA_PRODUCTS_API } from "../../apiUtils/config";

export function* addDataProductLink(action) {
  try {
    const serviceMethod = AXIOS_INSTANCE.post(
      `${DATA_PRODUCTS_API}/addDataProductLink`,
      action.payload
    );
    const apiResponse = yield call(baseMethod, serviceMethod, "", null, null);
    const response = yield call(checkHttpStatus, apiResponse);
    if (
      response.status >= 200 &&
      response.status < 300 &&
      response.data?.data?.isSuccess
    ) {
      const responseData = {
        statusCode: 200,
        response: response.data,
      };
      yield put(addDataProductLinkSuccess(responseData));
    } else {
      const responseData = {
        statusCode: response.statusCode || 400,
        data: response.data,
      };
      yield put(addDataProductLinkFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(addDataProductLinkFailure(errorData));
  }
}

export function* deleteDataproductRelation(action) {
  try {
    const serviceMethod = AXIOS_INSTANCE.delete(
      `${DATA_PRODUCTS_API}/deleteDataProductRelationship/${action.dataProductId}/${action.useCaseId}`
    );
    const apiResponse = yield call(baseMethod, serviceMethod, "", null, null);
    const response = yield call(checkHttpStatus, apiResponse);
    if (
      response.status >= 200 &&
      response.status < 300 &&
      response.data?.data?.isSuccess
    ) {
      const responseData = {
        statusCode: 200,
        response: response.data,
      };
      yield put(deleteDataProductRelationshipSuccess(responseData));
    } else {
      const responseData = {
        statusCode: response.statusCode || 400,
        data: response.data,
      };
      yield put(deleteDataProductRelationshipFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(deleteDataProductRelationshipFailure(errorData));
  }
}

export function* deleteDataproduct(action) {
  try {
    const serviceMethod = AXIOS_INSTANCE.delete(
      `${DATA_PRODUCTS_API}/delete/${action.payload.id}`
    );
    const apiResponse = yield call(baseMethod, serviceMethod, "", null, null);
    const response = yield call(checkHttpStatus, apiResponse);
    if (
      response.status >= 200 &&
      response.status < 300 &&
      response.data?.data?.isSuccess
    ) {
      const responseData = {
        statusCode: 200,
        response: response.data,
      };
      yield put(deleteDataProductSuccess(responseData));
    } else {
      const responseData = {
        statusCode: response.statusCode || 400,
        data: response.data,
      };
      yield put(deleteDataProductFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(deleteDataProductFailure(errorData));
  }
}

export function* watchAddDataProductLink() {
  yield takeEvery(
    DATAPRODUCT_WORKSPACE_CONST.ADD_DATAPRODUCT_LINK_REQUEST,
    addDataProductLink
  );
}

export function* watchDeleteDataproductRelation() {
  yield takeEvery(
    DATAPRODUCT_WORKSPACE_CONST.DELETE_DATA_PRODUCT_RELATIONSHIP_REQUEST,
    deleteDataproductRelation
  );
}

export function* watchDeleteDataproduct() {
  yield takeEvery(
    DATAPRODUCT_WORKSPACE_CONST.DELETE_DATA_PRODUCT_REQUEST,
    deleteDataproduct
  );
}

export function* DataProductsRelationSaga() {
  yield all([
    fork(watchAddDataProductLink),
    fork(watchDeleteDataproductRelation),
    fork(watchDeleteDataproduct),
  ]);
}

export default DataProductsRelationSaga;
