import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { pageStateOptions } from ".";
import Tooltip from "@mui/material/Tooltip";
import DeleteDataProductModal from "./DeleteDataProductModal";
import CollibraCommunityModal from "./CollibraCommunityModal";
import { GlobalNotificationHandle } from "../../services/NotificationHandler";

const FinalDecisionButtons = ({
  pageCurrentState,
  pageStateHandler,
  isOwner,
  dpimStatusValid,
  dataProductDetails,
  handleCollibraCommunityModalSubmit,
}) => {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isCollibraCommunityModalVisible, setIsCollibraCommunityModalVisible] =
    useState(false);

  const handleDeleteDataProductSubmit = () => {
    pageStateHandler(pageStateOptions.DELETE);
  };

  const handleReadyButtonClick = () => {
    if (dataProductDetails?.isCollibraDomain)
      setIsCollibraCommunityModalVisible(true);
    else {
      GlobalNotificationHandle(
        299,
        "Cannot proceed to next step as your Data Domain is not associated with a Data Domain within the Collibra Data catalog",
        false,
        true,
        true
      );
    }
  };

  if (!isOwner) {
    return null;
  }

  return (
    <>
      <DeleteDataProductModal
        isDeleteModalVisible={isDeleteModalVisible}
        setIsDeleteModalVisible={setIsDeleteModalVisible}
        onSubmit={handleDeleteDataProductSubmit}
        dataProductName={dataProductDetails.name}
      />
      {isCollibraCommunityModalVisible && (
        <CollibraCommunityModal
          isVisible={isCollibraCommunityModalVisible}
          setIsVisible={setIsCollibraCommunityModalVisible}
          onSubmit={handleCollibraCommunityModalSubmit}
          dataProductDetails={dataProductDetails}
        />
      )}
      <>
        <Divider variant="middle" />
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
          sx={{ margin: "1rem" }}
        >
          {!["Candidate", "Published"].includes(
            dataProductDetails?.dataProductStatus
          ) &&
          [pageStateOptions.REVIEW, pageStateOptions.EDIT].includes(
            pageCurrentState
          ) ? (
            <>
              <Button
                variant="outlined"
                color="error"
                onClick={() => setIsDeleteModalVisible(true)}
              >
                <span>Delete</span>
              </Button>
            </>
          ) : null}
          {pageCurrentState === pageStateOptions.REVIEW ? (
            <>
              {dpimStatusValid ? (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleReadyButtonClick}
                >
                  <span>Ready for Implementation</span>
                </Button>
              ) : (
                <Tooltip
                  title="All DPIMs having this data product need to be submitted"
                  arrow
                  className="cursor-not-allowed"
                >
                  <Button variant="contained" color="secondary">
                    <span>Ready for Implementation</span>
                  </Button>
                </Tooltip>
              )}
            </>
          ) : null}
        </Stack>
      </>
    </>
  );
};

FinalDecisionButtons.propTypes = {
  pageCurrentState: PropTypes.string.isRequired,
  pageStateHandler: PropTypes.func.isRequired,
  isOwner: PropTypes.bool.isRequired,
  dpimStatusValid: PropTypes.bool.isRequired,
  dataProductDetails: PropTypes.object,
  handleCollibraCommunityModalSubmit: PropTypes.func,
};

export default FinalDecisionButtons;
