import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Layout from "../../components/Common/Layout";
import Breadcrumb from "../../components/Common/BreadCrumb";
import "./index.scss";
import GlossaryFAQHeader from "./GlossaryFAQHeader";
import Glossary from "./Glossary";
import FAQ from "./FAQ";

function GlossaryFAQ({ type }) {
  const [searchWord, setSearchWord] = useState("");

  useEffect(() => {
    setSearchWord("");
  }, [type]);

  return (
    <div className="global-top-1 glossary-and-faq-page">
      <Layout>
        <div>
          <Breadcrumb
            styles={{ marginBottom: 30 }}
            steps={[
              { name: "Home Page", href: "/home" },
              { name: "FAQ and Glossary", active: true, href: "#" },
            ]}
          />
          <GlossaryFAQHeader
            type={type}
            searchWord={searchWord}
            setSearchWord={setSearchWord}
          />
          {type === "faq" ? (
            <FAQ searchWord={searchWord} />
          ) : (
            <Glossary searchWord={searchWord} />
          )}
        </div>
      </Layout>
    </div>
  );
}

GlossaryFAQ.propTypes = {
  type: PropTypes.string,
};

export default GlossaryFAQ;
