export const MINIMUM_HEIGHT = 250;
export const MAXIMUM_HEIGHT = 751;

export const sections = [
  {
    id: "vision",
    label: "Vision",
    infoText: "Roche's vision (e.g. Roche Ten-Year Ambitions)",
  },
  {
    id: "goals",
    label: "Business outcome",
    infoText:
      "Goals should be strategic and ambitious, and must align with the company vision. These should focus on high-level outcomes rather than tactical goals specific solutions or outputs.",
  },
  {
    id: "hypothesis",
    label: "Hypothesis",
    infoText:
      'Hypothesis (aka strategic bet) is a value hypothesis that the organization believes can help achieve the goal with which the bet is associated with. If a bet does not function then it is taken out the tree, thereby assuring that time is not spent on something that is not achieving the corporate vision. The word "bet" is purposefully used as it forces a shift in mindset to use a VUCA methodology.',
  },
  {
    id: "useCase",
    label: "Use Case",
    infoText:
      "Use cases (aka initatives) define what should be done to test the value hypothesis in order to determine if the hypothesis should be approved or denied.",
  },
];
export const DIAGRAM_ORDER = ["vision", "goals", "hypothesis", "useCase"];
export const LVT_ITEM = "lvtItem";
export const MOS = "mos";

export const CONNECTABLE_STEPS = {
  vision: ["goals"],
  goals: ["vision", "hypothesis"],
  hypothesis: ["goals", "useCase"],
  useCase: ["hypothesis"],
};

export const STEP_LABELS = {
  vision: "Vision",
  goals: "Business outcome",
  hypothesis: "Hypothesis",
  useCase: "Use Case",
};

export const generateInitialNodes = (nodes) => {
  const updatedHeightSections = {};
  sections.forEach((section) => {
    updatedHeightSections[section.id] = MINIMUM_HEIGHT;
  });
  nodes.forEach((node) => {
    if (node.position.y > (updatedHeightSections[node.parentNode] || 0)) {
      updatedHeightSections[node.parentNode] =
        node.position.y + (node?.height || 140);
    }
  });

  const newSections = sections.map((section) => ({
    id: section.id,
    data: { label: section.label, infoText: section.infoText },
    type: "section",
    position: {
      x: 0,
      y: 0,
    },
    draggable: false,
    selectable: false,
    style: {
      width: 6000,
      height: updatedHeightSections[section.id],
      pointerEvents: "none",
    },
  }));

  newSections.forEach((section, idx) => {
    newSections[idx] = {
      ...section,
      position: {
        x: 170,
        y: idx
          ? newSections[idx - 1].position?.y +
            newSections[idx - 1].style?.height
          : 0,
      },
    };
  });

  return newSections;
};
