import { DATA_PRODUCT_TYPES_CONST } from "./actionTypes";

export const getAllDataProductTypes = () => {
  return {
    type: DATA_PRODUCT_TYPES_CONST.GET_ALL_DATA_PRODUCT_TYPES_REQUEST,
    payload: {},
  };
};
export const getAllDataProductTypesSuccess = (response) => {
  return {
    type: DATA_PRODUCT_TYPES_CONST.GET_ALL_DATA_PRODUCT_TYPES_SUCCESS,
    payload: response,
  };
};
export const getAllDataProductTypesFailure = (error) => {
  return {
    type: DATA_PRODUCT_TYPES_CONST.GET_ALL_DATA_PRODUCT_TYPES_FAILURE,
    payload: error,
  };
};
