import { USERS_CONST } from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
};

const Users = (state, action) => {
  if (typeof state == "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case USERS_CONST.GET_ALL_USERS_REQUEST:
    case USERS_CONST.GET_ALL_USERS_FAILURE:
      state = {
        ...state,
        allUsers: null,
      };
      break;

    case USERS_CONST.GET_ALL_USERS_SUCCESS:
      state = {
        ...state,
        allUsers: action.payload?.response?.data,
      };
      break;

    case USERS_CONST.GET_USERS_BY_DOMAIN_ID_REQUEST:
    case USERS_CONST.GET_USERS_BY_DOMAIN_ID_FAILURE:
      state = {
        ...state,
        usersByDomain: null,
      };
      break;

    case USERS_CONST.GET_USERS_BY_DOMAIN_ID_SUCCESS:
      state = {
        ...state,
        usersByDomain: action.payload?.response?.data,
      };
      break;

    case USERS_CONST.GET_USERS_BY_TEAMSPACE_ID_REQUEST:
    case USERS_CONST.GET_USERS_BY_TEAMSPACE_ID_FAILURE:
      state = {
        ...state,
        usersByTeamSpace: null,
      };
      break;

    case USERS_CONST.GET_USERS_BY_TEAMSPACE_ID_SUCCESS:
      state = {
        ...state,
        usersByTeamSpace: action.payload?.response?.data,
      };
      break;

    case USERS_CONST.GET_USER_BY_TEAM_REQUEST:
    case USERS_CONST.GET_USER_BY_TEAM_FAILURE:
      state = {
        ...state,
        allUsersByTeam: null,
      };
      break;
    case USERS_CONST.GET_USER_BY_TEAM_SUCCESS:
      state = {
        ...state,
        allUsersByTeam: action.payload?.response?.data,
      };
      break;

    case USERS_CONST.MY_PROFILE_REQUEST:
    case USERS_CONST.MY_PROFILE_FAILURE:
      state = {
        ...state,
        getUserProfile: null,
      };
      break;
    case USERS_CONST.MY_PROFILE_SUCCESS:
      state = {
        ...state,
        getUserProfile: action.payload?.response?.data,
      };
      break;
    case USERS_CONST.GENERATE_USER:
      state = {
        ...state,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Users;
