import { DATA_SOURCES_CONST } from "./actionTypes";

export const getAllDataSources = () => {
  return {
    type: DATA_SOURCES_CONST.GET_ALL_DATA_SOURCES_REQUEST,
    payload: {},
  };
};
export const getAllDataSourcesSuccess = (response) => {
  return {
    type: DATA_SOURCES_CONST.GET_ALL_DATA_SOURCES_SUCCESS,
    payload: response,
  };
};
export const getAllDataSourcesFailure = (error) => {
  return {
    type: DATA_SOURCES_CONST.GET_ALL_DATA_SOURCES_FAILURE,
    payload: error,
  };
};

export const getRequiredDataSources = (dataProductId) => {
  return {
    type: DATA_SOURCES_CONST.GET_REQUIRED_DATA_SOURCES_REQUEST,
    payload: dataProductId,
  };
};
export const getRequiredDataSourcesSuccess = (response) => {
  return {
    type: DATA_SOURCES_CONST.GET_REQUIRED_DATA_SOURCES_SUCCESS,
    payload: response,
  };
};
export const getRequiredDataSourcesFailure = (error) => {
  return {
    type: DATA_SOURCES_CONST.GET_REQUIRED_DATA_SOURCES_FAILURE,
    payload: error,
  };
};
