import React from "react";
import PropTypes from "prop-types";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "./EditDomainBreadCrumbs.scss";

const EditDomainBreadCrumbs = ({ editDomain, setEditDomain }) => {
  const handleDataDomainClick = () => {
    setEditDomain({ editStatus: false, domainDetails: {} });
  };
  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      className="admin-edit-breadcrumb"
    >
      <span
        className="admin-edit-breadcrumb-data-domain"
        onClick={handleDataDomainClick}
      >
        Data Domains
      </span>
      <span className="admin-edit-breadcrumb-edit">{`Editing Data Domain - ${editDomain?.domainDetails?.name}`}</span>
    </Breadcrumbs>
  );
};

EditDomainBreadCrumbs.propTypes = {
  editDomain: PropTypes.object,
  setEditDomain: PropTypes.func,
};

export default EditDomainBreadCrumbs;
