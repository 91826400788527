import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "../src/assets/css/Common.css";
import "../src/assets/css/Custom.css";
import { setTranslations, setDefaultLanguage } from "react-multi-lang";
import { store, persistor } from "./store";
import en from "./translations/eng.json";
import GlobalLoader from "./services/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../src/assets/scss/theme.scss";
import { PersistGate } from "redux-persist/integration/react";
// import Amplify from 'aws-amplify'
// import awsconfig from './aws-exports'
// import { withAuthenticator } from '@aws-amplify/ui-react'
//setting en as default lang
setTranslations({ en });
setDefaultLanguage("en");

const app = (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <ToastContainer />
        <GlobalLoader />
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
