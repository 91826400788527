import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import DataProductLineage from "./DataProductLineage";
import PropTypes from "prop-types";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  applyEdgeChanges,
  applyNodeChanges,
  addEdge,
} from "reactflow";
import "reactflow/dist/style.css";

import useLineageData from "../../helpers/CustomHooks/useLineageData";
import DataProductLabel from "./DataProductLabel";
import LineageSection from "./LineageSection";
import LineageFilter from "./LineageFilter";

const DpLineageCanvas = ({
  dataProductDetails,
  lineageData,
  DataProductId,
  fullScreenButtonEnable = true,
}) => {
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);
  const [nodesAndEdgesData, setNodesAndEdgesData] = useState({});
  const nodeTypes = useMemo(
    () => ({
      section: (params) => <LineageSection {...params} />,
      hexagon: (params) => (
        <DataProductLineage {...params} setNodes={setNodes} />
      ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setNodes]
  );

  const [nodesData, edgesData] = useLineageData(
    lineageData,
    dataProductDetails
  );

  useEffect(() => {
    setNodes(nodesData);
    setEdges(edgesData);
    setNodesAndEdgesData({ nodesData: nodesData, edgesData: edgesData });
  }, [nodesData, edgesData]);

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );
  const onConnect = useCallback(
    (connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );

  return (
    <div className="dataproduct-lineage">
      <div className="dataproduct-lineage-wrapper">
        <LineageFilter
          nodesAndEdgesData={nodesAndEdgesData}
          DataProductId={DataProductId}
          setEdges={setEdges}
          setNodes={setNodes}
          fullScreenButtonEnable={fullScreenButtonEnable}
        />
        <div
          style={{ height: "90vh", backgroundColor: "#ffffff" }}
          className="reactflow-container w-100"
        >
          <ReactFlow
            style={{ background: "transparent" }}
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            nodeTypes={nodeTypes}
            attributionPosition="bottom-right"
            zoomOnDoubleClick={false}
            panOnDrag={true}
            disableKeyboardA11y
            defaultViewport={{ x: 0, y: 0, zoom: 1 }}
            fitView
          ></ReactFlow>
        </div>
        <DataProductLabel />
      </div>
    </div>
  );
};

DpLineageCanvas.propTypes = {
  dataProductDetails: PropTypes.object.isRequired,
  lineageData: PropTypes.object.isRequired,
  DataProductId: PropTypes.string.isRequired,
  fullScreenButtonEnable: PropTypes.bool,
};

export default memo(DpLineageCanvas);
