import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { reduxForm, FieldArray } from "redux-form";
import { required } from "../../../helpers/Validations";
import CustomModal from "../../../components/Common/CustomModal";
import { RenderMembers } from "./MosFieldArray";

const AddMosModal = ({
  id,
  addMos,
  addMosToggle,
  autoEnableMOSField,
  mosSubmitHanlder,
  fetchMosApi,
  deleteMosApi,
  handleSubmit,
  userEditAccess,
}) => {
  const [initialValues, setInitialValues] = useState([]);
  const [modalPrimaryBtn, setModalPrimaryBtn] = useState({
    name: "Loading...",
    btnHandler: addMosToggle,
  });

  useEffect(() => {
    const fetchMosDeatils = async () => {
      try {
        let result = await fetchMosApi(id);
        if (result.length > 0) {
          setInitialValues(result);
        }
        setModalPrimaryBtn({ name: "Save", btnHandler: mosSubmitHanlder });
      } catch (error) {
        // catch is present and empty here as were getting warnings in test files.
      }
    };
    fetchMosDeatils();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CustomModal
        displayState={addMos}
        toggleState={addMosToggle}
        hideBackdrop={true}
        title="Measures of Success"
        tooltipText="Measures are often characterized either as leading or lagging indicators. If a leading indicator informs business leaders of how to produce desired results, a lagging indicator measures current production and performance."
        primaryBtn={modalPrimaryBtn.name}
        primaryBtnHandler={modalPrimaryBtn.btnHandler}
        formId="mos-modal"
      >
        <form id="mos-modal" onSubmit={handleSubmit(mosSubmitHanlder)}>
          <FieldArray
            name="mosArray"
            component={RenderMembers}
            initialValues={initialValues}
            autoEnableMOSField={autoEnableMOSField}
            validate={required}
            setModalPrimaryBtn={setModalPrimaryBtn}
            mosSubmitHanlder={mosSubmitHanlder}
            deleteMosApi={deleteMosApi}
            userEditAccess={userEditAccess}
          />
        </form>
      </CustomModal>
    </>
  );
};

AddMosModal.propTypes = {
  id: PropTypes.string,
  addMos: PropTypes.bool,
  autoEnableMOSField: PropTypes.bool,
  addMosToggle: PropTypes.func,
  mosSubmitHanlder: PropTypes.func,
  fetchMosApi: PropTypes.func,
  deleteMosApi: PropTypes.func,
  handleSubmit: PropTypes.func,
  userEditAccess: PropTypes.bool,
};

export default reduxForm({
  form: "AddMosModalForm",
})(memo(AddMosModal));
