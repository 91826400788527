import React from "react";
import PropTypes from "prop-types";
import bannerImage from "../../assets/images/home-banner-new.png";
import SearchBar from "../../pages/Home/SearchBar";

import "./HomeBanner.scss";

const HomeBanner = ({
  isDomainAssigned,
  setDomainList,
  setFilterDetailsBy,
  searchValue,
  setSearchValue,
  testEnvtBtnHandler,
}) => {
  const userDetails = localStorage.getItem("loginInfo")
    ? JSON.parse(localStorage.getItem("loginInfo")).data?.userDetails
    : null;

  return (
    <div
      className="banner-section"
      style={{
        backgroundImage: `linear-gradient(to bottom,rgb(90 110 135 / 3%), #ffffff),url(${bannerImage})`,
      }}
      data-testid="homebanner-banner-section"
    >
      <div className="welcome-section" data-testid="homebanner-welcome-section">
        <h3 className="welcome-text" data-testid="homebanner-welcome-text">
          {"Hello "} {userDetails ? `${userDetails.name}!` : ""}{" "}
        </h3>
        <SearchBar
          setDomainList={setDomainList}
          setFilterDetailsBy={setFilterDetailsBy}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          data-testid="homebanner-search-bar"
        />
        {!isDomainAssigned ? (
          <>
            <p
              className="banner-text"
              data-testid="homebanner-banner-text-no-domain"
            >
              To start your Data Mesh journey you need to request a Data Domain
            </p>
            <button
              className="banner-btn test-env"
              onClick={testEnvtBtnHandler}
              data-testid="homebanner-test-env-btn"
            >
              Get a test environment
            </button>
          </>
        ) : (
          <p
            className="banner-text"
            data-testid="homebanner-banner-text-domain-assigned"
          >
            Let&apos;s get started with your Data Mesh journey
          </p>
        )}
      </div>
    </div>
  );
};

HomeBanner.propTypes = {
  isDomainAssigned: PropTypes.bool,
  setDomainList: PropTypes.func,
  setFilterDetailsBy: PropTypes.func,
  searchValue: PropTypes.any,
  setSearchValue: PropTypes.func,
  testEnvtBtnHandler: PropTypes.func,
};

export default HomeBanner;
