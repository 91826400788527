import React from "react";
import PropTypes from "prop-types";
import { AiOutlineInfoCircle } from "react-icons/ai";
import classNames from "classnames";
import Tooltip from "@mui/material/Tooltip";

const InfoCircle = ({ text, orientation, className }) => {
  return (
    <Tooltip title={text} placement={orientation}>
      <span>
        <AiOutlineInfoCircle
          data-testid="info-circle"
          className={classNames("info-circle", className)}
        ></AiOutlineInfoCircle>
      </span>
    </Tooltip>
  );
};
InfoCircle.propTypes = {
  text: PropTypes.any.isRequired,
  orientation: PropTypes.string.isRequired,
  className: PropTypes.string,
};
export default InfoCircle;
