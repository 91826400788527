// saga.js
import { takeEvery, fork, all, call, put } from "redux-saga/effects";
import { DATA_PRODUCT_TYPES_CONST } from "./actionTypes";
import {
  getAllDataProductTypesSuccess,
  getAllDataProductTypesFailure,
} from "./actions";
import { checkHttpStatus } from "../apiUtils";
import { baseMethod } from "../../services/BaseService";
import { AXIOS_INSTANCE, DATA_PRODUCT_TYPES_API } from "../apiUtils/config";

export function* getAllDataProductTypes() {
  try {
    const serviceMethod = AXIOS_INSTANCE.get(
      `${DATA_PRODUCT_TYPES_API}/getAll`
    );
    const apiResponse = yield call(baseMethod, serviceMethod, "", null, null);
    const response = yield call(checkHttpStatus, apiResponse);
    if (
      response.status >= 200 &&
      response.status < 300 &&
      response.data?.data?.isSuccess
    ) {
      const responseData = {
        statusCode: 200,
        response: response.data,
      };
      yield put(getAllDataProductTypesSuccess(responseData));
    } else {
      const responseData = {
        statusCode: response.statusCode || 400,
        data: response.data,
      };
      yield put(getAllDataProductTypesFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getAllDataProductTypesFailure(errorData));
  }
}

export function* watchGetAllDataProductTypes() {
  yield takeEvery(
    DATA_PRODUCT_TYPES_CONST.GET_ALL_DATA_PRODUCT_TYPES_REQUEST,
    getAllDataProductTypes
  );
}

export function* DataProductTypesSaga() {
  yield all([fork(watchGetAllDataProductTypes)]);
}

export default DataProductTypesSaga;
