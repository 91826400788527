import { useCallback } from "react";

const useMouseDrag = () => {
  const handleMouseEnter = useCallback((e, direction) => {
    const border = direction === "vertical" ? "borderLeft" : "borderBottom";
    e.sourceEvent.target.style[border] = "5px #7D0096 dashed";
  }, []);

  const handleMouseLeave = useCallback(
    (e, params, direction, id, handleSectionSizeChange) => {
      if (direction === "vertical") {
        removeStylesFromClass("borderLeft");
        handleSectionSizeChange(id, params.width);
      } else {
        removeStylesFromClass("borderBottom");
        handleSectionSizeChange(id, params.height);
      }
    },
    []
  );

  const removeStylesFromClass = (borderDirection) => {
    const bottomLinesElement = document.querySelectorAll(
      ".react-flow__resize-control"
    );
    bottomLinesElement.forEach((element) => {
      if (element.style[borderDirection]) {
        element.style[borderDirection] = "";
        return;
      }
    });
  };

  return { handleMouseEnter, handleMouseLeave };
};

export default useMouseDrag;
