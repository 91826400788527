import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { AXIOS_INSTANCE, DATA_PRODUCTS_API } from "../../store/apiUtils/config";
import { GlobalNotificationHandle } from "../../services/NotificationHandler";

const DataProductIsFavorite = ({
  dataProductId,
  defaultValue,
  updateHandler,
}) => {
  const [isFavorite, setIsFavorite] = useState(false);

  useEffect(() => {
    setIsFavorite(defaultValue !== undefined ? defaultValue : false);
  }, [defaultValue]);

  const createIsFavoriteRel = useCallback(
    async (event) => {
      event.stopPropagation();
      if (dataProductId !== undefined) {
        try {
          const { data } = await AXIOS_INSTANCE.post(
            `${DATA_PRODUCTS_API}/addFavoriteDataProduct`,
            { dataProductId }
          );
          if (data?.isSuccess) {
            setIsFavorite(true);
            if (updateHandler !== undefined) updateHandler();
          }
        } catch (e) {
          GlobalNotificationHandle(
            e.response.status,
            e.response.data.errorDescription,
            false,
            true
          );
        }
      }
    },
    [dataProductId, updateHandler]
  );

  const deleteIsFavoriteRel = useCallback(
    async (event) => {
      event.stopPropagation();
      if (dataProductId !== undefined) {
        try {
          const { data } = await AXIOS_INSTANCE.delete(
            `${DATA_PRODUCTS_API}/deleteFavoriteDataProduct/${dataProductId}`
          );
          if (data?.isSuccess) {
            setIsFavorite(false);
            if (updateHandler !== undefined) updateHandler();
          }
        } catch (e) {
          GlobalNotificationHandle(
            e.response.status,
            e.response.data.errorDescription,
            false,
            true
          );
        }
      }
    },
    [dataProductId, updateHandler]
  );

  return (
    <>
      {isFavorite ? (
        <FavoriteIcon
          color="secondary"
          onClick={deleteIsFavoriteRel}
          sx={{ cursor: "pointer" }}
        />
      ) : (
        <FavoriteBorderIcon
          color="secondary"
          onClick={createIsFavoriteRel}
          sx={{ cursor: "pointer" }}
        />
      )}
    </>
  );
};

DataProductIsFavorite.propTypes = {
  dataProductId: PropTypes.string,
  defaultValue: PropTypes.bool,
  updateHandler: PropTypes.func,
};

export default DataProductIsFavorite;
