import React, { useState } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import classNames from "classnames";
import { BiPlus } from "react-icons/bi";
import { Typography } from "@mui/material";
import "./CustomDataMultiSelectField.scss";
import { socket } from "../../services/Socket";
import { GlobalNotificationHandle } from "../../services/NotificationHandler";
import DataProductListModal from "./DataProductListModal";

const CustomDataMultiSelectField = ({
  isEdit,
  data = [],
  options = [],
  setOptions,
  customButtonText,
  customTextClass,
  customEditClass,
  error,
  errorMessage,
  resetError,
  resetErrorToTrue,
  domainId,
  isTeamSpace = false,
  isTeamSpaceOwner = false,
  isAdminPanel = false,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);

  const handleShowModal = (data) => {
    setModalData(data);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalData([]);
    setModalOpen(false);
  };
  const onItemChange = (item, changedItem) => {
    const modifiedItems = data.map((field) => {
      if (field.id !== item.id) {
        return field;
      }

      return changedItem;
    });
    setOptions(modifiedItems);
    if (isTeamSpaceOwner) {
      if (!modifiedItems.length) {
        resetErrorToTrue();
      } else {
        resetError();
      }
    }
  };

  const handleAddOption = () => setOptions((options) => [...options, {}]);

  const handleDelete = (item) => {
    if (domainId) {
      socket.emit("validateUserBeforeDelete", {
        domainId,
        userIdToValidate: item.id,
      });
      socket.once("userValidatedData", (data) => {
        if (data.userHasDP) {
          GlobalNotificationHandle(
            400,
            data.message,
            false,
            false,
            true,
            data.dpList,
            handleShowModal
          );
        } else {
          setOptions((options) =>
            options.filter((option) => option.id !== item.id)
          );
        }
      });
    }
    if (isTeamSpace || isAdminPanel) {
      setOptions((options) =>
        options.filter((option) => option.id !== item.id)
      );
      if (isTeamSpaceOwner && data.length === 1) {
        resetErrorToTrue();
      }
    }
  };

  const isAddAvailable = !data.find((item) => !item.id);

  return (
    <>
      {isEdit ? (
        <div
          className={classNames(
            customEditClass,
            "custom-data-multi-select-field-edit"
          )}
        >
          {data?.map((item) => (
            <div
              key={item.id}
              className="custom-data-multi-select-field-edit-item"
            >
              <Select
                styles={{
                  option: (styles) => ({
                    ...styles,
                    color: "#000",
                    background: "#FFF",
                    cursor: "pointer",
                    fontSize: 16,
                  }),
                }}
                className="custom-data-multi-select-field-edit-item-select"
                value={item}
                onChange={(changedItem) => onItemChange(item, changedItem)}
                isDisabled={item.disabled ? true : false}
                isLoading={false}
                isClearable={false}
                isRtl={false}
                isSearchable={true}
                options={options}
                getOptionLabel={(e) => e.name}
                menuPortalTarget={document.querySelector("body")}
              />
              <IconButton
                data-testid={`delete-button-${item.id}`}
                onClick={() => handleDelete(item)}
                disabled={item.disabled}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          ))}
          {isModalOpen && (
            <DataProductListModal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              dpList={modalData}
            />
          )}

          <Button
            disabled={!isAddAvailable}
            startIcon={<BiPlus />}
            className="custom-data-multi-select-field-edit-item-button"
            variant="text"
            onClick={handleAddOption}
          >
            {customButtonText || "Add new item"}
          </Button>
        </div>
      ) : (
        <div
          className={classNames(
            customTextClass,
            "custom-data-multi-select-field"
          )}
        >
          {data?.map((item, key) => (
            <p key={key}>{item.name}</p>
          ))}
        </div>
      )}
      {error && (
        <Typography className="team-errormessage">{errorMessage}</Typography>
      )}
    </>
  );
};

CustomDataMultiSelectField.propTypes = {
  isEdit: PropTypes.bool,
  data: PropTypes.array,
  options: PropTypes.array,
  setOptions: PropTypes.func,
  customButtonText: PropTypes.string,
  customTextClass: PropTypes.string,
  customEditClass: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  resetError: PropTypes.func,
  resetErrorToTrue: PropTypes.func,
  domainId: PropTypes.string,
  isTeamSpace: PropTypes.bool,
  isTeamSpaceOwner: PropTypes.bool,
  isAdminPanel: PropTypes.bool,
};

export default CustomDataMultiSelectField;
