import React from "react";
import { Button, Row, Col } from "reactstrap";
import { t } from "react-multi-lang";
import { useHistory } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import buttonIcon from "../../assets/images/create_button.svg";
import PropTypes from "prop-types";
import bannerImage from "../../assets/images/home-banner.png";

const DomainHeader = (props) => {
  const history = useHistory();
  const { domainName, domainId, enrolledDomainsList } = props;
  const activeTab = props.tab;

  const renderCreateDataProductButton = () => {
    if (
      enrolledDomainsList &&
      enrolledDomainsList.find((domain) => domain.id === domainId)
    ) {
      return (
        <Button
          color="primary"
          className="primary-btn mt-4"
          onClick={() => history.push(`/create-data-product`)}
          data-testid="create-data-product-button"
        >
          <img src={buttonIcon} alt="buttonIcon" className="mr-2" />
          {t("HOME.CREATE_DATA")}
        </Button>
      );
    } else return null;
  };

  return (
    <div
      className="container-fluid container-fluid-m"
      data-testid="domain-header"
    >
      <img src={bannerImage} alt="bannerImage" className="banner-image" />
      <Row>
        <Col xs={12} xl={9} className="m-0 mt-4">
          <Row>
            <Col xs={6} sm={1} xl={1}>
              <div
                className="mb-2 cursor-pointer icon-border"
                onClick={() => history.push("/home")}
                data-testid="back-button"
              >
                <BiArrowBack className="back-icon-size" />
              </div>
            </Col>
            <Col
              xs={12}
              sm={11}
              xl={11}
              className={
                props.tab === "1"
                  ? "domain-title page-header"
                  : "domain-title page-header pl-4"
              }
              data-testid="domain-title"
            >
              <h3>
                {domainName}{" "}
                {props.tab === "1"
                  ? t("DATA_PRODUCT.TITLE")
                  : t("DASHBORAD.TEAMS")}
              </h3>
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={3} xl={3}>
          <div className="f-right button-alignment">
            {activeTab === "2" ? (
              <Button
                color="primary"
                className="primary-btn mt-4"
                onClick={() =>
                  history.push(`/onboardnewteam?domainId=${domainId}`)
                }
                data-testid="onboard-new-team-button"
              >
                <img src={buttonIcon} alt="buttonIcon" className="mr-2" />
                {t("HOME.ON_BORAD_NEW_TEAM")}
              </Button>
            ) : (
              renderCreateDataProductButton()
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

DomainHeader.propTypes = {
  domainName: PropTypes.string,
  domainId: PropTypes.string,
  tab: PropTypes.string,
  enrolledDomainsList: PropTypes.array,
};

export default DomainHeader;
