import { DATA_PRODUCT_TYPES_CONST } from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
};

const DataProductTypes = (state, action) => {
  if (typeof state == "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case DATA_PRODUCT_TYPES_CONST.GET_ALL_DATA_PRODUCT_TYPES_REQUEST:
    case DATA_PRODUCT_TYPES_CONST.GET_ALL_DATA_PRODUCT_TYPES_FAILURE:
      state = {
        ...state,
        allDataProductTypes: null,
      };
      break;
    case DATA_PRODUCT_TYPES_CONST.GET_ALL_DATA_PRODUCT_TYPES_SUCCESS:
      state = {
        ...state,
        allDataProductTypes: action.payload?.response?.data,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default DataProductTypes;
