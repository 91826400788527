import axios from "axios";

import { jwtToken } from "../../helpers/token";

// base url
export const BASE_URL = `${process.env.REACT_APP_API_URL}`;

// creating global instance for the axios to call apis
export const AXIOS_INSTANCE = axios.create({
  headers: { "Access-Control-Allow-Origin": "*" },
});

AXIOS_INSTANCE.defaults.headers["Content-Type"] = "application/json";
AXIOS_INSTANCE.defaults.headers["Accept-Language"] = "en";

export const GET_AXIOS_INSTANCE = async (token) => {
  if (token) {
    AXIOS_INSTANCE.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else if (localStorage.getItem("loginInfo")) {
    const token = await jwtToken();
    AXIOS_INSTANCE.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
};

GET_AXIOS_INSTANCE();

//Domains api
export const DOMAINS_API = `${BASE_URL}/Domain`;
//DataProducts api
export const DATA_PRODUCTS_API = `${BASE_URL}/DataProduct`;
//DataProduct types api
export const DATA_PRODUCT_TYPES_API = `${BASE_URL}/DataProductType`;
//Roles api
export const ROLES_API = `${BASE_URL}/Role`;
//Teams api
export const TEAMS_API = `${BASE_URL}/Team`;
//Users api
export const USERS_API = `${BASE_URL}/User`;
//LeanValueTree api
export const LEAN_VALUE_TREE_API = `${BASE_URL}/LeanValueTree`;
//Data Product Interaction map api
export const DATA_PRODUCT_INTERACTION_MAP_API = `${BASE_URL}/DataProductInteractionMap`;
//Measure Of Success api
export const MEASURE_OF_SUCCESS_API = `${BASE_URL}/MeasureOfSuccess`;
//mockup templates api
export const MOCKUP_TEMPLATES_API = `${BASE_URL}/MockUpTemplates`;
// data source api
export const DATA_SOURCE_API = `${BASE_URL}/DataSource`;
// Collibra api
export const COLLIBRA_API = `${BASE_URL}/Collibra`;
// team spaces api
export const TEAM_SPACES_API = `${BASE_URL}/TeamSpaces`;
