import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import RoleMembers from "./RoleMembers";
import AddNewResponsibility from "./AddNewResponsibility";

const PaginatedTeamMembers = (props) => {
  const {
    teamMembers,
    teamMembersHandler,
    allRoles,
    usersByDomain,
    editAccess = false,
    editState = false,
  } = props;

  return (
    <>
      {editState ? (
        <Stack spacing={2} sx={{ marginBottom: "1rem" }}>
          <Typography
            variant="caption"
            className="wrap-text"
            sx={{ color: "#ff0000" }}
            data-testid="editAccessTextHelper"
          >
            {!editAccess ? "Only data product owner can change members" : null}
          </Typography>
        </Stack>
      ) : null}
      <Grid container columnSpacing={8} rowSpacing={8}>
        {allRoles
          ?.filter((roleDetails) => teamMembers[roleDetails.name] !== undefined)
          .map((roleData, index) => (
            <Grid
              item
              key={roleData.name}
              data-testid={`RoleMembers-${roleData.name}`}
            >
              <RoleMembers
                xs
                key={`${index}-${roleData.name}`}
                teamMembers={teamMembers[roleData.name]}
                roleData={roleData}
                editState={editState}
                editAccess={editAccess}
                usersByDomain={usersByDomain}
                teamMembersHandler={teamMembersHandler}
              />
            </Grid>
          ))}
        <Grid item xs={3} data-testid="AddNewResponsibility">
          {editState && editAccess ? (
            <AddNewResponsibility
              teamMembers={teamMembers}
              usersByDomain={usersByDomain}
              allRoles={allRoles}
              teamMembersHandler={teamMembersHandler}
            />
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};

PaginatedTeamMembers.propTypes = {
  teamMembers: PropTypes.object.isRequired,
  teamMembersHandler: PropTypes.func.isRequired,
  allRoles: PropTypes.array.isRequired,
  usersByDomain: PropTypes.array.isRequired,
  editAccess: PropTypes.bool,
  editState: PropTypes.bool,
};

export default PaginatedTeamMembers;
