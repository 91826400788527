import React from "react";
import Loader from "react-spinners/FadeLoader";
import Header from "../HorizontalLayout/Header";
import HomeBanner from "./HomeBanner";
const LazyLoading = () => {
  return (
    <div id="layout-wrapper">
      <div className="backdrop-loader bg-white" id="loader">
        <Header />
        <HomeBanner />
        <div className="overlay " data-testid="loader-icon">
          <Loader color={"#0E48EB"} loading={true} margin={2} size={20} />
        </div>
      </div>
    </div>
  );
};
export default LazyLoading;
