import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { FaRegEdit } from "react-icons/fa";

import CustomDataInputField from "../../components/Common/CustomDataInputField";
import CustomDataMultiSelectField from "../../components/Common/CustomDataMultiSelectField";
import DomainType from "../../components/Common/DomainType";
import { socket } from "../../services/Socket";
import { GlobalNotificationHandle } from "../../services/NotificationHandler";
import useModal from "../../helpers/CustomHooks/useModal";
import DeleteDomainModal from "./DeleteDomainModal";
import "./EditDomain.scss";

const EditDomain = ({
  selectedDomain,
  setEditDomain,
  PAGE_LIMIT,
  currentPage,
  setSearchWord,
}) => {
  const [members, setMembers] = useState([]);
  const [owners, setOwners] = useState([]);
  const [selectableUsers, setSelectableUsers] = useState([]);
  const [domainName, setDomainName] = useState(selectedDomain?.name || "");
  const [definition, setDefinition] = useState(
    selectedDomain?.description || ""
  );
  const { deleteDomainModal, deleteDomainModalToggle } = useModal();

  const { users } = useSelector((state) => ({
    users: state.Users?.allUsers?.userList,
  }));

  useEffect(() => {
    setDomainName(selectedDomain?.name || "");
    setDefinition(selectedDomain?.description || "");
  }, [selectedDomain]);

  useEffect(() => {
    //To fetch owners and memebers
    socket.emit(
      "getDomainById",
      { domainId: selectedDomain.id },
      socket.lastJoinedRoom
    );

    socket.on("receiveDomainData", (response) => {
      setMembers(response.members);
      setOwners(response.owners);
    });

    return () => {
      socket.off("receiveDomainData");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!users || !members || !owners) {
      return;
    }

    const filteredUserList = users.filter((user) => {
      const isMember = members.find((member) => user.id === member.id);
      const isOwner = owners.find((owner) => user.id === owner.id);
      return !isMember && !isOwner;
    });

    setSelectableUsers(filteredUserList);
  }, [users, members, owners]);

  const handleSave = async () => {
    await socket.emit(
      "updateDataDomain",
      {
        domainId: selectedDomain.id,
        name: domainName,
        members,
        owners,
        description: definition,
        isAdmin: true,
        limit: PAGE_LIMIT,
        page: currentPage
      },
      socket.lastJoinedRoom
    );
    await GlobalNotificationHandle(
      200,
      `${domainName} data domain updated`,
      true,
      true
    );
    await handleCancel();
    setSearchWord("")
  };

  const handleDelete = async (domain) => {
    await socket.emit(
      "deleteDomain",
      {
        id: domain.id,
        limit: PAGE_LIMIT,
        page: currentPage
      },
      socket.lastJoinedRoom
    );
    await handleCancel();
    await GlobalNotificationHandle(
      200,
      `${domainName} data domain deleted`,
      true,
      true
    );
    setSearchWord("")
  };

  const handleCancel = async () => {
    await setEditDomain({ editStatus: false, domainDetails: {} });
  };

  return (
    <div className="admin-details-card">
      <Grid container>
        <Grid item xs={6}>
          <CustomDataInputField
            data={domainName}
            onChangeHandler={setDomainName}
            textVariant="body2"
            isMultiLine={true}
            textFieldClass="admin-details-card-title"
            editState={selectedDomain.isCollibra ? false : true}
            component={selectedDomain.isCollibra ? "span" : ""}
          />
          <span className="admin-details-card-domain-type">
            <DomainType domainType={selectedDomain?.domainType} />
          </span>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              className="admin-details-card-button"
              variant={"outlined"}
              color="secondary"
              onClick={() => deleteDomainModalToggle()}
              disabled={selectedDomain.isCollibra ? true : false}
            >
              Delete Data Domain
            </Button>
            <Button
              className="admin-details-card-button"
              variant={"outlined"}
              color="secondary"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              className="admin-details-card-button"
              startIcon={<FaRegEdit />}
              variant={"contained"}
              color="secondary"
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        </Grid>
      </Grid>

      <div className="admin-details-card-body">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              className="admin-details-card-body-title"
              variant="subtitle2"
            >
              Definition:
            </Typography>
            <CustomDataInputField
              data={definition}
              onChangeHandler={setDefinition}
              textVariant="body2"
              isMultiLine={true}
              editState={selectedDomain.isCollibra ? false : true}
              textFieldClass="admin-details-card-body-definition"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              className="admin-details-card-body-title"
            >
              Data Domain Owner:
            </Typography>
            <CustomDataMultiSelectField
              domainId={selectedDomain.id}
              customEditClass="admin-details-card-members-edit"
              customTextClass="admin-details-card-members"
              data={owners}
              options={selectableUsers}
              isEdit={selectedDomain.isCollibra ? false : true}
              setOptions={setOwners}
              customButtonText="Add new person"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              className="admin-details-card-body-title"
            >
              Other Members:
            </Typography>
            <CustomDataMultiSelectField
              domainId={selectedDomain.id}
              customEditClass="admin-details-card-members-edit"
              customTextClass="admin-details-card-members"
              data={members}
              options={selectableUsers}
              isEdit={true}
              setOptions={setMembers}
              customButtonText="Add new person"
            />
          </Grid>
        </Grid>
      </div>
      <DeleteDomainModal
        selectedDomain={selectedDomain}
        handleDelete={handleDelete}
        deleteDomainModal={deleteDomainModal}
        deleteDomainModalToggle={deleteDomainModalToggle}
      />
    </div>
  );
};

EditDomain.propTypes = {
  selectedDomain: PropTypes.object,
  setEditDomain: PropTypes.func,
  PAGE_LIMIT: PropTypes.number,
  currentPage: PropTypes.number,
  setSearchWord: PropTypes.func,
};

export default EditDomain;
