import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { getUsersByDomainId } from "../../store/actions";
import CustomDataInputField from "../../components/Common/CustomDataInputField";
import RedAsterisk from "../../components/Common/RedAsterisk";
import CustomDataMultiSelectField from "../../components/Common/CustomDataMultiSelectField";
import useModal from "../../helpers/CustomHooks/useModal";
import DeleteDomainModal from "../AdminDetails/DeleteDomainModal";
import { socket } from "../../services/Socket";
import { GlobalNotificationHandle } from "../../services/NotificationHandler";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import "./EditTeamSpace.scss";

const EditTeamSpace = ({
  selectedTeamSpace,
  setEditTeamSpace,
  isEdit,
  domainName,
}) => {
  const [members, setMembers] = useState([]);
  const [owners, setOwners] = useState([]);
  const [selectableUsers, setSelectableUsers] = useState([]);
  const [teamSpaceNameError, setTeamSpaceNameError] = useState(false);
  const [teamSpaceDescriptionError, setTeamSpaceDescriptionError] =
    useState(false);
  const [ownerError, setOwnerError] = useState(false);
  const dispatch = useDispatch();
  const [teamSpaceName, setTeamSpaceName] = useState(
    selectedTeamSpace?.name || ""
  );
  const teamSpaceId = selectedTeamSpace?.id;
  const [definition, setDefinition] = useState(
    selectedTeamSpace?.description || ""
  );

  const { deleteDomainModal, deleteDomainModalToggle } = useModal();
  const domainUser = "domainUsers";
  const { users } = useSelector((state) => ({
    users: state.Users?.usersByDomain?.userList,
  }));

  useEffect(() => {
    if (isEdit) {
      dispatch(getUsersByDomainId(selectedTeamSpace?.domainId, domainUser));
    }
  }, [isEdit, dispatch]);

  useEffect(() => {
    setTeamSpaceName(selectedTeamSpace?.name || "");
    setDefinition(selectedTeamSpace?.description || "");
  }, [selectedTeamSpace]);

  useEffect(() => {
    //To fetch owners and memebers
    socket.emit("getTeamSpaceById", { teamSpaceId }, socket.lastJoinedRoom);

    socket.on("receiveTeamSpaceData", (response) => {
      setMembers(response.members);
      setOwners(response.owners);
    });

    return () => {
      socket.off("receiveTeamSpaceData");
    };
  }, []);

  useEffect(() => {
    if (!users || !members || !owners) {
      return;
    }

    const filteredUserList = users.filter((user) => {
      const isMember = members.find((member) => user.id === member.id);
      const isOwner = owners.find((owner) => user.id === owner.id);
      return !isMember && !isOwner;
    });

    setSelectableUsers(filteredUserList);
  }, [users, members, owners]);

  const handleSave = async () => {
    setTeamSpaceDescriptionError(false);
    setTeamSpaceNameError(false);
    setOwnerError(false);
    if (!teamSpaceName || !definition || !owners[0]?.id || !owners?.length) {
      if (!teamSpaceName) setTeamSpaceNameError(true);
      if (!definition) setTeamSpaceDescriptionError(true);
      if (!owners[0]?.id || owners?.length === 0) setOwnerError(true);

      return;
    }
    await socket.emit(
      "updateTeamSpaceData",
      {
        teamSpaceId: selectedTeamSpace?.id,
        domainId: selectedTeamSpace?.domainId,
        name: teamSpaceName,
        members,
        owners,
        description: definition,
        isAdmin: true,
      },
      socket.lastJoinedRoom
    );
    await GlobalNotificationHandle(
      200,
      `${teamSpaceName} Team Space updated`,
      true,
      true
    );
    await handleCancel();
  };

  const handleDelete = async () => {
    await socket.emit(
      "deleteTeamSpace",
      {
        teamSpaceId: selectedTeamSpace?.id,
        domainId: selectedTeamSpace?.domainId,
      },
      socket.lastJoinedRoom
    );
    await GlobalNotificationHandle(
      200,
      `${selectedTeamSpace?.name} Team Space deleted`,
      true,
      true
    );
    await handleCancel();
  };

  const handleCancel = async () => {
    setTeamSpaceDescriptionError(false);
    setTeamSpaceNameError(false);
    setOwnerError(false);
    await setEditTeamSpace({ editStatus: false, teamSpaceDetails: {} });
  };

  return (
    <div className="admin-details-card">
      <Grid container>
        <Grid item xs={6}>
          <Typography
            className="admin-details-card-body-title"
            variant="subtitle2"
          >
            Team Space Name: <RedAsterisk isEditMode={isEdit} />
          </Typography>
          <CustomDataInputField
            data={teamSpaceName}
            onChangeHandler={setTeamSpaceName}
            textVariant="body2"
            isMultiLine={false}
            textFieldClass="team-space-details-card-title-text"
            editState={true}
            component={""}
            error={teamSpaceNameError}
            errorMessage="Please enter the required information."
            handleError={setTeamSpaceNameError}
            hasError={true}
          />
        </Grid>
      </Grid>
      <div className="team-space-details-card-body">
        <Typography
          className="admin-details-card-body-title"
          variant="subtitle2"
        >
          Data Domain:
        </Typography>
        <div className="team-space-details-card-chip">
          <span>{domainName}</span>
        </div>
      </div>
      <div className="admin-details-card-body">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              className="admin-details-card-body-title"
              variant="subtitle2"
            >
              Definition: <RedAsterisk isEditMode={isEdit} />
            </Typography>
            <CustomDataInputField
              data={definition}
              onChangeHandler={setDefinition}
              textVariant="body2"
              isMultiLine={true}
              editState={true}
              textFieldClass="team-space-details-card-body-definition"
              textClass="team-sapce-details-card-body-definition"
              inputHeight="100px"
              error={teamSpaceDescriptionError}
              errorMessage="Please enter the required information."
              handleError={setTeamSpaceDescriptionError}
              hasError={true}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              className="admin-details-card-body-title"
            >
              Team Space Owner: <RedAsterisk isEditMode={isEdit} />
            </Typography>
            <CustomDataMultiSelectField
              customEditClass="admin-details-card-members-edit"
              customTextClass="admin-details-card-members"
              data={owners}
              options={selectableUsers}
              isEdit={true}
              setOptions={setOwners}
              customButtonText="Add new person"
              error={ownerError}
              errorMessage="Please select the required information."
              resetError={() => setOwnerError(false)}
              resetErrorToTrue={() => setOwnerError(true)}
              isTeamSpace={true}
              isTeamSpaceOwner={true}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              className="admin-details-card-body-title"
            >
              Other Members:
            </Typography>
            <CustomDataMultiSelectField
              customEditClass="admin-details-card-members-edit"
              customTextClass="admin-details-card-members"
              data={members}
              options={selectableUsers}
              isEdit={true}
              setOptions={setMembers}
              customButtonText="Add new person"
              isTeamSpace={true}
            />
          </Grid>
        </Grid>
      </div>
      <div className="team-space-details-card-button-container">
        <Button
          variant={"outlined"}
          color="secondary"
          onClick={() => deleteDomainModalToggle()}
          disabled={false}
        >
          Delete Team Space
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => handleCancel()}
        >
          <span>Cancel</span>
        </Button>
        <Button
          className="team-space-details-card-button"
          variant="contained"
          color="secondary"
          disabled={false}
          onClick={handleSave}
        >
          Save
        </Button>
      </div>

      <DeleteDomainModal
        selectedDomain={selectedTeamSpace}
        handleDelete={handleDelete}
        deleteDomainModal={deleteDomainModal}
        deleteDomainModalToggle={deleteDomainModalToggle}
      />
    </div>
  );
};

EditTeamSpace.propTypes = {
  selectedTeamSpace: PropTypes.object,
  setEditTeamSpace: PropTypes.func,
  isEdit: PropTypes.bool,
  domainName: PropTypes.string,
};

export default EditTeamSpace;
