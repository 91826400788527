export const status200 = 200;
export const status300 = 300;
export const status400 = 400;
export const status401 = 401;
export const status600 = 600;

export const SPECIAL_CHARACTERS_REGEX = /^[A-Za-z0-9 ]+$/;
/* eslint-disable no-useless-escape */
export const SELECTED_SPECIAL_CHARACTER_REGEX =
  /[`~!@#$%^&*()_|+\-=?;:.<>\{\}\[\]\\\/]/gi;
export const URL_REGEX =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g;

export const DATA_PRODUCT_TYPE_COLOR = {
  "Source-Oriented Data Product": "#4a83bc",
  "Aggregate Data Product": "#e5a73b",
  "Customer-Oriented Data Product": "#34c38f",
};

export const USER_ROLES = {
  OWNER: "Data Product Owner",
  MANAGER: "Data Product Manager",
};
export const CAN_PERFORM_DATA_PRODUCT_ACTION_ROLES = [
  "Data Product Owner",
  "Data Product Manager",
];

export const GO_TO_COLLIBRA_LINK_TEXT = "Go to Collibra";
