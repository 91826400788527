import React from "react";
import { Col, Row } from "reactstrap";
import dataProductIcon from "../../assets/images/new-data-product.png";

export const description = {
  dataProduct: {
    para1:
      "While creating your Data Product Interaction Map, you will be able to add Data Products. After that, your Data Product will be displayed here",
    para2:
      "If you see your product in Data Product Interaction Map but it is not displayed here, please reach out to your Data Product Team colleagues to add you to your data product",
  },
  dataInteractionMap: {
    para1:
      "You need to have use cases in your Lean Value Tree in order to design Data Product Interaction Map. After that, Data Product Interaction Map will be displayed here.",
    para2:
      "If you created your Data Product Interaction Map but it is not displayed here, please reach out to your product team colleagues to add you to data product.",
  },
};

const NoDataProducts = () => {
  return (
    <div className="no-products-image" data-testid="no-products-image">
      <div
        className="mr-3 mt-4 no-product-accordion"
        data-testid="no-product-accordion"
      >
        <Row>
          <Col className="text-center">
            <img
              className="no-product-img"
              src={dataProductIcon}
              alt="dataProduct"
              data-testid="no-product-img"
            />
          </Col>
        </Row>
        <Row>
          <Col className="description-section">
            <p data-testid="no-product-description-para1">
              {description.dataProduct.para1}
            </p>
            <p data-testid="no-product-description-para2">
              {description.dataProduct.para2}
            </p>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default NoDataProducts;
