import {
  takeEvery,
  fork,
  all,
  call,
  put,
  select,
  take,
} from "redux-saga/effects";
import { DATAPRODUCT_CONST } from "./actionTypes";
import { DOMAINS_CONST } from "../../store/domains/actionTypes";
import {
  getAllDataProductsSuccess,
  getAllDataProductsFailure,
  getDataProductsByDomainSuccess,
  getDataProductsByDomainFailure,
  getCollibraDataProductsByDomainSuccess,
  getCollibraDataProductsByDomainFailure,
  getMyDataProductsFailure,
  getMyDataProductsSuccess,
  createDataProductSuccess,
  createDataProductFailure,
  updateDataProductSuccess,
  updateDataProductFailure,
  getDataProductsByCriteriaSuccess,
  getDataProductsByCriteriaFailure,
  getDataProductsByIdSuccess,
  getDataProductsByIdFailure,
  getAllFavoritesDataProductsSuccess,
  getAllFavoritesDataProductsFailure,
  getRequiredDataProductsSuccess,
  getRequiredDataProductsFailure,
} from "./actions";
import { checkHttpStatus } from "../apiUtils";
import { baseMethod } from "../../services/BaseService";
import {
  AXIOS_INSTANCE,
  COLLIBRA_API,
  DATA_PRODUCTS_API,
} from "../apiUtils/config";
import { t } from "react-multi-lang";

export const getEnrolledDomains = (state) => state.Domains?.enrolledDomainsList;

export function* getAllDataProducts({
  payload: { limit, page, type = "All", searchValue = undefined, pageType },
}) {
  try {
    const serviceMethod = AXIOS_INSTANCE.get(`${DATA_PRODUCTS_API}/getAll`, {
      params: {
        limit,
        page,
        type,
        searchValue: searchValue === "" ? undefined : searchValue,
        pageType,
      },
    });
    const apiResponse = yield call(baseMethod, serviceMethod, "", null, null);
    const response = yield call(checkHttpStatus, apiResponse);
    if (
      response.status >= 200 &&
      response.status < 300 &&
      response.data?.data?.isSuccess
    ) {
      let enrolledDomains;
      while (true) {
        enrolledDomains = yield select(getEnrolledDomains);
        if (enrolledDomains) break;
        yield take(DOMAINS_CONST.GET_ENROLLED_DOMAINS_SUCCESS);
      }
      const sandboxDomain = enrolledDomains?.filter(
        (domain) => domain.isSandbox
      );
      const sandboxDomainId = sandboxDomain.length ? sandboxDomain[0].id : "";
      const dataProductResponse = response.data?.data?.data?.dataProductList;
      const combinedDataProducts = [
        ...dataProductResponse.filter((dp) => {
          return enrolledDomains.length === 0
            ? !dp.isSandbox
            : dp.domainId === sandboxDomainId || !dp.isSandbox;
        }),
      ];
      response.data.data.data.combinedDataProducts = combinedDataProducts;
      const responseData = {
        statusCode: 200,
        response: response.data?.data?.data,
      };
      yield put(getAllDataProductsSuccess(responseData));
    } else {
      const responseData = {
        statusCode: response.statusCode || 400,
        data: response.data,
      };
      yield put(getAllDataProductsFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getAllDataProductsFailure(errorData));
  }
}

export function* getDataProductsByDomain(action) {
  try {
    const serviceMethod = AXIOS_INSTANCE.get(
      `${DATA_PRODUCTS_API}/getByDomain`,
      {
        params: {
          page: action.payload.page,
          domainId: action.payload.domainId,
          limit: action.payload.limit,
          searchValue: action.payload.searchValue,
          type: action.payload.type,
          excludeSandbox: action.payload.excludeSandbox,
        },
      }
    );
    const apiResponse = yield call(baseMethod, serviceMethod, "", null, null);
    const response = yield call(checkHttpStatus, apiResponse);
    if (
      response.status >= 200 &&
      response.status < 300 &&
      response.data?.data?.isSuccess
    ) {
      const responseData = {
        statusCode: 200,
        response: response.data,
      };
      yield put(getDataProductsByDomainSuccess(responseData));
    } else {
      const responseData = {
        statusCode: response.statusCode || 400,
        data: response.data,
      };
      yield put(getDataProductsByDomainFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getDataProductsByDomainFailure(errorData));
  }
}

export function* getCollibraDataProductsByDomain(action) {
  try {
    const serviceMethod = AXIOS_INSTANCE.get(
      `${COLLIBRA_API}/getAllDataProductsByDomain?id=${action.payload}`
    );
    const apiResponse = yield call(baseMethod, serviceMethod, "", null, null);
    const response = yield call(checkHttpStatus, apiResponse);
    if (response.status >= 200 && response.status < 300) {
      const responseData = {
        statusCode: 200,
        response: {
          data: response.data.data.map((item) => ({
            ...item,
            isCollibra: true,
          })),
        },
      };
      yield put(getCollibraDataProductsByDomainSuccess(responseData));
    } else {
      const responseData = {
        statusCode: response.statusCode || 400,
        data: response.data,
      };
      yield put(getCollibraDataProductsByDomainFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getCollibraDataProductsByDomainFailure(errorData));
  }
}

export function* getMyDataProducts(action) {
  try {
    const serviceMethod = AXIOS_INSTANCE.get(
      `${DATA_PRODUCTS_API}/myDataProducts`,
      {
        params: { domainId: action.payload },
      }
    );
    const apiResponse = yield call(baseMethod, serviceMethod, "", null, null);
    const response = yield call(checkHttpStatus, apiResponse);
    if (
      response.status >= 200 &&
      response.status < 300 &&
      response.data?.data?.isSuccess
    ) {
      const responseData = {
        statusCode: 200,
        response: response.data.data,
      };
      yield put(getMyDataProductsSuccess(responseData));
    } else {
      const responseData = {
        statusCode: response.statusCode || 400,
        data: response.data,
      };
      yield put(getMyDataProductsFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statuText: error,
        netWorkError: true,
      },
    };
    yield put(getMyDataProductsFailure(errorData));
  }
}

export function* createDataProduct(action) {
  try {
    const serviceMethod = AXIOS_INSTANCE.post(
      `${DATA_PRODUCTS_API}/create`,
      action.payload
    );
    const apiResponse = yield call(
      baseMethod,
      serviceMethod,
      t("SUCCESS_MSGS.CREATE_DATAPRODUCT"),
      null,
      null
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (
      response.status >= 200 &&
      response.status < 300 &&
      response.data?.data?.isSuccess
    ) {
      const updatedResponse = {
        ...response.data.data,
        domain: action.payload.domain,
        domainId: action.payload.domainId,
      };
      const responseData = {
        statusCode: 200,
        response: updatedResponse,
      };
      yield put(createDataProductSuccess(responseData));
    } else {
      const responseData = {
        statusCode: response.statusCode || 400,
        data: response.data,
      };
      yield put(createDataProductFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(createDataProductFailure(errorData));
  }
}

export function* updateDataProduct(action) {
  try {
    const serviceMethod = AXIOS_INSTANCE.put(
      `${DATA_PRODUCTS_API}/update/${action.dataProductId}`,
      action.payload
    );
    const apiResponse = yield call(
      baseMethod,
      serviceMethod,
      action.notNotify ? null : t("SUCCESS_MSGS.UPDATE_DATAPRODUCT"),
      null,
      null
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (
      response.status >= 200 &&
      response.status < 300 &&
      response.data?.data?.isSuccess
    ) {
      const responseData = {
        statusCode: 200,
        response: {
          ...response.data?.data,
          isLabelUpdate: action.payload.isLabelUpdate,
          id: action.payload.dataProductId,
        },
      };
      yield put(updateDataProductSuccess(responseData));
    } else {
      const responseData = {
        statusCode: response.statusCode || 400,
        data: response.data,
      };
      yield put(updateDataProductFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(updateDataProductFailure(errorData));
  }
}

export function* dataProductsByCriteria(action) {
  try {
    const serviceMethod = AXIOS_INSTANCE.post(
      `${DATA_PRODUCTS_API}/getByCriteria`,
      action.payload
    );
    const apiResponse = yield call(baseMethod, serviceMethod, "", null, null);
    const response = yield call(checkHttpStatus, apiResponse);
    if (
      response.status >= 200 &&
      response.status < 300 &&
      response.data?.data?.isSuccess
    ) {
      const responseData = {
        statusCode: 200,
        response: response.data,
      };
      yield put(getDataProductsByCriteriaSuccess(responseData));
    } else {
      const responseData = {
        statusCode: response.statusCode || 400,
        data: response.data,
      };
      yield put(getDataProductsByCriteriaFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getDataProductsByCriteriaFailure(errorData));
  }
}

export function* getDataProductsById(action) {
  try {
    const serviceMethod = AXIOS_INSTANCE.get(`${DATA_PRODUCTS_API}/getById`, {
      params: { id: action.payload },
    });
    const apiResponse = yield call(baseMethod, serviceMethod, "", null, null);
    const response = yield call(checkHttpStatus, apiResponse);
    if (
      response.status >= 200 &&
      response.status < 300 &&
      response.data?.data?.isSuccess
    ) {
      const responseData = {
        statusCode: 200,
        response: response.data?.data,
      };
      yield put(getDataProductsByIdSuccess(responseData));
    } else {
      const responseData = {
        statusCode: response.statusCode || 400,
        data: response.data,
      };
      yield put(getDataProductsByIdFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      statusCode: 400,
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getDataProductsByIdFailure(errorData));
  }
}

export function* getAllFavoritesDataProducts(action) {
  try {
    const serviceMethod = AXIOS_INSTANCE.get(
      `${DATA_PRODUCTS_API}/getAllFavoritesDataProducts`,
      {
        params: { excludeSandbox: action.payload.excludeSandbox },
      }
    );
    const apiResponse = yield call(baseMethod, serviceMethod, "", null, null);
    const response = yield call(checkHttpStatus, apiResponse);
    if (
      response.status >= 200 &&
      response.status < 300 &&
      response.data?.isSuccess
    ) {
      const responseData = {
        statusCode: 200,
        response: response.data,
      };
      yield put(getAllFavoritesDataProductsSuccess(responseData));
    } else {
      const responseData = {
        statusCode: response.statusCode || 400,
        response: [],
      };
      yield put(getAllFavoritesDataProductsFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getAllFavoritesDataProductsFailure(errorData));
  }
}

export function* getRequiredDataProducts(action) {
  try {
    const serviceMethod = AXIOS_INSTANCE.get(
      `${DATA_PRODUCTS_API}/getRequiredByDataProductId`,
      {
        params: { dataProductId: action.payload },
      }
    );
    const apiResponse = yield call(baseMethod, serviceMethod, "", null, null);
    const response = yield call(checkHttpStatus, apiResponse);
    if (
      response.status >= 200 &&
      response.status < 300 &&
      response.data?.isSuccess
    ) {
      const responseData = {
        statusCode: 200,
        response: response.data?.data,
      };
      yield put(getRequiredDataProductsSuccess(responseData));
    } else {
      const responseData = {
        statusCode: response.statusCode || 400,
        data: response.data,
      };
      yield put(getRequiredDataProductsFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getRequiredDataProductsFailure(errorData));
  }
}

export function* watchGetAllDataProducts() {
  yield takeEvery(
    DATAPRODUCT_CONST.GET_ALL_DATAPRODUCTS_REQUEST,
    getAllDataProducts
  );
}

export function* watchGetDataProductsByDomain() {
  yield takeEvery(
    DATAPRODUCT_CONST.GET_DATAPRODUCTS_BY_DOMAIN_REQUEST,
    getDataProductsByDomain
  );
}

export function* watchGetCollibraDataProductsByDomain() {
  yield takeEvery(
    DATAPRODUCT_CONST.GET_COLLIBRA_DATAPRODUCTS_BY_DOMAIN_REQUEST,
    getCollibraDataProductsByDomain
  );
}

export function* watchGetMyDataProducts() {
  yield takeEvery(
    DATAPRODUCT_CONST.GET_MY_DATA_PRODUCT_REQUEST,
    getMyDataProducts
  );
}

export function* watchCreateDataProduct() {
  yield takeEvery(
    DATAPRODUCT_CONST.CREATE_DATAPRODUCT_REQUEST,
    createDataProduct
  );
}

export function* watchUpdateDataProduct() {
  yield takeEvery(
    DATAPRODUCT_CONST.UPDATE_DATAPRODUCT_REQUEST,
    updateDataProduct
  );
}

export function* watchDataProductsByCriteria() {
  yield takeEvery(
    DATAPRODUCT_CONST.GET_DATAPRODUCTS_BY_CRITERIA_REQUEST,
    dataProductsByCriteria
  );
}

export function* watchDataProductsById() {
  yield takeEvery(
    DATAPRODUCT_CONST.GET_DATAPRODUCTS_BY_ID_REQUEST,
    getDataProductsById
  );
}

export function* watchgetAllFavoritesDataProducts() {
  yield takeEvery(
    DATAPRODUCT_CONST.GET_ALL_FAVORITES_DATAPRODUCT_REQUEST,
    getAllFavoritesDataProducts
  );
}

export function* watchgetRequiredDataProducts() {
  yield takeEvery(
    DATAPRODUCT_CONST.GET_REQUIRED_DATAPRODUCTS_REQUEST,
    getRequiredDataProducts
  );
}

export function* DataProductsSaga() {
  yield all([
    fork(watchGetAllDataProducts),
    fork(watchGetDataProductsByDomain),
    fork(watchGetCollibraDataProductsByDomain),
    fork(watchGetMyDataProducts),
    fork(watchCreateDataProduct),
    fork(watchUpdateDataProduct),
    fork(watchDataProductsByCriteria),
    fork(watchDataProductsById),
    fork(watchgetAllFavoritesDataProducts),
    fork(watchgetRequiredDataProducts),
  ]);
}

export default DataProductsSaga;
