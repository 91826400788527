import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import Link from "@mui/material/Link";
import useModal from "../../../helpers/CustomHooks/useModal";
import Stack from "@mui/material/Stack";
import { socket } from "../../../services/Socket";
import Breadcrumb from "../../../components/Common/BreadCrumb";
import MultipleUserIndicator from "../../../components/Common/MultipleUserIndicator";
import StepperHorizontal from "../../../components/Common/StepperHorizontal";
import LVTUseCaseSelectorModal from "./LVTUseCaseSelectorModal";
import { GlobalNotificationHandle } from "../../../services/NotificationHandler";
import "./LVTHeader.scss";

const LVTHeader = ({
  selectedData,
  useCasesArray,
  activeStep,
  isTeamSpace,
  fetchLVTStatus,
}) => {
  const [activeUsers, setActiveUsers] = useState([]);
  const { lvtUseCasesModal, lvtUseCasesModalToggle } = useModal();

  socket.on("activeUsersInRoom", (usersList) => {
    if (usersList !== undefined) {
      setActiveUsers(usersList);
    }
  });

  const onClickHandler = async () => {
    let isLVTComplete = false;
    if (selectedData.id !== undefined && selectedData.id !== "") {
      if (isTeamSpace) {
        isLVTComplete = await fetchLVTStatus({ teamSpaceId: selectedData.id });
      } else {
        isLVTComplete = await fetchLVTStatus({ domainId: selectedData.id });
      }
    }
    if (isLVTComplete) {
      lvtUseCasesModalToggle();
    } else {
      GlobalNotificationHandle(
        500,
        "Please make sure all cards are linked in the Lean Value Tree before proceeding",
        false,
        true,
        true
      );
    }
  };

  return (
    <div>
      <div className="lvt-header pt-3">
        <div className="lvt-header-left">
          <div className="lvt-header-container">
            <Stack
              className="lvt-header-container-info"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <span className="lvt-header-container-title">
                Lean Value Tree{" "}
              </span>
              <StepperHorizontal activeStep={activeStep} />
              <MultipleUserIndicator activeUsers={activeUsers} />
            </Stack>
            <Breadcrumb
              steps={[
                { name: "Home Page", href: "/home" },
                {
                  name: "Data Product value realization understood by Lean Value Tree",
                  href: "#",
                  active: true,
                },
              ]}
            />
          </div>
        </div>
        <div className="lvt-header-container lvt-header-container-right">
          <div className="lvt-header-container-right-info">
            <p className="lvt-header-container-title">{selectedData?.name}</p>
            {isTeamSpace ? (
              <Link
                className="lvt-header-container-link"
                href={`/teamspace-details?teamSpaceId=${selectedData?.id}`}
              >
                See Team Space details
              </Link>
            ) : (
              <Link
                className="lvt-header-container-link"
                href={`/datadomain-details?domainId=${selectedData?.id}`}
              >
                See Data Domain details
              </Link>
            )}
          </div>
          <Button
            className="custom-btn lvt-header-container-right-button"
            onClick={() => onClickHandler()}
          >
            Design Data Product
          </Button>
        </div>
      </div>
      <LVTUseCaseSelectorModal
        useCasesArray={useCasesArray}
        domainId={!isTeamSpace ? selectedData.id : undefined}
        teamSpaceId={isTeamSpace ? selectedData.id : undefined}
        lvtUseCasesModal={lvtUseCasesModal}
        lvtUseCasesModalToggle={lvtUseCasesModalToggle}
      />
    </div>
  );
};

LVTHeader.propTypes = {
  selectedData: PropTypes.object,
  useCasesArray: PropTypes.array,
  activeStep: PropTypes.number,
  isTeamSpace: PropTypes.bool,
  fetchLVTStatus: PropTypes.func,
};

export default LVTHeader;
