import React from "react";
import { ReactComponent as Hexagon } from "../../assets/images/Hexagon.svg";
import "./DataProductLabel.scss";
import { dataProductTypeAbbreviations } from "../WorkSpaceScreens/DataProductInteractionMap/DPIMSettings";
import classNames from "classnames";
import { t } from "react-multi-lang";
import PropTypes from "prop-types";
import { DRAFT } from "../../constants/DataDomainType";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

const DataProductLabel = () => {
  const domainTypeTextMapping = {
    "In Review": `${t("DOMAIN.IN_REVIEW_TYPE")}/${DRAFT}`,
    Pharma: t("DOMAIN.PHARMA_TYPE"),
    Diagnostics: t("DOMAIN.DIAGNOSTICS_TYPE"),
  };

  const lineageSourceList = {
    Collibra: "Collibra",
    DMDS: "DMDS",
  };

  return (
    <div className="w-100 overflow-hidden" data-testid="dataProductLabel">
      <DataDomainTypeLabel
        data={dataProductTypeAbbreviations}
        hexagonShow={true}
        text="Data Product Type:"
      />
      <DataDomainTypeLabel
        data={domainTypeTextMapping}
        domainStatusShow={true}
        text="Roche Division:"
      />
      <DataDomainTypeLabel
        data={lineageSourceList}
        lineageSource={true}
        text="Data Lineage Source:"
      />
    </div>
  );
};

const DataDomainTypeLabel = ({
  data,
  hexagonShow,
  domainStatusShow,
  text,
  lineageSource,
}) => {
  return (
    <div className="product-container">
      <span className="product-container-text">{text}</span>
      {Object.keys(data).map(function (key) {
        return (
          <div
            key={key}
            className={classNames("product-container-content", data[key])}
          >
            {hexagonShow ? (
              <>
                <Hexagon
                  className={classNames("product-container-hexagon", data[key])}
                />
                {key?.replace("Data Product", "").trim()}
              </>
            ) : (
              ""
            )}
            {domainStatusShow ? (
              <>
                <span>
                  <span
                    className={`domain-type ${
                      key?.split(" ").join("-") || DRAFT
                    }`}
                  />
                </span>
                {data[key]}
              </>
            ) : (
              ""
            )}
            {lineageSource ? (
              <>
                <TrendingUpIcon
                  sx={{ color: data[key] === "Collibra" ? "#5b9d00" : "#000" }}
                />
                <span className="ml-1">{data[key]}</span>
              </>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

DataDomainTypeLabel.propTypes = {
  data: PropTypes.object.isRequired,
  hexagonShow: PropTypes.bool,
  domainStatusShow: PropTypes.bool,
  text: PropTypes.string.isRequired,
  lineageSource: PropTypes.bool,
};

export default DataProductLabel;
