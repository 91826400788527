import React, { useEffect, useState, useMemo } from "react";
import Breadcrumb from "../../components/Common/BreadCrumb";
import Layout from "../../components/Common/Layout";
import "./index.scss";
import TeamSpaceDetailsCard from "./TeamSpaceDetailsCard";
import LVTAccordion from "../../components/Common/LVTAccordion";
import DIMAccordion from "../../components/Common/DIMAccordion";
import DataProductAccordion from "./DataProductAccordion";
import useMatchMedia from "../../helpers/CustomHooks/useMatchMedia";
import { useHistory, useLocation } from "react-router-dom";
import { socket } from "../../services/Socket";

const TeamSpaceDetails = () => {
  const { search } = useLocation();
  const history = useHistory();
  const [teamSpaceData, setTeamSpaceData] = useState({});
  const [teamSpaceUseCases, setTeamSpaceUseCases] = useState([]);

  const params = new URLSearchParams(search);
  const teamSpaceId = params.get("teamSpaceId");

  const isSmallerScreen = useMatchMedia(1367);
  const dataDomainPerPage = useMemo(() => {
    if (isSmallerScreen === 1367) {
      return 3;
    }

    return isSmallerScreen ? 3 : 4;
  }, [isSmallerScreen]);

  useEffect(() => {
    if (!teamSpaceId) {
      history.push("/home");
    }

    socket.emit("getTeamSpaceById", { teamSpaceId }, socket.lastJoinedRoom);
    socket.emit(
      "getTeamSpaceRelatedUseCases",
      { teamSpaceId },
      socket.lastJoinedRoom
    );
  }, [teamSpaceId, history]);

  useEffect(() => {
    if (!teamSpaceId) {
      return;
    }

    socket.lastJoinedRoom = `TEAMSPACE-${teamSpaceId}`;
    socket.emit("joinRoom", socket.lastJoinedRoom);

    socket.on("receiveTeamSpaceData", (data) => {
      setTeamSpaceData(data);
    });
    socket.on("recieveTeamSpaceRelatedUseCases", (res) => {
      setTeamSpaceUseCases(res.data);
    });
  }, [teamSpaceId]);

  return (
    <div className="global-top-1 data-domain-details">
      <Layout title="Team Space Details">
        <div className="data-domain-details-breadcrumb">
          <Breadcrumb
            steps={[
              { name: "Home Page", href: "/home" },
              {
                name: "Data Domain Details",
                href: `/datadomain-details?domainId=${teamSpaceData.domainId}`,
              },
              { name: "Team Space Details", active: true },
            ]}
          />
          <TeamSpaceDetailsCard
            teamSpaceData={teamSpaceData || {}}
            teamSpaceId={teamSpaceId}
          />
          <LVTAccordion
            selectedTeamSpace={teamSpaceData || {}}
            customHeight={200}
            customWidth={338}
          />
          <DIMAccordion
            useCasePerPage={dataDomainPerPage}
            useCases={teamSpaceUseCases || []}
            selectedTeamSpace={teamSpaceData || {}}
          />
          <DataProductAccordion isExtended teamSpaceId={teamSpaceId} />
        </div>
      </Layout>
    </div>
  );
};

export default TeamSpaceDetails;
