/* eslint-disable max-lines */
import React, { useCallback, useState, useMemo } from "react";
import ReactFlow, { isNode } from "reactflow";
import PropTypes from "prop-types";

import DataProductItem from "./DataProductItem";
import DPSection from "./DPSection";
import DataProductHexagon from "./DataProductHexagon";
import { INITIAL_WIDTH, ITEM_FIELDS } from "./DPIMSettings";
import { socket } from "../../../services/Socket";
import { GlobalNotificationHandle } from "../../../services/NotificationHandler";
import useDPIMNodeCopy from "../../../helpers/CustomHooks/useDPIMNodeCopy";
import { DPIM_SECTION_ORDER } from "./DPIMSettings";

const DPDiagram = ({
  nodes,
  setNodes,
  onNodesChange,
  edges,
  setEdges,
  onEdgesChange,
  reactFlowWrapper,
  useCaseId,
  maxHeight,
  setMaxHeight,
  userEditAccess,
}) => {
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [isDraggable, setIsDraggable] = useState(true);
  useDPIMNodeCopy(
    setNodes,
    setEdges,
    nodes,
    edges,
    useCaseId,
    socket.lastJoinedRoom
  );

  const nodeTypes = useMemo(
    () => ({
      section: (params) => (
        <DPSection
          {...params}
          maxHeight={maxHeight}
          setMaxHeight={setMaxHeight}
          handleSectionSizeChange={handleSectionSizeChange}
        />
      ),
      dpItem: (params) => (
        <DataProductItem
          {...params}
          updateDPItem={updateDPItem}
          onNodesRemove={deleteDataProductItems}
          setNodes={setNodes}
          userEditAccess={userEditAccess}
          setIsDraggable={setIsDraggable}
        />
      ),
      hexagon: (params) => (
        <DataProductHexagon
          {...params}
          setNodes={setNodes}
          deleteDataProductItems={deleteDataProductItems}
          userEditAccess={userEditAccess}
        />
      ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [maxHeight, setMaxHeight, setNodes, userEditAccess]
  );

  const handleSectionSizeChange = (id, updatedWidth) => {
    const updatedSectionNodes = [];
    setNodes((prevNodes) => {
      const eventOnSection = DPIM_SECTION_ORDER.indexOf(id);
      const updatedNodes = [...prevNodes];
      updatedNodes.forEach((node, index) => {
        if (node.id === id) {
          const prevStyle = node.style;
          updatedNodes[index] = {
            ...node,
            style: {
              ...prevStyle,
              width: updatedWidth,
            },
          };
          updatedSectionNodes.push(node);
        } else {
          if (!DPIM_SECTION_ORDER.includes(node.id)) {
            if (node?.parentNode === id) {
              const currentNodePosition = node.position;
              const temp = updatedWidth - (node.width || 140);
              if (temp <= 0) {
                currentNodePosition.x = 10;
              } else {
                currentNodePosition.x =
                  currentNodePosition.x > temp ? temp : currentNodePosition.x;
              }
              updatedNodes[index] = {
                ...node,
                position: {
                  ...currentNodePosition,
                },
              };
            }
          } else {
            if (DPIM_SECTION_ORDER.indexOf(node.id) > eventOnSection) {
              const currentSectionPosition = node.position;
              updatedNodes[index] = {
                ...node,
                position: {
                  ...currentSectionPosition,
                  x:
                    updatedNodes[index - 1].position?.x +
                    updatedNodes[index - 1].style?.width,
                },
              };
            }
            updatedSectionNodes.push(node);
          }
        }
      });
      socket.emit(
        "updateSectionSizes",
        updatedNodes,
        useCaseId,
        socket.lastJoinedRoom
      );
      return updatedNodes;
    });
  };

  const updateDPItem = async (id, node) => {
    socket.emit(
      "updateDataProductItem",
      { id, node, useCaseId },
      socket.lastJoinedRoom
    );
  };

  const createDPItem = async (coordinateX, coordinateY, step) => {
    socket.emit(
      "createDPItem",
      {
        coordinateX,
        coordinateY,
        step,
        useCaseId,
      },
      socket.lastJoinedRoom
    );
  };

  const deleteDataProductItems = async (removedElements) => {
    if (removedElements[0].type === "dpItem") {
      socket.emit(
        "deleteDataProductItem",
        {
          id: removedElements[0].id,
          useCaseId,
        },
        socket.lastJoinedRoom
      );
    } else {
      socket.emit(
        "deleteDataProduct",
        {
          id: removedElements[0].id,
          useCaseId,
          permanantlyDelete: removedElements[0].permanantlyDelete,
        },

        socket.lastJoinedRoom
      );
    }
  };

  const handleDataProductItemConnection = async (params) => {
    socket.emit(
      "createDPIMConnection",
      {
        sourceId: params.source,
        targetId: params.target,
        targetHandle: params.targetHandle,
        sourceHandle: params.sourceHandle,
        useCaseId,
      },
      socket.lastJoinedRoom
    );
  };

  const canConnect = (params) => {
    if (
      edges.find(
        (edge) =>
          (edge.source === params.source && edge.target === params.target) ||
          (edge.source === params.target && edge.target === params.source)
      )
    ) {
      return false;
    }

    const sourceNode = nodes.find((node) => node.id === params.source);
    const targetNode = nodes.find((node) => node.id === params.target);

    if (
      (["sources", "data-entites"].includes(sourceNode.parentNode) &&
        targetNode.parentNode === "operational") ||
      (["sources", "data-entites"].includes(targetNode.parentNode) &&
        sourceNode.parentNode === "operational")
    ) {
      return false;
    }

    if (sourceNode.id === targetNode.id) {
      notifyUser("Not allowed! Cannot connect to source and target to itself");
      return false;
    }

    if (
      targetNode.parentNode === "operational-dp-job" &&
      sourceNode.parentNode === "operational-consumer"
    ) {
      let dpList = [];

      // Scenario 1 -> If the cosumer has another dpJob connected
      let operationalDpJobArray = getNodeDetailsByEdge(
        sourceNode.id,
        "target",
        "source",
        "operational-dp-job"
      );
      if (operationalDpJobArray?.length > 0) {
        let consumerArray = getNodesDetailsByNodes(
          operationalDpJobArray,
          "source",
          "target",
          "operational-consumer"
        );
        if (consumerArray?.length > 0) {
          let dpArray = getNodesDetailsByNodes(
            consumerArray,
            "source",
            "target",
            "",
            "hexagon"
          );

          if (dpArray.length > 0) {
            dpList.push(dpArray);
          }
        }
      }

      // Scenario 2 -> If the consumer has multiple dps connected

      let dpArrayFromConsumer = getNodeDetailsByEdge(
        sourceNode.id,
        "source",
        "target",
        "",
        "hexagon"
      );
      if (dpArrayFromConsumer?.length > 0) {
        dpList.push(dpArrayFromConsumer);
      }

      // Scenario 3 -> iF the dpJob has multiple dpJob conncted

      let otherDpJobArray = getNodeDetailsByEdge(
        targetNode.id,
        "source",
        "target",
        "operational-consumer"
      );

      if (otherDpJobArray?.length > 0) {
        let dpArray = getNodesDetailsByNodes(
          otherDpJobArray,
          "source",
          "target",
          "",
          "hexagon"
        );
        if (dpArray?.length > 0) {
          dpList.push(dpArray);
        }
      }

      if (isAlreadyConnectedToDp(dpList)) {
        notifyUser("Not allowed! Already connected to data product");
        return false;
      }
    }

    if (
      targetNode.parentNode === "operational-dp-job" &&
      sourceNode.type === "hexagon"
    ) {
      let dpList = [];
      // Scenario 1 - If the dpjob is connected to multiple dps directictly
      let dpArrFromDpJob = getNodeDetailsByEdge(
        targetNode.id,
        "source",
        "target",
        "",
        "hexagon"
      );

      dpList.push(sourceNode.id);
      if (dpArrFromDpJob?.length > 0) dpList.push(dpArrFromDpJob);

      // Scenario 2 - If the dpjob has multiple dp consumer which is connected to dp

      let consumerFromDpJob = getNodeDetailsByEdge(
        targetNode.id,
        "source",
        "target",
        "operational-consumer"
      );

      if (consumerFromDpJob?.length > 0) {
        let dpArray = getNodesDetailsByNodes(
          consumerFromDpJob,
          "source",
          "target",
          "",
          "hexagon"
        );

        if (dpArray.length > 0) {
          dpList.push(dpArray);
        }
      }

      if (isAlreadyConnectedToDp(dpList)) {
        notifyUser("Not allowed! Already connected to data product");
        return false;
      }
    }

    if (
      targetNode.parentNode === "operational-consumer" &&
      sourceNode.type === "hexagon"
    ) {
      let dpList = [];

      //Scenario 1 - If the Consumer is connected to multiple dp directly

      let dpArrFromConsumer = getNodeDetailsByEdge(
        targetNode.id,
        "source",
        "target",
        "",
        "hexagon"
      );
      dpList.push(sourceNode.id);
      if (dpArrFromConsumer?.length > 0) dpList.push(dpArrFromConsumer);

      //Scenario 2 - If the Consumer is connected to multiple dpJob

      let dpJobArrFromConsumer = getNodeDetailsByEdge(
        targetNode.id,
        "target",
        "source",
        "operational-dp-job"
      );

      if (dpJobArrFromConsumer?.length > 0) {
        let consumerArray = getNodesDetailsByNodes(
          dpJobArrFromConsumer,
          "source",
          "target",
          "operational-consumer"
        );
        if (consumerArray?.length > 0) {
          let dpArray = getNodesDetailsByNodes(
            consumerArray,
            "source",
            "target",
            "",
            "hexagon"
          );

          if (dpArray?.length > 0) {
            dpList.push(dpArray);
          }
        }
      }

      if (isAlreadyConnectedToDp(dpList)) {
        notifyUser("Not allowed! Already connected to data product");
        return false;
      }
    }

    return true;
  };

  const isAlreadyConnectedToDp = (dpList) => {
    if (dpList?.length > 0) {
      let flattenDp = dpList.flat();
      let removeDuplicate = [...new Set(flattenDp)];
      if (removeDuplicate?.length > 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const getNodeDetailsByEdge = (
    edgeId,
    edgeType,
    relatedEdgeType,
    nodeParentType = "",
    nodeType = ""
  ) => {
    let nodeArr = [];
    let getRelatedEdges = edges.filter(
      (edge) => edge[relatedEdgeType] === edgeId
    );
    if (getRelatedEdges?.length > 0) {
      getRelatedEdges.forEach((eachEdge) => {
        let nodeDetails = nodeExist(eachEdge[edgeType]);
        if (
          nodeDetails &&
          (nodeDetails.parentNode === nodeParentType ||
            nodeDetails.type === nodeType)
        ) {
          nodeArr.push(eachEdge[edgeType]);
        }
      });
      return [...new Set(nodeArr)];
    } else {
      return nodeArr;
    }
  };

  const getNodesDetailsByNodes = (
    nodeArr,
    edgeType,
    relatedEdgeType,
    nodeParentType = "",
    nodeType = ""
  ) => {
    let resultNodeArr = [];
    nodeArr.forEach((eachNode) => {
      resultNodeArr.push(
        getNodeDetailsByEdge(
          eachNode,
          edgeType,
          relatedEdgeType,
          nodeParentType,
          nodeType
        )
      );
    });
    if (resultNodeArr.length > 0) {
      resultNodeArr = resultNodeArr.flat();
      return [...new Set(resultNodeArr)];
    } else {
      return resultNodeArr;
    }
  };

  const nodeExist = (nodId) => {
    return nodes.find((node) => node.id === nodId);
  };

  const notifyUser = (msg) => {
    GlobalNotificationHandle(299, msg, false, true, true);
  };

  const onConnect = async (params) => {
    if (!canConnect(params)) {
      return;
    }

    await handleDataProductItemConnection(params);
  };

  const onEdgesRemove = (removedElements) => {
    socket.emit(
      "deleteDPIMConnection",
      {
        id: removedElements[0].id,
        useCaseId,
      },
      socket.lastJoinedRoom
    );
    return removedElements;
  };

  const onDrop = async (event) => {
    event.preventDefault();

    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const type = event.dataTransfer.getData("application/reactflow");

    if (typeof type === "undefined" || !type) {
      return;
    }

    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });

    const parentNode = droppedOnNode(position).id;

    if (type === "dpItem" && !ITEM_FIELDS.includes(parentNode)) {
      return;
    }
    await createDPItem(10, event.clientY - reactFlowBounds.top, parentNode);
  };

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const droppedOnNode = (position) => {
    return nodes.find((element) => {
      if (isNode(element)) {
        const x1 = element.position.x;
        const y1 = element.position.y;
        const htmlElement = document.querySelector(
          `div.react-flow__node[data-id="${element.id}"]`
        );
        const x2 = x1 + htmlElement.offsetWidth;
        const y2 = y1 + htmlElement.offsetHeight;
        return (
          position.x > x1 &&
          position.x < x2 &&
          position.y > y1 &&
          position.y < y2
        );
      }
      return undefined;
    });
  };

  const handleNodeDragStop = (event, node) => {
    if (node.type === "dpItem") {
      updateDPItem(node.id, node);
    } else {
      socket.emit(
        "updateDataProductPosition",
        {
          id: node.id,
          x: node.position.x,
          y: node.position.y,
          useCaseId,
        },
        socket.lastJoinedRoom
      );
    }
  };

  const wrapper = reactFlowWrapper?.current?.getBoundingClientRect() || {};
  return (
    <div style={{ height: "90vh" }} className="w-100" ref={reactFlowWrapper}>
      <ReactFlow
        style={{ background: "#F7F7F7" }}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesDelete={onEdgesRemove}
        deleteKeyCode={userEditAccess ? ["Backspace", "Delete"] : null}
        nodesConnectable={userEditAccess}
        nodesDraggable={!userEditAccess ? userEditAccess : isDraggable}
        onEdgesChange={onEdgesChange}
        onDrop={onDrop}
        onDragOver={onDragOver}
        onConnect={onConnect}
        onInit={(instance) => {
          setReactFlowInstance(instance);
          setTimeout(() => {
            instance.fitBounds({
              x: 0,
              y: -100,
              width: INITIAL_WIDTH,
              height: wrapper.height,
            });
            localStorage.setItem("hideLoader", true);
          }, 1000);
        }}
        nodeTypes={nodeTypes}
        onNodesDelete={deleteDataProductItems}
        disableKeyboardA11y
        connectionMode="loose"
        onNodeDragStop={handleNodeDragStop}
        panOnDrag={isDraggable}
        zoomOnDoubleClick={false}
        data-testid="reactflow-wrapper"
      ></ReactFlow>
    </div>
  );
};

DPDiagram.propTypes = {
  setNodes: PropTypes.func,
  setEdges: PropTypes.func,
  nodes: PropTypes.array,
  onNodesChange: PropTypes.func,
  edges: PropTypes.array,
  onEdgesChange: PropTypes.func,
  reactFlowWrapper: PropTypes.any,
  useCaseId: PropTypes.string,
  maxHeight: PropTypes.number,
  setMaxHeight: PropTypes.func,
  userEditAccess: PropTypes.bool,
};

export default DPDiagram;
