import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { FiSearch } from "react-icons/fi";
import { IoMdArrowDropdown } from "react-icons/io";
import { DATA_DOMAIN_STATUS } from "../../constants/DataProductStatusOptions";
import DataProductStatus from "../../components/Common/DataProductStatus";
import "./MyDataProductSettings.scss";

const ALL_OPTION = { value: "All", label: "All" };
function MyDataProductSettings({
  setFilteredList = () => {},
  list,
  setSearchValueExternal,
  setSelectedValueExternal,
}) {
  const [statuses, setStatuses] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState(ALL_OPTION.value);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const statusOptions = Object.values(DATA_DOMAIN_STATUS).map((key) => ({
      value: key,
      label: key,
    }));
    setStatuses([ALL_OPTION, ...statusOptions]);
  }, [list]);

  useEffect(() => {
    setFilteredList(
      list.filter(
        (item) =>
          item.name.toLowerCase().includes(searchValue.toLowerCase()) &&
          (item.dataProductStatus === filterValue ||
            filterValue === ALL_OPTION.value)
      )
    );
  }, [filterValue, searchValue, list, setFilteredList]);

  const handleSearch = (value) => {
    setSearchValue(value);
    if (setSearchValueExternal) {
      setSearchValueExternal(value);
    }
  };

  const handleFilter = (value) => {
    setFilterValue(value);
    setSelectedValueExternal(value);
  };

  return (
    <div className="d-flex my-data-product-settings">
      <div className="my-data-product-settings-search">
        <FiSearch className="my-data-product-settings-search-icon" />
        <Input
          onChange={(e) => handleSearch(e.target.value)}
          className="my-data-product-settings-search-input"
          placeholder="Search..."
        />
      </div>
      <div className="my-data-product-settings-filter">
        <Dropdown
          isOpen={dropdownOpen}
          toggle={() => setDropdownOpen(!dropdownOpen)}
          size="lg"
        >
          <DropdownToggle caret color={"default"} className="custom-select3">
            {filterValue}
            <IoMdArrowDropdown
              style={{ marginLeft: "10px" }}
            ></IoMdArrowDropdown>
          </DropdownToggle>
          <DropdownMenu flip={false}>
            {statuses.map((status) => (
              <DropdownItem
                key={status.value}
                onClick={() => handleFilter(status.value)}
              >
                <DataProductStatus status={status.label} />
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  );
}

MyDataProductSettings.propTypes = {
  setFilteredList: PropTypes.func,
  list: PropTypes.array,
  setSearchValueExternal: PropTypes.func,
  setSelectedValueExternal: PropTypes.func,
};

export default MyDataProductSettings;
