import React, { useEffect, useRef } from "react";
import _ from "lodash";
/*function for get ordered(asc) array items*/
export const getOrderedItems = (rawArray, order, conditionName) => {
  if (conditionName) {
    return _.orderBy(rawArray, [conditionName], [order]); // Use Lodash to sort array by 'condition'
  } else {
    return _.orderBy(rawArray, [(item) => item], [order]); // Use Lodash to sort array by 'Label'
  }
};

//global function to compare props
export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const renderOptions = (optionsList, label, value) => {
  if (label && value && optionsList) {
    return optionsList.map((option) => {
      if (option) {
        return (
          <option value={option[value]} key={option[value]}>
            {option[label]}
          </option>
        );
      }
      return null;
    });
  } else if (optionsList) {
    return optionsList.map((option) => {
      return (
        <option value={option.value} key={option.value}>
          {option.label}
        </option>
      );
    });
  }
};

// getBase64 format function
export const getBase64 = (file, cb) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
};
export const getOrderByDate = (array, sortCondition) => {
  return array.sort(function (a, b) {
    var dateA = new Date(a[sortCondition]).getTime();
    var dateB = new Date(b[sortCondition]).getTime();
    return dateB > dateA ? 1 : -1;
  });
};

export const getStructureByConnections = (structure, name, connections) => {
  if (structure && structure[name] && structure[name].length) {
    structure[name].forEach((item) => {
      if (item && item[name] && item[name].length) {
        getStructureByConnections(item, name, connections);
        connections.push(item);
      } else if (item) {
        connections.push(item);
      }
    });
  }
  return connections;
};
export const groupByCustomOrder = (name, data) => {
  return (
    _.chain(data)
      // Group the elements of Array based on `name` property
      .groupBy(name)
      // `key` is group's name (name of dataproducttype), `value` is the array of connections
      .map((value, key) => ({
        parentId: key,
        connections: value,
      }))
      .value()
  );
};
export const sortByCustomOrder = (order, array, orderKeyName) => {
  let inputArray = [];
  inputArray = array;
  return inputArray.sort(
    (a, b) => order.indexOf(a[orderKeyName]) - order.indexOf(b[orderKeyName])
  );
};

export const getDiffDays = (modifiedAt) => {
  const modifiedDate = new Date(modifiedAt);
  const currentDate = new Date();
  const diffTime = Math.abs(currentDate - modifiedDate);
  return Math.floor(diffTime / (1000 * 60 * 60 * 24));
};

export const domainName = (domainId) => {
  const domainDetails = localStorage.getItem("domainsList")
    ? JSON.parse(localStorage.getItem("domainsList"))
    : null;

  let domain;
  if (domainDetails?.length) {
    domain = domainDetails.find((e) => e.id === domainId)?.name;
  }
  return { domain };
};

export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export * from "./Permissions";
