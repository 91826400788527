import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";

const immediateUpdate = "IMMEDIATE_UPDATE";
const delayedUpdate = "DELAYED_UPDATE";

const useInactivityTimer = () => {
  const history = useHistory();
  const timeout = parseInt(process.env.REACT_APP_INACTIVITY_TIMEOUT, 10);
  const [userSignedIn, setUserSignedIn] = useState(false);
  let timeoutTracker;
  let interval;

  useEffect(() => {
    const loginInfo = localStorage.getItem("loginInfo") ? true : false;
    setUserSignedIn(loginInfo);

    if (loginInfo) {
      const expiredTime = parseInt(
        localStorage.getItem("_expiredTime") || 0,
        10
      );
      if (expiredTime > 0 && expiredTime < Date.now()) {
        handleLogout();
      } else {
        tracker();
        startInterval();
      }
    }

    return () => {
      cleanUp();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSignedIn]);

  const handleLogout = () => {
    Auth.signOut();
    localStorage.clear();
    history.push("/login");
  };

  const startInterval = () => {
    updateExpiredTime(immediateUpdate);
    interval = setInterval(() => {
      const loginInfo = localStorage.getItem("loginInfo") ? true : false;
      setUserSignedIn(loginInfo);

      if (loginInfo) {
        const expiredTime = parseInt(
          localStorage.getItem("_expiredTime") || 0,
          10
        );
        if (expiredTime < Date.now()) {
          handleLogout();
        }
      } else {
        clearInterval(interval);
      }
    }, 1500);
  };

  const updateExpiredTime = (updateMode = delayedUpdate) => {
    clearTimeout(timeoutTracker);
    if (userSignedIn) {
      if (updateMode === immediateUpdate) {
        localStorage.setItem("_expiredTime", Date.now() + timeout * 1000);
      } else {
        timeoutTracker = setTimeout(() => {
          localStorage.setItem("_expiredTime", Date.now() + timeout * 1000);
        }, 750);
      }
    }
  };

  const tracker = () => {
    window.addEventListener("mousemove", updateExpiredTime);
    window.addEventListener("scroll", updateExpiredTime);
    window.addEventListener("keydown", updateExpiredTime);
  };

  const cleanUp = () => {
    clearInterval(interval);
    clearTimeout(timeoutTracker);
    localStorage.removeItem("_expiredTime");
    window.removeEventListener("mousemove", updateExpiredTime);
    window.removeEventListener("scroll", updateExpiredTime);
    window.removeEventListener("keydown", updateExpiredTime);
  };
};

export default useInactivityTimer;
