import React from "react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import FixMenu from "../../../components/Common/FixMenu";
import NewLVT from "../../../assets/images/NewLVT.svg";
import SearchDataProduct from "./SearchDataProduct";
import { socket } from "../../../services/Socket";
const DPMenu = ({
  setNodes,
  useCaseId,
  selectedDomainId,
  selectedTeamSpaceId,
  userEditAccess,
  nodes,
}) => {
  const [allTeamSpace, setAllTeamSpace] = useState([]);

  useEffect(() => {
    if (selectedTeamSpaceId) {
      socket.emit("getAllTeamSpaces", {});
    }
  }, [selectedTeamSpaceId]);
  useEffect(() => {
    socket.on("receiveAllTeamSpaces", (data) => {
      setAllTeamSpace(data?.teamSpaceList);
    });
  }, []);

  return (
    <div>
      <FixMenu
        userEditAccess={userEditAccess || false}
        items={[
          {
            icon: (
              <img src={NewLVT} alt="NewItem" className="lvt-menu-icon mb-2" />
            ),
            eventName: "dpItem",
          },
          {
            body: (
              <SearchDataProduct
                nodes={nodes}
                setNodes={setNodes}
                useCaseId={useCaseId}
                selectedDomainId={selectedDomainId}
                selectedTeamSpaceId={selectedTeamSpaceId}
                userEditAccess={userEditAccess}
                allTeamSpace={allTeamSpace}
              />
            ),
            eventName: "dataProduct",
          },
        ]}
      />
    </div>
  );
};

DPMenu.propTypes = {
  setNodes: PropTypes.func,
  useCaseId: PropTypes.string,
  selectedDomainId: PropTypes.string,
  selectedTeamSpaceId: PropTypes.string,
  userEditAccess: PropTypes.bool,
  nodes: PropTypes.array,
};

export default DPMenu;
