import { USERS_CONST } from "./actionTypes";

export const getAllUsers = () => {
  return {
    type: USERS_CONST.GET_ALL_USERS_REQUEST,
    payload: {},
  };
};
export const getAllUsersSuccess = (response) => {
  return {
    type: USERS_CONST.GET_ALL_USERS_SUCCESS,
    payload: response,
  };
};
export const getAllUsersFailure = (error) => {
  return {
    type: USERS_CONST.GET_ALL_USERS_FAILURE,
    payload: error,
  };
};

export const getUsersByDomainId = (id, domainUser) => {
  return {
    type: USERS_CONST.GET_USERS_BY_DOMAIN_ID_REQUEST,
    payload: id,
    domainUser: domainUser,
  };
};
export const getUsersByDomainIdSuccess = (response) => {
  return {
    type: USERS_CONST.GET_USERS_BY_DOMAIN_ID_SUCCESS,
    payload: response,
  };
};
export const getUsersByDomainIdFailure = (error) => {
  return {
    type: USERS_CONST.GET_USERS_BY_DOMAIN_ID_FAILURE,
    payload: error,
  };
};

export const getUsersByTeamSpaceId = (id, domainUser) => {
  return {
    type: USERS_CONST.GET_USERS_BY_TEAMSPACE_ID_REQUEST,
    payload: id,
    domainUser: domainUser,
  };
};
export const getUsersByTeamSpaceIdSuccess = (response) => {
  return {
    type: USERS_CONST.GET_USERS_BY_TEAMSPACE_ID_SUCCESS,
    payload: response,
  };
};
export const getUsersByTeamSpaceIdFailure = (error) => {
  return {
    type: USERS_CONST.GET_USERS_BY_TEAMSPACE_ID_FAILURE,
    payload: error,
  };
};
export const getUserByTeam = (teamId) => {
  return {
    type: USERS_CONST.GET_USER_BY_TEAM_REQUEST,
    payload: teamId,
  };
};
export const getUserByTeamSuccess = (response) => {
  return {
    type: USERS_CONST.GET_USER_BY_TEAM_SUCCESS,
    payload: response,
  };
};
export const getUserByTeamFailure = (error) => {
  return {
    type: USERS_CONST.GET_USER_BY_TEAM_FAILURE,
    payload: error,
  };
};

export const getUserProfile = () => {
  return {
    type: USERS_CONST.MY_PROFILE_REQUEST,
    payload: {},
  };
};
export const getUserProfileSuccess = (response) => {
  return {
    type: USERS_CONST.MY_PROFILE_SUCCESS,
    payload: response,
  };
};
export const getUserProfileFailure = (error) => {
  return {
    type: USERS_CONST.MY_PROFILE_FAILURE,
    payload: error,
  };
};
export const generateUser = (details) => {
  return {
    type: USERS_CONST.GENERATE_USER,
    payload: details,
  };
};
