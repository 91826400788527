import React from "react";
import { toast, Slide } from "react-toastify";
import { GoCheck } from "react-icons/go";
import { AiOutlineInfoCircle } from "react-icons/ai";
import {
  status200,
  status300,
  status400,
  status401,
  status600,
} from "../constants";

/*-------function that handle all type of error and success notifications----*/
export function GlobalNotificationHandle(
  status,
  message,
  isSuccess,
  isStopRedirectionToLogin,
  isCustomError = false,
  dpList,
  showModal
) {
  let content;
  if (status >= status200 && status < status300 && isSuccess) {
    if (message) {
      toast.success(
        <>
          <span className="toast-icon success">
            <GoCheck size={20} />
          </span>{" "}
          <span className="toast-text">{message}</span>
        </>,
        {
          position: "top-right",
          autoClose: 5000,
          transition: Slide,
        }
      );
    }
  } else {
    if (
      (status >= status400 && status < status600 && status !== status401) ||
      (status >= status200 && status < status300)
    ) {
      content = message;
    } else if (status === status401) {
      if (!isStopRedirectionToLogin) {
        localStorage.removeItem("loginInfo"); //remove stored loggedin user data
        window.location = "login";
      }
    }
    if (content) {
      content = "DATABASE_ERROR";
      if (dpList) {
        toast.error(
          <>
            <span className="toast-icon">
              <AiOutlineInfoCircle size={20} />
            </span>
            <span className="toast-text">
              {isCustomError ? message : content}
            </span>
            <div
              onClick={() => showModal(dpList)}
              style={{ cursor: "pointer" }}
            >
              <a className="toast-details">Click to see more details</a>
            </div>
          </>,
          {
            position: "top-right",
            autoClose: 5000,
            transition: Slide,
            closeOnClick: false,
          }
        );
      } else {
        toast.error(
          <>
            <span className="toast-icon">
              <AiOutlineInfoCircle size={20} />
            </span>
            <span className="toast-text">
              {isCustomError ? message : content}
            </span>
          </>,
          {
            position: "top-right",
            autoClose: 5000,
            transition: Slide,
          }
        );
      }
    }
  }
}
