import { all } from "redux-saga/effects";

//public
import AuthSaga from "./auth/login/saga";
import LayoutSaga from "./layout/saga";
import DomainsSaga from "./domains/saga";
import DataProductsSaga from "./dataProducts/saga";
import DataProductTypesSaga from "./dataProductTypes/saga";
import RolesSaga from "./role/saga";
import TeamsSaga from "./team/saga";
import UsersSaga from "./user/saga";
import DataSourceSaga from "./dataSource/saga";
import DataProductInteractionMapSaga from "./dataProductInteractionMap/saga";
import DataProductsRelationSaga from "./dataProducts/relations/saga";
import TeamSpaceSaga from "./teamSpaces/saga";

export default function* rootSaga() {
  yield all([
    //public
    AuthSaga(),
    LayoutSaga(),
    DomainsSaga(),
    DataProductsSaga(),
    DataProductTypesSaga(),
    RolesSaga(),
    TeamsSaga(),
    UsersSaga(),
    DataSourceSaga(),
    DataProductsRelationSaga(),
    DataProductInteractionMapSaga(),
    TeamSpaceSaga(),
  ]);
}
