import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { getDataProductsByDomain } from "../../store/actions";
import { AXIOS_INSTANCE, DATA_PRODUCTS_API } from "../../store/apiUtils/config";
import { GlobalNotificationHandle } from "../../services/NotificationHandler";

const ImportTeamMembers = ({ domainId, teamMembersHandler }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [teamDataProductId, setTeamDataProductId] = useState("");
  const dispatch = useDispatch();

  const { dataProductsByDomain } = useSelector((state) => ({
    dataProductsByDomain: state.DataProducts.dataProductsByDomain,
  }));

  useEffect(() => {
    if (
      dataProductsByDomain === undefined &&
      domainId !== "" &&
      domainId !== undefined
    ) {
      const id = domainId;
      dispatch(getDataProductsByDomain(undefined, id));
    }
  }, [domainId, dataProductsByDomain, dispatch]);

  const initializeState = () => {
    setTeamDataProductId("");
    setShowOptions(false);
  };

  const fetchTeamMembersByDataProduct = useCallback(async () => {
    try {
      const { data } = await AXIOS_INSTANCE.get(
        `${DATA_PRODUCTS_API}/getById?id=${teamDataProductId}`
      );
      const teamMembers = data?.data?.dataProduct?.teams;
      const ownerMemberName = data?.data?.dataProduct?.ownerName;
      teamMembersHandler("import", { teamMembers, ownerMemberName });
      initializeState();
    } catch (e) {
      GlobalNotificationHandle(
        e.response.status,
        e.response.data.errorDescription,
        false,
        true
      );
    }
  }, [teamDataProductId, teamMembersHandler]);

  const renderElements = useCallback(() => {
    if (dataProductsByDomain?.isSuccess) {
      if (showOptions) {
        return (
          <Stack spacing={1}>
            <TextField
              select
              label="Select Data Product"
              onChange={(event) => setTeamDataProductId(event.target.value)}
              value={teamDataProductId}
              size="small"
            >
              {dataProductsByDomain?.dataProductList?.map((dataProduct) => (
                <MenuItem
                  key={`team-of-${dataProduct.name}`}
                  value={dataProduct.id}
                >
                  {dataProduct.name}
                </MenuItem>
              ))}
            </TextField>
            <Stack
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              spacing={2}
            >
              <Button
                variant="contained"
                color="grey"
                onClick={() => initializeState()}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                disabled={teamDataProductId === ""}
                onClick={() => fetchTeamMembersByDataProduct()}
              >
                Import
              </Button>
            </Stack>
          </Stack>
        );
      } else {
        return (
          <Typography
            variant="body2"
            color="secondary"
            className="wrap-text"
            sx={{ zIndex: 30, cursor: "pointer" }}
            onClick={() => setShowOptions(true)}
          >
            + Import team members
          </Typography>
        );
      }
    } else return null;
  }, [
    dataProductsByDomain,
    showOptions,
    teamDataProductId,
    fetchTeamMembersByDataProduct,
  ]);

  return <>{renderElements()}</>;
};

ImportTeamMembers.propTypes = {
  domainId: PropTypes.string.isRequired,
  teamMembersHandler: PropTypes.func.isRequired,
};

export default ImportTeamMembers;
