import React, { Component, Suspense } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Loader from "react-spinners/FadeLoader";

class NonAuthLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Suspense
        fallback={
          <div className="backdrop-loader" data-testid="NonAuthLayout-loader">
            <div className="overlay">
              <Loader color={"#0E48EB"} loading={true} margin={2} size={20} />
            </div>
          </div>
        }
      >
        <React.Fragment>{this.props.children}</React.Fragment>
      </Suspense>
    );
  }
}
NonAuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withRouter(NonAuthLayout);
