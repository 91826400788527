import React from "react";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import PropTypes from "prop-types";

import "./Alert.scss";

const Alert = ({ children }) => {
  return (
    <div className="review-alert">
      <ReportProblemOutlinedIcon
        sx={{ color: "#FFC414", margin: "0 8px", fontSize: "21px" }}
        data-testid="Alert-Icon"
      />
      {children}
    </div>
  );
};

Alert.propTypes = {
  children: PropTypes.any,
};

export default Alert;
