import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";

import DomainList from "./DomainList";
import Layout from "../../components/Common/Layout";
import Breadcrumb from "../../components/Common/BreadCrumb";
import EditDomainBreadCrumbs from "./EditDomainBreadCrumbs";
import EditDomain from "./EditDomain";
import AddDomainComponent from "./AddDomainComponent";
import AddDomainModal from "./AddDomainModal";

import { getUserData } from "../../helpers";
import { getAllUsers } from "../../store/actions";
import { GlobalNotificationHandle } from "../../services/NotificationHandler";
import { socket } from "../../services/Socket";
import AdminList from "./AdminList";
import EditAdmins from "./EditAdmins";
import EditAdminComponent from "./EditAdminComponent";
import SearchDataDomains from "./SearchDataDomains";

import "./index.scss";
const PAGE_LIMIT = 12;

const AdminDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [searchWord, setSearchWord] = useState("");
  const [adminList, setadminList] = useState([]);
  const [copyAdminList, setCopyAdminList] = useState([]); // To collect add and delete admins
  const [editDomain, setEditDomain] = useState({
    editStatus: false,
    domainDetails: {},
  });
  const [editAdminsStatus, setEditAdminsStatus] = useState(false);
  const [isAddDomainModalVisible, setIsAddDomainModalVisible] = useState(false);
  const { userDetails } = getUserData();
  const [domainList, setDomainList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    socket.emit("getAllDomains", {
      searchValue: searchWord,
      limit: PAGE_LIMIT,
      page: currentPage,
    });

    socket.on("receiveAllDomains", (payload) => {
      setDomainList(payload.domainList);
      setTotal(payload.total);
    });

    return () => {
      socket.off("receiveAllDomains");
    };
  }, [currentPage, searchWord]);

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  useEffect(() => {
    socket.lastJoinedRoom = `admin`;
    socket.emit("joinRoom", socket.lastJoinedRoom);
    socket.emit("getAllAdmins");
    socket.emit("getAllUsers");

    return () => {
      socket.off("receiveAllDomains");
      socket.off("receiveAllAdmins");
      socket.emit("leaveRoom", socket.lastJoinedRoom);
      socket.lastJoinedRoom = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    socket.on("receiveAllAdmins", (payload) => {
      if (userDetails) {
        const isCurrentUserExists = payload.find(
          (user) => user.id === userDetails.id
        );
        if (!isCurrentUserExists) {
          handleLogout();
        }
      } else {
        handleLogout();
      }
      setadminList(payload);
      const disableCurrentUser = payload.map((user) => {
        if (user.id === userDetails?.id) user.disabled = true;
        return user;
      });
      setCopyAdminList(disableCurrentUser);
    });
    socket.on("receiveAllUsers", (payload) => {
      setUsers(payload);
    });

    socket.on("error", (err) => {
      if (err.message === "You are not an admin user") handleLogout();
    });
  }, []);

  const handleLogout = () => {
    Auth.signOut();
    localStorage.clear();
    history.push("/login");
    GlobalNotificationHandle(403, `You are not and admin`, false, true, true);
  };

  const handleDomainSubmit = (domainName) => {
    socket.emit(
      "createDomain",
      {
        name: domainName,
        limit: PAGE_LIMIT,
        page: currentPage,
      },
      socket.lastJoinedRoom
    );
    GlobalNotificationHandle(
      200,
      `${domainName} data domain has been created`,
      true,
      true
    );
  };

  const handleSaveAdmins = () => {
    const { adminsToBeAdded, adminsToBeDeleted } = getAdminsToAddDelete();
    socket.emit(
      "updateAdmins",
      {
        adminsToBeAdded: adminsToBeAdded,
        adminsToBeDeleted: adminsToBeDeleted,
      },
      socket.lastJoinedRoom
    );
    GlobalNotificationHandle(200, `Admins updated successfully`, true, true);
    setEditAdminsStatus(false);
  };

  const getAdminsToAddDelete = () => {
    const adminsToBeAdded = copyAdminList.filter(
      (obj) => !adminList.some((item) => item.id === obj.id)
    );
    const adminsToBeDeleted = adminList.filter(
      (obj) => !copyAdminList.some((item) => item.id === obj.id)
    );

    return { adminsToBeAdded, adminsToBeDeleted };
  };

  return (
    <div className="global-top-1 admin-details">
      <Layout>
        {!editDomain.editStatus && (
          <div className="admin-details-breadcrumb">
            <Breadcrumb
              steps={[
                { name: "Home Page", href: "/home" },
                {
                  name: "Admin Panel",
                  active: true,
                },
              ]}
            />
          </div>
        )}
        {editDomain.editStatus ? (
          <div>
            <EditDomainBreadCrumbs
              editDomain={editDomain}
              setEditDomain={setEditDomain}
            />
            <EditDomain
              selectedDomain={editDomain?.domainDetails}
              setEditDomain={setEditDomain}
              currentPage={currentPage}
              PAGE_LIMIT={PAGE_LIMIT}
              setSearchWord={setSearchWord}
            />
          </div>
        ) : (
          <div>
            <SearchDataDomains
              searchWord={searchWord}
              setSearchWord={setSearchWord}
              setCurrentPage={setCurrentPage}
            />
            <AddDomainComponent
              setIsAddDomainModalVisible={setIsAddDomainModalVisible}
            />
            <DomainList
              setCurrentPage={setCurrentPage}
              domainList={domainList}
              total={total}
              searchWord={searchWord}
              setEditDomain={setEditDomain}
              currentPage={currentPage}
            />
          </div>
        )}

        <EditAdminComponent
          editAdminsStatus={editAdminsStatus}
          setEditAdminsStatus={setEditAdminsStatus}
          handleSaveAdmins={handleSaveAdmins}
        />
        {editAdminsStatus ? (
          <EditAdmins
            copyAdminList={copyAdminList}
            setCopyAdminList={setCopyAdminList}
            users={users}
          />
        ) : (
          <AdminList adminList={adminList} />
        )}
      </Layout>
      <AddDomainModal
        isAddDomainModalVisible={isAddDomainModalVisible}
        setIsAddDomainModalVisible={setIsAddDomainModalVisible}
        handleDomainSubmit={handleDomainSubmit}
      />
    </div>
  );
};

export default AdminDetails;
