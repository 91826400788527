export const sections = [
  {
    id: "operational-dp-job",
    label: "Data Product Job",
    width: 1.75,
    infoText:
      "what “job” will be done thanks to this Data Product. By ”job” we mean a decision that has to be made or an insight generated.",
    merged: {
      parentTitle: "Operational",
      isSecond: false,
    },
  },
  {
    id: "operational-consumer",
    label: "Consumer",
    width: 1.75,
    infoText:
      "The main consumer (role, team, department) for whom the Data Product has been designed for.",
    merged: {
      parentTitle: "Operational",
      isSecond: true,
      parentInfoText: "Operational defines the usage of Data Product",
    },
  },
  {
    id: "Consumer Oriented Data Product",
    label: "Consumer Oriented Data Products",
    infoText:
      "Consumer Oriented Data Products generally aim to satisfy a particular need or use case. These data products may enrich, combine or adapt source data to address a particular use case and may include analytical data, machine learning models or reports.",
    width: 1.5,
  },
  {
    id: "Aggregate Data Product",
    label: "Aggregate Data Products",
    infoText:
      "Source Oriented Data Products generally contain data that is closely aligned to data in the source systems. This may include transactional data, master data or events. This data is generally less enriched than other Data Product types, but may be more general and able to be adapted to specific needs.",
    width: 1.5,
  },
  {
    id: "Source Oriented Data Product",
    label: "Source Oriented Data Products",
    infoText:
      "Source Oriented Data Products generally contain data that is closely aligned to data in the source systems. This may include transactional data, master data or events. This data is generally less enriched than other Data Product types, but may be more general and able to be adapted to specific needs.",
    width: 1.5,
  },
  {
    id: "data-entites",
    label: "Data Entities",
    infoText:
      "Data Entity is a high level data asset that represents information created and used in business capabilities and processes",
    width: 2,
  },
  {
    id: "sources",
    label: "Sources",
    infoText: "The source of data",
    width: 2,
  },
];

export const dataProductTypeAbbreviations = {
  "Source Oriented Data Product": "SoDP",
  "Aggregate Data Product": "ADP",
  "Consumer Oriented Data Product": "CoDP",
};

export const COLUMN_WIDTH = 200;
export const INITIAL_HEIGHT = 1000;
export const INITIAL_WIDTH = COLUMN_WIDTH * 12;
export const ITEM_FIELDS = [
  "sources",
  "data-entites",
  "operational-consumer",
  "operational-dp-job",
];

export const requiredConnections = {
  sources: ["source"],
  "data-entites": ["target", "source"],
  "operational-consumer": ["target", "source"],
  "operational-dp-job": ["target"],
  hexagon: ["target", "source"],
};

export const DATA_PRODUCT_SEARCH_PAGE_SIZE = 10;

export const DPIM_SECTION_ORDER = sections.map((section) => section.id);

export const generateInitialNodes = (nodes, userUpdatedSecionWidth) => {
  const updatedWidthSections = {};

  sections.forEach((section) => {
    updatedWidthSections[section.id] = section.width * COLUMN_WIDTH;
  });

  nodes.forEach((node) => {
    if (node.position.x > (updatedWidthSections[node.parentNode] || 0)) {
      updatedWidthSections[node.parentNode] =
        node.position.x + (node?.width || 140);
    }
  });

  const newSections = sections.map((section) => ({
    id: section.id,
    data: { ...section },
    type: "section",
    position: {
      x: 0,
      y: 0,
    },
    draggable: false,
    selectable: false,
    style: {
      width:
        userUpdatedSecionWidth[section.id] || updatedWidthSections[section.id],
      height: INITIAL_HEIGHT,
    },
  }));

  newSections.forEach((section, idx) => {
    newSections[idx] = {
      ...section,
      position: {
        x: idx
          ? newSections[idx - 1].position?.x + newSections[idx - 1].style?.width
          : 0,
        y: 0,
      },
    };
  });

  return newSections;
};
