import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

const Pagination = ({
  itemsPerPage,
  totalItems,
  setCurrentPage,
  currentPage,
  className,
  selectedFilter,
  searchValue,
  handleChange = () => {},
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [activeStep, setActiveStep] = useState(currentPage + 1);

  useEffect(() => {
    setCurrentPage(activeStep - 1);
  }, [activeStep, setCurrentPage]);

  useEffect(() => {
    setActiveStep(1);
  }, [selectedFilter, searchValue]);

  const handleLeftBtn = () => {
    if (activeStep > 1) {
      setActiveStep((prev) => prev - 1);
      handleChange(activeStep - 2);
    }
  };

  const handleRightBtn = () => {
    if (activeStep < totalPages) {
      setActiveStep((prev) => prev + 1);
      handleChange(activeStep);
    }
  };

  return (
    <div className={className}>
      <KeyboardArrowLeft
        style={{ cursor: activeStep > 1 ? "pointer" : "default" }}
        aria-label="left"
        onClick={handleLeftBtn}
      />
      <span data-testid="pagnum-by-totalPage">
        {activeStep} / {totalPages}
      </span>
      <KeyboardArrowRight
        style={{ cursor: activeStep < totalPages ? "pointer" : "default" }}
        aria-label="right"
        onClick={handleRightBtn}
      />
    </div>
  );
};

Pagination.propTypes = {
  itemsPerPage: PropTypes.number,
  totalItems: PropTypes.number,
  setCurrentPage: PropTypes.func,
  currentPage: PropTypes.number,
  handleChange: PropTypes.func,
  className: PropTypes.string,
  selectedFilter: PropTypes.string,
  searchValue: PropTypes.string,
};

export default Pagination;
