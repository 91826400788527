export const DOMAINS_CONST = {
  GET_ALL_DOMAINS_REQUEST: "GET_ALL_DOMAINS_REQUEST",
  GET_ALL_DOMAINS_SUCCESS: "GET_ALL_DOMAINS_SUCCESS",
  GET_ALL_DOMAINS_FAILURE: "GET_ALL_DOMAINS_FAILURE",
  GET_ENROLLED_DOMAINS_REQUEST: "GET_ENROLLED_DOMAINS_REQUEST",
  GET_ENROLLED_DOMAINS_SUCCESS: "GET_ENROLLED_DOMAINS_SUCCESS",
  GET_ENROLLED_DOMAINS_FAILURE: "GET_ENROLLED_DOMAINS_FAILURE",
  GET_COLLIBRA_DOMAINS_REQUEST: "GET_COLLIBRA_DOMAINS_REQUEST",
  GET_COLLIBRA_DOMAINS_SUCCESS: "GET_COLLIBRA_DOMAINS_SUCCESS",
  GET_COLLIBRA_DOMAINS_FAILURE: "GET_COLLIBRA_DOMAINS_FAILURE",
};
