import { useCallback, useEffect } from "react";
import { socket } from "../../services/Socket";
import {
  AXIOS_INSTANCE,
  LEAN_VALUE_TREE_API,
  MEASURE_OF_SUCCESS_API,
} from "../../store/apiUtils/config";
import { GlobalNotificationHandle } from "../../services/NotificationHandler";

const useLVTNodeCopy = (
  setNodes,
  setEdges,
  nodes,
  edges,
  domainId,
  teamSpaceId,
  socketRoom,
  fetchLVTStatus
) => {
  const handleCopy = useCallback(
    async (event) => {
      const workSpace = socketRoom.split("-")[0];
      if (
        (event.ctrlKey || event.metaKey) &&
        (event.key === "c" || event.key === "C") &&
        event.srcElement.className !== "w-tc-editor-text"
      ) {
        let copiedNodes = [],
          copiedEdges = [],
          copiedNodesId = [];
        setNodes((prevNodes) => {
          copiedNodes = prevNodes?.filter((node) => {
            if (node?.data?.nodeSelectedForCopy) {
              copiedNodesId.push(node.id);
              return true;
            }
            return false;
          });
          return prevNodes;
        });
        setEdges((prevEdges) => {
          copiedEdges = prevEdges?.filter((edge) => {
            if (
              copiedNodesId.includes(edge.source) &&
              copiedNodesId.includes(edge.target)
            ) {
              return true;
            }
            return false;
          });
          return prevEdges;
        });
        const copiedCardCount = copiedNodes.length,
          copiedEdgeCount = copiedEdges.length;
        if (copiedCardCount > 0) {
          localStorage.setItem(
            `${workSpace}-copiedNodes`,
            JSON.stringify(copiedNodes)
          );
          localStorage.setItem(
            `${workSpace}-copiedEdges`,
            JSON.stringify(copiedEdges)
          );
          let successMsg;
          if (copiedEdgeCount > 0) {
            successMsg = `${copiedCardCount} cards and ${copiedEdgeCount} connection${
              copiedEdgeCount > 1 ? "s" : ""
            } copied successfully`;
          } else {
            successMsg = `${copiedCardCount} card${
              copiedCardCount > 1 ? "s" : ""
            } copied successfully`;
          }
          GlobalNotificationHandle(200, successMsg, true, true);
        }
      }

      if (
        (event.ctrlKey || event.metaKey) &&
        (event.key === "v" || event.key === "V") &&
        event.srcElement.className !== "w-tc-editor-text"
      ) {
        const currentNodeId = teamSpaceId ? teamSpaceId : domainId;
        const isTeamSpace = teamSpaceId ? true : false;
        const copiedNodes = JSON.parse(
          localStorage.getItem(`${workSpace}-copiedNodes`) || JSON.stringify([])
        );
        const copiedEdges = JSON.parse(
          localStorage.getItem(`${workSpace}-copiedEdges`) || JSON.stringify([])
        );
        localStorage.removeItem(`${workSpace}-copiedNodes`);
        localStorage.removeItem(`${workSpace}-copiedEdges`);

        let updatedNodes = [],
          updatedEdges = [],
          getCopiedMOS = [];
        let updatedNodeIds = {};
        let isLVTUpdated = false;
        let duplicateCardCount = 0,
          duplicateConnectionCount = 0;
        if (copiedNodes) {
          const nodePromises = copiedNodes.map(async (copiedNode) => {
            const copiedNodeId = copiedNode.id;
            if (
              nodes.find((existingNode) => existingNode.id === copiedNodeId)
            ) {
              ++duplicateCardCount;
              return;
            }
            try {
              const { data } = await AXIOS_INSTANCE.post(
                `${LEAN_VALUE_TREE_API}/create`,
                {
                  step: copiedNode.data.step,
                  coordinateX: copiedNode.position.x,
                  coordinateY: copiedNode.position.y,
                  domainId,
                  teamSpaceId,
                  label: copiedNode.data.label,
                }
              );
              if (data?.data?.isSuccess) {
                const newNodeId = await data.data.data.id; // uniquie id from creation
                updatedNodeIds[copiedNodeId] = newNodeId;
                const getCopiedMOSData = await AXIOS_INSTANCE.get(
                  `${MEASURE_OF_SUCCESS_API}/getByLeanValueTreeId`,
                  { params: { id: copiedNodeId } }
                );
                if (getCopiedMOSData?.data?.data?.isSuccess) {
                  getCopiedMOS = getCopiedMOSData?.data?.data?.data;
                  const mosPromises = getCopiedMOS?.map(async (obj) => {
                    try {
                      const newMOS = await AXIOS_INSTANCE.post(
                        `${MEASURE_OF_SUCCESS_API}/create`,
                        {
                          leanValueTreeId: newNodeId,
                          description: obj.label,
                        }
                      );
                      const newMOSData = newMOS?.data?.data?.data;
                      return newMOSData;
                    } catch (e) {
                      let status = e.response?.status ? e.response.status : 501;
                      let message = e.response?.message
                        ? e.response.message
                        : "Failed";
                      GlobalNotificationHandle(status, message, false, true);
                    }
                  });
                  await Promise.all(mosPromises);
                }
                const newNode = {
                  ...copiedNode,
                  data: {
                    ...copiedNode.data,
                    nodeSelectedForCopy: false,
                  },
                  id: newNodeId,
                };
                return newNode;
              } else return;
            } catch (e) {
              GlobalNotificationHandle(
                e.response.status,
                e.response.data.errorDescription,
                false,
                true
              );
            }
          });
          const nodePromisesData = await Promise.all(nodePromises);
          updatedNodes = nodePromisesData.filter((node) => node?.id);
        }

        if (copiedEdges) {
          const edgePromises = copiedEdges.map(async (copiedEdge) => {
            if (
              edges.find((existingEdge) => existingEdge.id === copiedEdge.id)
            ) {
              ++duplicateConnectionCount;
              return;
            }
            const copiedEdgeSourceId = copiedEdge.source;
            const copiedEdgeTargetId = copiedEdge.target;
            const updatedEdgeSourceId = updatedNodeIds[copiedEdgeSourceId];
            const updatedEdgeTargetId = updatedNodeIds[copiedEdgeTargetId];
            if (updatedEdgeSourceId && updatedEdgeTargetId) {
              try {
                const { data } = await AXIOS_INSTANCE.post(
                  `${LEAN_VALUE_TREE_API}/createHasLVTConnection`,
                  {
                    sourceId: updatedEdgeSourceId,
                    targetId: updatedEdgeTargetId,
                    domainId,
                    teamSpaceId,
                  }
                );
                if (data.data.isSuccess) {
                  const newEdgeId = await data.data.id;
                  return {
                    ...copiedEdge,
                    id: newEdgeId,
                    source: updatedEdgeSourceId,
                    target: updatedEdgeTargetId,
                  };
                } else return;
              } catch (e) {
                GlobalNotificationHandle(
                  e.response.status,
                  e.response.data.errorDescription,
                  false,
                  true
                );
              }
            }
          });
          const edgePromisesData = await Promise.all(edgePromises);
          updatedEdges = edgePromisesData.filter((edge) => edge?.id);
        }

        if (updatedNodes.length) {
          setNodes((existingNodes) => {
            const finalNodesArray = Array.from(
              new Set([...existingNodes, ...updatedNodes])
            );
            socket.emit(
              "updateLVTNodes",
              finalNodesArray,
              currentNodeId,
              isTeamSpace,
              socket.lastJoinedRoom
            );
            return finalNodesArray;
          });
          isLVTUpdated = true;
        }

        if (updatedEdges.length) {
          setEdges((existingEdges) => {
            const finalEdgesArray = Array.from(
              new Set([...existingEdges, ...updatedEdges])
            );
            socket.emit(
              "updateLVTEdges",
              finalEdgesArray,
              currentNodeId,
              isTeamSpace,
              socket.lastJoinedRoom
            );
            return finalEdgesArray;
          });
          isLVTUpdated = true;
        }
        if (isLVTUpdated) {
          fetchLVTStatus({ domainId, teamSpaceId });
          const pastedCardCount = updatedNodes.length;
          const pastedConnectionCount = updatedEdges.length;
          let successMsg = "";
          if (pastedCardCount > 0) {
            if (pastedConnectionCount > 0) {
              successMsg = `${pastedCardCount} card${
                pastedCardCount > 1 ? "s" : ""
              } and ${pastedConnectionCount} connection${
                pastedConnectionCount > 1 ? "s" : ""
              } pasted successfully`;
            } else {
              successMsg = `${pastedCardCount} card${
                pastedCardCount > 1 ? "s" : ""
              } pasted successfully`;
            }
          }
          GlobalNotificationHandle(200, successMsg, true, true);
        }
        let errorMsg = "";
        if (duplicateCardCount > 0) {
          if (duplicateConnectionCount > 0) {
            errorMsg = `${duplicateCardCount} copied card${
              duplicateCardCount > 1 ? "s" : ""
            } and ${duplicateConnectionCount} copied connection${
              duplicateConnectionCount > 1 ? "s" : ""
            } already exist within the LVT`;
          } else {
            errorMsg = `${duplicateCardCount} copied card${
              duplicateCardCount > 1 ? "s" : ""
            } already exist within the LVT`;
          }
          GlobalNotificationHandle(500, errorMsg, false, true, true);
        }
      }
    },
    [
      nodes,
      edges,
      setNodes,
      setEdges,
      socketRoom,
      domainId,
      teamSpaceId,
      fetchLVTStatus,
    ]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleCopy);

    return () => {
      document.removeEventListener("keydown", handleCopy);
    };
  }, [handleCopy]);
};

export default useLVTNodeCopy;
