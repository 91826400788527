import io from "socket.io-client";
import { jwtToken } from "../helpers/token";

export const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
  auth: async (cb) => {
    const token = await jwtToken();
    cb({ token });
  },
  extraHeaders: {
    username: localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))?.data?.userDetails?.name
      : null,
  },
});

socket.on("connect_error", (err) => {
  // eslint-disable-next-line no-console
  console.error(`Socket Error: ${err.message}`);
});
