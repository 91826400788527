import { DATA_SOURCES_CONST } from "./actionTypes";
const initialState = {
  error: "",
  loading: false,
};

const DataSource = (state, action) => {
  if (typeof state == "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case DATA_SOURCES_CONST.GET_ALL_DATA_SOURCES_REQUEST:
    case DATA_SOURCES_CONST.GET_ALL_DATA_SOURCES_FAILURE:
      state = {
        ...state,
        allDataSources: [],
      };
      break;
    case DATA_SOURCES_CONST.GET_ALL_DATA_SOURCES_SUCCESS:
      state = {
        ...state,
        allDataSources: action.payload?.response?.dataSourceList,
      };
      break;
    case DATA_SOURCES_CONST.GET_REQUIRED_DATA_SOURCES_REQUEST:
    case DATA_SOURCES_CONST.GET_REQUIRED_DATA_SOURCES_FAILURE:
      return {
        ...state,
        getRequiredDataSources: [],
      };
    case DATA_SOURCES_CONST.GET_REQUIRED_DATA_SOURCES_SUCCESS:
      return {
        ...state,
        getRequiredDataSources: action.payload?.response,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default DataSource;
