import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";

const AutoComplete = ({
  input,
  options,
  getOptionLabel,
  getOptionValue,
  placeholder,
  meta: { touched, error, warning },
}) => (
  <>
    <Select
      {...input}
      onChange={(value) => input.onChange(value)}
      onBlur={() => input.onBlur(input.value)}
      options={options}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      placeholder={placeholder}
      data-testid="autocomplete-select"
    />
    {touched &&
      (error ? (
        <span className="form-feedback" data-testid="autocomplete-error">
          {error}
        </span>
      ) : (
        warning && <span data-testid="autocomplete-warning">{warning}</span>
      ))}
  </>
);

AutoComplete.propTypes = {
  input: PropTypes.object,
  options: PropTypes.any,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  placeholder: PropTypes.string,
  meta: PropTypes.object,
};

export default AutoComplete;
