import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reset } from "redux-form";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { useLocation } from "react-router-dom";

import { ReactComponent as AddDP } from "../../../assets/images/AddDP.svg";
import useComponentVisible from "../../../helpers/CustomHooks/useComponentVisible";
import useModal from "../../../helpers/CustomHooks/useModal";
import {
  getAllDataProducts,
  getAllDomains,
  getAllFavoritesDataProducts,
  getDataProductsByDomain,
  getEnrolledDomains,
} from "../../../store/actions";
import DataProductList from "./DataProductList";
import CreateNewDataProductModal from "../../../components/Common/CreateNewDataProductModal";
import { GlobalNotificationHandle } from "../../../services/NotificationHandler";
import "./SearchDataProduct.scss";
import { socket } from "../../../services/Socket";
import { DATA_PRODUCT_SEARCH_PAGE_SIZE } from "./DPIMSettings";

export const MY_FAVORITES = "MY_FAVORITES";
export const ALL_DATA_PRODUCTS = "ALL_DATA_PRODUCTS";

const SearchDataProduct = ({
  setNodes,
  useCaseId,
  selectedDomainId,
  selectedTeamSpaceId,
  userEditAccess,
  nodes,
  allTeamSpace,
}) => {
  const [isSubMenuOpen, setSubMenuOpen] = useState(false);
  const [searchType, setSearchType] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible();
  const { createDpModal, createDpModalToggle } = useModal();
  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const excludeSandbox = true;

  useEffect(() => {
    dispatch(getEnrolledDomains()); // To check for test/sandbox domain status
    dispatch(getAllDomains());
  }, [dispatch]);

  const {
    allDataProducts,
    totalDataProducts,
    allDomains,
    getAllFavoritesDataProductsData,
    isDataProductsLoading,
    getAllDataProductsByDomain,
    totalDataProductsSandbox,
  } = useSelector((state) => ({
    allDataProducts: state.DataProducts.allDataProducts,
    totalDataProducts: state.DataProducts?.totalDataProducts,
    allDomains: state.Domains?.allDomains,
    getAllFavoritesDataProductsData:
      state.DataProducts?.getAllFavoritesDataProductsData,
    isDataProductsLoading: state.DataProducts.isDataProductsLoading,
    getAllDataProductsByDomain: state.DataProducts?.dataProductsByDomain,
    totalDataProductsSandbox: state.DataProducts?.totalDataProductsByDomain,
  }));

  let totalFavorite = getAllFavoritesDataProductsData?.length;
  const selectedData =
    allDomains?.find((domain) => domain.id === selectedDomainId) ||
    allTeamSpace?.find((teamspace) => teamspace.id === selectedTeamSpaceId);

  useEffect(() => {
    if (isSubMenuOpen && isComponentVisible) {
      if (selectedData?.isSandbox) {
        dispatch(
          getDataProductsByDomain(
            currentPage,
            selectedData?.id,
            DATA_PRODUCT_SEARCH_PAGE_SIZE,
            searchValue,
            "",
            !excludeSandbox
          )
        );
        dispatch(getAllFavoritesDataProducts(!excludeSandbox));
      } else {
        {
          dispatch(
            getAllDataProducts(
              DATA_PRODUCT_SEARCH_PAGE_SIZE,
              currentPage,
              null,
              searchValue,
              "dpim"
            )
          );
          dispatch(getAllFavoritesDataProducts(excludeSandbox));
        }
      }
    }
  }, [
    dispatch,
    isSubMenuOpen,
    isComponentVisible,
    searchType,
    currentPage,
    searchValue,
  ]);

  useEffect(() => {
    if (!isComponentVisible && isSubMenuOpen) {
      setSubMenuOpen(false);
      setSearchType(false);
    }
  }, [isComponentVisible, isSubMenuOpen]);

  useEffect(() => {
    if (searchType) {
      setCurrentPage(0);
    }
  }, [searchType]);

  useEffect(() => {
    if (!createDpModal) {
      dispatch(reset("CreateNewDataProductModalForm"));
    }
  }, [createDpModal, dispatch]);

  const listItems = useMemo(() => {
    if (isDataProductsLoading) {
      return [];
    }

    let dataProductList;

    if (selectedData?.isSandbox) {
      dataProductList =
        searchType === ALL_DATA_PRODUCTS
          ? getAllDataProductsByDomain
          : getAllFavoritesDataProductsData;
    } else {
      dataProductList =
        searchType === ALL_DATA_PRODUCTS
          ? allDataProducts
          : getAllFavoritesDataProductsData;
    }
    const mappedList = dataProductList?.map((dataProduct) => {
      return {
        ...dataProduct,
        isDisabled: nodes.some((node) => node.id === dataProduct.id),
      };
    });

    return mappedList || [];
  }, [
    allDataProducts,
    getAllDataProductsByDomain,
    getAllFavoritesDataProductsData,
    isDataProductsLoading,
    searchType,
    selectedData,
    nodes,
  ]);

  const refetchList = () => {
    if (selectedData?.isSandbox) {
      dispatch(
        getDataProductsByDomain(
          currentPage,
          selectedData?.id,
          DATA_PRODUCT_SEARCH_PAGE_SIZE,
          searchValue,
          "",
          !excludeSandbox
        )
      );
      dispatch(getAllFavoritesDataProducts(!excludeSandbox));
    } else {
      dispatch(
        getAllDataProducts(
          DATA_PRODUCT_SEARCH_PAGE_SIZE,
          currentPage,
          null,
          searchValue,
          "dpim"
        )
      );
      dispatch(getAllFavoritesDataProducts(excludeSandbox));
    }
  };

  const handleItemClick = async (dataProduct) => {
    const newNode = {
      id: dataProduct.id,
      type: "hexagon",
      position: {
        x: 0,
        y: 60,
      },
      connectable: true,
      parentNode: dataProduct.dataProductTypeName,
      extent: "parent",
      data: { label: dataProduct.name, dataProduct },
    };

    socket.emit(
      "addDataProductToDPIM",
      {
        coordinateX: 0,
        coordinateY: 60,
        useCaseId,
        dataProductId: dataProduct.id,
      },
      socket.lastJoinedRoom
    );

    setNodes((nds) => nds.concat(newNode));
    setIsComponentVisible(false);
    setCurrentPage(0);
  };

  const afterSubmit = (res) => {
    GlobalNotificationHandle(200, res.data.data.message, true, true);
    createDpModalToggle();
    socket.emit(
      "getAllDataProductInteractionMapItemsByUseCaseId",
      {
        useCaseId: params.get("useCaseId"),
      },
      socket.lastJoinedRoom
    );
  };

  const handleSubMenuClick = () => {
    return userEditAccess ? setSubMenuOpen((isOpen) => !isOpen) : null;
  };

  return (
    <div>
      <AddDP
        onClick={handleSubMenuClick}
        className={classNames("lvt-menu-icon big custom-body", {
          active: isSubMenuOpen,
          "cursor-pointer": userEditAccess,
          "cursor-not-allowed": !userEditAccess,
        })}
        data-testid="AddDP"
      />
      {isSubMenuOpen && isComponentVisible ? (
        <div ref={ref} className="dp-submenu" data-testid="dataProducts">
          <div
            onClick={() =>
              setSearchType((prev) =>
                prev === ALL_DATA_PRODUCTS ? false : ALL_DATA_PRODUCTS
              )
            }
            className={classNames("dp-submenu-item", {
              active: searchType === ALL_DATA_PRODUCTS,
            })}
          >
            Data Products
          </div>
          <div
            data-testid="favorites"
            onClick={() =>
              setSearchType((prev) =>
                prev === MY_FAVORITES ? false : MY_FAVORITES
              )
            }
            className={classNames("dp-submenu-item", {
              active: searchType === MY_FAVORITES,
            })}
          >
            <FavoriteBorderIcon color="secondary" /> Favorites
          </div>
          <div
            className="dp-submenu-item"
            onClick={() => createDpModalToggle()}
          >
            Create new
          </div>
          {searchType ? (
            <DataProductList
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setSearchValue={setSearchValue}
              isItemsLoading={isDataProductsLoading}
              searchable={searchType}
              items={listItems}
              onItemClick={handleItemClick}
              refetchList={refetchList}
              totalDataProducts={totalDataProducts}
              totalDataProductsSandbox={totalDataProductsSandbox}
              totalFavorite={totalFavorite}
              isSandbox={selectedData?.isSandbox}
            />
          ) : null}
        </div>
      ) : null}
      {selectedData !== undefined ? (
        <CreateNewDataProductModal
          createDpModal={createDpModal}
          createDpModalToggle={createDpModalToggle}
          afterSubmit={afterSubmit}
          useCaseId={useCaseId}
          selectedData={selectedData}
        />
      ) : null}
    </div>
  );
};

SearchDataProduct.propTypes = {
  setNodes: PropTypes.func,
  useCaseId: PropTypes.string,
  selectedDomainId: PropTypes.string,
  selectedTeamSpaceId: PropTypes.string,
  userEditAccess: PropTypes.bool.isRequired,
  nodes: PropTypes.array,
  allTeamSpace: PropTypes.array,
};

export default SearchDataProduct;
