import React, { useState, useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import MenuItem from "@mui/material/MenuItem";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutline";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Tooltip from "@mui/material/Tooltip";

import {
  CONNECTABLE_STEPS,
  DIAGRAM_ORDER,
  STEP_LABELS,
} from "./LVTDiagramSettings";
import CustomMenu from "../../../components/Common/CustomMenu";
import "./DiagramItemMenu.scss";

const DiagramItemMenu = ({
  data,
  id,
  linkAnchorEl,
  setLinkAnchorEl,
  isLinkMenuOpen,
  setIsLinkMenuOpen,
  onNodesRemove,
  editAccess,
  onConnect,
}) => {
  const [subAnchorEl, setSubAnchorEl] = useState(null);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [stepAnchorEl, setStepAnchorEl] = useState(null);
  const [isStepMenuOpen, setIsStepMenuOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState("");
  const [hoverText, setHoverText] = useState(null);
  const [nodesCopy, setNodesCopy] = useState([]);
  const [edgesCopy, setEdgesCopy] = useState([]);
  const [connectableNodesByStep, setConnectableNodesByStep] = useState({});
  const connectableSteps = CONNECTABLE_STEPS[data?.step] || [];

  useEffect(() => {
    setNodesCopy(JSON.parse(sessionStorage.getItem("nodesCopy")));
    setEdgesCopy(JSON.parse(sessionStorage.getItem("edgesCopy")));
  }, [
    // eslint-disable-next-line
    sessionStorage.getItem("nodesCopy"),
    // eslint-disable-next-line
    sessionStorage.getItem("edgesCopy"),
  ]);

  useEffect(() => {
    const totalNodesBySteps = Array.isArray(nodesCopy)
      ? nodesCopy.filter((node) => {
          return connectableSteps.includes(node.data.step);
        })
      : [];

    const connectableNodes = totalNodesBySteps.filter((node) => {
      return !isAlreadyConnected(node.id);
    });
    let connectableNodesByStepTemp = {};
    connectableSteps.forEach((step) => {
      let stepNodes = connectableNodes.filter((node) => {
        return node.data.step === step ? node : null;
      });
      connectableNodesByStepTemp[step] = stepNodes;
    });
    setConnectableNodesByStep(connectableNodesByStepTemp);
    const hoverText = hoverTextForLink(
      totalNodesBySteps,
      connectableNodesByStepTemp,
      connectableSteps
    );
    setHoverText(hoverText);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodesCopy]);

  const hoverTextForLink = (
    totalNodesBySteps,
    connectableNodesByStep,
    connectableSteps
  ) => {
    if (!totalNodesBySteps.length) {
      return `Please add at least one card of type ${connectableSteps
        .map((step) => STEP_LABELS[step])
        .join(" or ")} into the canvas`;
    } else if (!Object.values(connectableNodesByStep).flat().length) {
      return `${data.label} is connected to all possible card`;
    } else return null;
  };

  const isAlreadyConnected = (targetId) => {
    // Since connection can be in both way, check for both below
    return edgesCopy.some(
      (edge) =>
        (edge.source === id && edge.target === targetId) ||
        (edge.source === targetId && edge.target === id)
    );
  };

  const handleSubmenuBtnClick = (event) => {
    setSubAnchorEl(event.currentTarget);
    setIsSubMenuOpen(true);
  };
  const handleStepBtnClick = (event, step) => {
    setStepAnchorEl(event.currentTarget);
    setIsStepMenuOpen(true);
    setCurrentStep(step);
  };

  const handleOnConnect = (nodeDetails) => {
    const sourceType = data.step;
    const targetType = nodeDetails.data.step;

    let source = id;
    let target = nodeDetails.id;
    // Swap the source and target if not in order
    if (DIAGRAM_ORDER.indexOf(targetType) < DIAGRAM_ORDER.indexOf(sourceType)) {
      target = id;
      source = nodeDetails.id;
    }
    onConnect({ source: source, target: target });
  };

  const closeAllMenu = () => {
    setIsSubMenuOpen(false);
    setIsStepMenuOpen(false);
    setIsLinkMenuOpen(false);
  };

  const commonClass = classNames({
    "cursor-pointer": editAccess,
    "cursor-not-allowed": !editAccess,
  });

  return (
    <>
      <CustomMenu
        id="delet-lvt-icon"
        anchorEl={linkAnchorEl}
        setAnchorEl={setLinkAnchorEl}
        isMenuOpen={isLinkMenuOpen}
        setIsMenuOpen={setIsLinkMenuOpen}
        closeAllMenu={closeAllMenu}
      >
        <Tooltip title={hoverText} placement="top">
          <div>
            <MenuItem
              className={commonClass}
              onClick={handleSubmenuBtnClick}
              disabled={hoverText ? true : false}
            >
              Link with
              <ArrowRightIcon />
            </MenuItem>
          </div>
        </Tooltip>
        <MenuItem
          className={commonClass}
          onClick={editAccess ? () => onNodesRemove(id) : null}
        >
          Delete
          <DeleteOutlinedIcon />
        </MenuItem>
      </CustomMenu>

      {/* Submenu */}
      {Object.values(connectableNodesByStep).flat().length > 0 && (
        <>
          <CustomMenu
            anchorEl={subAnchorEl}
            setAnchorEl={setSubAnchorEl}
            isMenuOpen={isSubMenuOpen}
            setIsMenuOpen={setIsSubMenuOpen}
            closeAllMenu={closeAllMenu}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
          >
            {Object.keys(connectableNodesByStep)?.map((step, index) => {
              return (
                connectableNodesByStep[step].length > 0 && (
                  <MenuItem
                    key={index}
                    onClick={(event) => handleStepBtnClick(event, step)}
                  >
                    {STEP_LABELS[step]}
                    <ArrowRightIcon />
                  </MenuItem>
                )
              );
            })}
          </CustomMenu>

          {/* Step Menu */}
          {currentStep && connectableNodesByStep[currentStep].length > 0 && (
            <CustomMenu
              anchorEl={stepAnchorEl}
              setAnchorEl={setStepAnchorEl}
              isMenuOpen={isStepMenuOpen}
              setIsMenuOpen={setIsStepMenuOpen}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              closeAllMenu={closeAllMenu}
            >
              {connectableNodesByStep[currentStep]?.map((eachConnect) => {
                return (
                  <Tooltip
                    title={eachConnect.data.label}
                    key={eachConnect.id}
                    placement="right"
                  >
                    <MenuItem
                      key={eachConnect.id}
                      onClick={() => handleOnConnect(eachConnect)}
                    >
                      <span className="connectable-node">
                        {eachConnect.data?.label || "Empty card"}
                      </span>
                    </MenuItem>
                  </Tooltip>
                );
              })}
            </CustomMenu>
          )}
        </>
      )}
    </>
  );
};

DiagramItemMenu.propTypes = {
  id: PropTypes.string,
  data: PropTypes.object,
  onConnect: PropTypes.func,
  linkAnchorEl: PropTypes.any,
  setLinkAnchorEl: PropTypes.func,
  isLinkMenuOpen: PropTypes.bool,
  setIsLinkMenuOpen: PropTypes.func,
  onNodesRemove: PropTypes.func,
  handleClose: PropTypes.func,
  editAccess: PropTypes.bool,
};

export default DiagramItemMenu;
