import React, { useCallback, useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { Button } from "reactstrap";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIosNew";
import IconButton from "@mui/material/IconButton";

import { LEAN_VALUE_TREE_API } from "../../../store/apiUtils/config";
import Breadcrumb from "../../../components/Common/BreadCrumb";
import InfoCircle from "../../../components/Common/InfoCircle";
import { requiredConnections } from "./DPIMSettings";
import useStatus from "../../../helpers/CustomHooks/useStatus";
import { socket } from "../../../services/Socket";
import Stack from "@mui/material/Stack";
import MultipleUserIndicator from "../../../components/Common/MultipleUserIndicator";
import StepperHorizontal from "../../../components/Common/StepperHorizontal";
import { GlobalNotificationHandle } from "../../../services/NotificationHandler";

import "./DPHeader.scss";

const DPHeader = ({
  fetchDPIMInformation,
  useCaseId,
  selectedDomainId,
  selectedTeamSpaceId,
  nodes,
  edges,
  statusDP,
  userEditAccess,
  activeStep,
  setActiveStep,
}) => {
  const [useCases, setUseCases] = useState([]);
  const [selectedUseCase, setSelectedUseCase] = useState({});
  const [selectorOpen, setSelectorOpen] = useState(true);
  const { status, checkStatus } = useStatus(
    `STATUS-DPIM-${useCaseId}`,
    useCaseId,
    {
      update: "updateDPIMStatus",
      check: "checkDPIMStatus",
    }
  );
  const [activeUsers, setActiveUsers] = useState([]);

  socket.on("activeUsersInRoom", (usersList) => {
    if (usersList !== undefined) {
      setActiveUsers(usersList);
    }
  });

  const breadcrumbParam = selectedTeamSpaceId
    ? `teamSpaceId=${selectedTeamSpaceId}`
    : `domainId=${selectedDomainId}`;
  const fetchUseCases = useCallback(async () => {
    const { data } = await fetchDPIMInformation(
      "get",
      `getRelatedUseCases?useCaseId=${useCaseId}&${breadcrumbParam}`,
      null,
      false
    );

    setUseCases(data.data.data);
    setSelectedUseCase(
      data.data.data.find((useCase) => useCase.id === useCaseId)
    );

    // Fetch LVT & DIM status
    const dimRes = await fetchDPIMInformation(
      "get",
      `getAllItemsByUseCaseId?useCaseId=${useCaseId}`,
      null,
      false
    );

    if (dimRes?.data?.data?.status === "submitted") {
      setActiveStep(2);
      return;
    } else {
      const lvtRes = await getLvtStatus(selectedDomainId, selectedTeamSpaceId);
      lvtRes?.data?.data?.data.LVTDiscovered
        ? setActiveStep(1)
        : setActiveStep(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLvtStatus = useCallback(
    (domainId, teamSpaceId) => {
      const url = teamSpaceId
        ? `getLVTDiscoveredByTeamSpaceId?teamSpaceId=${teamSpaceId}`
        : `getDomainDiscoveredByDomainId?domainId=${domainId}`;
      return fetchDPIMInformation("get", url, null, false, LEAN_VALUE_TREE_API);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const getIsConnectionMissing = useCallback(
    (type, step) => {
      const selectedNodes = step
        ? nodes.filter((node) => node.type === type && node.data.step === step)
        : nodes.filter((node) => node.type === type);
      if (!selectedNodes.length) {
        return null;
      }

      // check if ADP has a minimum of 2 input connections
      if (type === "hexagon") {
        const adpNodes = selectedNodes.filter(
          (node) => node.parentNode === "Aggregate Data Product"
        );
        if (adpNodes.length) {
          const getInputConnections = adpNodes.map((node) => {
            return (
              edges.filter((edge) => edge["target"] === node.id).length > 1
            );
          });

          if (getInputConnections.includes(false)) {
            return true;
          }
        }
      }

      const nodeConnectionOccurences = selectedNodes.map((node) => {
        let selectedEdges = [];
        let multipleConnectionsValid = true;
        requiredConnections[step || type].forEach((connectionType) => {
          const newEdges = edges.filter(
            (edge) => edge[connectionType] === node.id
          );
          selectedEdges = [...selectedEdges, ...newEdges];
          if (step === undefined && !newEdges.length) {
            multipleConnectionsValid = false;
          }
        });

        return multipleConnectionsValid ? selectedEdges.length : 0;
      });

      const hasNodeWithoutConnections =
        nodeConnectionOccurences.find((item) => item === 0) === 0;

      return hasNodeWithoutConnections;
    },
    [edges, nodes]
  );

  const isSubmitButtonVisible = useMemo(() => {
    const sourcesMissing = getIsConnectionMissing("dpItem", "sources");
    const dataEntitiesMissing = getIsConnectionMissing(
      "dpItem",
      "data-entites"
    );
    const dataproductsMissing = getIsConnectionMissing("hexagon");
    const operationalConsumerMissing = getIsConnectionMissing(
      "dpItem",
      "operational-consumer"
    );
    const operationalDpJobMissing = getIsConnectionMissing(
      "dpItem",
      "operational-dp-job"
    );

    if (
      sourcesMissing === null ||
      dataEntitiesMissing === null ||
      dataproductsMissing === null ||
      operationalConsumerMissing === null ||
      operationalDpJobMissing === null
    ) {
      return false;
    }

    return (
      !sourcesMissing &&
      !dataEntitiesMissing &&
      !dataproductsMissing &&
      !operationalConsumerMissing &&
      !operationalDpJobMissing
    );
  }, [getIsConnectionMissing]);

  const onSubmit = async () => {
    const lvtRes = await getLvtStatus(selectedDomainId, selectedTeamSpaceId);
    if (lvtRes?.data?.data?.data.LVTDiscovered) {
      if (isSubmitButtonVisible && statusDP !== "submitted") {
        await fetchDPIMInformation("post", "submit", { useCaseId });
        checkStatus(useCaseId);
        setActiveStep(2);
      } else {
        GlobalNotificationHandle(
          500,
          "The basic requirement is not met. Please connect all items on Data Product Interaction Map.",
          false,
          true,
          true
        );
      }
    } else {
      GlobalNotificationHandle(
        500,
        "Please complete the Lean Value Tree lineage before submitting.",
        false,
        true,
        true
      );
    }
  };

  useEffect(() => {
    checkStatus(useCaseId);
  }, [checkStatus, useCaseId]);

  useEffect(() => {
    fetchUseCases();
  }, [fetchUseCases]);

  return (
    <div>
      <div className="dpim-header pb-5">
        <div className="dpim-header-left">
          <div className="dpim-header-container dpim-header-container-title-container">
            <Stack
              direction="row"
              alignItems="center"
              className="dpim-header-container-title-stack"
            >
              <p className="dpim-header-container-title">
                Data Product Interaction Map{" "}
                <InfoCircle
                  text="Data Product Interaction Map is a conceptual tool to identify and design your Data Products that you need to achieve your use case."
                  orientation="right"
                />
              </p>
              <StepperHorizontal activeStep={activeStep} />
              <MultipleUserIndicator activeUsers={activeUsers} />
            </Stack>
            <Breadcrumb
              steps={[
                { name: "Home Page", href: "/home" },
                {
                  name: "Data Product value realization understood by Lean Value Tree",
                  href: `/leanvaluetree?${breadcrumbParam}`,
                },
                {
                  name: "Data Product discovery by Data Product Interaction Map",
                  href: `#`,
                  active: true,
                },
              ]}
            />
          </div>
        </div>
        <div className="dpim-header-container dpim-header-container-select-container">
          <IconButton
            className="dpim-header-container-select-icon"
            size="small"
            onClick={() => setSelectorOpen((prev) => !prev)}
          >
            {selectorOpen ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}
          </IconButton>
          {selectorOpen ? (
            <Select
              className="dp-usecase-select"
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  height: 50,
                }),
                valueContainer: (baseStyles) => ({
                  ...baseStyles,
                  marginTop: 16,
                }),
              }}
              value={selectedUseCase}
              onChange={(value) => {
                window.location = `/dataproduct-interaction-map?useCaseId=${value.id}&${breadcrumbParam}`;
              }}
              isDisabled={false}
              isLoading={false}
              isClearable={false}
              isRtl={false}
              isSearchable={false}
              name="color"
              options={useCases}
              getOptionLabel={(e) => e.description}
              getOptionValue={(e) => e.id}
              data-id="useCase-dropdown"
            />
          ) : null}
        </div>
        {!status?.DPIMSubmited && userEditAccess && (
          <div className="dpim-header-container dpim-header-container-button-container">
            <Button
              onClick={onSubmit}
              className="custom-btn dpim-header-button"
              data-testid="submit-dpim-button"
            >
              DP Interaction Map Done
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

DPHeader.propTypes = {
  setFullScreen: PropTypes.func,
  fullScreen: PropTypes.bool,
  fetchDPIMInformation: PropTypes.func,
  useCaseId: PropTypes.string,
  selectedDomainId: PropTypes.string,
  selectedTeamSpaceId: PropTypes.string,
  statusDP: PropTypes.string,
  nodes: PropTypes.array,
  edges: PropTypes.array,
  userEditAccess: PropTypes.bool,
  activeStep: PropTypes.number,
  setActiveStep: PropTypes.func,
};

export default DPHeader;
