import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import TextField from "@mui/material/TextField";
import CustomModal from "../../components/Common/CustomModal";

const AddDomainModal = ({
  handleDomainSubmit,
  setIsAddDomainModalVisible,
  isAddDomainModalVisible,
}) => {
  const [domainName, setDomainName] = useState("");

  useEffect(() => {
    return () => {
      setDomainName("");
    };
  }, []);

  const onPrimaryClick = (e) => {
    e.preventDefault();
    if (domainName !== "") {
      handleDomainSubmit(domainName);
      setDomainName("");
      setIsAddDomainModalVisible(false);
    }
  };

  return (
    <CustomModal
      data-testid="AddDomainModal-Modal"
      displayState={isAddDomainModalVisible}
      toggleState={() => setIsAddDomainModalVisible((prevState) => !prevState)}
      primaryBtn="Save"
      secondaryBtn="Cancel"
      primaryBtnHandler={onPrimaryClick}
      secondaryHandler={() => setIsAddDomainModalVisible(false)}
      formId="add-domain-form"
    >
      <form
        id="add-domain-form"
        onSubmit={onPrimaryClick}
        data-testid="AddDomainModal-Form"
      >
        <h4 data-testid="AddDomainModal-Title">Add New Data domain</h4>
        <h6
          className="mt-2 font-weight-normal"
          data-testid="AddDomainModal-DomainNameLabel"
        >
          Domain name
        </h6>
        <TextField
          data-testid="AddDomainModal-TextField"
          onChange={(e) => setDomainName(e.target.value)}
          size="small"
          fullWidth={true}
          autoComplete="off"
          error={domainName === ""}
          helperText={domainName === "" ? "Domain name cannot be empty" : ""}
          inputProps={{ "data-testid": "AddDomainModal-Input" }}
        />
        <p className="mt-4" data-testid="AddDomainModal-InfoText">
          Adding the Data Domain Owner and other domain members will be
          available once the Data Domain has been added using edit
          functionality.
        </p>
      </form>
    </CustomModal>
  );
};

AddDomainModal.propTypes = {
  handleDomainSubmit: PropTypes.func,
  setIsAddDomainModalVisible: PropTypes.func,
  isAddDomainModalVisible: PropTypes.bool,
};

export default AddDomainModal;
