import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";

import Layout from "../../components/Common/Layout";
import Breadcrumb from "../../components/Common/BreadCrumb";
import TeamSpaceList from "./TeamSpaceList";
import CreateTeamSpaceButton from "./CreateTeamSpaceButton";
import { socket } from "../../services/Socket";
import { getUserData } from "../../helpers";
import { GlobalNotificationHandle } from "../../services/NotificationHandler";
import EditTeamSpaceBreadCrumbs from "./EditTeamSpaceBreadCrumbs";
import EditTeamSpace from "./EditTeamSpace";

import "./index.scss";

const TeamSpaceForDomain = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const domainId = params.get("domainId");
  const [teamSpace, setTeamSpaceData] = useState([]);
  const domainName = decodeURIComponent(window.location.pathname.split("/")[2]);
  const { userDetails } = getUserData();
  const [editTeamSpace, setEditTeamSpace] = useState({
    editStatus: false,
    teamSpaceDetails: {},
  });
  const history = useHistory();
  const [teamSpaceTotal, setTeamSpaceTotal] = useState(0);

  const refreshTeamSpaces = () => {
    socket.emit("getTeamSpaceByDomainId", { domainId });
    socket.on("receiveTeamSpaceByDomainIdData", (data) => {
      setTeamSpaceData(data?.teamSpaceList);
      setTeamSpaceTotal(data?.total);
    });

    return () => {
      socket.off("receiveTeamSpaceByDomainIdData");
    };
  };

  useEffect(() => {
    refreshTeamSpaces();
  }, [domainId]);

  useEffect(() => {
    socket.emit("getAllAdmins");
    socket.on("receiveAllAdmins", (payload) => {
      const isCurrentUserExists = payload.find(
        (user) => user.id === userDetails.id
      );
      if (!isCurrentUserExists) {
        handleLogout();
      }
    });
    socket.on("error", (err) => {
      if (err.message === "You are not an admin user") handleLogout();
    });
  }, []);

  const handleLogout = () => {
    Auth.signOut();
    localStorage.clear();
    history.push("/login");
    GlobalNotificationHandle(403, `You are not and admin`, false, true, true);
  };

  return (
    <div className="global-top-1 admin-details-team-space">
      <Layout>
        {!editTeamSpace.editStatus && (
          <div className="admin-details-team-space-breadcrumb">
            <Breadcrumb
              steps={[
                { name: "Home Page", href: "/home" },
                {
                  name: "Admin Panel",
                  href: "/admin-details",
                },
                { name: "Team Spaces", active: true },
              ]}
            />
          </div>
        )}
        {editTeamSpace.editStatus ? (
          <div>
            <EditTeamSpaceBreadCrumbs
              editTeamSpace={editTeamSpace}
              setEditTeamSpace={setEditTeamSpace}
            />
            <EditTeamSpace
              selectedTeamSpace={editTeamSpace?.teamSpaceDetails}
              setEditTeamSpace={setEditTeamSpace}
              isEdit={editTeamSpace?.editStatus}
              domainName={domainName}
            ></EditTeamSpace>
          </div>
        ) : (
          <>
            <div className="team-space-name">Team Spaces - {domainName}</div>
            <CreateTeamSpaceButton
              id={domainId}
              name={domainName}
            ></CreateTeamSpaceButton>
            {teamSpaceTotal ? (
              <>
                <div className="">
                  {teamSpace.map((item, id) => {
                    return <TeamSpaceList key={id} teamSpaceList={item} />;
                  })}
                </div>
                <TeamSpaceList
                  teamSpaceList={teamSpace}
                  total={teamSpaceTotal}
                  setEditTeamSpace={setEditTeamSpace}
                ></TeamSpaceList>
              </>
            ) : null}
          </>
        )}
      </Layout>
    </div>
  );
};

export default TeamSpaceForDomain;
