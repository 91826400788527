import React, { useEffect, useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import MultipurposeAccordion from "../../components/Common/MultipurposeAccordion";
import { GlobalNotificationHandle } from "../../services/NotificationHandler";
import MyDataProductSettings from "../../components/Common/MyDataProductSettings";
import DataProductCard from "../../components/Common/DataProductCard";
import Pagination from "./Pagination";
import NoDataProducts from "./NoDataProducts";
import useMatchMedia from "../../helpers/CustomHooks/useMatchMedia";
import useDebounce from "../../helpers/CustomHooks/useDebounce";
import {
  getAllDomains,
  getDataProductsByDomain,
  getEnrolledDomains,
} from "../../store/actions";

const excludeSandbox = true;
function DataProductAccordion({ isExtended = false }) {
  const [setDataProductsWithDetails] = useState([]);
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("All");
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const domainId = params.get("domainId");
  const debouncedSearch = useDebounce(searchValue, 1000);
  const isSmallerScreen = useMatchMedia(1367);

  const dataProductPerPage = useMemo(() => {
    if (isSmallerScreen === 1367) {
      return 4;
    }

    return isSmallerScreen ? 4 : 6;
  }, [isSmallerScreen]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEnrolledDomains());
    dispatch(getAllDomains());
  }, [dispatch]);

  const { allDomains, dataProductsByDomain, totalDataProductsByDomain } =
    useSelector((state) => ({
      allDomains: state.Domains?.allDomains,
      dataProductsByDomain: state.DataProducts?.dataProductsByDomain,
      totalDataProductsByDomain: state.DataProducts?.totalDataProductsByDomain,
    }));
  const selectedDomain = allDomains?.find((domain) => domain.id === domainId);

  useEffect(() => {
    if (!dataProductPerPage) {
      return;
    }
    if (selectedDomain?.isSandbox) {
      dispatch(
        getDataProductsByDomain(
          currentPage,
          domainId,
          dataProductPerPage,
          debouncedSearch ? debouncedSearch : undefined,
          selectedFilter,
          !excludeSandbox
        )
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    } else {
      dispatch(
        getDataProductsByDomain(
          currentPage,
          domainId,
          dataProductPerPage,
          debouncedSearch ? debouncedSearch : undefined,
          selectedFilter,
          excludeSandbox
        )
      );
    }
  }, [
    currentPage,
    debouncedSearch,
    dataProductPerPage,
    selectedFilter,
    allDomains,
  ]);

  useEffect(() => {
    const maxPage = Math.max(
      Math.floor((totalDataProductsByDomain - 1) / dataProductPerPage),
      0
    );
    if (currentPage > maxPage) {
      setCurrentPage(maxPage);
    }
  }, [dataProductPerPage, totalDataProductsByDomain, currentPage]);

  const getExtended = useCallback(async () => {
    try {
      if (dataProductsByDomain?.length > 0) {
        return;
      }

      setDataProductsWithDetails(dataProductsByDomain);
    } catch (e) {
      GlobalNotificationHandle(400, e?.toString(), false, false, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProductsByDomain]);

  useEffect(() => {
    if (!isExtended) {
      getExtended();
    }
  }, [getExtended, isExtended]);

  const handleEditDataProduct = (dataProduct) => {
    history.push(
      `/dataproduct-details?dataproductId=${dataProduct.id}`,
      dataProduct
    );
  };
  return (
    <MultipurposeAccordion title="Data Products">
      <>
        <MyDataProductSettings
          list={dataProductsByDomain || []}
          setSearchValueExternal={setSearchValue}
          setSelectedValueExternal={setSelectedFilter}
        />
        {dataProductsByDomain?.length ? (
          <>
            <div className="dataProductGrid">
              {dataProductsByDomain?.map((item, id) => {
                return (
                  <DataProductCard
                    key={id}
                    onClickEvent={() => handleEditDataProduct(item)}
                    dataProduct={item}
                    handleDataProduct={handleEditDataProduct}
                  />
                );
              })}
            </div>
            <div className="pagination-center">
              <Pagination
                totalItems={totalDataProductsByDomain}
                itemsPerPage={dataProductPerPage}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                selectedFilter={selectedFilter}
                searchValue={searchValue}
              />
            </div>
          </>
        ) : (
          <div>
            <NoDataProducts />
          </div>
        )}
      </>
    </MultipurposeAccordion>
  );
}

DataProductAccordion.propTypes = {
  selectedItems: PropTypes.array,
  isExtended: PropTypes.bool,
  searchValue: PropTypes.string,
};

export default DataProductAccordion;
