import React from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { FaRegEdit } from "react-icons/fa";
import "./EditAdminComponent.scss";

const EditAdminComponent = ({
  editAdminsStatus,
  setEditAdminsStatus,
  handleSaveAdmins,
}) => {
  return (
    <div className="mb-3" data-testid="EditAdminComponent-Container">
      <span className="admin-span" data-testid="EditAdminComponent-Label">
        Admins
      </span>
      {editAdminsStatus ? (
        <Box display="inline" data-testid="EditAdminComponent-Buttons">
          <Button
            className="edit-admins-btn"
            variant={"outlined"}
            color="secondary"
            onClick={() => setEditAdminsStatus(false)}
            data-testid="EditAdminComponent-CancelButton"
          >
            Cancel
          </Button>
          <Button
            className="edit-admins-btn"
            startIcon={<FaRegEdit className="edit-admins-btn-icon" />}
            variant={"outlined"}
            color="secondary"
            onClick={handleSaveAdmins}
            data-testid="EditAdminComponent-SaveButton"
          >
            Save
          </Button>
        </Box>
      ) : (
        <Button
          className="edit-admins-btn"
          startIcon={<FaRegEdit className="edit-admins-btn-icon" />}
          variant={"outlined"}
          color="secondary"
          onClick={() => setEditAdminsStatus(true)}
          data-testid="EditAdminComponent-EditButton"
        >
          Edit Admins
        </Button>
      )}
    </div>
  );
};

EditAdminComponent.propTypes = {
  editAdminsStatus: PropTypes.bool,
  setEditAdminsStatus: PropTypes.func,
  handleSaveAdmins: PropTypes.func,
};

export default EditAdminComponent;
