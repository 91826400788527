export const TEAMS_CONST = {
  GET_ALL_TEAMS_REQUEST: "GET_ALL_TEAMS_REQUEST",
  GET_ALL_TEAMS_SUCCESS: "GET_ALL_TEAMS_SUCCESS",
  GET_ALL_TEAMS_FAILURE: "GET_ALL_TEAMS_FAILURE",

  CREATE_TEAM_REQUEST: "CREATE_TEAM_REQUEST",
  CREATE_TEAM_SUCCESS: "CREATE_TEAM_SUCCESS",
  CREATE_TEAM_FAILURE: "CREATE_TEAM_FAILURE",

  UPDATE_TEAM_REQUEST: "UPDATE_TEAM_REQUEST",
  UPDATE_TEAM_SUCCESS: "UPDATE_TEAM_SUCCESS",
  UPDATE_TEAM_FAILURE: "UPDATE_TEAM_FAILURE",

  GET_TEAMS_BY_USER_REQUEST: "GET_TEAMS_BY_USER_REQUEST",
  GET_TEAMS_BY_USER_SUCCESS: "GET_TEAMS_BY_USER_SUCCESS",
  GET_TEAMS_BY_USER_FAILURE: "GET_TEAMS_BY_USER_FAILURE",
};
