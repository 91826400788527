import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Badge } from "reactstrap";
import Tooltip from "@mui/material/Tooltip";

import DataProductDescription from "../../constants/DataProductDescription";

import "./DataProductStatus.scss";

export const statusClass = {
  All: "all",
  "In Design": "in-design",
  "In Review": "in-review",
  "In Initiation": "in-initiation",
  Candidate: "candidate",
  Deployed: "deployed",
  Published: "published",
  Rejected: "rejected",
  Retired: "retired",
  "Not Implemented": "not-implemented",
};

export const statusValidation = (status) => {
  return Object.keys(statusClass).includes(status) ? status : "Not Implemented";
};
function DataProductStatus({
  status,
  tooltipTitle = DataProductDescription[status],
  tooltipPlacement = "left",
}) {
  return (
    <Tooltip title={tooltipTitle} placement={tooltipPlacement}>
      <span>
        <Badge
          className={classNames(
            "dp-status-name",
            statusClass[statusValidation(status)]
          )}
          pill
        >
          {statusValidation(status)}
        </Badge>
      </span>
    </Tooltip>
  );
}

DataProductStatus.propTypes = {
  status: PropTypes.string,
  tooltipTitle: PropTypes.string,
  tooltipPlacement: PropTypes.string,
};

export default DataProductStatus;
