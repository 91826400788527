import React, { useState } from "react";
import { Handle } from "reactflow";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import classNames from "classnames";
import "./DataProductLineage.scss";
import { ReactComponent as Hexagon } from "../../assets/images/Hexagon.svg";
import { ReactComponent as Details } from "../../assets/images/Details.svg";
import { dataProductTypeAbbreviations } from "../WorkSpaceScreens/DataProductInteractionMap/DPIMSettings";
import collibraIcon from "../../assets/images/collibraIcon.png";
import { DRAFT } from "../../constants/DataDomainType";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import { Popper } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const DataProductLineage = (data) => {
  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDetails = () => {
    window.location.href = `/dataproduct-details?dataproductId=${data?.data?.dataProductId}`;
    handleClose();
  };

  const handleClickAway = (event) => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
  };

  return (
    <div className="dataproduct-lineage">
      {data?.data?.type === "input" || data?.data?.type === "default" ? (
        <Handle
          type="source"
          position="right"
          id="2"
          style={{ background: "#555", marginLeft: -20 }}
          className="dataproduct-lineage-handle-dot"
          isConnectable={true}
          data-testid="input-handle"
        />
      ) : null}
      <div className={classNames("diagram-lineage-container", data.data.label)}>
        <Tooltip
          disableFocusListener
          disableTouchListener
          key={data?.data?.label}
          title={
            <>
              <span>{`Product Name: ${data?.data?.label}`}</span>
              <br />
              <span>{`Domain Name: ${data.data.domainName}`}</span>
            </>
          }
          placement="bottom"
        >
          <Hexagon
            className={classNames(
              "dataproduct-hexagon",
              dataProductTypeAbbreviations[data.data.dataProductType]
            )}
          />
        </Tooltip>
        <div className="dataproduct-hexagon-title">
          {data.data.label}
          <div className="dataproduct-hexagon-domainType">
            <span>
              <span
                className={`domain-type ${
                  data?.data?.domainType?.split(" ").join("-") || DRAFT
                }`}
              />
              <span>{data?.data?.domainName || DRAFT}</span>
            </span>
          </div>
        </div>
        {data?.data?.isCollibra ? (
          <img
            className="dataproduct-hexagon-collibra-lineage"
            alt="collibra icon"
            src={collibraIcon}
            data-testid="collibraIcon"
          />
        ) : null}
        {data?.data?.type !== "default" ? (
          <>
            <Details
              onClick={handleClick}
              className={classNames(
                "dataproduct-hexagon-edit-lineage",
                dataProductTypeAbbreviations[data.data.dataProductType]
              )}
              data-testid="detail-icon"
            />
            <Popper
              id="lineage-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              placement={"bottom-start"}
            >
              <ClickAwayListener
                mouseEvent="onClick"
                touchEvent="onTouchStart"
                onClickAway={handleClickAway}
              >
                <Paper elevation={3} sx={{ background: "#fff!important" }}>
                  <MenuItem
                    data-testid="details-button"
                    onClick={handleDetails}
                  >
                    Details
                  </MenuItem>
                </Paper>
              </ClickAwayListener>
            </Popper>
          </>
        ) : null}
      </div>

      {data?.data?.type === "output" || data?.data?.type === "default" ? (
        <Handle
          type="target"
          position="left"
          id="4"
          style={{ background: "#555" }}
          className="dataproduct-lineage-handle-dot"
          isConnectable={true}
          data-testid="output-handle"
        />
      ) : null}
    </div>
  );
};

DataProductLineage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default DataProductLineage;
