import React from "react";
import PropTypes from "prop-types";

import CustomModal from "../../components/Common/CustomModal";

const DeleteDataProductModal = ({
  onSubmit,
  setIsDeleteModalVisible,
  isDeleteModalVisible,
  dataProductName,
}) => {
  const onPrimaryClick = () => {
    onSubmit();
    setIsDeleteModalVisible(false);
  };

  return (
    <CustomModal
      displayState={isDeleteModalVisible}
      toggleState={() => setIsDeleteModalVisible((prevState) => !prevState)}
      primaryBtn="Yes, delete"
      secondaryBtn="No"
      primaryBtnHandler={onPrimaryClick}
      secondaryHandler={() => setIsDeleteModalVisible(false)}
      closeButton={false}
    >
      <h4>
        Are you sure you want to delete &quot;{dataProductName}&quot; Data
        Product?
      </h4>
    </CustomModal>
  );
};

DeleteDataProductModal.propTypes = {
  onSubmit: PropTypes.func,
  setIsDeleteModalVisible: PropTypes.func,
  isDeleteModalVisible: PropTypes.bool,
  dataProductName: PropTypes.string,
};

export default DeleteDataProductModal;
