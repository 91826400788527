import React, { useState } from "react";
import PropTypes from "prop-types";
import Pagination from "./Pagination";
import "./CollibraCommunityDataProducts.scss";

const CollibraCommunityDataProducts = ({ dataProducts }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [dataProductPerPage] = useState(2);
  const lastIndexPage = currentPage * dataProductPerPage;
  const firstIndexPage = lastIndexPage - dataProductPerPage;
  return (
    <div className="collibra-community-dp">
      <h4>Data Products :</h4>
      {dataProducts?.slice(firstIndexPage, lastIndexPage).map((eachDp) => {
        return (
          <div key={eachDp.name} className="pt-2 pb-2">
            <span className="circle"></span>
            <span className="name">{eachDp.name}</span>
            <span className="domain">{eachDp.dataDomainName}</span>
            <span className="owner-title">
              {eachDp.owners.length > 1 ? "Owners : " : "Owner : "}
            </span>
            <span>{eachDp.owners.join()}</span>
            <span className="status badge badge-secondary badge-pill">
              {eachDp.status}
            </span>
          </div>
        );
      })}
      <div className="dp-pagination">
        <Pagination
          totalItems={dataProducts.length}
          itemsPerPage={dataProductPerPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

CollibraCommunityDataProducts.propTypes = {
  dataProducts: PropTypes.array,
};

export default CollibraCommunityDataProducts;
