import { LOGIN_CONST } from "./actionTypes";

export const loginUser = () => {
  return {
    type: LOGIN_CONST.LOGIN_REQUEST,
    payload: {},
  };
};
export const loginSuccess = (user) => {
  return {
    type: LOGIN_CONST.LOGIN_SUCCESS,
    payload: user,
  };
};
export const loginError = (error) => {
  return {
    type: LOGIN_CONST.LOGIN_ERROR,
    payload: error,
  };
};
