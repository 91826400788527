import { useEffect, useState } from "react";

const useMatchMedia = (maxWidth) => {
  const [isMatching, setIsMatching] = useState(
    () => window.matchMedia(`(max-width: ${maxWidth}px)`).matches
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia(`(max-width: ${maxWidth}px)`);
    const handleChange = (event) => {
      setIsMatching(event.matches);
    };

    mediaQuery.addEventListener("change", handleChange);
    setIsMatching(mediaQuery.matches);
    return () => {
      mediaQuery.removeEventListener("change", handleChange);
    };
  }, [maxWidth]);

  return isMatching;
};

export default useMatchMedia;
