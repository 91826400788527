import React from "react";
import PropTypes from "prop-types";

import InfoCircle from "../../../components/Common/InfoCircle";
import useMouseDrag from "../../../helpers/CustomHooks/useMouseDrag";
import { MINIMUM_HEIGHT, MAXIMUM_HEIGHT } from "./LVTDiagramSettings";
import "./LVTSection.scss";
import { NodeResizeControl } from "reactflow";

const LVTSection = ({ data, id, handleSectionSizeChange }) => {
  const { handleMouseEnter, handleMouseLeave } = useMouseDrag();
  return (
    <>
      <NodeResizeControl
        position="bottom"
        variant="line"
        minHeight={MINIMUM_HEIGHT}
        maxHeight={MAXIMUM_HEIGHT}
        onResizeStart={(e) => handleMouseEnter(e, "horizontal")}
        onResizeEnd={(e, params) =>
          handleMouseLeave(e, params, "horizontal", id, handleSectionSizeChange)
        }
        data-testid={`node-resize-control-${id}`}
      />
      <div className="lvt-section" data-testid={`lvt-section-${id}`}>
        <span className="lvt-section-label">
          {data.label}{" "}
          <InfoCircle
            text={data.infoText}
            orientation="top"
            className="lvt-section-label-info"
          />
        </span>
      </div>
    </>
  );
};

LVTSection.propTypes = {
  data: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  handleSectionSizeChange: PropTypes.func.isRequired,
};

export default LVTSection;
