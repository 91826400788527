import { t } from "react-multi-lang";
import {
  SPECIAL_CHARACTERS_REGEX,
  SELECTED_SPECIAL_CHARACTER_REGEX,
  URL_REGEX,
} from "../constants";
import { getUserRoleIds } from "./index";

export const required = (value) => {
  return !value ? t("VALIDATE.REQUIRED") : "";
};

export const requiredWithoutSpecialChar = (value) => {
  return !value
    ? t("VALIDATE.REQUIRED")
    : SELECTED_SPECIAL_CHARACTER_REGEX.test(value)
    ? t("VALIDATE.NO_SPECIAL_CHARACTERS")
    : "";
};

export const requiredForDropdown = (value) => {
  return !value || value === "0"
    ? t("VALIDATE.ENTER_REQUIRED_INFORMATION")
    : "";
};

export const requiredForMultiSelectDropdown = (value) => {
  return value === undefined || !value || value?.length < 1
    ? t("VALIDATE.ENTER_REQUIRED_INFORMATION")
    : "";
};

export const teamNameValidation = (value) => {
  return !value
    ? t("VALIDATE.REQUIRED")
    : !SPECIAL_CHARACTERS_REGEX.test(value)
    ? t("VALIDATE.NO_SPECIAL_CHARACTERS")
    : "";
};

export const validateDescription = (value) => {
  return !value
    ? t("VALIDATE.VALIDATE_DESCRIPTION")
    : value.length > 500
    ? t("VALIDATE.VALIDATE_DESCRIPTION_LENGTH")
    : "";
};
export const validateURL = (value) => {
  return !URL_REGEX.test(value)
    ? value
      ? t("VALIDATE.VALIDATE_URL")
      : ""
    : "";
};

export const validateRenderMembers = (value) => {
  const { ownerId, managerId } = getUserRoleIds();
  const errors = {};
  let owner = [];
  let manager = [];

  value &&
    value.map((eachValue) => {
      if (eachValue.roleId === ownerId) {
        owner.push(eachValue);
      } else if (eachValue.roleId === managerId) {
        manager.push(eachValue);
      }
      return null;
    });
  if (owner.length === 0) {
    errors.teamMembers = t("VALIDATE.DATA_PRODUCT_OWNER");
  } else if (owner.length > 1) {
    errors.teamMembers = t("VALIDATE.DATA_PRODUCT_MANAGER_MORE_THAN_ONE");
  } else if (manager.length >= 2) {
    errors.teamMembers = t("VALIDATE.DATA_PRODUCT_MANAGER");
  } else {
    errors.teamMembers = null;
  }
  return errors.teamMembers === null ? undefined : errors;
};
