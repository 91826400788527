export default {
  "In Design":
    "Data Products that are under design by the Data Domain team. In this step Data Product core specifications should be defined: data product name, owner, required sources, required data products (if applicable), usage, consumers and job. The status ends with the design of Data Product including the above specifications.",

  "In Review":
    "Data Products that are under review by the Data Product owner. In this step, all Data Product core specifications of the status 'design' should be reviewed, corrected if necessary. The status ends with the DP specification confirmation. With that a DP will be initiated in the data mesh.",

  "In Initiation":
    "Data product currently in the Data Mesh service 'Data Product Initiation'.",

  Candidate:
    "Data product is initially created, registered to the Roche Data Mesh with unique Data Product Id. It provides services, eg. ‘Configure Data Product’. While in this status Data Product is usually enriched with data receiving mechanisms (input ports), delivery mechanisms (output ports). The status ends with deploying the Data Product.",

  Deployed:
    "Data product is performing its configured code (data transformations, policy controls, etc.) and providing data via output ports. It may check its service levels and fitness. It is also shown on the RDM portal.",

  Published:
    "Additionally to “Deployed”, it is shoppable for consumers via the RDM portal.",

  Rejected: "Not implemented in DMDS",

  Retired: "Data product not active anymore",

  "Not Implemented":
    "This status covers all other statuses coming from Collibra that are not part of the Data Product Lifecycle, including Accepted, Obsolete, Invalid and other non-standard statuses. ",
};
