export const USERS_CONST = {
  GET_ALL_USERS_REQUEST: "GET_ALL_USERS_REQUEST",
  GET_ALL_USERS_SUCCESS: "GET_ALL_USERS_SUCCESS",
  GET_ALL_USERS_FAILURE: "GET_ALL_USERS_FAILURE",

  GET_USERS_BY_DOMAIN_ID_REQUEST: "GET_USERS_BY_DOMAIN_ID_REQUEST",
  GET_USERS_BY_DOMAIN_ID_SUCCESS: "GET_USERS_BY_DOMAIN_ID_SUCCESS",
  GET_USERS_BY_DOMAIN_ID_FAILURE: "GET_USERS_BY_DOMAIN_ID_FAILURE",

  GET_USERS_BY_TEAMSPACE_ID_REQUEST: "GET_USERS_BY_TEAMSPACE_ID_REQUEST",
  GET_USERS_BY_TEAMSPACE_ID_SUCCESS: "GET_USERS_BY_TEAMSPACE_ID_SUCCESS",
  GET_USERS_BY_TEAMSPACE_ID_FAILURE: "GET_USERS_BY_TEAMSPACE_ID_FAILURE",

  GET_USER_BY_TEAM_REQUEST: "GET_USER_BY_TEAM_REQUEST",
  GET_USER_BY_TEAM_SUCCESS: "GET_USER_BY_TEAM_SUCCESS",
  GET_USER_BY_TEAM_FAILURE: "GET_USER_BY_TEAM_FAILURE",

  MY_PROFILE_REQUEST: "MY_PROFILE_REQUEST",
  MY_PROFILE_SUCCESS: "MY_PROFILE_SUCCESS",
  MY_PROFILE_FAILURE: "MY_PROFILE_FAILURE",

  GENERATE_USER: "GENERATE_USER",
};
