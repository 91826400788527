import React from "react";
import PropTypes from "prop-types";

import Menu from "@mui/material/Menu";

const CustomMenu = ({
  id,
  children,
  anchorEl,
  setAnchorEl,
  isMenuOpen,
  setIsMenuOpen,
  closeAllMenu = () => {},
  ...props
}) => {
  const handleClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
    closeAllMenu();
  };

  return (
    <Menu
      id={id}
      anchorEl={anchorEl}
      open={isMenuOpen}
      onClose={handleClose}
      {...props}
    >
      {children}
    </Menu>
  );
};

CustomMenu.propTypes = {
  id: PropTypes.string,
  children: PropTypes.any,
  anchorEl: PropTypes.any,
  setAnchorEl: PropTypes.func,
  isMenuOpen: PropTypes.bool,
  setIsMenuOpen: PropTypes.func,
  closeAllMenu: PropTypes.func,
};

export default CustomMenu;
