import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import classNames from "classnames";
import { FiTrash } from "react-icons/fi";

import DeleteMosModal from "./DeleteMosModal";
import { RenderCustomTextField } from "../../../components/Common/ReduxFields";
import useModal from "../../../helpers/CustomHooks/useModal";
import { required } from "../../../helpers/Validations";

export const RenderMembers = ({
  fields,
  initialValues,
  autoEnableMOSField,
  setModalPrimaryBtn,
  deleteMosApi,
  mosSubmitHanlder,
  userEditAccess,
}) => {
  const { deleteMosModal, deleteMosModalToggle } = useModal();
  const [index, setIndex] = useState(null);
  useEffect(() => {
    if (initialValues.length > 0) {
      initialValues.forEach((data) => {
        fields.push(data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const createMos = () => {
    fields.push({});
    setModalPrimaryBtn({ name: "Save", btnHandler: mosSubmitHanlder });
  };

  const tiggerDeleteMosModal = (index) => {
    setIndex(index);
    deleteMosModalToggle();
  };

  const submitHandler = async () => {
    if (fields.get(index).id) {
      deleteMosApi(fields.get(index).id);
    } else {
      if (fields.length === 1) deleteMosApi(false);
    }
    await fields.remove(index);
    deleteMosModalToggle();
  };

  useEffect(() => {
    if (autoEnableMOSField) createMos();
  }, []);
  return (
    <div>
      {fields.map((item, index, members) => (
        <div key={index}>
          <div>
            <Field
              name={`${item}.label`}
              id={`${item}.id`}
              component={RenderCustomTextField}
              placeholder="Type Measure of Success"
              validate={[required]}
              type={members.get(index).label ? "input" : "textarea"}
              rows={members.get(index).label ? 2 : 5}
            />
          </div>
          <div className="mt-1 delete-mos">
            <span
              className={classNames("cursor-pointer", {
                "cursor-pointer": userEditAccess,
                "cursor-not-allowed": !userEditAccess,
              })}
              onClick={
                userEditAccess ? () => tiggerDeleteMosModal(index) : null
              }
            >
              Delete this Measure of Success
              <FiTrash className="ml-1" />
            </span>
          </div>
        </div>
      ))}
      <hr />
      <div
        className={classNames("float-end mt-4 cursor-pointer create-mos", {
          "cursor-pointer": userEditAccess,
          "cursor-not-allowed": !userEditAccess,
        })}
        onClick={userEditAccess ? createMos : null}
      >
        <i className="fa fa-plus pr-1"></i> Add new Measure of Success
      </div>
      {deleteMosModal && (
        <DeleteMosModal
          deleteMosModal={deleteMosModal}
          deleteMosModalToggle={deleteMosModalToggle}
          submitHandler={submitHandler}
        />
      )}
    </div>
  );
};

RenderMembers.propTypes = {
  initialValues: PropTypes.array,
  fields: PropTypes.object,
  deleteMosApi: PropTypes.func,
  setModalPrimaryBtn: PropTypes.func,
  mosSubmitHanlder: PropTypes.func,
  userEditAccess: PropTypes.bool,
  autoEnableMOSField: PropTypes.bool,
};
