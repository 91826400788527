import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { HashLink as Link } from "react-router-hash-link";
import classNames from "classnames";
import { useLocation } from "react-router-dom";

import { glossaryItems } from "./GlossaryItems";

import "./Glossary.scss";

function Glossary({ searchWord }) {
  const { hash } = useLocation();

  const alphabet = useMemo(() => {
    const alpha = Array.from(Array(26)).map((e, i) => i + 65);
    return alpha.map((x) => String.fromCharCode(x));
  }, []);

  const glossary = useMemo(() => {
    const result = {};
    glossaryItems.forEach((item) => {
      if (result[item.title.substring(0, 1)]) {
        result[item.title.substring(0, 1)] = [
          ...result[item.title.substring(0, 1)],
          item,
        ];
      } else {
        result[item.title.substring(0, 1)] = [item];
      }
    });
    return result;
  }, []);

  const filteredGlossary = useMemo(() => {
    const result = {};
    Object.keys(glossary).forEach((item) => {
      const filteredItems = glossary[item].filter(
        (value) =>
          value.title.toLowerCase().includes(searchWord.toLowerCase()) ||
          value.description.toLowerCase().includes(searchWord.toLowerCase())
      );
      if (filteredItems.length) {
        result[item] = filteredItems;
      }
    });
    return result;
  }, [searchWord, glossary]);

  return (
    <div>
      <div className="glossary-header">
        {alphabet.map((char) => (
          <Link
            key={char}
            to={`/glossary#${char}`}
            className={classNames({
              "glossary-header-item-disabled":
                !Object.keys(filteredGlossary).includes(char),
              "glossary-header-item-selected": `#${char}` === hash,
            })}
          >
            <div className="glossary-header-item">{char}</div>
          </Link>
        ))}
      </div>
      <div className="glossary-body">
        {Object.keys(filteredGlossary).map((item) => (
          <div
            key={item}
            className={classNames({
              "glossary-body-item-selected": `#${item}` === hash,
            })}
          >
            <div id={item} className="glossary-body-item-title">
              {item}
            </div>
            <div>
              {filteredGlossary[item]?.map((item) => (
                <div key={item.title}>
                  <div className="glossary-body-item-sub-title">
                    {item.title}
                  </div>
                  <div className="glossary-body-item-description">
                    {item.description}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

Glossary.propTypes = {
  searchWord: PropTypes.string,
};

export default Glossary;
