// saga.js
import {
  takeEvery,
  fork,
  all,
  call,
  put,
  select,
  take,
} from "redux-saga/effects";
import { DOMAINS_CONST } from "./actionTypes";
import {
  getAllDomainsSuccess,
  getAllDomainsFailure,
  getEnrolledDomainsSuccess,
  getEnrolledDomainsFailure,
} from "./actions";
import { checkHttpStatus } from "../apiUtils";
import { baseMethod } from "../../services/BaseService";
import { AXIOS_INSTANCE, DOMAINS_API } from "../apiUtils/config";

export function* getAllDomains() {
  try {
    const serviceMethod = AXIOS_INSTANCE.get(`${DOMAINS_API}/getAll`);
    const apiResponse = yield call(baseMethod, serviceMethod, "", null, null);
    const response = yield call(checkHttpStatus, apiResponse);
    if (
      response.status >= 200 &&
      response.status < 300 &&
      response.data?.data?.isSuccess
    ) {
      let enrolledDomains;
      const getEnrolledDomainsSelector = (state) =>
        state.Domains?.enrolledDomainsList;
      while (true) {
        enrolledDomains = yield select(getEnrolledDomainsSelector);
        if (enrolledDomains) break;
        yield take(DOMAINS_CONST.GET_ENROLLED_DOMAINS_SUCCESS);
      }

      const sandboxDomain = enrolledDomains?.filter(
        (domain) => domain.isSandbox
      );
      const sandboxDomainId = sandboxDomain.length ? sandboxDomain[0].id : "";

      response.data.data.domainList = response?.data?.data?.domainList.filter(
        (domain) => {
          return enrolledDomains?.length === 0
            ? !domain.isSandbox
            : domain.id === sandboxDomainId || !domain.isSandbox;
        }
      );

      const responseData = {
        statusCode: 200,
        response: response.data,
      };
      localStorage.setItem(
        "domainsList",
        JSON.stringify(response.data.data?.domainList)
      );

      yield put(getAllDomainsSuccess(responseData));
    } else {
      const responseData = {
        statusCode: response.statusCode || 400,
        data: response.data,
      };
      yield put(getAllDomainsFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getAllDomainsFailure(errorData));
  }
}

export function* getEnrolledDomains() {
  try {
    const serviceMethod = AXIOS_INSTANCE.get(
      `${DOMAINS_API}/getEnrolledDomains`
    );
    const apiResponse = yield call(baseMethod, serviceMethod, "", null, null);
    const response = yield call(checkHttpStatus, apiResponse);
    if (
      response.status >= 200 &&
      response.status < 300 &&
      response.data?.data?.isSuccess
    ) {
      const responseData = {
        statusCode: 200,
        response: response.data,
      };
      yield put(getEnrolledDomainsSuccess(responseData));
    } else {
      const responseData = {
        statusCode: response.statusCode || 400,
        data: response.data,
      };
      yield put(getEnrolledDomainsFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };

    yield put(getEnrolledDomainsFailure(errorData));
  }
}

export function* watchGetAllDomains() {
  yield takeEvery(DOMAINS_CONST.GET_ALL_DOMAINS_REQUEST, getAllDomains);
}

export function* watchGetEnrolledDomains() {
  yield takeEvery(
    DOMAINS_CONST.GET_ENROLLED_DOMAINS_REQUEST,
    getEnrolledDomains
  );
}

export function* DomainsSaga() {
  yield all([fork(watchGetAllDomains), fork(watchGetEnrolledDomains)]);
}

export default DomainsSaga;
