import React from "react";
import Button from "@mui/material/Button";
import "./CreateTeamsSpace.scss";
import { useHistory } from "react-router-dom";
import { PropTypes } from "prop-types";

const CreateTeamSpace = ({ id, name, isCollibra, isOwner }) => {
  const history = useHistory();
  const isCollibraValue = isCollibra || false;
  const handleCreateTeamSpace = () => {
    const url = `/create-team-space/${encodeURIComponent(name)}?domainId=${id}`;
    history.push(url);
  };

  return (
    <div className="my-button">
      <Button
        onClick={handleCreateTeamSpace}
        className="add-new-teamspace-btn"
        variant={"outlined"}
        color="secondary"
        data-testid="create-team-space-button"
        data-title={
          !isCollibraValue
            ? "Team Spaces can only be created for Domains registered in Collibra."
            : ""
        }
        disabled={!isCollibraValue || !isOwner}
      >
        Create Team Space
      </Button>
    </div>
  );
};

CreateTeamSpace.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  isCollibra: PropTypes.bool,
  isOwner: PropTypes.bool,
};

export default CreateTeamSpace;
