import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../components/Common/BreadCrumb";
import Layout from "../../components/Common/Layout";
import LVTAccordion from "../../components/Common/LVTAccordion";
import DIMAccordion from "../../components/Common/DIMAccordion";
import DataDomainDetailsCard from "./DataDomainDetailsCard";
import { getAllDataProductInteractionMapItemsByUseCaseId } from "../../store/actions";
import "./index.scss";
import { socket } from "../../services/Socket";
import DataProductAccordion from "./DataProductAccordion";
import useMatchMedia from "../../helpers/CustomHooks/useMatchMedia";
import TeamSpacesAccordian from "./TeamSpacesAccordian";

const DataDomainDetails = () => {
  const [selectedDomain, setSelectedDomain] = useState({});
  const [teamSpaceData, setTeamSpaceData] = useState({});
  const dispatch = useDispatch();
  const { search } = useLocation();
  const history = useHistory();
  const { selectedDPIM } = useSelector((state) => ({
    selectedDPIM: state.DataProductInteractionMap.selectedDPIM,
    dataProducts:
      state.DataProducts?.dataProductsByDomain?.dataProductList || [],
  }));
  const isSmallerScreen = useMatchMedia(1367);
  const dataDomainPerPage = useMemo(() => {
    if (isSmallerScreen === 1367) {
      return 3;
    }

    return isSmallerScreen ? 3 : 4;
  }, [isSmallerScreen]);

  const userDetails = localStorage.getItem("loginInfo")
    ? JSON.parse(localStorage.getItem("loginInfo")).data?.userDetails
    : null;
  const params = new URLSearchParams(search);
  const domainId = params.get("domainId");
  const allOwners = [
    ...(selectedDomain?.owners || []),
    ...(selectedDomain?.members || []),
  ];
  const userId = userDetails?.id;
  const isOwner = allOwners.some((user) => user.id === userId);
  const isAdmin = userDetails?.isAdmin;

  useEffect(() => {
    if (!domainId) {
      return;
    }

    socket.lastJoinedRoom = `DATADOMAIN-${domainId}`;
    socket.emit("joinRoom", socket.lastJoinedRoom);

    socket.on("receiveDomainData", setSelectedDomain);

    //IF DOMAIN NOT FOUND / WRONG UUID ENTERED IN URL GO TO PAGE NOT FOUND
    socket.on("error", (data) => {
      if (data.message === "DOMAIN_NOT_FOUND") {
        history.push("/page-not-found");
      }
    });

    return () => {
      socket.emit("leaveRoom", socket.lastJoinedRoom);
      socket.lastJoinedRoom = null;
    };
  }, [domainId]);

  useEffect(() => {
    if (!domainId) {
      history.push("/home");
    }

    socket.emit("getDomainById", { domainId }, socket.lastJoinedRoom);
  }, [domainId, history]);
  useEffect(() => {
    socket.emit("getTeamSpaceByDomainId", { domainId });
  }, [domainId]);
  useEffect(() => {
    socket.on("receiveTeamSpaceByDomainIdData", (data) => {
      setTeamSpaceData(data);
    });
  }, []);

  useEffect(() => {
    if (selectedDomain?.id) {
      dispatch(
        getAllDataProductInteractionMapItemsByUseCaseId({
          id: selectedDomain?.id,
        })
      );
    }
  }, [dispatch, selectedDomain, domainId]);

  return (
    <div className="global-top-1 data-domain-details">
      <Layout title="Data Domain Details">
        <div className="data-domain-details-breadcrumb">
          <Breadcrumb
            steps={[
              { name: "Home Page", href: "/home" },
              {
                name: "Data Domain Details",
                active: true,
              },
            ]}
          />
        </div>
        <DataDomainDetailsCard selectedDomain={selectedDomain || {}} />
        <TeamSpacesAccordian
          isExtended
          selectedTeamSpace={teamSpaceData}
          domainName={selectedDomain?.name}
          isOwner={isOwner}
          isAdmin={isAdmin}
          isCollibra={selectedDomain?.isCollibra}
          customHeight={200}
          customWidth={338}
        />
        <LVTAccordion
          selectedDomain={selectedDomain || {}}
          customHeight={200}
          customWidth={338}
        />
        <DIMAccordion
          useCasePerPage={dataDomainPerPage}
          useCases={selectedDPIM || []}
          selectedDomain={selectedDomain || {}}
        />
        <DataProductAccordion isExtended />
      </Layout>
    </div>
  );
};

export default DataDomainDetails;
