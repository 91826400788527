import React, { useState, useEffect } from "react";
import "./DowntimeBanner.scss";
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const DowntimeBanner = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [bannerInfo, setBannerInfo] = useState({
    date: "",
    time: "",
    description: "",
  });

  useEffect(() => {
    const visible = process.env.REACT_APP_DOWNTIME_VISIBLE === "true";
    const date = process.env.REACT_APP_DOWNTIME_DATE || "";
    const time = process.env.REACT_APP_DOWNTIME_TIME || "";
    const description = process.env.REACT_APP_DOWNTIME_DESCRIPTION || "";
    setBannerInfo({ date, time, description });

    const bannerClosed = localStorage.getItem("downtimeBannerClosed");
    if (bannerClosed !== "true" && visible) {
      setIsVisible(true);
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    localStorage.setItem("downtimeBannerClosed", "true");
  };

  if (!isVisible) return null;

  return (
    <div className="downtime-banner">
      <CampaignOutlinedIcon fontSize="medium" className="bannerIcon"/>
      <div className="downtime-content">
        <div className="downtime-description">{bannerInfo.description} 
          <span className="downtime-date-time">Date: {bannerInfo.date}</span> 
          <span className="downtime-date-time">Time: {bannerInfo.time} UTC</span>
        </div>

        <button onClick={handleClose} className="downtime-close-button">
          <CloseOutlinedIcon fontSize="small"/>
        </button>
      </div>
    </div>
  );
};

export default DowntimeBanner;
