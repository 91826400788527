export const DATAPRODUCT_STATUS_OPTIONS = {
  IN_DESIGN: "In Design",
  IN_REVIEW: "In Review",
  IN_DEVELOPMENT: "In Development",
  IN_INITIATION: "In Initiation",
};

export const COLLIBRA_DATAPRODUCT_STATUS = {
  IN_INITIATION: "In Initiation",
  CANDIDATE: "Candidate",
  DEPLOYED: "Deployed",
  PUBLISHED: "Published",
  REJECTED: "Rejected",
  RETIRED: "Retired",
};

export const DATA_DOMAIN_STATUS = {
  IN_DESIGN: "In Design",
  IN_REVIEW: "In Review",
  IN_INITIATION: "In Initiation",
  CANDIDATE: "Candidate",
  DEPLOYED: "Deployed",
  PUBLISHED: "Published",
  REJECTED: "Rejected",
  RETIRED: "Retired",
  ACCEPTED: "Not Implemented",
};
