import {
  USER_ROLES,
  CAN_PERFORM_DATA_PRODUCT_ACTION_ROLES,
} from "../constants";
//to get connected user Data
export const getUserData = () => {
  const userDetails = localStorage.getItem("loginInfo")
    ? JSON.parse(localStorage.getItem("loginInfo"))?.data?.userDetails
    : null;
  const roles =
    (typeof localStorage.getItem("roles") === "string" &&
      JSON.parse(localStorage.getItem("roles"))) ||
    [];
  const userId = userDetails?.id;
  const isAdminUser = userDetails?.isAdmin;
  return { userId, isAdminUser, userDetails, roles };
};
//to get connected user role Id's
export const getUserRoleIds = () => {
  const { roles } = getUserData();
  let ownerId, managerId;
  if (roles?.length) {
    ownerId = roles.find((e) => e.name === USER_ROLES.OWNER)?.id;
    managerId = roles.find((e) => e.name === USER_ROLES.MANAGER)?.id;
  }
  return { ownerId, managerId };
};
//to get all team-member id's of selected node
export const getNodeTeamMemberIdsList = (nodeTeamsList, node) => {
  const teamMemberIdsList = [];
  if (node?.dataProductId && nodeTeamsList?.length) {
    const selectedDataProductObj = nodeTeamsList.find(
      (eachTeam) => eachTeam.id === node.dataProductId
    );
    if (selectedDataProductObj?.teamData?.length) {
      selectedDataProductObj.teamData.map((each) => {
        teamMemberIdsList.push(each.userId);
        return null;
      });
    }
  }
  return teamMemberIdsList;
};
//to check connected user is team member of selected node or not
export const isTeamMemberOfNode = (teamsList, node) => {
  const teamMemberIdsList = getNodeTeamMemberIdsList(teamsList, node);
  const { userId } = getUserData();
  return teamMemberIdsList.includes(userId);
};
//to check connected user is data product owner or manager
export const isConnectedUserIsOwnerOrManager = (teamDetails) => {
  const { userId } = getUserData();
  const teamMemberIdsList = teamDetails
    ? teamDetails.map((member) => {
        return CAN_PERFORM_DATA_PRODUCT_ACTION_ROLES.includes(member.roleName)
          ? member.userId
          : null;
      })
    : [];
  return teamMemberIdsList.includes(userId);
};
// connected user is team member or not
export const isConnectedUserIsTeamMember = (teamsList = []) => {
  const { userId } = getUserData();
  const teamMemberIdsList = teamsList?.length
    ? teamsList.map((data) => data.userId)
    : [];
  return teamMemberIdsList.includes(userId);
};
