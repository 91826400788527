import React, { useState } from "react";
import { Input, Label, FormGroup } from "reactstrap";
import PropTypes from "prop-types";

export const renderTextField = ({
  disabled,
  input,
  id,
  min,
  label,
  maxlength,
  placeholder,
  type,
  meta: { touched, error, warning },
}) => {
  return (
    <div className="mb-3">
      {label && (
        <Label className="login-text-1 mb-1" for={id}>
          {label}
        </Label>
      )}
      <Input
        {...input}
        min={min}
        className="login-input form-control"
        type={type}
        disabled={disabled}
        label={label}
        maxLength={maxlength}
        placeholder={placeholder}
        autoComplete="off"
        id={id}
        rows="2"
        cols="40"
      />
      {touched &&
        (error ? (
          <span className="form-feedback">{error}</span>
        ) : (
          warning && <span>{warning}</span>
        ))}
    </div>
  );
};

export const RenderCustomTextField = ({
  disabled,
  input,
  id,
  min,
  label,
  placeholder,
  type,
  rows,
  meta: { touched, error, warning },
}) => {
  const [inputType, setInputType] = useState(type);
  const [customRows, setCustomRows] = useState(rows);
  const onClickHanlder = () => {
    setInputType("textarea");
    setCustomRows("5");
  };
  const moveCursoerAtEnd = (e) => {
    var temp_value = e.target.value;
    e.target.value = "";
    e.target.value = temp_value;
  };

  return (
    <div>
      <Label className="login-text-1 mb-1">{label}</Label>
      <Input
        data-testid="custom-text-input"
        {...input}
        min={min}
        className="login-input form-control"
        type={inputType}
        disabled={disabled}
        label={label}
        placeholder={placeholder}
        autoComplete="off"
        id={id}
        rows={customRows}
        cols="40"
        onClick={onClickHanlder}
        autoFocus
        onFocus={moveCursoerAtEnd}
      />
      {touched &&
        (error ? (
          <span className="custom-form-feedback">{error}</span>
        ) : (
          warning && <span>{warning}</span>
        ))}
    </div>
  );
};

export const renderSelectField = ({
  options,
  input,
  placeholder,
  label,
  selectedValue,
  defaultValue,
  meta: { touched, error },
  disabled,
  ...custom
}) => {
  return (
    <FormGroup className="mb-3 ">
      <Label className="lean-label" htmlFor="select-field">
        {label}
      </Label>
      <Input
        id="select-field"
        type="select"
        name={input.name}
        {...input}
        disabled={disabled}
        // defaultValue={defaultValue}
        {...custom}
        value={input.value ? input.value : defaultValue}
        className={touched && error ? "errormsg" : ""}
        label={label}
      >
        {
          <option
            value={selectedValue === undefined ? "0" : selectedValue.value}
          >
            {selectedValue === undefined ? placeholder : selectedValue.label}
          </option>
        }
        {options}
      </Input>
      {touched && error && (
        <span className="form-feedback d-block">{error}</span>
      )}
    </FormGroup>
  );
};

RenderCustomTextField.propTypes = {
  meta: PropTypes.any,
  disabled: PropTypes.bool,
  input: PropTypes.any,
  id: PropTypes.string,
  label: PropTypes.string,
  min: PropTypes.any,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  rows: PropTypes.number,
};
