import React from "react";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import { PropTypes } from "prop-types";
import "./Button.scss";

const CreateTeamSpaceButton = ({ id, name }) => {
  const history = useHistory();
  const handleCreateTeamSpace = () => {
    const url = `/create-teamspace/${encodeURIComponent(name)}?domainId=${id}`;
    history.push(url);
  };

  return (
    <div className="my-button-admin">
      <Button
        onClick={handleCreateTeamSpace}
        className="add-new-teamspace-btn-admin"
        variant={"outlined"}
        color="secondary"
      >
        Create Team Space
      </Button>
    </div>
  );
};

CreateTeamSpaceButton.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
};
export default CreateTeamSpaceButton;
