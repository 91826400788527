import React, { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
} from "reactstrap";
import { t } from "react-multi-lang";
import { Field, reduxForm } from "redux-form";

import { getEnrolledDomains, getAllUsers } from "../../store/actions";
import AutoComplete from "../../components/Common/AutoComplete";
import { AXIOS_INSTANCE, USERS_API } from "../../store/apiUtils/config";
import { GlobalNotificationHandle } from "../../services/NotificationHandler";

const AddUserToDomainModal = ({ domainId, toggle, handleSubmit }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEnrolledDomains());
    dispatch(getAllUsers());
  }, [dispatch]);

  const { enrolledDomainsList, allUsers } = useSelector((state) => ({
    enrolledDomainsList: state.Domains.enrolledDomainsList,
    allUsers: state.Users.allUsers?.userList,
  }));

  useEffect(() => {
    if (
      enrolledDomainsList &&
      !enrolledDomainsList.find((domain) => domain.id === domainId)
    ) {
      //toggle();
    }
  });

  const onSubmit = async (formData) => {
    try {
      const { data } = await AXIOS_INSTANCE.post(
        `${USERS_API}/Domain/createConnection`,
        {
          userId: formData?.name?.id,
          domainId,
        }
      );

      if (data?.data?.isSuccess) {
        GlobalNotificationHandle(200, data?.data?.message, true, true);
        toggle();
      }
    } catch (e) {
      GlobalNotificationHandle(
        e.response.status,
        e.response.data,
        false,
        true,
        e.response.data === "This user is already part of the domain"
      );
    }
  };

  return (
    <Modal isOpen={true} toggle={toggle} className="sourceProductModal">
      <ModalHeader className="data-source-header" toggle={toggle}>
        Add User to Domain
      </ModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody className="pt-5 custom-modal-padding">
          <FormGroup>
            <Field
              component={AutoComplete}
              name="name"
              placeholder={t("CREATE.ENTER_NAME")}
              isClearable
              isSearchable
              options={allUsers || []}
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e.id}
            />
          </FormGroup>
        </ModalBody>
        <div className="mt-2">
          <ModalFooter>
            <Button type="submit" className="primary-btn f-right lean-btn">
              Add
            </Button>
          </ModalFooter>
        </div>
      </form>
    </Modal>
  );
};

AddUserToDomainModal.propTypes = {
  domainId: PropTypes.string,
  toggle: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default reduxForm({
  form: "AddUserToDomainModal",
  enableReinitialize: true,
})(memo(AddUserToDomainModal));
