import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import classNames from "classnames";
import "./CustomDataInputField.scss";

const CustomDataInputField = ({
  editState,
  data,
  onChangeHandler,
  isMultiLine,
  parseDataToHTML = false,
  textFieldClass = "",
  textClass = "",
  component = "",
  placeholder = "",
  inputHeight = "auto",
  inputWidth = "100%",
  error,
  errorMessage,
  handleError,
  hasError,
  "data-testid": testId,
}) => {
  const handleChange = (event) => {
    onChangeHandler(event.target.value);
    const value = event.target.value.trim();
    if (hasError) {
      if (editState && value === "") {
        handleError(true);
      } else {
        handleError(false);
      }
    }
  };

  return (
    <>
      {editState ? (
        <>
          <div>
            <TextField
              className={classNames(
                textFieldClass,
                "custom-data-input-field-text"
              )}
              multiline={isMultiLine}
              onChange={handleChange}
              value={data}
              color="secondary"
              placeholder={placeholder}
              inputProps={{ style: { height: inputHeight, width: inputWidth } }}
              data-testid={testId}
            />
            {error && (
              <Typography className="team-errormessage">
                {errorMessage}
              </Typography>
            )}
          </div>
        </>
      ) : (
        <Typography
          data-testid="parsed-html"
          className={classNames(
            textClass,
            "wrap-text",
            "custom-data-input-field-text"
          )}
          component={component}
        >
          {parseDataToHTML ? (
            <span dangerouslySetInnerHTML={{ __html: data }} />
          ) : (
            data
          )}
        </Typography>
      )}
    </>
  );
};
CustomDataInputField.propTypes = {
  editState: PropTypes.bool.isRequired,
  data: PropTypes.any,
  onChangeHandler: PropTypes.func.isRequired,
  isMultiLine: PropTypes.bool.isRequired,
  textFieldClass: PropTypes.string,
  textClass: PropTypes.string,
  parseDataToHTML: PropTypes.any,
  component: PropTypes.any,
  placeholder: PropTypes.string,
  inputHeight: PropTypes.string,
  inputWidth: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  handleError: PropTypes.func,
  hasError: PropTypes.bool,
  "data-testid": PropTypes.string,
};

export default CustomDataInputField;
