import React from "react";
import PropTypes from "prop-types";
import { Input } from "reactstrap";
import { FiSearch } from "react-icons/fi";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useHistory } from "react-router-dom";

import "./GlossaryFAQHeader.scss";

function GlossaryFAQHeader({
  setSearchWord,
  searchWord,
  toggleValue,
  setToggleValue,
  type,
}) {
  const history = useHistory();

  return (
    <div className="glossary-faq-header">
      {type === "glossary" ? (
        <div className="global-search glossary-faq-header-input">
          <FiSearch className="global-search-icon" />
          <Input
            onChange={(e) => setSearchWord(e.target.value)}
            value={searchWord}
            className="global-search-input glossary-faq-header-search"
            placeholder="Search..."
          />
        </div>
      ) : null}
      <div className="glossary-faq-header-button-group">
        <ToggleButtonGroup
          value={toggleValue}
          exclusive
          onChange={setToggleValue}
          aria-label="section selector"
        >
          <ToggleButton
            selected={type === "faq"}
            onClick={() => history.push("/faq")}
            value="faq"
            aria-label="Frequently Asked Questions"
            className="glossary-faq-header-button"
          >
            Frequently Asked Questions
          </ToggleButton>
          <ToggleButton
            selected={type === "glossary"}
            onClick={() => history.push("/glossary")}
            value="glossary"
            aria-label="Glossary"
            className="glossary-faq-header-button"
          >
            Glossary
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    </div>
  );
}

GlossaryFAQHeader.propTypes = {
  searchWord: PropTypes.string,
  setSearchWord: PropTypes.func,
  toggleValue: PropTypes.string,
  type: PropTypes.string,
  setToggleValue: PropTypes.func,
};

export default GlossaryFAQHeader;
