export const DATA_SOURCES_CONST = {
  GET_ALL_DATA_SOURCES_REQUEST: "GET_ALL_DATA_SOURCES_REQUEST",
  GET_ALL_DATA_SOURCES_SUCCESS: "GET_ALL_DATA_SOURCES_SUCCESS",
  GET_ALL_DATA_SOURCES_FAILURE: "GET_ALL_DATA_SOURCES_FAILURE",

  CREATE_DATA_SOURCE_REQUEST: "CREATE_DATA_SOURCE_REQUEST",
  CREATE_DATA_SOURCE_SUCCESS: "CREATE_DATA_SOURCE_SUCCESS",
  CREATE_DATA_SOURCE_FAILURE: "CREATE_DATA_SOURCE_FAILURE",

  GET_REQUIRED_DATA_SOURCES_REQUEST: "GET_REQUIRED_DATA_SOURCES_REQUEST",
  GET_REQUIRED_DATA_SOURCES_SUCCESS: "GET_REQUIRED_DATA_SOURCES_SUCCESS",
  GET_REQUIRED_DATA_SOURCES_FAILURE: "GET_REQUIRED_DATA_SOURCES_FAILURE",
};
