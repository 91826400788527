import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

import "./CustomDataSelectField.scss";

const CustomDataSelectField = ({
  editState,
  data,
  onChangeHandler,
  helperText,
  optionsList = [],
}) => {
  const fieldValue =
    optionsList?.find((option) => option.label === data)?.value || "";

  return (
    <>
      {editState ? (
        <TextField
          select
          defaultValue={fieldValue}
          value={fieldValue}
          onChange={(event) => onChangeHandler(event.target.value)}
          helperText={helperText || ""}
          size="small"
          data-testid="custom-select"
        >
          {optionsList.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      ) : (
        <p className="wrap-text custom-data-select-field-text">{data}</p>
      )}
    </>
  );
};

CustomDataSelectField.propTypes = {
  editState: PropTypes.bool.isRequired,
  data: PropTypes.string.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  helperText: PropTypes.string,
  optionsList: PropTypes.array,
};

export default CustomDataSelectField;
