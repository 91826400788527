import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

const Pagination = ({
  itemsPerPage,
  totalItems,
  setCurrentPage,
  currentPage,
  className,
  handleChange = () => {},
}) => {
  const pageNumbers = [];
  const [activeStep, setActive] = useState(1);

  /*------If current step exceeds the max steps reset the active step----- */
  useEffect(() => {
    if (activeStep > pageNumbers.length) setActive(1);
  }, [pageNumbers, activeStep]);

  useEffect(() => {
    if (currentPage !== undefined) {
      setActive(currentPage + 1);
    }
  }, [currentPage]);

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleLeftBtn = () => {
    handleChange(activeStep - 2); // index of item
    if (activeStep > 1) {
      setActive((prev) => prev - 1);
      setCurrentPage(activeStep - 1);
    }
  };

  const handleRightBtn = () => {
    handleChange(activeStep); // index of item
    if (activeStep <= pageNumbers.length) {
      setActive((prev) => prev + 1);
      setCurrentPage(activeStep + 1);
    }
  };
  return (
    <div className={className}>
      {activeStep === 1 ? (
        <KeyboardArrowLeft style={{ color: "#c6c6d5" }} />
      ) : (
        <KeyboardArrowLeft
          style={{ cursor: "pointer" }}
          onClick={() => handleLeftBtn()}
        />
      )}
      <span data-testid="pagnum-by-totalPage">
        {activeStep} / {pageNumbers.length}
      </span>
      {activeStep === pageNumbers.length ? (
        <KeyboardArrowRight style={{ color: "#c6c6d5" }} />
      ) : (
        <KeyboardArrowRight
          style={{ cursor: "pointer" }}
          onClick={() => handleRightBtn()}
        />
      )}
    </div>
  );
};

Pagination.propTypes = {
  itemsPerPage: PropTypes.number,
  totalItems: PropTypes.number,
  setCurrentPage: PropTypes.func,
  currentPage: PropTypes.number,
  handleChange: PropTypes.func,
  className: PropTypes.string,
};

export default Pagination;
