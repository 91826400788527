import React from "react";
import { useLocation } from "react-router-dom";
import Breadcrumb from "../../components/Common/BreadCrumb";
import Layout from "../../components/Common/Layout";
import "./index.scss";
import CreateTeamSpaceCard from "./CreateTeamSpaceCard";

const CreateNewTeamSpace = () => {
  const search = useLocation().search;
  const domainId = new URLSearchParams(search).get("domainId");
  const domainName = decodeURIComponent(window.location.pathname.split("/")[2]);

  return (
    <div className="global-top-1 team-space-details">
      <Layout title="Team Space Details">
        <div className="team-space-details-breadcrumb">
          <Breadcrumb
            steps={[
              { name: "Home Page", href: "/home" },
              {
                name: "Data Domain Details",
                href: `/datadomain-details?domainId=${domainId}`,
              },
              { name: "Create Team Space", active: true },
            ]}
          />
          <CreateTeamSpaceCard domainId={domainId} domainName={domainName} />
        </div>
      </Layout>
    </div>
  );
};

export default CreateNewTeamSpace;
