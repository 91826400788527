export default {
  Draft: "Data Domain that only exists in this application",

  Pharma: "Official Pharma Data Domain",

  Diagnostics: "Official Diagnostics Data Domain",

  "In Review":
    "Data Domain that is neither in the Official Pharma Data Domain list nor in the Official Diagnostics Data Domain list.",
};
