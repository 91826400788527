import { TEAMSPACE_CONST } from "./actionsTypes";

export const getTeamSpacesByDomain = (
  page,
  id,
  limit,
  searchValue,
  selectedFilter
) => {
  return {
    type: TEAMSPACE_CONST.GET_TEAMSPACES_BY_DOMAIN_REQUEST,
    payload: {
      page,
      domainId: id,
      limit,
      searchValue: searchValue === "" ? undefined : searchValue,
      type: selectedFilter,
    },
  };
};
export const getTeamSpacesByDomainSuccess = (response) => {
  return {
    type: TEAMSPACE_CONST.GET_TEAMSPACES_BY_DOMAIN_SUCCESS,
    payload: response,
  };
};
export const getTeamSpacesByDomainFailure = (error) => {
  return {
    type: TEAMSPACE_CONST.GET_TEAMSPACES_BY_DOMAIN_FAILURE,
    payload: error,
  };
};
