import React from "react";
import "./index.scss";
const PageNotFound = () => {
  return (
    <div className="global-top">
      <img
        src="/static/media/lvtbanner.05eaed9e.png"
        alt="banner"
        width="100%"
      />
      <div className=" page-not-found-content page-not-found-content-spacing">
        <div>
          <h1>404 - Page Not Found</h1>
          <p className="page-not-found-content">
            Oops! The page you are looking for does not exist.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
