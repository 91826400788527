import React from "react";
import { PropTypes } from "prop-types";

const RedAsterisk = ({ isEditMode }) => {
  return isEditMode ? <span className="team-errormessage">*</span> : null;
};

RedAsterisk.propTypes = {
  isEditMode: PropTypes.bool,
};
export default RedAsterisk;
