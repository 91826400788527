import { DATAPRODUCT_WORKSPACE_CONST } from "./actionTypes";

const initialState = {
  loading: false,
  addDataProductLinkData: null,
};
const DataProductsRelations = (state, action) => {
  if (typeof state == "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case DATAPRODUCT_WORKSPACE_CONST.ADD_DATAPRODUCT_LINK_SUCCESS:
      return {
        ...state,
        addDataProductLinkData: action.payload?.response?.data,
      };
    
      case DATAPRODUCT_WORKSPACE_CONST.ADD_DATAPRODUCT_LINK_FAILURE:
      return {
        ...state,
        addDataProductLinkData: action.payload?.data,
      };

    case DATAPRODUCT_WORKSPACE_CONST.DELETE_DATA_PRODUCT_RELATIONSHIP_SUCCESS:
      return {
        ...state,
        deleteDataProductRelation: action.payload?.response?.data,
      };
    case DATAPRODUCT_WORKSPACE_CONST.DELETE_DATA_PRODUCT_RELATIONSHIP_FAILURE:
      return {
        ...state,
        deleteDataProductRelation: null,
      };

    case DATAPRODUCT_WORKSPACE_CONST.DELETE_DATA_PRODUCT_SUCCESS:
      return {
        ...state,
        deleteDataProduct: action.payload?.response?.data,
      };
    case DATAPRODUCT_WORKSPACE_CONST.DELETE_DATA_PRODUCT_FAILURE:
      return {
        ...state,
        deleteDataProduct: action.payload?.data?.error,
      };

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default DataProductsRelations;
