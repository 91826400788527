import { socket } from "../../services/Socket";
import { useState, useEffect, useCallback } from "react";

const useStatus = (roomName, id, events = {}) => {
  const [status, setStatus] = useState();

  useEffect(() => {
    if (!id) {
      return;
    }

    socket.on(events.update, (status) => {
      setStatus(status);
    });
  }, [roomName, events.update, id]);

  useEffect(() => {
    if (!id) {
      return;
    }

    socket.emit("joinRoom", roomName);
    return () => {
      socket.emit("leaveRoom", roomName);
    };
  }, [roomName, id]);

  const checkStatus = useCallback(
    (payload) => {
      if (!id) {
        return;
      }

      socket.emit(events.check, payload, roomName);
    },
    [roomName, events.check, id]
  );

  return { status, checkStatus };
};

export default useStatus;
