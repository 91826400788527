import React from "react";
import PropTypes from "prop-types";
import { Input } from "reactstrap";
import { FiSearch } from "react-icons/fi";

import "./SearchDataDomains.scss";

function SearchDataDomains({ searchWord, setSearchWord, setCurrentPage }) {
  const handleInputChange = (e) => {
    setSearchWord(e.target.value);
    setCurrentPage(1);
  };

  return (
    <div
      className="global-search admin-search-data-domain"
      data-testid="SearchDataDomains-Container"
    >
      <FiSearch
        className="global-search-icon"
        data-testid="SearchDataDomains-Icon"
      />
      <Input
        onChange={handleInputChange}
        value={searchWord}
        className="global-search-input admin-search-data-domain-input"
        placeholder="Search in your Admin Panel"
        data-testid="SearchDataDomains-Input"
      />
    </div>
  );
}

SearchDataDomains.propTypes = {
  searchWord: PropTypes.string,
  setSearchWord: PropTypes.func,
  setCurrentPage: PropTypes.func,
};

export default SearchDataDomains;
