import { takeEvery, fork, all, call, put } from "redux-saga/effects";
import { USERS_CONST } from "./actionTypes";
import {
  getAllUsersSuccess,
  getAllUsersFailure,
  getUserByTeamSuccess,
  getUserByTeamFailure,
  getUserProfileSuccess,
  getUserProfileFailure,
  getUsersByDomainIdSuccess,
  getUsersByDomainIdFailure,
  getUsersByTeamSpaceIdSuccess,
  getUsersByTeamSpaceIdFailure,
} from "./actions";
import { loginSuccess } from "../auth/login/actions";
import { checkHttpStatus } from "../apiUtils";
import { baseMethod } from "../../services/BaseService";
import { AXIOS_INSTANCE, USERS_API } from "../apiUtils/config";

export function* getAllUsers() {
  try {
    const serviceMethod = AXIOS_INSTANCE.get(`${USERS_API}/getAll`);
    const apiResponse = yield call(baseMethod, serviceMethod, "", null, null);
    const response = yield call(checkHttpStatus, apiResponse);
    if (
      response.status >= 200 &&
      response.status < 300 &&
      response.data?.data?.isSuccess
    ) {
      const responseData = {
        statusCode: 200,
        response: response.data,
      };
      yield put(getAllUsersSuccess(responseData));
    } else {
      const responseData = {
        statusCode: response.statusCode || 400,
        data: response.data,
      };
      yield put(getAllUsersFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getAllUsersFailure(errorData));
  }
}

export function* getUsersByDomainId(action) {
  try {
    const serviceMethod = AXIOS_INSTANCE.get(
      `${USERS_API}/getByDomain?domainId=${action.payload}`,
      {
        params: { type: action.domainUser },
      }
    );
    const apiResponse = yield call(baseMethod, serviceMethod, "", null, null);
    const response = yield call(checkHttpStatus, apiResponse);
    if (
      response.status >= 200 &&
      response.status < 300 &&
      response.data?.data?.isSuccess
    ) {
      const responseData = {
        statusCode: 200,
        response: response.data,
      };
      yield put(getUsersByDomainIdSuccess(responseData));
    } else {
      const responseData = {
        statusCode: response.statusCode || 400,
        data: response.data,
      };
      yield put(getUsersByDomainIdFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getUsersByDomainIdFailure(errorData));
  }
}

export function* getUsersByTeamSpaceId(action) {
  try {
    const serviceMethod = AXIOS_INSTANCE.get(
      `${USERS_API}/getByTeamSpace?teamSpaceId=${action.payload}`,
      {
        params: { type: action.domainUser },
      }
    );
    const apiResponse = yield call(baseMethod, serviceMethod, "", null, null);
    const response = yield call(checkHttpStatus, apiResponse);
    if (
      response.status >= 200 &&
      response.status < 300 &&
      response.data?.data?.isSuccess
    ) {
      const responseData = {
        statusCode: 200,
        response: response.data,
      };
      yield put(getUsersByTeamSpaceIdSuccess(responseData));
    } else {
      const responseData = {
        statusCode: response.statusCode || 400,
        data: response.data,
      };
      yield put(getUsersByTeamSpaceIdFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getUsersByTeamSpaceIdFailure(errorData));
  }
}

export function* getUsersProfile() {
  try {
    const serviceMethod = AXIOS_INSTANCE.get(`${USERS_API}/myProfile`);
    const apiResponse = yield call(baseMethod, serviceMethod, "", null, null);
    const response = yield call(checkHttpStatus, apiResponse);
    if (
      response.status >= 200 &&
      response.status < 300 &&
      response.data?.data?.isSuccess
    ) {
      const responseData = {
        statusCode: 200,
        response: response.data,
      };
      yield put(getUserProfileSuccess(responseData));
    } else {
      const responseData = {
        statusCode: response.statusCode || 400,
        data: response.data,
      };
      yield put(getUserProfileFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getUserProfileFailure(errorData));
  }
}

export function* getUsersByTeam(action) {
  try {
    const serviceMethod = AXIOS_INSTANCE.get(
      `${USERS_API}/getByTeam?teamId=${action.payload}`
    );
    const apiResponse = yield call(baseMethod, serviceMethod, "", null, null);
    const response = yield call(checkHttpStatus, apiResponse);
    if (
      response.status >= 200 &&
      response.status < 300 &&
      response.data?.data?.isSuccess
    ) {
      const responseData = {
        statusCode: 200,
        response: response.data,
      };

      yield put(getUserByTeamSuccess(responseData));
    } else {
      const responseData = {
        statusCode: response.statusCode || 400,
        data: response.data,
      };

      yield put(getUserByTeamFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };

    yield put(getUserByTeamFailure(errorData));
  }
}

export function* generateUser(action) {
  try {
    const serviceMethod = AXIOS_INSTANCE.post(
      `${USERS_API}/addUser`,
      action.payload
    );
    const apiResponse = yield call(baseMethod, serviceMethod, "", null, null);
    const response = yield call(checkHttpStatus, apiResponse);
    if (
      response.status >= 200 &&
      response.status < 300 &&
      response.data?.data?.isSuccess
    ) {
      const responseData = {
        statusCode: 200,
        data: {
          userDetails: {
            id: response.data.data.id,
            name: action.payload.name,
            email: action.payload.email,
            isAdmin: response.data.data.isAdmin,
          },
          isSuccess: true,
        },
      };

      localStorage.setItem("loginInfo", JSON.stringify(responseData));
      yield put(loginSuccess(responseData));
      window.location = "home";
    } else {
      // Handle failure if needed
    }
  } catch (error) {
    // Handle exception if needed
  }
}

export function* watchGetAllUsers() {
  yield takeEvery(USERS_CONST.GET_ALL_USERS_REQUEST, getAllUsers);
}

export function* watchGetUsersByTeam() {
  yield takeEvery(USERS_CONST.GET_USER_BY_TEAM_REQUEST, getUsersByTeam);
}

export function* watchGetUsersProfile() {
  yield takeEvery(USERS_CONST.MY_PROFILE_REQUEST, getUsersProfile);
}

export function* watchGenerateUser() {
  yield takeEvery(USERS_CONST.GENERATE_USER, generateUser);
}

export function* watchGetUsersByDomainId() {
  yield takeEvery(
    USERS_CONST.GET_USERS_BY_DOMAIN_ID_REQUEST,
    getUsersByDomainId
  );
}

export function* watchGetUsersByTeamSpaceId() {
  yield takeEvery(
    USERS_CONST.GET_USERS_BY_TEAMSPACE_ID_REQUEST,
    getUsersByTeamSpaceId
  );
}

export function* UsersSaga() {
  yield all([
    fork(watchGetAllUsers),
    fork(watchGetUsersByTeam),
    fork(watchGetUsersProfile),
    fork(watchGenerateUser),
    fork(watchGetUsersByDomainId),
    fork(watchGetUsersByTeamSpaceId),
  ]);
}

export default UsersSaga;
