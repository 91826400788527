import React, { useState, useEffect, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { FormGroup, Label } from "reactstrap";

import {
  AXIOS_INSTANCE,
  DATA_PRODUCT_INTERACTION_MAP_API,
  DATA_PRODUCTS_API,
} from "../../store/apiUtils/config";

import {
  getAllDataProductTypes,
  getUsersByDomainId,
  getUsersByTeamSpaceId,
} from "../../store/actions";
import { renderTextField, renderSelectField } from "../Common/ReduxFields";
import { renderOptions } from "../../helpers";
import {
  required,
  requiredForDropdown,
  requiredForMultiSelectDropdown,
} from "../../helpers/Validations";
import CustomModal from "../Common/CustomModal";
import AutoComplete from "../Common/AutoComplete";
import MultipleSelectCheckMarks from "../Common/MultipleSelectCheckMarks";
import DisabledTextField from "../Common/DisabledTextField";
import InfoCircle from "../Common/InfoCircle";
import { GlobalNotificationHandle } from "../../services/NotificationHandler";

const CreateNewDataProductModal = ({
  createDpModal,
  createDpModalToggle,
  useCaseId,
  selectedData,
  handleSubmit,
  afterSubmit,
}) => {
  const [useCases, setUseCases] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUseCases = async () => {
      const urlParam = selectedData.domainId
        ? `teamSpaceId=${selectedData?.id}`
        : `domainId=${selectedData?.id}`;
      const { data } = await AXIOS_INSTANCE.get(
        `${DATA_PRODUCT_INTERACTION_MAP_API}/getRelatedUseCases?${urlParam}`
      );
      setUseCases(data?.data?.data || []);
    };

    if (selectedData?.id) {
      fetchUseCases();
      selectedData.domainId
        ? dispatch(getUsersByTeamSpaceId(selectedData?.id))
        : dispatch(getUsersByDomainId(selectedData?.id));
      dispatch(getAllDataProductTypes());
    }
  }, [selectedData.id, dispatch]);

  const dpSubmitHandler = (formData) => {
    if (
      formData?.name &&
      formData?.ownerDetails &&
      formData?.dataProductTypeId &&
      formData?.useCases
    ) {
      const { name, ownerDetails, dataProductTypeId, useCases } = formData;
      const domainId = selectedData?.domainId ? undefined : selectedData?.id;
      const teamSpaceId = selectedData?.domainId ? selectedData?.id : undefined;
      const ownerId = ownerDetails.id;
      const description = "";
      const isSandbox = selectedData?.isSandbox ? true : false;

      AXIOS_INSTANCE.post(`${DATA_PRODUCTS_API}/create`, {
        name,
        description,
        ownerId,
        domainId,
        teamSpaceId,
        dataProductTypeId,
        useCases,
        isSandbox,
      })
        .then((res) => {
          if (res.data.data.isSuccess) {
            afterSubmit(res);
          } else {
            GlobalNotificationHandle(
              201,
              "Failed to create Data Product",
              false,
              true
            );
          }
        })
        .catch((error) => {
          GlobalNotificationHandle(
            error.response.status,
            error.response.data,
            false,
            true,
            true
          );
        });
    }
  };

  const { allDataProductTypes, usersByDomain, usersByTeamSpace } = useSelector(
    (state) => ({
      allDataProductTypes:
        state.DataproductTypes.allDataProductTypes?.dataProductTypeList,
      usersByDomain: state.Users.usersByDomain?.userList,
      usersByTeamSpace: state.Users.usersByTeamSpace?.userList,
    })
  );

  return (
    <CustomModal
      displayState={createDpModal}
      toggleState={createDpModalToggle}
      title="Create New Data Product"
      tooltipText="A data product is a life-cycle managed combination of technology and data that delivers value in specific business contexts."
      primaryBtn="Create Data Product"
      primaryBtnHandler={dpSubmitHandler}
      formId="create-new-dp-modal"
    >
      <form id="create-new-dp-modal" onSubmit={handleSubmit(dpSubmitHandler)}>
        <FormGroup>
          <Label className="form-label mt-3" htmlFor="dataProductName">
            Data Product Name
          </Label>
          <Field
            id="dataProductName"
            component={renderTextField}
            type="text"
            name="name"
            validate={[required]}
          />
        </FormGroup>
        <FormGroup>
          <Label className="form-label mt-3">
            Data Product Owner
            <InfoCircle
              orientation="top"
              text="A person who is accountable for understanding and meeting the needs of their users and the quality and scope of their data product matching demands."
            />
          </Label>
          <Field
            component={AutoComplete}
            name="ownerDetails"
            options={
              selectedData.domainId ? usersByTeamSpace : usersByDomain || []
            }
            getOptionLabel={(e) => e.name}
            getOptionValue={(e) => e.id}
            placeholder="Type name or surname"
            validate={[requiredForDropdown]}
          />
        </FormGroup>
        <FormGroup>
          <Label
            className="form-label mt-3 disable-label"
            htmlFor={selectedData?.id}
          >
            Data Product Domain
          </Label>
          <Field
            component={DisabledTextField}
            type="text"
            name="domainId"
            disabledValue={
              selectedData?.domainName
                ? selectedData.domainName
                : selectedData?.name
            }
            id={selectedData?.id}
          />
        </FormGroup>
        <FormGroup>
          <Label className="form-label mt-3 disable-label">
            Data Product Team Space
          </Label>
          <Field
            component={DisabledTextField}
            type="text"
            name="id"
            disabledValue={selectedData?.domainId ? selectedData.name : ""}
            id={selectedData?.id}
          />
        </FormGroup>
        <FormGroup>
          <Label className="form-label mt-3">Data Product Type</Label>
          <Field
            component={renderSelectField}
            name="dataProductTypeId"
            options={renderOptions(allDataProductTypes, "name", "id")}
            validate={[requiredForDropdown]}
          />
        </FormGroup>
        <FormGroup>
          <Label className="form-label mt-3">Use Case</Label>
          <Field
            defaultItems={[useCaseId]}
            component={MultipleSelectCheckMarks}
            name="useCases"
            options={useCases || []}
            validate={[requiredForMultiSelectDropdown]}
          />
        </FormGroup>
      </form>
    </CustomModal>
  );
};

CreateNewDataProductModal.propTypes = {
  createDpModal: PropTypes.bool,
  createDpModalToggle: PropTypes.func,
  useCaseId: PropTypes.string,
  selectedData: PropTypes.object,
  handleSubmit: PropTypes.func,
  afterSubmit: PropTypes.func,
};

export default reduxForm({
  form: "CreateNewDataProductModalForm",
})(memo(CreateNewDataProductModal));
