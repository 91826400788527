import { useCallback, useLayoutEffect, useRef, useState } from "react";

const useHoverWhenOverflow = () => {
  const textElementRef = useRef(null);
  const [hoverStatus, setHover] = useState(false);

  const compareSize = useCallback(() => {
    if (textElementRef.current) {
      const compare =
        textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
      setHover(compare);
    }
  }, []);

  useLayoutEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);

    return () => {
      window.removeEventListener("resize", compareSize);
    };
  }, [compareSize]);

  return { hoverStatus, textElementRef };
};

export default useHoverWhenOverflow;
