import { DATAPRODUCT_INTERACTION_MAP_CONST } from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
};

const DataProductInteractionMap = (state, action) => {
  if (typeof state == "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case DATAPRODUCT_INTERACTION_MAP_CONST.GET_DPIM_BY_USECASE_ID_REQUEST:
    case DATAPRODUCT_INTERACTION_MAP_CONST.GET_DPIM_BY_USECASE_ID_FAILURE:
      state = {
        ...state,
        selectedDPIM: null,
      };
      break;
    case DATAPRODUCT_INTERACTION_MAP_CONST.GET_DPIM_BY_USECASE_ID_SUCCESS:
      state = {
        ...state,
        selectedDPIM: action.payload?.response?.data?.data,
      };
      break;
    case DATAPRODUCT_INTERACTION_MAP_CONST.GET_ITEMS_BY_USECASE_ID_REQUEST:
    case DATAPRODUCT_INTERACTION_MAP_CONST.GET_ITEMS_BY_USECASE_ID_FAILURE:
      state = {
        ...state,
        selectedItems: null,
      };
      break;
    case DATAPRODUCT_INTERACTION_MAP_CONST.GET_ITEMS_BY_USECASE_ID_SUCCESS:
      state = {
        ...state,
        selectedItems: action.payload?.response?.data?.dataProducts,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default DataProductInteractionMap;
