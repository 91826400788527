import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";

import "./RoleMembers.scss";

const RoleMembers = ({
  teamMembers,
  roleData,
  usersByDomain,
  editState,
  editAccess,
  teamMembersHandler,
}) => {
  const [addMember, setAddMember] = useState(false);
  const [newMemberId, setNewMemberId] = useState("");
  const [disableAddButton, setDisableAddButton] = useState(true);
  const [ownerCount, setOwnerCount] = useState(0);

  useEffect(() => {
    if (newMemberId !== "") {
      const memberExists = teamMembers.find(
        (member) => member.userId === newMemberId
      );
      setDisableAddButton(memberExists !== undefined ? true : false);
    } else setDisableAddButton(true);

    const ownerList = teamMembers?.filter((member) => {
      return member.roleName === "Data Product Owner";
    });
    setOwnerCount(ownerList?.length);
  }, [newMemberId, teamMembers]);

  const initializeState = useCallback(() => {
    setAddMember(false);
    setNewMemberId("");
  }, []);

  const updateMembers = (action) => {
    teamMembersHandler(action, {
      userId: newMemberId,
      roleId: roleData.id,
      roleName: roleData.name,
    });
    initializeState();
  };

  const dataProductOwnerDescription =
    roleData.name === "Data Product Owner"
      ? "A person who is accountable for understanding and meeting the needs of their users and the quality and scope of their data product matching demands"
      : "";

  const renderRole = () => {
    if (roleData?.sequence === 1) {
      return (
        <Tooltip title={dataProductOwnerDescription} placement="top-start">
          <p className="data-product-details-subtitle">{roleData.name}</p>
        </Tooltip>
      );
    } else {
      return (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Tooltip title={roleData.name}>
            <p className="data-product-details-subtitle">{roleData.name}</p>
          </Tooltip>
          {editState && editAccess ? (
            <Tooltip title="Remove the role and all assigned people" arrow>
              <DeleteIcon
                onClick={() =>
                  teamMembersHandler("deleteRole", {
                    roleId: roleData.id,
                    roleName: roleData.name,
                  })
                }
                data-testid={`${roleData.id}-delete-icon`}
                sx={{ cursor: "pointer" }}
              />
            </Tooltip>
          ) : null}
        </Stack>
      );
    }
  };

  const renderMembers = (member) => {
    if (roleData?.sequence === 1) {
      if (editState && editAccess) {
        return (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            key={`${roleData.name}-${member.userName}`}
          >
            <TextField
              select
              label="Select Member"
              onChange={(event) =>
                teamMembersHandler("updateOwner", {
                  userId: event.target.value,
                  roleId: roleData.id,
                  roleName: roleData.name,
                })
              }
              value={member.userId}
              size="small"
              sx={{ width: "100%" }}
              key={`${roleData.name}-${member.userName}`}
              data-testid={member.userId}
            >
              {usersByDomain.map((user) => (
                <MenuItem
                  key={user.id}
                  value={user.id}
                  data-testid={`${member.userId}-${user.id}`}
                >
                  {user.name}
                </MenuItem>
              ))}
            </TextField>
            <Tooltip
              title={
                ownerCount <= 1
                  ? `There should atleast be one Data Product Owner`
                  : `Remove ${member.userName} from ${roleData.name}`
              }
              arrow
            >
              <DeleteIcon
                className={classNames({
                  "delete-icon-disable": ownerCount <= 1,
                  "delete-icon-enable": ownerCount > 1,
                })}
                onClick={
                  ownerCount > 1
                    ? () =>
                        teamMembersHandler("deleteUser", {
                          userId: member.userId,
                          roleId: roleData.id,
                          roleName: roleData.name,
                        })
                    : null
                }
                data-testid={`${member.userId}-delete-icon`}
              />
            </Tooltip>
          </Stack>
        );
      } else {
        return (
          <p
            className="data-product-details-text wrap-text"
            key={`${roleData.name}-${member.userName}`}
          >
            {member?.userName}
          </p>
        );
      }
    } else {
      return (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          key={`${roleData.name}-${member.userName}`}
        >
          <p className="data-product-details-text">{member?.userName}</p>
          {editState && editAccess ? (
            <Tooltip
              title={`Remove ${member.userName} from ${roleData.name}`}
              arrow
            >
              <DeleteIcon
                className="delete-icon"
                onClick={() =>
                  teamMembersHandler("deleteUser", {
                    userId: member.userId,
                    roleId: roleData.id,
                    roleName: roleData.name,
                  })
                }
                data-testid={`${member.userId}-delete-icon`}
              />
            </Tooltip>
          ) : null}
        </Stack>
      );
    }
  };

  const renderMembersOptions = () => {
    if (editState && editAccess) {
      if (!addMember) {
        return (
          <Typography
            variant="body2"
            color="secondary"
            className="wrap-text"
            sx={{
              marginTop: "10px",
              cursor: "pointer",
            }}
            onClick={() => setAddMember(true)}
          >
            {roleData.sequence !== 1 ? "+ Add new member" : "+ Add new owner"}
          </Typography>
        );
      } else {
        return (
          <Stack spacing={1}>
            <TextField
              select
              label="Select Member"
              onChange={(event) => setNewMemberId(event.target.value)}
              value={newMemberId}
              size="small"
              data-testid="newMemberAdditionDropdown"
            >
              {usersByDomain.map((user) => (
                <MenuItem
                  key={user.id}
                  value={user.id}
                  data-testid={`new-member-${user.id}`}
                >
                  {user.name}
                </MenuItem>
              ))}
            </TextField>
            <Stack
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              spacing={2}
            >
              <Button
                variant="contained"
                color="grey"
                onClick={() => initializeState()}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                disabled={disableAddButton}
                onClick={() => updateMembers("add")}
              >
                Add
              </Button>
            </Stack>
          </Stack>
        );
      }
    } else return null;
  };

  return (
    <Stack spacing={2}>
      {renderRole()}
      <Stack spacing={1}>
        {teamMembers?.map((member) => renderMembers(member))}
        {renderMembersOptions()}
      </Stack>
    </Stack>
  );
};

RoleMembers.propTypes = {
  teamMembers: PropTypes.array.isRequired,
  roleData: PropTypes.object.isRequired,
  usersByDomain: PropTypes.array.isRequired,
  editAccess: PropTypes.bool,
  editState: PropTypes.bool,
  teamMembersHandler: PropTypes.func.isRequired,
};

export default RoleMembers;
