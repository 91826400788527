import React, { useState, useRef, useEffect, memo } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Popper,
  Paper,
  Grid,
  Checkbox,
  Switch,
  ClickAwayListener,
} from "@mui/material";
import { ReactComponent as FilterIcon } from "../../assets/images/FilterIcon.svg";
import "./LineageFilter.scss";
import PropTypes from "prop-types";
import {
  TO_BE_DECIDED,
  DRAFT,
  PHARMA,
  DIAGNOSTICS,
  IN_REVIEW,
} from "../../constants/DataDomainType";

import { useDispatch, useSelector } from "react-redux";
import {
  getSourceFilterData,
  getShowFilterData,
  getProductOwner,
  resetFilter,
} from "../../store/dataProducts/LineageFilter/actions";

const LineageFilter = ({
  nodesAndEdgesData,
  setEdges,
  setNodes,
  DataProductId,
  fullScreenButtonEnable = true,
}) => {
  const filterTypes = {
    sourceFilterData: {
      [TO_BE_DECIDED]: IN_REVIEW,
      [PHARMA]: PHARMA,
      [DIAGNOSTICS]: DIAGNOSTICS,
      [DRAFT]: null,
    },
    showFilterData: {
      Input: "input",
      Output: "output",
    },
    ownerList: {
      productOwner: "Show only Data Products I own",
    },
  };

  const [open, setOpen] = useState(false);
  const anchorEl = useRef(null);

  const dispatch = useDispatch();
  const LineageFilterList = useSelector((state) => state.LineageFilter);
  //handle popup hide and show
  const handleClick = () => {
    setOpen((pre) => !pre);
  };

  const id = "simple-popper-lineage";

  //source filter handler
  const sourceFilterApply = (event) => {
    const isChecked = event.target.checked;
    const filterValue = event.target.value;
    dispatch(getSourceFilterData(filterValue, isChecked));
  };

  function showFilterApply(event) {
    const isChecked = event.target.checked;
    const filterValue = event.target.value;
    dispatch(getShowFilterData(filterValue, isChecked));
  }

  const handleClearFilter = () => {
    // setSourceCheckedList(sourceFilterData);
    // setShowCheckedList(showFilterData);
    dispatch(resetFilter());
    // setProductOwner(false);
  };

  const productOwnerFilterApply = (event) => {
    const isChecked = event.target.checked;
    const filterValue = event.target.value;
    dispatch(getProductOwner(filterValue, isChecked));
  };

  const handleClickAway = (event) => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    const updateElementStyle = (el) => {
      const conditions = [
        !LineageFilterList.sourceFilterData[TO_BE_DECIDED] &&
          el.data?.domainType === filterTypes.sourceFilterData[TO_BE_DECIDED],
        !LineageFilterList.sourceFilterData[PHARMA] &&
          el.data?.domainType === filterTypes.sourceFilterData[PHARMA],
        !LineageFilterList.sourceFilterData[DIAGNOSTICS] &&
          el.data?.domainType === filterTypes.sourceFilterData[DIAGNOSTICS],
        !LineageFilterList.sourceFilterData[DRAFT] &&
          el.data?.domainType === filterTypes.sourceFilterData[DRAFT],
        !LineageFilterList.showFilterData["Input"] &&
          el.data?.type === filterTypes.showFilterData["Input"],
        !LineageFilterList.showFilterData["Output"] &&
          el.data?.type === filterTypes.showFilterData["Output"],
        LineageFilterList.ownerList["productOwner"] && !el.data?.isDpOwner,
      ];
      return {
        ...el,
        style: {
          ...el.style,
          opacity: conditions.some((cond) => cond) ? 0.08 : 1,
        },
      };
    };

    setNodes((prevNodes) =>
      prevNodes.map((el) =>
        el.type === "hexagon" ? updateElementStyle(el) : el
      )
    );
    setEdges((prevEdges) => prevEdges.map((el) => updateElementStyle(el)));
  }, [dispatch, LineageFilterList, nodesAndEdgesData]);

  const rr = useHistory();

  const fullScreenHandler = () => {
    if (fullScreenButtonEnable) {
      rr.push(
        `/dataproduct-details?dataproductId=${DataProductId}&lineagefullscreen=true`
      );
    } else {
      rr.push(`/dataproduct-details?dataproductId=${DataProductId}`);
    }
  };

  return (
    <div className="lineage-filter">
      <div className="lineage-filter-content">
        <div className="lineage-filter-buttons-content">
          <Button
            variant="text"
            className="lineage-filter-buttons"
            onClick={fullScreenHandler}
            data-testid="go_to_full_screen_button"
          >
            <span className="lineage-filter-text">
              {fullScreenButtonEnable
                ? "Go to full screen"
                : "Close full screen"}
            </span>
          </Button>

          <Button
            variant="text"
            onClick={handleClick}
            className="lineage-filter-buttons"
            aria-describedby={id}
            ref={anchorEl}
          >
            <FilterIcon />
            <span className="lineage-filter-text ml-2">
              {open ? "Hide filters" : "Show filters"}
            </span>
          </Button>
        </div>

        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl.current}
          sx={{ width: "480px" }}
          placement={"bottom-end"}
        >
          <ClickAwayListener
            mouseEvent="onClick"
            touchEvent="onTouchStart"
            onClickAway={handleClickAway}
          >
            <Paper
              elevation={3}
              className="lineage-filter-popup"
              sx={{ background: "#fff!important" }}
              data-testid="filter-popup"
            >
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <div className="lineage-filter-popup-content">
                    <p className="lineage-filter-popup-text">Roche Division</p>
                    <div className="lineage-filter-popup-checkbox-container">
                      {Object.keys(filterTypes.sourceFilterData).map((key) => {
                        return (
                          <div
                            className="lineage-filter-popup-checkbox-content"
                            key={key}
                          >
                            <Checkbox
                              className="lineage-filter-popup-checkbox-input"
                              checked={LineageFilterList.sourceFilterData[key]}
                              id={`checkbox-${key}`}
                              value={key}
                              onChange={(e) => sourceFilterApply(e)}
                              data-testid={`checkbox-${key}`}
                            />
                            <span className="lineage-filter-popup-label">
                              <label
                                className="lineage-filter-popup-label"
                                htmlFor={key}
                              >
                                {key}
                              </label>
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="lineage-filter-popup-content">
                    <p className="lineage-filter-popup-text">Show</p>
                    <div className="lineage-filter-popup-checkbox-container">
                      {Object.keys(filterTypes.showFilterData).map((key) => {
                        return (
                          <div
                            className="lineage-filter-popup-checkbox-content"
                            key={key}
                          >
                            <Checkbox
                              className="lineage-filter-popup-checkbox-input"
                              checked={LineageFilterList.showFilterData[key]}
                              value={key}
                              id={`checkbox-${key}`}
                              key={key}
                              onChange={(e) => showFilterApply(e)}
                            />
                            <span className="lineage-filter-popup-label">
                              <label
                                className="lineage-filter-popup-label"
                                htmlFor={key}
                              >
                                {key}
                              </label>
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="lineage-filter-popup-prodoct-filter">
                    {Object.keys(filterTypes.ownerList).map((key) => {
                      return (
                        <React.Fragment key={key}>
                          <div key={key} className="lineage-filter-popup-label">
                            {filterTypes.ownerList[key]}
                          </div>
                          <div>
                            <Switch
                              onChange={(e) => {
                                productOwnerFilterApply(e);
                              }}
                              key={key}
                              id="product-owner"
                              data-testid="product-owner"
                              value={key}
                              checked={LineageFilterList.ownerList[key]}
                            />
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </Grid>
                <Grid key="clearfilter" item xs={12}>
                  <div>
                    <Button
                      variant="text"
                      onClick={handleClearFilter}
                      className="px-0 py-1"
                    >
                      <span className="lineage-filter-popup-clear-buttons">
                        Reset Filters
                      </span>
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </div>
    </div>
  );
};

LineageFilter.propTypes = {
  setEdges: PropTypes.func.isRequired,
  setNodes: PropTypes.func.isRequired,
  DataProductId: PropTypes.string.isRequired,
  fullScreenButtonEnable: PropTypes.bool,
  nodesAndEdgesData: PropTypes.object,
};

export default memo(LineageFilter);
