import React, { useState, memo } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
// import { FiShare2 } from 'react-icons/fi';
import { GrFormEdit } from "react-icons/gr";
import { MdContentCopy } from "react-icons/md";
import { getUserData } from "../../helpers";

const EditDropdown = ({ dataProduct, handleEditDataProduct }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const teamMemberId = dataProduct?.teams?.length
    ? dataProduct.teams.map((dataId) => dataId.userId)
    : [];

  const showEditIcon = () => {
    const { userId, isAdminUser } = getUserData();
    return (
      userId === dataProduct.ownerId ||
      teamMemberId.includes(userId) ||
      isAdminUser
    );
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className="edit-dropdown">
      <DropdownToggle>
        <i className="fa fa-ellipsis-v"></i>
      </DropdownToggle>
      <DropdownMenu className="edit-dropdown-menu">
        {showEditIcon() && (
          <DropdownItem
            className="edit-dropdown-item icon-bg"
            onClick={handleEditDataProduct}
          >
            <GrFormEdit />
          </DropdownItem>
        )}

        <DropdownItem className="edit-dropdown-item icon-bg d-none">
          <MdContentCopy />
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
EditDropdown.propTypes = {
  dataProductId: PropTypes.string,
  dataProduct: PropTypes.object.isRequired,
  handleEditDataProduct: PropTypes.func,
};
export default memo(EditDropdown);
