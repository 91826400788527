import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import MultipurposeAccordion from "../../components/Common/MultipurposeAccordion";
import { GlobalNotificationHandle } from "../../services/NotificationHandler";
import TeamSpacesCard from "../../components/Common/TeamSpacesCard";
import CreateTeamSpace from "./CreateTeamSpace";

function TeamSpacesAccordian({
  isExtended = false,
  selectedTeamSpace,
  domainName,
  isCollibra,
  isOwner,
  isAdmin,
}) {
  const [setTeamSpaceWithDetails] = useState([]);
  const [teamSpaceList, setTeamSpaceList] = useState([]);
  // const history = useHistory();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const domainId = params.get("domainId");
  const totalTeamSpace = selectedTeamSpace?.total;

  useEffect(() => {
    if (selectedTeamSpace?.teamSpaceList) {
      setTeamSpaceList(selectedTeamSpace.teamSpaceList);
    }
  }, [selectedTeamSpace]);

  const getExtended = useCallback(async () => {
    try {
      if (totalTeamSpace > 0) {
        return;
      }
      setTeamSpaceWithDetails(selectedTeamSpace?.teamSpaceList);
    } catch (e) {
      GlobalNotificationHandle(400, e?.toString(), false, false, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTeamSpace]);

  useEffect(() => {
    if (!isExtended) {
      getExtended();
    }
  }, [getExtended, isExtended]);

  return (
    <MultipurposeAccordion title="Team Spaces">
      <>
        <CreateTeamSpace
          id={domainId}
          name={domainName}
          isCollibra={isCollibra}
          isOwner={isOwner}
          isAdmin={isAdmin}
        />
        {totalTeamSpace ? (
          <>
            <div className="teamSpaceGrid">
              {teamSpaceList.map((item, id) => {
                return <TeamSpacesCard key={id} teamSpace={item} />;
              })}
            </div>
          </>
        ) : null}
      </>
    </MultipurposeAccordion>
  );
}

TeamSpacesAccordian.propTypes = {
  isExtended: PropTypes.bool,
  selectedTeamSpace: PropTypes.object,
  domainName: PropTypes.string,
  isCollibra: PropTypes.bool,
  isOwner: PropTypes.bool,
  isAdmin: PropTypes.bool,
};

export default TeamSpacesAccordian;
