import { TEAMS_CONST } from "./actionTypes";

export const getAllTeams = () => {
  return {
    type: TEAMS_CONST.GET_ALL_TEAMS_REQUEST,
    payload: {},
  };
};
export const getAllTeamsSuccess = (response) => {
  return {
    type: TEAMS_CONST.GET_ALL_TEAMS_SUCCESS,
    payload: response,
  };
};
export const getAllTeamsFailure = (error) => {
  return {
    type: TEAMS_CONST.GET_ALL_TEAMS_FAILURE,
    payload: error,
  };
};

export const createTeam = (formData) => {
  return {
    type: TEAMS_CONST.CREATE_TEAM_REQUEST,
    payload: formData,
  };
};
export const createTeamSuccess = (response) => {
  return {
    type: TEAMS_CONST.CREATE_TEAM_SUCCESS,
    payload: response,
  };
};
export const createTeamFailure = (error) => {
  return {
    type: TEAMS_CONST.CREATE_TEAM_FAILURE,
    payload: error,
  };
};

export const updateTeam = (user) => {
  return {
    type: TEAMS_CONST.UPDATE_TEAM_REQUEST,
    payload: { user },
  };
};
export const updateTeamSuccess = (response) => {
  return {
    type: TEAMS_CONST.UPDATE_TEAM_SUCCESS,
    payload: response,
  };
};
export const updateTeamFailure = (error) => {
  return {
    type: TEAMS_CONST.UPDATE_TEAM_FAILURE,
    payload: error,
  };
};

export const getAllTeamsByUser = () => {
  return {
    type: TEAMS_CONST.GET_TEAMS_BY_USER_REQUEST,
    payload: {},
  };
};
export const getAllTeamsByUserSuccess = (response) => {
  return {
    type: TEAMS_CONST.GET_TEAMS_BY_USER_SUCCESS,
    payload: response,
  };
};
export const getAllTeamsByUserFailure = (error) => {
  return {
    type: TEAMS_CONST.GET_TEAMS_BY_USER_FAILURE,
    payload: error,
  };
};
