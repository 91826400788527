import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { FaRegEdit } from "react-icons/fa";
import "./TeamSpaceDetailsCard.scss";
import CustomDataInputField from "../../components/Common/CustomDataInputField";
import CustomDataMultiSelectField from "../../components/Common/CustomDataMultiSelectField";
import RedAsterisk from "../../components/Common/RedAsterisk";
import { socket } from "../../services/Socket";
import useIsEdit from "../../helpers/CustomHooks/useIsEdit";
import { getUsersByDomainId } from "../../store/actions";
import Divider from "@mui/material/Divider";

const TeamSpaceDetailsCard = ({ teamSpaceData, teamSpaceId }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [members, setMembers] = useState([]);
  const [owners, setOwners] = useState([]);
  const [selectableUsers, setSelectableUsers] = useState([]);
  const [teamSpaceName, setTeamSpaceName] = useState("");
  const [definition, setDefinition] = useState("");
  const [teamSpaceNameError, setTeamSpaceNameError] = useState(false);
  const [teamSpaceDescriptionError, setTeamSpaceDescriptionError] =
    useState(false);
  const [ownerError, setOwnerError] = useState(false);
  const { editable, handleEditState } = useIsEdit(socket.lastJoinedRoom);
  const dispatch = useDispatch();
  const domainUser = "domainUsers";
  const userDetails = localStorage.getItem("loginInfo")
    ? JSON.parse(localStorage.getItem("loginInfo")).data?.userDetails
    : null;

  const { users } = useSelector((state) => ({
    users: state.Users?.usersByDomain?.userList,
  }));

  useEffect(() => {
    if (isEditMode) {
      dispatch(getUsersByDomainId(teamSpaceData?.domainId, domainUser));
    }
  }, [isEditMode, dispatch]);

  useEffect(() => {
    setMembers(teamSpaceData?.members || []);
    setOwners(teamSpaceData?.owners || []);
    setDefinition(teamSpaceData?.description || "");
    setTeamSpaceName(teamSpaceData?.name || "");
  }, [teamSpaceData]);

  useEffect(() => {
    if (!users || !members || !owners) {
      return;
    }

    const filteredUserList = users.filter((user) => {
      const isMember = members.find((member) => user.id === member.id);
      const isOwner = owners.find((owner) => user.id === owner.id);
      return !isMember && !isOwner;
    });

    setSelectableUsers(filteredUserList);
  }, [users, members, owners]);

  const handleSave = async () => {
    setTeamSpaceDescriptionError(false);
    setTeamSpaceNameError(false);
    setOwnerError(false);

    if (!teamSpaceName || !definition || !owners[0]?.id || !owners?.length) {
      if (!teamSpaceName) setTeamSpaceNameError(true);
      if (!definition) setTeamSpaceDescriptionError(true);
      if (!owners[0]?.id || owners?.length === 0) setOwnerError(true);

      return;
    }

    if (isOwner || isMember) {
      socket.emit(
        "updateTeamSpaceData",
        {
          name: teamSpaceName,
          teamSpaceId: teamSpaceData.id,
          domainId: teamSpaceData?.domainId,
          description: definition,
          members,
          owners,
          isOwner,
          isMember,
        },
        socket.lastJoinedRoom
      );
    }
    setIsEditMode(false);
    handleEditState(true, socket.lastJoinedRoom);
  };

  const handleEdit = () => {
    setIsEditMode(true);
    handleEditState(false, socket.lastJoinedRoom);
  };
  const handleCancel = () => {
    setIsEditMode(false);
    setOwnerError(false);
    setTeamSpaceNameError(false);
    setTeamSpaceDescriptionError(false);
    handleEditState(true, socket.lastJoinedRoom);
    socket.emit("getTeamSpaceById", { teamSpaceId }, socket.lastJoinedRoom);
    socket.on("receiveTeamSpaceData", (data) => {
      setTeamSpaceName(data.name);
      setDefinition(data.description);
      setOwners(data.owners);
      setMembers(data.members);
    });
  };

  const isOwner = teamSpaceData?.owners?.find(
    (owner) => owner.id === userDetails.id
  );

  const isMember = teamSpaceData?.members?.find(
    (member) => member.id === userDetails.id
  );

  return (
    <div className="team-space-details-card">
      <Typography
        className="team-space-details-card-body-title"
        variant="subtitle2"
      >
        Team Space Name: <RedAsterisk isEditMode={isEditMode} />
      </Typography>
      <div className="team-space-details-card-title">
        <CustomDataInputField
          editState={isEditMode && !!isOwner}
          data={teamSpaceName}
          onChangeHandler={setTeamSpaceName}
          textVariant="body2"
          textFieldClass="team-space-details-card-title-text"
          isMultiLine={false}
          error={teamSpaceNameError}
          errorMessage="Please enter the required information."
          handleError={setTeamSpaceNameError}
          hasError={true}
          isTeamSpace={true}
        />
      </div>
      <div className="team-space-details-card-body">
        <Typography
          className="team-space-details-card-body-title"
          variant="subtitle2"
        >
          Data Domain:
        </Typography>
        <div className="team-space-details-card-chip">
          <span>{teamSpaceData.domainName}</span>
        </div>
      </div>
      <div className="team-space-details-card-body">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              className="team-space-details-card-body-title"
              variant="subtitle2"
            >
              Description: <RedAsterisk isEditMode={isEditMode} />
            </Typography>
            <CustomDataInputField
              editState={isEditMode && !!isOwner}
              data={definition}
              onChangeHandler={setDefinition}
              isMultiLine={true}
              textVariant="body2"
              textFieldClass="team-space-details-card-body-definition"
              textClass="team-space-details-card-body-definition"
              inputHeight="100px"
              error={teamSpaceDescriptionError}
              errorMessage="Please enter the required information."
              handleError={setTeamSpaceDescriptionError}
              hasError={true}
              isTeamSpace={true}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              className="team-space-details-card-owner-title"
            >
              Team Space Owner: <RedAsterisk isEditMode={isEditMode} />
            </Typography>
            <CustomDataMultiSelectField
              customEditClass="team-space-details-card-members-edit"
              customTextClass="team-space-details-card-members"
              data={owners}
              options={selectableUsers}
              isEdit={isEditMode && !!isOwner}
              setOptions={setOwners}
              customButtonText="Add new person"
              error={ownerError}
              errorMessage="Please select the required information."
              resetError={() => setOwnerError(false)}
              resetErrorToTrue={() => setOwnerError(true)}
              isTeamSpace={true}
              isTeamSpaceOwner={true}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              className="team-space-details-card-body-title"
            >
              Members:
            </Typography>
            <CustomDataMultiSelectField
              customEditClass="team-space-details-card-members-edit"
              customTextClass="team-space-details-card-members"
              data={members}
              options={selectableUsers}
              isEdit={isEditMode}
              setOptions={setMembers}
              customButtonText="Add new person"
              isTeamSpace={true}
            />
          </Grid>
        </Grid>

        <Divider variant="middle" />
      </div>
      <div className="team-space-details-card-button-container">
        {isEditMode && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => handleCancel()}
          >
            <span>Cancel</span>
          </Button>
        )}
        {isOwner || isMember ? (
          <Button
            className="team-space-details-card-button"
            startIcon={<FaRegEdit />}
            variant={isEditMode ? "contained" : "outlined"}
            color="secondary"
            disabled={!editable && !isEditMode}
            onClick={isEditMode ? handleSave : handleEdit}
          >
            {isEditMode ? "Save" : "Edit"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

TeamSpaceDetailsCard.propTypes = {
  teamSpaceData: PropTypes.object,
  teamSpaceId: PropTypes.string,
};
export default TeamSpaceDetailsCard;
