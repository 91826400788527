import React from "react";
import PropTypes from "prop-types";
import CustomModal from "../../../components/Common/CustomModal";

const DeleteMosModal = ({
  deleteMosModal,
  deleteMosModalToggle,
  submitHandler,
}) => {
  return (
    <div>
      <CustomModal
        displayState={deleteMosModal}
        toggleState={deleteMosModalToggle}
        title="Do you confirm deleting Measure of Success?"
        primaryBtn="Yes"
        primaryBtnHandler={submitHandler}
        secondaryBtn="Cancel"
        secondaryHandler={() => deleteMosModalToggle()}
      />
    </div>
  );
};

DeleteMosModal.propTypes = {
  deleteMosModal: PropTypes.bool,
  deleteMosModalToggle: PropTypes.func,
  submitHandler: PropTypes.func,
};

export default DeleteMosModal;
