import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { FiSearch } from "react-icons/fi";
import { Input } from "reactstrap";

import Skeleton from "@mui/material/Skeleton";

import "./DataProductList.scss";
import DataProductListBody from "./DataProductListBody";
import { DATA_PRODUCT_SEARCH_PAGE_SIZE } from "./DPIMSettings";
import useDebounce from "../../../helpers/CustomHooks/useDebounce";
import { MY_FAVORITES } from "./SearchDataProduct";

const DataProductList = ({
  searchable,
  items = [],
  onItemClick,
  refetchList,
  isItemsLoading = false,
  totalDataProducts,
  totalDataProductsSandbox,
  currentPage,
  setCurrentPage,
  setSearchValue,
  totalFavorite,
  isSandbox,
}) => {
  const [tempSearchValue, setTempSearchValue] = useState("");
  const searchValue = useDebounce(tempSearchValue, 1000);
  const total = isSandbox ? totalDataProductsSandbox : totalDataProducts;
  const searchInputRef = useRef(null);

  useEffect(() => {
    setSearchValue(searchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isItemsLoading]);

  const onChange = (e) => {
    setTempSearchValue(e.target.value.toLowerCase());
    setCurrentPage(0);
  };

  return searchable === MY_FAVORITES && totalFavorite === 0 ? null : (
    <div className="dp-list">
      {searchable && searchable !== MY_FAVORITES ? (
        <div className="dp-list-input-container">
          <FiSearch className="mb-3 dp-list-input-icon" />
          <Input
            disabled={isItemsLoading}
            placeholder="Search for data products, data domains or data product types..."
            onChange={onChange}
            className="mb-3 dp-list-input"
            innerRef={searchInputRef}
            data-testid="input-box-for-search"
          />
        </div>
      ) : null}
      {isItemsLoading ? (
        Array.from({ length: DATA_PRODUCT_SEARCH_PAGE_SIZE }).map((_, idx) => (
          <Skeleton
            sx={{ mb: 1 }}
            key={idx}
            variant="rectangular"
            width={290}
            height={24}
            data-testid="skeleton"
          />
        ))
      ) : (
        <DataProductListBody
          onItemClick={onItemClick}
          searchable={searchable}
          filter={items}
          currentPage={currentPage}
          refetchList={refetchList}
          setCurrentPage={setCurrentPage}
          total={total}
        />
      )}
    </div>
  );
};

DataProductList.propTypes = {
  searchable: PropTypes.any,
  items: PropTypes.array,
  onItemClick: PropTypes.func,
  refetchList: PropTypes.func,
  isItemsLoading: PropTypes.bool,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  setSearchValue: PropTypes.func,
  totalFavorite: PropTypes.number,
  totalDataProducts: PropTypes.number,
  totalDataProductsSandbox: PropTypes.number,
  isSandbox: PropTypes.bool,
};

export default DataProductList;
