import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import InfoCircle from "../../../components/Common/InfoCircle";
import useMouseDrag from "../../../helpers/CustomHooks/useMouseDrag";
import { dataProductTypeAbbreviations, COLUMN_WIDTH } from "./DPIMSettings";
import { NodeResizeControl } from "reactflow";

import "./DPSection.scss";

const DPSection = (props) => {
  const { data, id, xPos, maxHeight, setMaxHeight, handleSectionSizeChange } =
    props;
  const sectionRef = useRef();
  const { handleMouseEnter, handleMouseLeave } = useMouseDrag();
  const titleHeight = data?.merged ? maxHeight + 32 : maxHeight;
  const titleClass = dataProductTypeAbbreviations[id] || id;
  const minWidth = data?.width * COLUMN_WIDTH;
  const maxWidth = data?.width * COLUMN_WIDTH * 4;

  useEffect(() => {
    if (
      sectionRef.current?.offsetHeight &&
      sectionRef.current?.offsetHeight > maxHeight
    ) {
      setMaxHeight(sectionRef.current?.offsetHeight);
    }
  }, [sectionRef.current, setMaxHeight, maxHeight]);

  return (
    <>
      <NodeResizeControl
        minWidth={minWidth}
        maxWidth={maxWidth}
        position="right"
        variant="line"
        onResizeStart={(e) => handleMouseEnter(e, "vertical")}
        onResizeEnd={(e, params) =>
          handleMouseLeave(e, params, "vertical", id, handleSectionSizeChange)
        }
      />
      <div
        className={classNames("dp-section", { "merged-section": data?.merged })}
        style={{ height: `calc(100% - ${maxHeight}px)` }}
      >
        {data?.merged?.isSecond ? (
          <div
            className="dp-section-title p-1 operational"
            style={{
              height: maxHeight,
              marginTop: -maxHeight,
              width: `calc(100% + ${xPos}px)`,
              transform: `translateX(-${xPos}px)`,
            }}
          >
            {data?.merged?.parentTitle}
            <InfoCircle
              className="dp-section-info"
              orientation="top"
              text={data?.merged?.parentInfoText}
            />
          </div>
        ) : null}
        <div
          style={{
            height: titleHeight,
            marginTop: -maxHeight,
          }}
          className={classNames("dp-section-title p-1", titleClass)}
        >
          <span className="dp-section-title-container" ref={sectionRef}>
            {data.label}
            {data.infoText ? (
              <InfoCircle
                orientation="top"
                text={data.infoText}
                className="dp-section-info ml-2"
              />
            ) : null}
          </span>
        </div>
      </div>
    </>
  );
};

DPSection.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  xPos: PropTypes.number,
  setMaxHeight: PropTypes.func,
  maxHeight: PropTypes.number,
  handleSectionSizeChange: PropTypes.func,
};

export default DPSection;
