import React from "react";
import PropTypes from "prop-types";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "./EditTeamSpaceBreadCrumb.scss";

const EditTeamSpaceBreadCrumbs = ({ editTeamSpace, setEditTeamSpace }) => {
  const handleDataDomainClick = () => {
    setEditTeamSpace({ editStatus: false, teamSpaceDetails: {} });
  };

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      className="admin-edit-breadcrumb-ts"
    >
      <span
        className="admin-edit-breadcrumb-ts-name"
        onClick={handleDataDomainClick}
      >
        Team Spaces
      </span>
      <span className="admin-edit-breadcrumb-ts-edit">{`Editing Team Space - ${editTeamSpace?.teamSpaceDetails?.name} `}</span>
    </Breadcrumbs>
  );
};

EditTeamSpaceBreadCrumbs.propTypes = {
  editTeamSpace: PropTypes.object,
  setEditTeamSpace: PropTypes.func,
};

export default EditTeamSpaceBreadCrumbs;
