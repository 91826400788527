import React from "react";
import { useLocation } from "react-router-dom";

import Breadcrumb from "../../components/Common/BreadCrumb";
import Layout from "../../components/Common/Layout";
import CreateTeamSpaceCard from "../CreateNewTeamSpace/CreateTeamSpaceCard";
import "./CreateTeamSpaceAdmin.scss";

const CreateNewTeamSpaceAdmin = () => {
  const search = useLocation().search;
  const domainId = new URLSearchParams(search).get("domainId");
  const domainName = decodeURIComponent(window.location.pathname.split("/")[2]);

  return (
    <div className="global-top-1 team-space-details-admin">
      <Layout title="Team Space Details">
        <div className="team-space-details-admin-breadcrumb">
          <Breadcrumb
            steps={[
              { name: "Home Page", href: "/home" },
              {
                name: "Admin Panel",
                href: `/admin-details`,
              },
              {
                name: "Team Spaces",
                href: `/team-space/${encodeURIComponent(
                  domainName
                )}?domainId=${domainId}`,
              },
              { name: "Create Team Space", active: true },
            ]}
          />
          <CreateTeamSpaceCard
            domainId={domainId}
            domainName={domainName}
            isAdminPage={true}
          />
        </div>
      </Layout>
    </div>
  );
};

export default CreateNewTeamSpaceAdmin;
