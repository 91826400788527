import React from "react";
import { PropTypes } from "prop-types";
import { Row, Col } from "reactstrap";
import { t } from "react-multi-lang";
import Tooltip from "@mui/material/Tooltip";

import EditDropdown from "../../pages/DomainDataProducts/EditDropdown";
import DataProductStatus from "./DataProductStatus";

import "./DataProductCard.scss";
import useHoverWhenOverflow from "../../helpers/CustomHooks/useHoverWhenOverflow";

const DataProductCard = ({
  dataProduct,
  isTeamDetailsScreen,
  handleDataProduct,
  onClickEvent,
}) => {
  const { hoverStatus, textElementRef } = useHoverWhenOverflow();

  return (
    <div
      className="data-product-card pr-4 pb-2 cursor-pointer"
      onClick={
        onClickEvent
          ? onClickEvent
          : () => {
              return false;
            }
      }
    >
      <div className="">
        {isTeamDetailsScreen && (
          <EditDropdown
            dataProduct={dataProduct}
            dataProductId={dataProduct.id}
            handleEditDataProduct={handleDataProduct}
          />
        )}
      </div>

      <Row>
        <Col sm={12} className="pb-2 d-flex justify-content-between">
          <Tooltip title={dataProduct.name} disableHoverListener={!hoverStatus}>
            <h3 ref={textElementRef} className="data-product-card-heading">
              {dataProduct.name}
            </h3>
          </Tooltip>
          <DataProductStatus status={dataProduct?.dataProductStatus} />
        </Col>
        <Col sm={12}>
          <p className="data-product-card-domain">{dataProduct?.domainName}</p>
        </Col>
        <Col sm={12}>
          <p className="data-product-card-text">
            <b>{t("DATA_PRODUCT.OWNED")} :</b>{" "}
            {dataProduct?.ownerArray?.map(({ name }) => name).join(", ")}
          </p>
        </Col>
        <Col sm={12} className="data-product-card-text">
          <span>
            {dataProduct.dataProductType || dataProduct.dataProductTypeName}
          </span>
        </Col>
      </Row>
    </div>
  );
};
DataProductCard.propTypes = {
  dataProduct: PropTypes.object.isRequired,
  handleDataProduct: PropTypes.func,
  onClickEvent: PropTypes.func,
  isTeamDetailsScreen: PropTypes.bool,
  isDatProductsScreen: PropTypes.bool,
};
export default DataProductCard;
