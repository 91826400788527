import React from "react";
import PropTypes from "prop-types";
import { t } from "react-multi-lang";

import "./DomainType.scss";
import Tooltip from "@mui/material/Tooltip";
import DataDomainTypeDescription from "../../constants/DataDomainTypeDescription";
import { DRAFT } from "../../constants/DataDomainType";

function DomainType({
  domainType,
  tooltipTitle = DataDomainTypeDescription[domainType || DRAFT],
  tooltipPlacement = "left",
  displayIndicator = true,
}) {
  const domainTypeTextMapping = {
    "In Review": t("DOMAIN.IN_REVIEW_TYPE"),
    Pharma: t("DOMAIN.PHARMA_TYPE"),
    Diagnostics: t("DOMAIN.DIAGNOSTICS_TYPE"),
    All: t("DOMAIN.ALL_TYPE"),
  };

  return (
    <Tooltip title={tooltipTitle} placement={tooltipPlacement}>
      <span>
        {displayIndicator ? (
          <span
            className={`domain-type ${
              domainType?.split(" ").join("-") || DRAFT
            }`}
          />
        ) : null}
        <span>{domainTypeTextMapping[domainType] || DRAFT}</span>
      </span>
    </Tooltip>
  );
}

DomainType.propTypes = {
  domainType: PropTypes.string,
  tooltipTitle: PropTypes.string,
  tooltipPlacement: PropTypes.string,
  displayIndicator: PropTypes.bool,
};

export default DomainType;
