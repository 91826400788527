import { TEAMS_CONST } from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
};

const Teams = (state, action) => {
  if (typeof state == "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case TEAMS_CONST.GET_ALL_TEAMS_REQUEST:
    case TEAMS_CONST.GET_ALL_TEAMS_FAILURE:
      state = {
        ...state,
        allTeams: null,
      };
      break;

    case TEAMS_CONST.GET_ALL_TEAMS_SUCCESS:
      state = {
        ...state,
        allTeams: action.payload?.response?.data,
      };
      break;

    case TEAMS_CONST.CREATE_TEAM_REQUEST:
    case TEAMS_CONST.CREATE_TEAM_FAILURE:
      state = {
        ...state,
        createTeamRequest: false,
        createTeamData: "",
      };
      break;

    case TEAMS_CONST.CREATE_TEAM_SUCCESS:
      state = {
        ...state,
        createTeamData: action.payload?.response,
      };
      break;
    case TEAMS_CONST.UPDATE_TEAM_REQUEST:
    case TEAMS_CONST.UPDATE_TEAM_FAILURE:
      state = {
        ...state,
        updateTeamData: null,
      };
      break;

    case TEAMS_CONST.UPDATE_TEAM_SUCCESS:
      state = {
        ...state,
        updateTeamData: action.payload?.response?.data,
      };
      break;

    case TEAMS_CONST.GET_TEAMS_BY_USER_REQUEST:
    case TEAMS_CONST.GET_TEAMS_BY_USER_FAILURE:
      state = {
        ...state,
        userTeams: null,
      };
      break;
    case TEAMS_CONST.GET_TEAMS_BY_USER_SUCCESS:
      state = {
        ...state,
        userTeams: action.payload?.response?.data,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Teams;
