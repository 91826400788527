import React from "react";
import PropTypes from "prop-types";

const LineageSection = (data) => {
  return (
    <div
      className="lineage-background-section"
      style={{ height: `${data?.data?.height}px` }}
    >
      {data?.data?.sectionName ? (
        <p className="dataproduct-lineage-input">{data?.data?.sectionName}</p>
      ) : null}
    </div>
  );
};

LineageSection.propTypes = {
  data: PropTypes.object.isRequired,
};

export default LineageSection;
