import { useCallback, useEffect } from "react";
import { socket } from "../../services/Socket";

const useNodeCopy = (
  setNodes,
  setEdges,
  nodes,
  edges,
  useCaseId,
  socketRoom
) => {
  const handleCopy = useCallback(
    (event) => {
      if (
        (event.ctrlKey || event.metaKey) &&
        (event.key === "c" || event.key === "C")
      ) {
        localStorage.setItem(
          "copiedNodes",
          JSON.stringify(nodes?.filter((node) => node.selected) || [])
        );
        localStorage.setItem(
          "copiedEdges",
          JSON.stringify(edges?.filter((edge) => edge.selected) || [])
        );
      }

      if (
        (event.ctrlKey || event.metaKey) &&
        (event.key === "v" || event.key === "V")
      ) {
        const copiedNodes =
          JSON.parse(localStorage.getItem("copiedNodes")) || [];
        const copiedEdges =
          JSON.parse(localStorage.getItem("copiedEdges")) || [];

        const newNodes = copiedNodes.filter(
          (node) => !nodes.find((nds) => nds.id === node.id)
        );
        const newEdges = copiedEdges.filter(
          (edge) => !edges.find((nds) => nds.id === edge.id)
        );

        if (newNodes.length === 0 && newEdges.length === 0) {
          return;
        }

        newNodes.forEach((node) => {
          if (node.type === "dpItem") {
            socket.emit(
              "addExistingDataProductItem",
              {
                coordinateX: node.position.x,
                coordinateY: node.position.y,
                step: node.data.step,
                useCaseId,
                id: node.id,
                label: node.data.label,
              },
              socketRoom
            );
          } else {
            socket.emit(
              "addDataProductToDPIM",
              {
                coordinateX: node.position.x,
                coordinateY: node.position.y,
                useCaseId,
                dataProductId: node.id,
              },
              socketRoom
            );
          }
        });

        newEdges.forEach((edge) => {
          socket.emit(
            "createDPIMConnection",
            {
              sourceId: edge.source,
              targetId: edge.target,
              targetHandle: edge.targetHandle,
              sourceHandle: edge.sourceHandle,
              useCaseId,
            },
            socketRoom
          );
        });

        if (newNodes.length > 0) {
          setNodes((nds) => Array.from(new Set([...nds, ...newNodes])));
        }
        if (newEdges.length > 0) {
          setEdges((nds) => Array.from(new Set([...nds, ...newEdges])));
        }
      }
    },
    [nodes, edges, setNodes, setEdges, socketRoom, useCaseId]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleCopy);

    return () => {
      document.removeEventListener("keydown", handleCopy);
    };
  }, [handleCopy]);
};

export default useNodeCopy;
