import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";

import lvtScreenshot from "../../../src/assets/images/lvtScreenshot.png";

const LVTCard = ({ selectedPageData, customHeight, customWidth }) => {
  const linkRedirectParam = selectedPageData.domainId
    ? `teamSpaceId=${selectedPageData.id}`
    : `domainId=${selectedPageData.id}`;

  return (
    <Link
      to={`/leanvaluetree?${linkRedirectParam}`}
      className="mt-3 mb-3"
      data-testid="LVTCard-link"
    >
      <Card sx={{ maxWidth: customWidth || "100%" }}>
        <CardMedia
          sx={{ height: customHeight || 80 }}
          image={lvtScreenshot}
          title="lvt"
          data-testid="LVTCard-media"
        />
        <CardActions>
          <Button size="small" data-testid="LVTCard-button">
            Lean Value Tree
          </Button>
        </CardActions>
      </Card>
    </Link>
  );
};

LVTCard.propTypes = {
  selectedPageData: PropTypes.object,
  customHeight: PropTypes.number,
  customWidth: PropTypes.number,
};

export default LVTCard;
