import React from "react";
import { PropTypes } from "prop-types";
import { Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import "./TeamSpacesCard.scss";
import useHoverWhenOverflow from "../../helpers/CustomHooks/useHoverWhenOverflow";

const TeamSpacesCard = ({ teamSpace }) => {
  const { hoverStatus, textElementRef } = useHoverWhenOverflow();
  const history = useHistory();
  const handleClick = (teamSpace) => {
    history.push(`/teamspace-details?teamSpaceId=${teamSpace.id}`);
  };
  return (
    <div className="team-space-card pr-2 pb-2">
      <Row>
        <Col sm={12} className="pb-2 d-flex justify-content-between">
          <Tooltip title={teamSpace.name} disableHoverListener={!hoverStatus}>
            <h3 ref={textElementRef} className="team-space-card-heading">
              {teamSpace.name}
            </h3>
          </Tooltip>
        </Col>
        <Col sm={12}>
          <p className="team-space-card-domain">{teamSpace?.domainName}</p>
        </Col>
        <Col
          sm={12}
          className="team-space-card-text"
          onClick={() => handleClick(teamSpace)}
        >
          <span>Go to Team Space Details</span>
        </Col>
      </Row>
    </div>
  );
};

TeamSpacesCard.propTypes = {
  teamSpace: PropTypes.object.isRequired,
};
export default TeamSpacesCard;
