import React from "react";

import { connect } from "react-redux";

import { Link } from "react-router-dom";

// Redux Store
import { toggleLeftmenu } from "../../store/actions";
// reactstrap

// Import menuDropdown
import ProfileMenu from "./ProfileMenu";

import logo from "../../assets/images/roche-logo.png";

import { t } from "react-multi-lang";
import DowntimeBanner from "../Common/DowntimeBanner";
import "./Header.scss";

const Header = () => {
  return (
    <>
      <header id="page-topbar">
        <DowntimeBanner />
        <div className="navbar-header">
          <div className="d-flex header-div">
            <div className="navbar-brand-box">
              <Link to="/home" className="logo">
                <span className="logo-lg">
                  <img src={logo} alt="logo" width="100px" />
                </span>
              </Link>
            </div>
            <h3 className="logo-text">{t("HOME.LOGO_TEXT")}</h3>
          </div>
          <ProfileMenu />
        </div>
      </header>
    </>
  );
};

const mapStatetoProps = (state) => {
  const { layoutType, leftMenu } = state.Layout;
  return { layoutType, leftMenu };
};

export default connect(mapStatetoProps, {
  toggleLeftmenu,
})(Header);
