import { DOMAINS_CONST } from "./actionTypes";

export const getAllDomains = () => {
  return {
    type: DOMAINS_CONST.GET_ALL_DOMAINS_REQUEST,
    payload: {},
  };
};
export const getAllDomainsSuccess = (response) => {
  return {
    type: DOMAINS_CONST.GET_ALL_DOMAINS_SUCCESS,
    payload: response,
  };
};
export const getAllDomainsFailure = (error) => {
  return {
    type: DOMAINS_CONST.GET_ALL_DOMAINS_FAILURE,
    payload: error,
  };
};

export const getEnrolledDomains = () => {
  return {
    type: DOMAINS_CONST.GET_ENROLLED_DOMAINS_REQUEST,
    payload: {},
  };
};
export const getEnrolledDomainsSuccess = (response) => {
  return {
    type: DOMAINS_CONST.GET_ENROLLED_DOMAINS_SUCCESS,
    payload: response,
  };
};
export const getEnrolledDomainsFailure = (error) => {
  return {
    type: DOMAINS_CONST.GET_ENROLLED_DOMAINS_FAILURE,
    payload: error,
  };
};
