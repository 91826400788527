import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import NestedSelect from "../../components/Common/NestedSelect";

const AddNewResponsibility = ({
  teamMembers,
  allRoles,
  usersByDomain,
  teamMembersHandler,
}) => {
  const [addResponsibility, setAddResponsibility] = useState(false);
  const [newMemberDetails, setNewMemberDetails] = useState({
    userId: "",
    roleId: "",
  });

  const filteredRoles = useCallback(
    (group) => {
      const existingRoles = Object.keys(teamMembers);
      return allRoles.filter(
        (role) => !existingRoles.includes(role.name) && role.group === group
      );
    },
    [allRoles, teamMembers]
  );

  const handleChange = (roleId) => {
    setNewMemberDetails({
      ...newMemberDetails,
      roleId,
    });
  };

  const initializeState = useCallback(() => {
    setAddResponsibility(false);
    setNewMemberDetails({
      userId: "",
      roleId: "",
    });
  }, []);

  const addMemberResponsibility = () => {
    teamMembersHandler("add", newMemberDetails);
    initializeState();
  };

  return (
    <>
      {!addResponsibility ? (
        <Typography
          variant="body2"
          color="secondary"
          className="wrap-text"
          sx={{ cursor: "pointer" }}
          onClick={() => setAddResponsibility(true)}
          data-testid="addnew"
        >
          + Add new responsibility
        </Typography>
      ) : (
        <Stack spacing={1}>
          <NestedSelect
            label="Select Role"
            data-testid="selectRole"
            values={[
              { title: "Core Team", options: filteredRoles("Core Team") },
              {
                title: "Shared Resources",
                options: filteredRoles("Shared Resources"),
              },
            ]}
            handleChange={handleChange}
            selectedValue={newMemberDetails.roleId}
          />
          <TextField
            select
            label="Select Member"
            data-testid="selectMember"
            onChange={(event) =>
              setNewMemberDetails({
                ...newMemberDetails,
                userId: event.target.value,
              })
            }
            value={newMemberDetails.userId}
            size="small"
          >
            {usersByDomain.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.name}
              </MenuItem>
            ))}
          </TextField>
          <Stack
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            spacing={2}
          >
            <Button variant="contained" color="grey" onClick={initializeState}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              disabled={
                newMemberDetails.userId === "" || newMemberDetails.roleId === ""
              }
              onClick={addMemberResponsibility}
            >
              Add
            </Button>
          </Stack>
        </Stack>
      )}
    </>
  );
};

AddNewResponsibility.propTypes = {
  teamMembers: PropTypes.object.isRequired,
  allRoles: PropTypes.array.isRequired,
  usersByDomain: PropTypes.array.isRequired,
  teamMembersHandler: PropTypes.func.isRequired,
};

export default AddNewResponsibility;
