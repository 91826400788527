import React, { useState } from "react";
import classNames from "classnames";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import "./BreadCrumb.scss";

const Breadcrumb = ({ steps, styles, shorten = false }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorLvt, setAnchorLvt] = useState(null);

  const open = Boolean(anchorEl);
  const openLvt = Boolean(anchorLvt);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOpen = (event) => {
    setAnchorLvt(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorLvt(null);
  };

  const renderList = () => {
    return steps.map((step, idx) => {
      if (step.dropDownList) {
        return (
          <div key={idx}>
            <Tooltip title={step.name} placement="bottom">
              <Link
                underline="hover"
                color="inherit"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                data-testid={`breadcrumb-step-${idx}`}
              >
                {step.name}
                <ArrowDropDownIcon id="basic-button" />
              </Link>
            </Tooltip>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              data-testid={`breadcrumb-menu-${idx}`}
            >
              {step.dropDownList.map((eachItem, idx) => (
                <MenuItem
                  key={idx}
                  onClick={handleClose}
                  data-testid={`breadcrumb-menu-item-${idx}`}
                >
                  {step?.href?.length > 0 ? (
                    <div className="breadcrumb-dropdown-overflow">
                      <Tooltip title={eachItem} placement="bottom">
                        <Link
                          className="breadcrumb-link-style"
                          underline="hover"
                          color="inherit"
                          href={step.href[idx]}
                          data-testid={`breadcrumb-menu-link-${idx}`}
                        >
                          {eachItem}
                        </Link>
                      </Tooltip>
                    </div>
                  ) : (
                    <div className="breadcrumb-dropdown-overflow">
                      <Tooltip title={eachItem} placement="bottom">
                        <Link
                          className="breadcrumb-link-style"
                          underline="none"
                          color="gray"
                          data-testid={`breadcrumb-menu-link-${idx}`}
                        >
                          {eachItem}
                        </Link>
                      </Tooltip>
                    </div>
                  )}
                </MenuItem>
              ))}
            </Menu>
          </div>
        );
      } else if (step.LVTDropDownList) {
        return (
          <div key={idx}>
            <Tooltip title={step.name} placement="bottom">
              <Link
                underline="hover"
                color="inherit"
                aria-controls={openLvt ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openLvt ? "true" : undefined}
                onClick={handleOpen}
                data-testid={`breadcrumb-step-${idx}`}
              >
                {step.name}
                <ArrowDropDownIcon id="basic-button" />
              </Link>
            </Tooltip>
            <Menu
              id="basic-menu"
              anchorEl={anchorLvt}
              open={openLvt}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              data-testid={`breadcrumb-menu-${idx}`}
            >
              {step.LVTDropDownList.map((eachItem, idx) => (
                <MenuItem
                  key={idx}
                  onClick={handleClose}
                  data-testid={`breadcrumb-menu-item-${idx}`}
                >
                  {step?.href?.length > 0 ? (
                    <div className="breadcrumb-dropdown-overflow">
                      <Tooltip title={eachItem} placement="bottom">
                        <Link
                          className="breadcrumb-link-style"
                          underline="hover"
                          color="inherit"
                          href={step.href[idx]}
                          data-testid={`breadcrumb-menu-link-${idx}`}
                        >
                          {eachItem}
                        </Link>
                      </Tooltip>
                    </div>
                  ) : (
                    <div className="breadcrumb-dropdown-overflow">
                      <Tooltip title={eachItem} placement="bottom">
                        <Link
                          className="breadcrumb-link-style"
                          underline="none"
                          color="gray"
                          data-testid={`breadcrumb-menu-link-${idx}`}
                        >
                          {eachItem}
                        </Link>
                      </Tooltip>
                    </div>
                  )}
                </MenuItem>
              ))}
            </Menu>
          </div>
        );
      } else {
        if (shorten) {
          if (
            idx <= 1 ||
            steps.length - 1 === idx ||
            steps.length - 2 === idx
          ) {
            return (
              <span key={idx} data-testid={`breadcrumb-step-${idx}`}>
                {step.name}
              </span>
            );
          } else if (idx === 2) {
            return (
              <span key={idx} data-testid={`breadcrumb-step-${idx}`}>
                ...
              </span>
            );
          } else {
            return null;
          }
        } else {
          return (
            <Tooltip title={step.name} placement="bottom" key={idx}>
              <Link
                className={classNames("breadcrumb-link-style", {
                  "breadcrumb-active": step.active,
                })}
                underline="hover"
                href={step.href}
                data-testid={`breadcrumb-step-${idx}`}
              >
                {step.name}
              </Link>
            </Tooltip>
          );
        }
      }
    });
  };
  return (
    <Tooltip
      title={shorten ? steps?.map((step) => step.name).join(" > ") : null}
      placement="bottom-start"
    >
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        style={styles}
        data-testid="breadcrumb-container"
      >
        {renderList()}
      </Breadcrumbs>
    </Tooltip>
  );
};

Breadcrumb.propTypes = {
  steps: PropTypes.array.isRequired,
  styles: PropTypes.object,
  shorten: PropTypes.bool,
};

export default Breadcrumb;
