import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import MultipurposeAccordion from "./MultipurposeAccordion";
import DIMCard from "./DIMCard";
import Carousel from "./Carousel";

const DIMAccordion = ({
  customTitle,
  useCases,
  selectedDomain,
  selectedTeamSpace,
  setDefaultUseCase = () => {},
  useCasePerPage = 1,
  defaultItemIndex,
}) => {
  return (
    <div data-testid="dim-accordion">
      <MultipurposeAccordion
        title={customTitle || "Data Product Interaction Map"}
        data-testid="dim-accordion-title"
      >
        <Row className="accordion-div">
          <Col sm={12}>
            <Carousel
              onChange={(index) => setDefaultUseCase(useCases[index]?.id)}
              itemsPerPage={useCasePerPage}
              defaultSlide={defaultItemIndex}
              data-testid="dim-carousel"
            >
              {useCases.map((eachUseCase, key) => (
                <div className="p-2" key={key} data-testid={`dim-card-${key}`}>
                  <DIMCard
                    useCase={eachUseCase}
                    selectedData={
                      selectedTeamSpace ? selectedTeamSpace : selectedDomain
                    }
                  />
                </div>
              ))}
            </Carousel>
          </Col>
        </Row>
      </MultipurposeAccordion>
    </div>
  );
};

DIMAccordion.propTypes = {
  customTitle: PropTypes.string,
  useCases: PropTypes.array,
  selectedDomain: PropTypes.object,
  selectedTeamSpace: PropTypes.object,
  setDefaultUseCase: PropTypes.func,
  defaultItemIndex: PropTypes.number,
  useCasePerPage: PropTypes.number,
};

export default DIMAccordion;
