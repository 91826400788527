import React from "react";
import { Redirect } from "react-router-dom";
import DataProductsByDomain from "../pages/DomainDataProducts";
//profile related
import MyAccount from "../components/HorizontalLayout/MyAccount";
//wprkspace screens
import LeanValueTree from "../pages/WorkSpaceScreens/LeanValueTree";
import DataProductDetails from "../pages/DataProductDetails";
import DataProductInteractionMap from "../pages/WorkSpaceScreens/DataProductInteractionMap";
import DataDomainDetails from "../pages/DataDomainDetails";
import AdminDetails from "../pages/AdminDetails";
import GlossaryFAQ from "../pages/GlossaryFAQ";
import TeamSpaceDetails from "../pages/TeamSpaceDetails";
import CreateNewTeamSpace from "../pages/CreateNewTeamSpace";
import TeamSpaceForDomain from "../pages/TeamSpaceForDomain";
import CreateNewTeamSpaceAdmin from "../pages/TeamSpaceForDomain/CreateTeamSpaceAdmin";
import Releases from "../pages/Releases";
import versionLogs from "../versionLogs.json";
import PageNotFound from "../pages/PageNotFound";

//lazy loading for optimising performance
// auth routes
const Login = React.lazy(() => import("../pages/Authentication/Login"));
// admin routes
const Home = React.lazy(() => import("../pages/Home"));
//maintenance routes
const Downtime = React.lazy(() =>
  import("../pages/MaintenanceDowntime/Downtime")
);

const userRoutes = [
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/domain-dataproducts",
    component: DataProductsByDomain,
  },
  {
    path: "/leanvaluetree",
    component: LeanValueTree,
  },
  {
    path: "/dataproduct-details",
    component: DataProductDetails,
  },
  {
    path: "/team-space/:name",
    component: TeamSpaceForDomain,
  },
  {
    path: "/create-teamspace/:name",
    component: CreateNewTeamSpaceAdmin,
  },
  {
    path: "/dataproduct-interaction-map",
    component: DataProductInteractionMap,
  },
  {
    path: "/myaccount",
    component: MyAccount,
  },
  {
    path: "/datadomain-details",
    component: DataDomainDetails,
  },
  {
    path: "/admin-details",
    component: AdminDetails,
  },
  {
    path: "/faq",
    component: () => <GlossaryFAQ type="faq" />,
  },
  {
    path: "/glossary",
    component: () => <GlossaryFAQ type="glossary" />,
  },
  {
    path: "/releases",
    component: () => <Releases versionLogs={versionLogs} />,
  },
  {
    path: "/teamspace-details",
    component: TeamSpaceDetails,
  },
  // {
  //   path: "/create--domain-team-space",
  //   component: CreateTeamSpaceForDomain,
  // },
  {
    path: "/create-team-space/:name",
    component: CreateNewTeamSpace,
  },
  {
    path: "/page-not-found",
    component: PageNotFound,
  },
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
  // this route should be at the end of all other routes
  { path: "*", component: () => <Redirect to="/page-not-found" /> },
];

const authRoutes = [{ path: "/login", component: Login }];

const maintenanceRoutes = [{ path: "/maintenance", component: Downtime }];

export { userRoutes, authRoutes, maintenanceRoutes };
