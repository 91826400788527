import { DATAPRODUCT_CONST } from "./actionTypes";

export const getAllDataProducts = (
  limit,
  page,
  selectedOption,
  searchValue,
  pageType
) => {
  return {
    type: DATAPRODUCT_CONST.GET_ALL_DATAPRODUCTS_REQUEST,
    payload: { limit, page, type: selectedOption, searchValue, pageType },
  };
};
export const getAllDataProductsSuccess = (response) => {
  return {
    type: DATAPRODUCT_CONST.GET_ALL_DATAPRODUCTS_SUCCESS,
    payload: response,
  };
};
export const getAllDataProductsFailure = (error) => {
  return {
    type: DATAPRODUCT_CONST.GET_ALL_DATAPRODUCTS_FAILURE,
    payload: error,
  };
};
export const getCollibraDataProductsByDomain = (id) => {
  return {
    type: DATAPRODUCT_CONST.GET_COLLIBRA_DATAPRODUCTS_BY_DOMAIN_REQUEST,
    payload: id,
  };
};
export const getCollibraDataProductsByDomainSuccess = (response) => {
  return {
    type: DATAPRODUCT_CONST.GET_COLLIBRA_DATAPRODUCTS_BY_DOMAIN_SUCCESS,
    payload: response,
  };
};
export const getCollibraDataProductsByDomainFailure = (error) => {
  return {
    type: DATAPRODUCT_CONST.GET_COLLIBRA_DATAPRODUCTS_BY_DOMAIN_FAILURE,
    payload: error,
  };
};

export const getDataProductsByDomain = (
  page,
  domainId,
  limit,
  searchValue,
  selectedFilter,
  excludeSandbox
) => {
  return {
    type: DATAPRODUCT_CONST.GET_DATAPRODUCTS_BY_DOMAIN_REQUEST,
    payload: {
      page,
      domainId,
      limit,
      searchValue: searchValue === "" ? undefined : searchValue,
      type: selectedFilter,
      excludeSandbox: excludeSandbox,
    },
  };
};
export const getDataProductsByDomainSuccess = (response) => {
  return {
    type: DATAPRODUCT_CONST.GET_DATAPRODUCTS_BY_DOMAIN_SUCCESS,
    payload: response,
  };
};
export const getDataProductsByDomainFailure = (error) => {
  return {
    type: DATAPRODUCT_CONST.GET_DATAPRODUCTS_BY_DOMAIN_FAILURE,
    payload: error,
  };
};
export const getMyDataProducts = (id) => {
  return {
    type: DATAPRODUCT_CONST.GET_MY_DATA_PRODUCT_REQUEST,
    payload: id,
  };
};
export const getMyDataProductsSuccess = (response) => {
  return {
    type: DATAPRODUCT_CONST.GET_MY_DATA_PRODUCT_SUCCESS,
    payload: response,
  };
};
export const getMyDataProductsFailure = (error) => {
  return {
    type: DATAPRODUCT_CONST.GET_MY_DATA_PRODUCT_FAILURE,
    payload: error,
  };
};
export const createDataProduct = (formData) => {
  return {
    type: DATAPRODUCT_CONST.CREATE_DATAPRODUCT_REQUEST,
    payload: formData,
  };
};
export const createDataProductSuccess = (response) => {
  return {
    type: DATAPRODUCT_CONST.CREATE_DATAPRODUCT_SUCCESS,
    payload: response,
  };
};
export const createDataProductFailure = (error) => {
  return {
    type: DATAPRODUCT_CONST.CREATE_DATAPRODUCT_FAILURE,
    payload: error,
  };
};

export const updateDataProduct = (formData) => {
  const { dataProductId, notNotify, ...restFormData } = formData;
  return {
    type: DATAPRODUCT_CONST.UPDATE_DATAPRODUCT_REQUEST,
    payload: restFormData,
    dataProductId,
    notNotify,
  };
};
export const updateDataProductSuccess = (response) => {
  return {
    type: DATAPRODUCT_CONST.UPDATE_DATAPRODUCT_SUCCESS,
    payload: response,
  };
};
export const updateDataProductFailure = (error) => {
  return {
    type: DATAPRODUCT_CONST.UPDATE_DATAPRODUCT_FAILURE,
    payload: error,
  };
};
export const getDataProductsByCriteria = (criteria) => {
  return {
    type: DATAPRODUCT_CONST.GET_DATAPRODUCTS_BY_CRITERIA_REQUEST,
    payload: criteria,
  };
};
export const getDataProductsByCriteriaSuccess = (response) => {
  return {
    type: DATAPRODUCT_CONST.GET_DATAPRODUCTS_BY_CRITERIA_SUCCESS,
    payload: response,
  };
};
export const getDataProductsByCriteriaFailure = (error) => {
  return {
    type: DATAPRODUCT_CONST.GET_DATAPRODUCTS_BY_CRITERIA_FAILURE,
    payload: error,
  };
};
export const getDataproductDetails = (id) => {
  return {
    type: DATAPRODUCT_CONST.GET_DATAPRODUCTS_BY_ID_REQUEST,
    payload: id,
  };
};
export const getDataProductsByIdSuccess = (response) => {
  return {
    type: DATAPRODUCT_CONST.GET_DATAPRODUCTS_BY_ID_SUCCESS,
    payload: response,
  };
};
export const getDataProductsByIdFailure = (error) => {
  return {
    type: DATAPRODUCT_CONST.GET_DATAPRODUCTS_BY_ID_FAILURE,
    payload: error,
  };
};

export const getAllFavoritesDataProducts = (excludeSandbox) => {
  return {
    type: DATAPRODUCT_CONST.GET_ALL_FAVORITES_DATAPRODUCT_REQUEST,
    payload: {
      excludeSandbox: excludeSandbox,
    },
  };
};
export const getAllFavoritesDataProductsSuccess = (response) => {
  return {
    type: DATAPRODUCT_CONST.GET_ALL_FAVORITES_DATAPRODUCT_SUCCESS,
    payload: response,
  };
};
export const getAllFavoritesDataProductsFailure = (error) => {
  return {
    type: DATAPRODUCT_CONST.GET_ALL_FAVORITES_DATAPRODUCT_FAILURE,
    payload: error,
  };
};
export const getRequiredDataProducts = (dataProductId) => {
  return {
    type: DATAPRODUCT_CONST.GET_REQUIRED_DATAPRODUCTS_REQUEST,
    payload: dataProductId,
  };
};
export const getRequiredDataProductsSuccess = (response) => {
  return {
    type: DATAPRODUCT_CONST.GET_REQUIRED_DATAPRODUCTS_SUCCESS,
    payload: response,
  };
};
export const getRequiredDataProductsFailure = (error) => {
  return {
    type: DATAPRODUCT_CONST.GET_REQUIRED_DATAPRODUCTS_FAILURE,
    payload: error,
  };
};
