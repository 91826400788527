import React from "react";
import PropTypes from "prop-types";

import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import "./AddDomainComponent.scss";

const AddDomainComponent = ({ setIsAddDomainModalVisible }) => {
  return (
    <div className="mb-3">
      <span className="data-domain-span">Data Domains</span>
      <Button
        data-testid="AddDomainComponent-Button"
        className="add-new-domain-btn"
        startIcon={
          <AddIcon
            data-testid="AddDomainComponent-Icon"
            className="add-new-domain-btn-icon"
          />
        }
        variant={"outlined"}
        color="secondary"
        onClick={() => setIsAddDomainModalVisible(true)}
      >
        Add new Data Domain
      </Button>
    </div>
  );
};

AddDomainComponent.propTypes = {
  setIsAddDomainModalVisible: PropTypes.func,
};

export default AddDomainComponent;
