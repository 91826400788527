import React from "react";
import PropTypes from "prop-types";
import { Input, Label } from "reactstrap";

const DisabledTextField = ({
  input,
  id,
  label,
  type,
  disabledValue,
  meta: { touched, error, warning },
}) => {
  return (
    <div className="mb-3">
      {label && <Label className="login-text-1 mb-1">{label}</Label>}
      <Input
        {...input}
        autoFocus
        className="disabled-field form-control"
        type={type}
        readOnly={true}
        label={label}
        autoComplete="off"
        id={id}
        rows="2"
        cols="40"
        value={disabledValue}
      />
      {touched &&
        (error ? (
          <span className="form-feedback">{error}</span>
        ) : (
          warning && <span>{warning}</span>
        ))}
    </div>
  );
};

DisabledTextField.propTypes = {
  input: PropTypes.object,
  options: PropTypes.any,
  id: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  disabledValue: PropTypes.string,
  meta: PropTypes.object,
};

export default DisabledTextField;
