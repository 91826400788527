import React from "react";
import PropTypes from "prop-types";

import MultipurposeAccordion from "./MultipurposeAccordion";
import LVTCard from "./LVTCard";

const LVTAccordion = ({
  selectedDomain,
  selectedTeamSpace,
  customTitle,
  customHeight,
  customWidth,
}) => {
  return (
    <MultipurposeAccordion title={customTitle || "Lean Value Tree"}>
      <div>
        <LVTCard
          customHeight={customHeight}
          customWidth={customWidth}
          selectedPageData={
            selectedTeamSpace ? selectedTeamSpace : selectedDomain
          }
        />
      </div>
    </MultipurposeAccordion>
  );
};

LVTAccordion.propTypes = {
  customTitle: PropTypes.string,
  selectedDomain: PropTypes.object,
  selectedTeamSpace: PropTypes.object,
  customHeight: PropTypes.number,
  customWidth: PropTypes.number,
};

export default LVTAccordion;
