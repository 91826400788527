import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import InfoCircle from "../../../components/Common/InfoCircle";
import { LVT_ITEM, sections } from "./LVTDiagramSettings";
import NewLVT from "../../../assets/images/NewLVT.svg";

import "./LVTItemMenu.scss";

function LVTItemMenu({ userEditAccess }) {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const onDragStart = (event, nodeType, parentNode) => {
    if (userEditAccess) {
      event.dataTransfer.setData("application/reactflow", nodeType);
      event.dataTransfer.setData("parentNode", parentNode);
      event.dataTransfer.effectAllowed = "move";
    }
  };

  return (
    <div>
      <img
        onClick={() => setIsSubMenuOpen((prev) => !prev)}
        src={NewLVT}
        alt="NewLVTIcon"
        className="lvt-menu-icon mb-2 cursor-pointer"
        draggable={false}
      />
      {isSubMenuOpen ? (
        <div className="lvt-item-sub-menu-container">
          {sections.map((section) => (
            <div className="lvt-item-menu-section" key={section.id}>
              <div
                data-testid="section-box"
                onDragStart={(event) =>
                  onDragStart(event, LVT_ITEM, section.id)
                }
                onDragEnd={() => setIsSubMenuOpen(false)}
                draggable={userEditAccess}
                className={classNames("lvt-item-menu-section-box", section.id, {
                  "cursor-not-allowed": !userEditAccess,
                })}
              />
              <p className="lvt-item-menu-section-label">
                {section.label}{" "}
                <InfoCircle text={section.infoText} orientation="right" />
              </p>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}

LVTItemMenu.propTypes = { userEditAccess: PropTypes.bool };

export default LVTItemMenu;
