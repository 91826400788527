import React, { Suspense, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { changeLayout } from "../../store/actions";
import PropTypes from "prop-types";
import Header from "./Header";
import LazyLoading from "../Common/LazyLoading";

function Layout(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(changeLayout("horizontal"));
  }, [dispatch]);

  return (
    <React.Fragment>
      <Suspense fallback={<LazyLoading />}>
        <div id="layout-wrapper" data-testid="layout-wrapper">
          <Header data-testid="layout-header" />
          <div className="main-content" data-testid="layout-content">
            {props.children}
          </div>
        </div>
      </Suspense>
    </React.Fragment>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
};

export default connect()(withRouter(Layout));
