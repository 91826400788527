import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getDataProductsByCriteria,
  getAllDataProductTypes,
  getEnrolledDomains,
} from "../../store/actions";
import DataProductCard from "../../components/Common/DataProductCard";

import { Row, Col } from "reactstrap";
import ProductTypesFilter from "./ProductTypesFilter";
import { t } from "react-multi-lang";
import { getOrderedItems } from "../../helpers";
import DomainHeader from "../../components/Common/DomainHeader";
import { useHistory } from "react-router-dom";

const DataProducts = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [iIsFiltered, setIsFiltered] = useState(false);
  const [dataProductTypes, setDataProductTypes] = useState([]);
  const [filterLable, setFilterLable] = useState([]);
  const [recentlyViewed, setRecentlyViewed] = useState([]);
  const [showRecentlyViewed, setShowRecentlyViewed] = useState(true);
  const domainId = history.location.search.substring(1);

  /*----------on mounting-----------------*/
  useEffect(() => {
    dispatch(getAllDataProductTypes());
    dispatch(getEnrolledDomains());
  }, [dispatch]);

  /*----------store data-----------------*/
  const nextProps = useSelector((state) => ({
    enrolledDomainsList: state.Domains.enrolledDomainsList,
    allDataProductsByCriteria: state.DataProducts.allDataProductsByCriteria,
    allDataProductTypes:
      state.DataproductTypes.allDataProductTypes?.dataProductTypeList,
    isLoading: state.Loader.isLoading,
  }));

  /*----------to select all filters by default-----------------*/
  const isAddingFilters = useRef(true);
  useEffect(() => {
    if (isAddingFilters.current) {
      isAddingFilters.current = false;
      return;
    }
    if (nextProps.allDataProductTypes?.length) {
      let filters = [];
      nextProps.allDataProductTypes.forEach((item) => {
        filters.push(item.id);
      });
      setDataProductTypes(filters);
    }
  }, [nextProps.allDataProductTypes]);

  /*------to get data based or modified date-------*/
  const isRecentItems = useRef(true);
  useEffect(() => {
    if (isRecentItems.current) {
      isRecentItems.current = false;
      return;
    }
    setRecentlyViewed(
      nextProps.allDataProductsByCriteria?.length
        ? getOrderedItems(
            nextProps.allDataProductsByCriteria,
            "desc",
            "modifiedAt"
          )
        : []
    );
  }, [nextProps.allDataProductsByCriteria]);

  /*------to filter data whenever search criteria changes-------*/
  const isFiltering = useRef(true);
  useEffect(() => {
    if (isFiltering.current) {
      isFiltering.current = false;
      return;
    }
    dispatch(
      getDataProductsByCriteria({
        domainId: [domainId],
        dataProductTypeId: dataProductTypes,
      })
    );
  }, [dispatch, dataProductTypes, domainId]);

  /*----------to handle product type filters----------*/
  const handleProductTypes = (event) => {
    setIsFiltered(true);
    let filters = [];
    let array = [];
    if (nextProps.allDataProductTypes?.length) {
      //to get all product type id's
      if (event.name === "All" && event.checked) {
        nextProps.allDataProductTypes.forEach((item) => {
          filters.push(item.id);
        });
      } else {
        nextProps.allDataProductTypes.forEach((item) => {
          //to add/remove product type id based on existance
          if (event.name === item.name && event.checked) {
            filters.push(item.id);
          } else if (event.name === item.name && !event.checked) {
            const index = dataProductTypes.indexOf(item.id);
            if (index !== -1) {
              dataProductTypes.splice(index, 1);
              dataProductTypes.forEach((typeId) => {
                filters.push(typeId);
              });
            }
          }
        });
      }
      if (dataProductTypes.length && event.name !== "All" && event.checked) {
        //to add previous product type id's
        dataProductTypes.forEach((item) => {
          filters.push(item);
        });
      }
      setDataProductTypes(filters);
    }
    nextProps.allDataProductTypes.forEach((each) => {
      if (filters.includes(each.id)) {
        array.push(each.name);
      }
    });
    setFilterLable(array);
  };
  const handleEditDataProduct = (dataProduct) => {
    history.push(
      `/dataproduct-details?dataproductId=${dataProduct.id}`,
      dataProduct
    );
  };

  return (
    <div className="global-top-1 bg-white">
      <div className="bg-white">
        <div className="mb-3">
          <DomainHeader
            domainName={history.location.state}
            tab="1"
            domainId={domainId}
            enrolledDomainsList={nextProps.enrolledDomainsList}
          />
        </div>
        {nextProps.allDataProductsByCriteria?.length || iIsFiltered ? (
          <div className="container-fluid container-fluid-m mt-4">
            {showRecentlyViewed && (
              <div className="dataproduct-section-1">
                <p className="login-text-1 p-3 mb-0 d-inline-block">
                  {t("HOME.RECENTLY_VIEWED")}
                </p>
                <div className="seeall-section">
                  {/* <button color="primary" className="seeall-btn graph-text ">
                    {t("HOME.SEE_ALL")}
                  </button> */}
                  <i
                    className="fa fa-times cursor-pointer"
                    onClick={() => setShowRecentlyViewed(false)}
                  ></i>
                </div>
                <Row>
                  {!nextProps.isLoading && recentlyViewed?.length ? (
                    recentlyViewed.map((item, index) => {
                      if (index < 4) {
                        return (
                          <Col
                            lg={3}
                            md={3}
                            sm={6}
                            xs={12}
                            key={item.id}
                            // onClick={() => handleEditDataProduct(item)}
                          >
                            <DataProductCard
                              onClickEvent={() => handleEditDataProduct(item)}
                              dataProduct={item}
                              handleDataProduct={handleEditDataProduct}
                            />
                          </Col>
                        );
                      }
                      return null;
                    })
                  ) : (
                    <Col lg={12} md={12} sm={12} xs={12}>
                      {" "}
                      <h3 className="my-5 text-center">
                        {t("WORKSPACE.NO_DATAPRODUCTS")}{" "}
                      </h3>
                    </Col>
                  )}
                </Row>
              </div>
            )}
            <div className="dataproduct-section-2">
              <div className="viewby-section1">
                <div className="viewby-section">
                  <button
                    color="primary"
                    className="dataproduct-text1 view-btn"
                  >
                    {t("HOME.VIEW_BY")}
                  </button>
                  <ProductTypesFilter
                    handleProductTypes={handleProductTypes}
                    allDataProductTypes={nextProps.allDataProductTypes}
                    dataProductTypeFilters={dataProductTypes}
                    filterLable={filterLable}
                  />
                </div>
              </div>
              <Row>
                {recentlyViewed?.length ? (
                  recentlyViewed.map((item) => {
                    return (
                      <Col
                        lg={3}
                        md={3}
                        sm={6}
                        xs={12}
                        key={item.id}
                        // onClick={() => handleEditDataProduct(item)}
                      >
                        <DataProductCard
                          onClickEvent={() => handleEditDataProduct(item)}
                          dataProduct={item}
                          handleDataProduct={handleEditDataProduct}
                        />
                      </Col>
                    );
                  })
                ) : (
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <h3 className="my-5 text-center">
                      {t("WORKSPACE.NO_DATAPRODUCTS")}{" "}
                    </h3>
                  </Col>
                )}
              </Row>
            </div>
          </div>
        ) : !iIsFiltered && !nextProps.isLoading ? (
          <Col lg={12} md={12} sm={12} xs={12}>
            {" "}
            <h3 className="mt-5 text-center">
              {t("WORKSPACE.NO_DATAPRODUCTS")}{" "}
            </h3>
          </Col>
        ) : null}
      </div>
    </div>
  );
};
export default DataProducts;
