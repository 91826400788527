import React from "react";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import AvatarIconLarge from "../../assets/images/avatar-icon-large.png";
import "./AdminList.scss";

const AdminList = ({ adminList }) => {
  return (
    <div className="admin-list" data-testid="AdminList-Container">
      <Grid container spacing={3}>
        {adminList.map((admin, index) => {
          return (
            <Grid
              item
              xs={3}
              key={index}
              data-testid={`AdminList-Item-${index}`}
            >
              <img
                className="admin-list-avatar-icon"
                src={AvatarIconLarge}
                alt="avatar-icon"
                data-testid={`AdminList-Avatar-${index}`}
              />
              <span
                className="admin-list-name"
                data-testid={`AdminList-Name-${index}`}
              >
                {admin.name}
              </span>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

AdminList.propTypes = {
  adminList: PropTypes.array.isRequired,
};

export default AdminList;
