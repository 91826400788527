import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import EditIcon from "@mui/icons-material/Edit";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import SaveIcon from "@mui/icons-material/Save";

import {
  DATAPRODUCT_STATUS_OPTIONS,
  COLLIBRA_DATAPRODUCT_STATUS,
} from "../../constants/DataProductStatusOptions";
import { pageStateOptions } from ".";
import CustomModal from "../../components/Common/CustomModal";
import { GlobalNotificationHandle } from "../../services/NotificationHandler";

const ActionButtons = ({
  pageCurrentState,
  pageStateHandler,
  isOwner,
  isTeamMember,
  isDPDetailsEditing,
  isDPDetailsInReview,
  isInReviewState,
  dataProductStatus,
  dataProductDescription,
  error,
}) => {
  const [confirmationModal, setConfirmationModal] = useState(false);
  const requestConfirmation = useCallback(() => {
    if (isDPDetailsEditing) {
      GlobalNotificationHandle(
        500,
        "This Data Product is currently being edited by another user.",
        false,
        true,
        true
      );
      pageStateHandler(pageStateOptions.DISPLAY);
    } else if (isDPDetailsInReview) {
      GlobalNotificationHandle(
        500,
        "This Data Product is currently being reviewed by another user.",
        false,
        true,
        true
      );
      pageStateHandler(pageStateOptions.DISPLAY);
    } else if (dataProductDescription === "") {
      GlobalNotificationHandle(
        500,
        "Please add description before proceeding",
        false,
        true,
        true
      );
    } else {
      if (dataProductStatus === DATAPRODUCT_STATUS_OPTIONS.IN_DESIGN)
        setConfirmationModal(true);
      else pageStateHandler(pageStateOptions.REVIEW);
    }
  }, [
    dataProductStatus,
    pageStateHandler,
    isDPDetailsEditing,
    isDPDetailsInReview,
  ]);

  const actionConfirmed = useCallback(() => {
    isInReviewState();
    pageStateHandler(pageStateOptions.REVIEW);
    setConfirmationModal(false);
  }, [pageStateHandler, isInReviewState]);

  if (
    dataProductStatus === DATAPRODUCT_STATUS_OPTIONS.IN_DEVELOPMENT ||
    Object.values(COLLIBRA_DATAPRODUCT_STATUS).includes(dataProductStatus) ||
    !isTeamMember
  ) {
    return null;
  }

  return (
    <>
      <CustomModal
        displayState={confirmationModal}
        toggleState={() => setConfirmationModal((prevState) => !prevState)}
        primaryBtn="Proceed"
        secondaryBtn="Cancel"
        primaryBtnHandler={actionConfirmed}
        secondaryHandler={() => setConfirmationModal(false)}
        closeButton={false}
      >
        <h4>
          Once in Review state, Data Product will only be editable by owners.
        </h4>
      </CustomModal>
      {pageCurrentState === pageStateOptions.DISPLAY &&
      [
        DATAPRODUCT_STATUS_OPTIONS.IN_DESIGN,
        DATAPRODUCT_STATUS_OPTIONS.IN_REVIEW,
      ].includes(dataProductStatus) ? (
        <>
          <Button
            variant="outlined"
            color="secondary"
            data-testid="edit"
            onClick={() => pageStateHandler(pageStateOptions.EDIT)}
            disabled={
              dataProductStatus === DATAPRODUCT_STATUS_OPTIONS.IN_REVIEW
            }
            sx={{ display: "inline-block", width: "120px" }}
          >
            <span>
              <EditIcon sx={{ paddingBottom: "0.25rem" }} />
              Edit&nbsp;&nbsp;
            </span>
          </Button>
          {isOwner ? (
            <Button
              variant="outlined"
              color="secondary"
              onClick={requestConfirmation}
              sx={{ display: "inline-block", width: "120px" }}
            >
              <span>
                <StarHalfIcon sx={{ paddingBottom: "0.25rem" }} />
                Review&nbsp;&nbsp;
              </span>
            </Button>
          ) : null}
        </>
      ) : null}
      {[pageStateOptions.EDIT, pageStateOptions.REVIEW].includes(
        pageCurrentState
      ) ? (
        <>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => pageStateHandler(pageStateOptions.SAVE)}
            sx={{ display: "inline-block", width: "120px" }}
            disabled={error === true}
          >
            <SaveIcon sx={{ paddingBottom: "0.25rem" }} />
            Save&nbsp;&nbsp;
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => pageStateHandler(pageStateOptions.CANCEL)}
            sx={{ display: "inline-block", width: "120px" }}
          >
            <CancelOutlinedIcon sx={{ paddingBottom: "0.25rem" }} />
            Cancel&nbsp;&nbsp;
          </Button>
        </>
      ) : null}
    </>
  );
};

ActionButtons.propTypes = {
  pageCurrentState: PropTypes.string.isRequired,
  pageStateHandler: PropTypes.func.isRequired,
  isOwner: PropTypes.bool.isRequired,
  isDPDetailsEditing: PropTypes.bool,
  isDPDetailsInReview: PropTypes.bool,
  isInReviewState: PropTypes.func.isRequired,
  isTeamMember: PropTypes.bool.isRequired,
  dataProductStatus: PropTypes.string,
  dataProductDescription: PropTypes.string,
  error: PropTypes.bool,
};

export default ActionButtons;
