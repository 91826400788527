// saga.js
import { takeEvery, fork, all, call, put } from "redux-saga/effects";
import { TEAMS_CONST } from "./actionTypes";
import {
  getAllTeamsSuccess,
  getAllTeamsFailure,
  createTeamSuccess,
  createTeamFailure,
  updateTeamSuccess,
  updateTeamFailure,
  getAllTeamsByUserSuccess,
  getAllTeamsByUserFailure,
} from "./actions";
import { checkHttpStatus } from "../apiUtils";
import { baseMethod } from "../../services/BaseService";
import { AXIOS_INSTANCE, TEAMS_API } from "../apiUtils/config";
import { t } from "react-multi-lang";

export function* getAllTeams() {
  try {
    const serviceMethod = AXIOS_INSTANCE.get(`${TEAMS_API}/getAll`);
    const apiResponse = yield call(baseMethod, serviceMethod, "", null, null);
    const response = yield call(checkHttpStatus, apiResponse);
    if (
      response.status >= 200 &&
      response.status < 300 &&
      response.data?.data?.isSuccess
    ) {
      const responseData = {
        statusCode: 200,
        response: response.data,
      };
      yield put(getAllTeamsSuccess(responseData));
    } else {
      const responseData = {
        statusCode: response.statusCode || 400,
        data: response.data,
      };
      yield put(getAllTeamsFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getAllTeamsFailure(errorData));
  }
}

export function* createTeam(action) {
  try {
    const serviceMethod = AXIOS_INSTANCE.post(
      `${TEAMS_API}/create`,
      action.payload
    );
    const apiResponse = yield call(
      baseMethod,
      serviceMethod,
      t("SUCCESS_MSGS.CREATE_TEAM"),
      null,
      null
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (
      response.status >= 200 &&
      response.status < 300 &&
      response.data?.data?.isSuccess
    ) {
      const responseData = {
        statusCode: 200,
        response: { domainId: action.payload.domainId, ...response.data?.data },
      };
      yield put(createTeamSuccess(responseData));
    } else {
      const responseData = {
        statusCode: response.statusCode || 400,
        data: response.data,
      };
      yield put(createTeamFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(createTeamFailure(errorData));
  }
}

export function* updateTeam(action) {
  try {
    const serviceMethod = AXIOS_INSTANCE.post(
      `${TEAMS_API}/update`,
      action.payload.user
    );
    const apiResponse = yield call(
      baseMethod,
      serviceMethod,
      t("SUCCESS_MSGS.UPDATE_TEAM"),
      null,
      null
    );
    const response = yield call(checkHttpStatus, apiResponse);
    if (
      response.status >= 200 &&
      response.status < 300 &&
      response.data?.data?.isSuccess
    ) {
      const responseData = {
        statusCode: 200,
        response: response.data,
      };
      yield put(updateTeamSuccess(responseData));
    } else {
      const responseData = {
        statusCode: response.statusCode || 400,
        data: response.data,
      };
      yield put(updateTeamFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(updateTeamFailure(errorData));
  }
}

export function* getAllTeamsByUser() {
  try {
    const serviceMethod = AXIOS_INSTANCE.get(`${TEAMS_API}/getTeamsByUser`);
    const apiResponse = yield call(baseMethod, serviceMethod, "", null, null);
    const response = yield call(checkHttpStatus, apiResponse);
    if (
      response.status >= 200 &&
      response.status < 300 &&
      response.data?.data?.isSuccess
    ) {
      const responseData = {
        statusCode: 200,
        response: response.data,
      };
      yield put(getAllTeamsByUserSuccess(responseData));
    } else {
      const responseData = {
        statusCode: response.statusCode || 400,
        data: response.data,
      };
      yield put(getAllTeamsByUserFailure(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getAllTeamsByUserFailure(errorData));
  }
}

export function* watchGetAllTeams() {
  yield takeEvery(TEAMS_CONST.GET_ALL_TEAMS_REQUEST, getAllTeams);
}

export function* watchCreateTeam() {
  yield takeEvery(TEAMS_CONST.CREATE_TEAM_REQUEST, createTeam);
}

export function* watchUpdateTeam() {
  yield takeEvery(TEAMS_CONST.UPDATE_TEAM_REQUEST, updateTeam);
}

export function* watchGetAllTeamsByUser() {
  yield takeEvery(TEAMS_CONST.GET_TEAMS_BY_USER_REQUEST, getAllTeamsByUser);
}

export function* TeamsSaga() {
  yield all([
    fork(watchGetAllTeams),
    fork(watchCreateTeam),
    fork(watchUpdateTeam),
    fork(watchGetAllTeamsByUser),
  ]);
}

export default TeamsSaga;
