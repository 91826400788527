import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import CustomDataMultiSelectField from "../../components/Common/CustomDataMultiSelectField";

const EditAdmins = ({ copyAdminList, setCopyAdminList, users }) => {
  const [selectableUsers, setSelectableUsers] = useState([]);

  useEffect(() => {
    if (!copyAdminList) {
      return;
    }

    const filteredUserList = users.filter((user) => {
      const isAdmin = copyAdminList.find((admin) => user.id === admin.id);
      return !isAdmin;
    });

    setSelectableUsers(filteredUserList);
  }, [copyAdminList, users]);

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <CustomDataMultiSelectField
            customEditClass="admin-details-card-members-edit"
            customTextClass="admin-details-card-members"
            data={copyAdminList}
            options={selectableUsers}
            isEdit={true}
            setOptions={setCopyAdminList}
            customButtonText="Add new person"
            isAdminPanel={true}
          />
        </Grid>
      </Grid>
    </div>
  );
};

EditAdmins.propTypes = {
  copyAdminList: PropTypes.array,
  setCopyAdminList: PropTypes.func,
  users: PropTypes.array,
};

export default EditAdmins;
