import React from "react";
import PropTypes from "prop-types";

import Tooltip from "@mui/material/Tooltip";

import DomainType from "../../components/Common/DomainType";
import Pagination from "../../components/Common/Pagination";
import EditIcon from "../../assets/images/edit_button.png";

import "./DomainList.scss";
import { Link } from "react-router-dom";

const DomainList = ({
  setEditDomain,
  domainList,
  total,
  setCurrentPage,
  currentPage,
}) => {
  const onEditDomainHandler = (item) => {
    setEditDomain({ editStatus: true, domainDetails: item });
  };

  if (!domainList?.length) {
    return null;
  }
  return (
    <>
      <div
        className="admin-details-domain-card-container"
        data-testid="DomainList-Container"
      >
        {domainList?.map((item, index) => {
          return (
            <div
              className="p-2"
              key={index}
              data-testid={`DomainList-Item-${index}`}
            >
              <div className="admin-details-domain-card">
                <div className="admin-details-domain-info">
                  <Tooltip title={item?.name} placement={"top"}>
                    <span
                      className="admin-details-domain-name"
                      data-testid={`DomainList-Name-${index}`}
                    >
                      {item?.name}
                    </span>
                  </Tooltip>
                  {item?.isCollibra ? (
                    <Link
                      to={`/team-space/${encodeURIComponent(
                        item?.name
                      )}?domainId=${item.id}`}
                      className="admin-details-team-spaces-text"
                      data-testid={`DomainList-TeamSpaceLink-${index}`}
                    >
                      Team Spaces
                    </Link>
                  ) : (
                    <span
                      className="admin-details-no-team-spaces"
                      data-testid={`DomainList-NoTeamSpace-${index}`}
                    >
                      &nbsp;
                    </span>
                  )}
                </div>
                <div className="admin-details-domain-status-and-edit">
                  <span
                    className="admin-details-domain-type"
                    data-testid={`DomainList-Type-${index}`}
                  >
                    <DomainType
                      domainType={item?.domainType}
                      tooltipPlacement={"top"}
                    />
                  </span>
                  <img
                    className="admin-details-domain-edit-icon"
                    src={EditIcon}
                    alt="edit-icon"
                    data-testid={`DomainList-EditIcon-${index}`}
                    onClick={() => onEditDomainHandler(item)}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Pagination
        className="admin-details-domain-pagination"
        totalItems={total}
        itemsPerPage={12}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage - 1}
        data-testid="DomainList-Pagination"
      />
    </>
  );
};

DomainList.propTypes = {
  domainList: PropTypes.array.isRequired,
  setEditDomain: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  currentPage: PropTypes.number,
};

export default DomainList;
