import React from "react";
import PropTypes from "prop-types";

const FixMenu = ({ items, userEditAccess = true }) => {
  const onDragStart = (event, nodeType) => {
    if (userEditAccess) {
      event.dataTransfer.setData("application/reactflow", nodeType);
      event.dataTransfer.effectAllowed = "move";
    }
  };

  return (
    <div className="d-flex lvt-menu flex-column align-items-center">
      {items.map((item, idx) =>
        item.body ? (
          <div key={idx} data-testid={`fixmenu-body-${idx}`}>
            {item.body}
          </div>
        ) : (
          <div
            key={idx}
            data-testid={`fixmenu-icon-${idx}`}
            onDragStart={(event) => onDragStart(event, item.eventName)}
            draggable={userEditAccess}
            style={{ cursor: userEditAccess ? "grab" : "not-allowed" }}
          >
            {item.icon}
          </div>
        )
      )}
    </div>
  );
};

FixMenu.propTypes = {
  items: PropTypes.array,
  userEditAccess: PropTypes.bool,
};

export default FixMenu;
