import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { MdExpandMore } from "react-icons/md";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import domainDiagram from "../../assets/images/domain-diagram.png";
import dpDiagram from "../../assets/images/dp-diagram.png";
import lvtDiagram from "../../assets/images/LVT-Diagram.png";

import "./FAQ.scss";

function FAQ() {
  const faqItems = useMemo(
    () => [
      {
        title: "What is Data Mesh?",
        body: (
          <div>
            Data mesh is a data platform architecture that allows end-users to
            easily access important data without transporting it to a data lake
            or data warehouse and without needing expert data teams to
            intervene. Data mesh focuses on decentralization, distributing data
            ownership among teams who can manage data as a product independently
            and securely — reducing bottlenecks and silos in data management and
            enabling scalability without sacrificing data governance. Data mesh
            makes your data discoverable, widely accessible, secure, and
            interoperable — giving you better decision-making power and faster
            time to value. The four essential pillars of data mesh are:
            <ol>
              <li>
                Unite diverse data sources — giving your organization a single
                source of truth, despite scattered data assets in different
                systems that may not communicate with each other
              </li>
              <li>Safeguard through data governance</li>
              <li>
                Achieve the highest data quality — regardless of your volume of
                big data, knowing that demand is increasing for instantaneous
                data access and response times
              </li>
              <li>
                Enable self-service without the need for data team intervention
                — promoting effective data management and collaboration between
                data engineers, data scientists, and data consumers alike.
              </li>
            </ol>
          </div>
        ),
      },
      {
        title: "Why is Data Mesh needed?",
        body: (
          <div>
            Our current way of working, which involves siloed and
            hyper-specialized ownership, leads to value streams being spread
            across multiple teams.
            <br />
            This often slows innovation and limits data sharing due to
            misaligned incentives. On a broader scale, this constrains our
            ability to link data across domains to form rich cross-functional
            insights.
            <br /> Data meshes provide a solution to the shortcomings of data
            lakes by allowing greater autonomy and flexibility for data owners,
            facilitating greater data experimentation and innovation while
            lessening the burden on data teams to field the needs of every data
            consumer through a single pipeline.
          </div>
        ),
      },
      {
        title: "What I need to start with Data Mesh Design Studio?",
        body: (
          <div>
            To get started please contact us to set up your first data domain.
            After we create or assign you to the data domain, you will be able
            to create Lean Value Tree, create Data Products and Data Product
            Interaction Maps.
            <div>
              <div className="faq-step-list-item">
                <div>
                  <p className="faq-step-list-item-step-number">1</p>
                </div>
                <div className="faq-step-list-item-title">
                  Data Domain Assigned
                </div>
                <div className="faq-step-list-item-description">
                  Data Domain - A data domain is a structural set of
                  definitions, data and data products led by a dedicated team
                  that encompasses all types of data within an area of
                  importance and value to the business.
                </div>
              </div>
              <div className="faq-step-list-item">
                <div>
                  <p className="faq-step-list-item-step-number">2</p>
                </div>
                <div className="faq-step-list-item-title">
                  Data Product value realization understood by Lean Value Tree
                </div>
                <div className="faq-step-list-item-description">
                  Lean Value Tree - is a conceptual tool to facilitate, share,
                  and drive the company&apos;s strategy and vision across all
                  levels and teams of an organization. It helps us to define and
                  realize the value that we want to create by delivering data
                  products. Go to Lean Value Tree to add a new use case to be
                  able to design a new Data Product Interaction Map
                </div>
              </div>
              <div className="faq-step-list-item">
                <div>
                  <p className="faq-step-list-item-step-number">3</p>
                </div>
                <div className="faq-step-list-item-title">Use Case defined</div>
                <div className="faq-step-list-item-description">
                  Defining use cases is necessary to create a Data Products.
                  Without use case we won&apos;t know what porpoise it will
                  serve.
                </div>
              </div>
              <div className="faq-step-list-item">
                <div>
                  <p className="faq-step-list-item-step-number">4</p>
                </div>
                <div className="faq-step-list-item-title">
                  Data Product discovered by Data Product Interaction Map
                </div>
                <div className="faq-step-list-item-description">
                  Data Product Interaction Map - Go to one of the Data Product
                  Interaction Maps to design a new Data Product
                </div>
              </div>
              <div className="faq-step-list-item">
                <div>
                  <p className="faq-step-list-item-step-number">5</p>
                </div>
                <div className="faq-step-list-item-title">
                  Data Product reviewed and initiated
                </div>
                <div className="faq-step-list-item-description">
                  Last step is reviewing the Data Product. It needs to be done
                  by the Data Product Owner to make sure that everything is in
                  place before initiation of the product.
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        title: "What are Data Domains?",
        body: (
          <div>
            A data domain is a structural set of definitions, data and data
            products led by a dedicated team that encompasses all types of data
            within an area of importance and value to the business. Domains are
            teams tied to value streams that are responsible for identifying
            units of data based on a specific set of objectives and turning them
            into data products that can be shared and consumed across the
            enterprise and/or with external collaborators.
            <div className="faq-image-container">
              <img src={domainDiagram} alt="domain diagram" />
            </div>
          </div>
        ),
      },
      {
        title: "What are Data Products?",
        body: (
          <div>
            A data product is a life-cycle managed combination of technology and
            data that delivers value in specific business contexts. Data product
            guidelines are provided by the RDI Architecture Forum.
            <div className="faq-image-container">
              <img src={dpDiagram} alt="data product diagram" />
            </div>
          </div>
        ),
      },
      {
        title: "What is Lean Value Tree?",
        body: (
          <div>
            The LVT tool is used to capture and share your organizational vision
            and strategy. Everything in the tree stems from the executive
            business vision and is framed in terms of outcomes, so the value
            those activities will provide to the organization is clear.
            <br />
            The LVT, embraces the value-driven practices of lean development, as
            all work stems from the vision and clearly links to goals. <br />
            LVT provides a line-of-sight to the strategic intent of the
            organization and, therefore, becomes critical input to decision
            making through the entire value stream. When done well, this tool
            closes the traditional gap between strategic plans that are well
            understood by the executives and the decision making of the people
            in each value stream that actually steers the business on a
            day-to-day basis.
            <div className="faq-image-container">
              <img src={lvtDiagram} alt="data product diagram 2" />
            </div>
          </div>
        ),
      },
      {
        title: "What is Data Product Interaction Map?",
        body: (
          <div>
            Data Product Interaction Map - is a conceptual tool to identify and
            design your Data Products that you need to achieve your use case.
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      {faqItems.map(({ title, body }, idx) => (
        <Accordion className="faq-item" key={idx}>
          <AccordionSummary
            className="faq-item-title"
            expandIcon={<MdExpandMore />}
          >
            {title}
          </AccordionSummary>
          <AccordionDetails className="faq-item-body">{body}</AccordionDetails>
        </Accordion>
      ))}
      <p className="faq-footer mt-4">
        Want to know more?{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="faq-link"
          href="https://sites.google.com/gene.com/rdi/home"
          alt="RDI Site"
        >
          Go to the RDI site!
        </a>
      </p>
    </>
  );
}

FAQ.propTypes = { searchWord: PropTypes.string };

export default FAQ;
