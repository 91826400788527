import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

const QontoConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-48% + 7px)",
    right: "calc(44% + 7px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#7d0096",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#7d0096",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#eaeaf0",
    borderTopWidth: 2,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "#7d0096",
  },
  "& .QontoStepIcon-activeIcon": {
    color: "white",
    zIndex: 1,
    fontSize: 18,
    width: 25,
    height: 25,
    borderRadius: "50%",
    backgroundColor: "#7d0096",
    "& .activeIconStyle": {
      padding: "3px 0px 0px 8px",
      position: "absolute",
      fontSize: "13px",
      fontWeight: 400,
    },
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className, activeStep } = props;
  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <div className="QontoStepIcon-completedIcon" />
      ) : active ? (
        <div className="QontoStepIcon-activeIcon">
          <span className="activeIconStyle">{activeStep + 1}</span>
        </div>
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

const StepperHorizontal = ({ activeStep }) => {
  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<QontoConnector />}
    >
      {[...Array(4)].map((label, index) => (
        <Step key={index}>
          <StepLabel
            StepIconComponent={QontoStepIcon}
            StepIconProps={{ activeStep }}
          ></StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  className: PropTypes.any,
  activeStep: PropTypes.number,
};

StepperHorizontal.propTypes = {
  activeStep: PropTypes.number,
};

export default StepperHorizontal;
