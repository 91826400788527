import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import Select from "react-select";
import { Input } from "reactstrap";
import PropTypes from "prop-types";
import { Col } from "reactstrap";

import Skeleton from "@mui/material/Skeleton";

import { AXIOS_INSTANCE, COLLIBRA_API } from "../../store/apiUtils/config";
import { GlobalNotificationHandle } from "../../services/NotificationHandler";
import useDebounce from "../../helpers/CustomHooks/useDebounce";
import Alert from "../../components/Common/Alert";
import CollibraCommunityCard from "../../components/Common/CollibraCommunityCard";
import CustomModal from "../../components/Common/CustomModal";
import Pagination from "../../components/Common/Pagination";

import "./CollibraCommunityModal.scss";

const COMMUNITIES_PER_PAGE = 4;

const CollibraCommunityModal = ({
  isVisible,
  setIsVisible,
  dataProductDetails,
  onSubmit,
}) => {
  const options = [
    {
      value: "recommended",
      label: "Recommended",
      domainName: dataProductDetails?.domainName,
    },
    { value: "alphabetical", label: "Alphabetical", domainName: "" },
  ];
  const [communities, setCommunities] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchWord, setSearchWord] = useState("");
  const [itemCount, setItemCount] = useState(0);
  const [selectedCommunityId, setSelectedCommunityId] = useState();
  const [orderBy, setOrderBy] = useState(options[0]);
  const [isLoading, setIsLoading] = useState(false);

  const debouncedSearch = useDebounce(searchWord, 1000);

  useEffect(() => {
    if (!isVisible) {
      return;
    }
    const offset = currentPage - 1;
    const SearchValue = debouncedSearch ? debouncedSearch : "";
    fetchCommunities(offset, orderBy.value, orderBy.domainName, SearchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, debouncedSearch, isVisible]);

  const fetchCommunities = async (offset, orderBy, domainName, searchVal) => {
    try {
      const dataDomainName = domainName ? `&dataDomainName=${domainName}` : "";
      const search = searchVal ? `&search=${searchVal}` : "";
      setCommunities([]);
      setIsLoading(true);
      const { data } = await AXIOS_INSTANCE.get(
        `${COLLIBRA_API}/getCommunities?offset=${offset}&orderBy=${orderBy}${dataDomainName}${search}`
      );
      setIsLoading(false);
      setCommunities(data.data.data);
      setItemCount(data.data.totalCommunities);
    } catch (e) {
      if (e.response.data === "Please provide a valid dataDomainName")
        customGlobalNotify(
          "Cannot proceed to next step as your Data Domain is not associated with a Data Domain within the Collibra Data catalog"
        );
      else
        GlobalNotificationHandle(
          e.response.status,
          e.response.data,
          false,
          true,
          true
        );
    }
  };

  const handleOptionChange = (option) => {
    const offset = currentPage - 1;
    setOrderBy(option);
    if (option.label === "Recommended") {
      fetchCommunities(offset, option.value, option.domainName, searchWord);
    } else {
      fetchCommunities(offset, option.value, "", searchWord);
    }
  };

  const cardRadioBtnClick = (communityId) => {
    setSelectedCommunityId(communityId);
  };

  const handlePrimaryClick = async () => {
    if (selectedCommunityId) {
      dataProductDetails.selectedCommunityId = selectedCommunityId;
      if (
        !dataProductDetails.valueStatement ||
        !dataProductDetails.description
      ) {
        customGlobalNotify(
          !dataProductDetails.valueStatement
            ? "Please save the value statement before proceeding"
            : "Please save the description before proceeding"
        );
      } else {
        try {
          await onSubmit(dataProductDetails);
          setIsVisible(false);
        } catch (e) {
          setIsVisible(true);
        }
      }
    } else {
      customGlobalNotify("Please choose a community");
    }
  };

  const customGlobalNotify = (mesg) => {
    GlobalNotificationHandle(299, mesg, false, true, true);
  };

  return (
    <div>
      <CustomModal
        width={80}
        displayState={isVisible}
        toggleState={() => setIsVisible((prevState) => !prevState)}
        primaryBtn="Submit for Implementation"
        secondaryBtn="Cancel"
        primaryBtnHandler={handlePrimaryClick}
        secondaryHandler={() => setIsVisible(false)}
      >
        <>
          <Alert>
            Select your Collibra Community which will be used for your Data
            Product implementation
          </Alert>
          <div className="collibra-community-header">
            <div className="collibra-community-search">
              <FiSearch className="collibra-community-search-icon" />
              <Input
                onChange={(e) => setSearchWord(e.target.value)}
                value={searchWord}
                className="collibra-community-search-input"
                placeholder="Search for Collibra Community Name"
              />
            </div>
            <div className="collibra-community-filter">
              Order by:
              <Select
                className="collibra-community-filter-input"
                options={options}
                onChange={handleOptionChange}
                defaultValue={options[0]}
              />
            </div>
          </div>
          <div className="collibra-community-body" data-testid="progressbar">
            {isLoading ? (
              [...Array(4)].map((e, i) => (
                <Col sx={6} className="collibra-community-card" key={i}>
                  {[...Array(4)].map((e, idx) => (
                    <Skeleton
                      sx={{ mb: 1 }}
                      key={idx}
                      variant="rectangular"
                      width="auto"
                      height={24}
                    />
                  ))}
                </Col>
              ))
            ) : communities.length > 0 ? (
              communities.map((item) => (
                <div
                  className="collibra-community-body-item"
                  key={item.collibraCommunityId}
                >
                  <CollibraCommunityCard
                    details={item}
                    onClick={cardRadioBtnClick}
                    isSelected={
                      selectedCommunityId === item.collibraCommunityId
                    }
                  />
                </div>
              ))
            ) : (
              <h5>No community found</h5>
            )}
          </div>
          <div className="collibra-community-footer">
            {itemCount > 0 && (
              <>
                <Pagination
                  itemsPerPage={COMMUNITIES_PER_PAGE}
                  setCurrentPage={setCurrentPage}
                  totalItems={itemCount}
                  className="collibra-community-pagination"
                />
                <span>
                  {itemCount > 4 ? 4 : itemCount} of {itemCount} items displayed
                </span>
              </>
            )}
          </div>
        </>
      </CustomModal>
    </div>
  );
};

CollibraCommunityModal.propTypes = {
  isVisible: PropTypes.bool,
  setIsVisible: PropTypes.func,
  dataProductDetails: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default CollibraCommunityModal;
