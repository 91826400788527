export const DATAPRODUCT_CONST = {
  GET_ALL_DATAPRODUCTS_REQUEST: "GET_ALL_DATAPRODUCTS_REQUEST",
  GET_ALL_DATAPRODUCTS_SUCCESS: "GET_ALL_DATAPRODUCTS_SUCCESS",
  GET_ALL_DATAPRODUCTS_FAILURE: "GET_ALL_DATAPRODUCTS_FAILURE",

  GET_DATAPRODUCTS_BY_DOMAIN_REQUEST: "GET_DATAPRODUCTS_BY_DOMAIN_REQUEST",
  GET_DATAPRODUCTS_BY_DOMAIN_SUCCESS: "GET_DATAPRODUCTS_BY_DOMAIN_SUCCESS",
  GET_DATAPRODUCTS_BY_DOMAIN_FAILURE: "GET_DATAPRODUCTS_BY_DOMAIN_FAILURE",

  GET_COLLIBRA_DATAPRODUCTS_BY_DOMAIN_REQUEST:
    "GET_COLLIBRA_DATAPRODUCTS_BY_DOMAIN_REQUEST",
  GET_COLLIBRA_DATAPRODUCTS_BY_DOMAIN_SUCCESS:
    "GET_COLLIBRA_DATAPRODUCTS_BY_DOMAIN_SUCCESS",
  GET_COLLIBRA_DATAPRODUCTS_BY_DOMAIN_FAILURE:
    "GET_COLLIBRA_DATAPRODUCTS_BY_DOMAIN_FAILURE",

  GET_MY_DATA_PRODUCT_REQUEST: "GET_MY_DATA_PRODUCT_REQUEST",
  GET_MY_DATA_PRODUCT_SUCCESS: "GET_MY_DATA_PRODUCT_SUCCESS",
  GET_MY_DATA_PRODUCT_FAILURE: "GET_MY_DATA_PRODUCT_FAILURE",

  CREATE_DATAPRODUCT_REQUEST: "CREATE_DATAPRODUCT_REQUEST",
  CREATE_DATAPRODUCT_SUCCESS: "CREATE_DATAPRODUCT_SUCCESS",
  CREATE_DATAPRODUCT_FAILURE: "CREATE_DATAPRODUCT_FAILURE",

  UPDATE_DATAPRODUCT_REQUEST: "UPDATE_DATAPRODUCT_REQUEST",
  UPDATE_DATAPRODUCT_SUCCESS: "UPDATE_DATAPRODUCT_SUCCESS",
  UPDATE_DATAPRODUCT_FAILURE: "UPDATE_DATAPRODUCT_FAILURE",

  GET_DATAPRODUCTS_BY_CRITERIA_REQUEST: "GET_DATAPRODUCTS_BY_CRITERIA_REQUEST",
  GET_DATAPRODUCTS_BY_CRITERIA_SUCCESS: "GET_DATAPRODUCTS_BY_CRITERIA_SUCCESS",
  GET_DATAPRODUCTS_BY_CRITERIA_FAILURE: "GET_DATAPRODUCTS_BY_CRITERIA_FAILURE",

  GET_DATAPRODUCTS_BY_ID_REQUEST: "GET_DATAPRODUCTS_BY_ID_REQUEST",
  GET_DATAPRODUCTS_BY_ID_SUCCESS: "GET_DATAPRODUCTS_BY_ID_SUCCESS",
  GET_DATAPRODUCTS_BY_ID_FAILURE: "GET_DATAPRODUCTS_BY_ID_FAILURE",

  PUBLISH_DATAPRODUCT_REQUEST: "PUBLISH_DATAPRODUCT_REQUEST",
  PUBLISH_DATAPRODUCT_SUCCESS: "PUBLISH_DATAPRODUCT_SUCCESS",
  PUBLISH_DATAPRODUCT_FAILURE: "PUBLISH_DATAPRODUCT_FAILURE",

  UNPUBLISH_DATAPRODUCT_REQUEST: "UNPUBLISH_DATAPRODUCT_REQUEST",
  UNPUBLISH_DATAPRODUCT_SUCCESS: "UNPUBLISH_DATAPRODUCT_SUCCESS",
  UNPUBLISH_DATAPRODUCT_FAILURE: "UNPUBLISH_DATAPRODUCT_FAILURE",

  GET_ALL_FAVORITES_DATAPRODUCT_REQUEST:
    "GET_ALL_FAVORITES_DATAPRODUCT_REQUEST",
  GET_ALL_FAVORITES_DATAPRODUCT_SUCCESS:
    "GET_ALL_FAVORITES_DATAPRODUCT_SUCCESS",
  GET_ALL_FAVORITES_DATAPRODUCT_FAILURE:
    "GET_ALL_FAVORITES_DATAPRODUCT_FAILURE",

  GET_REQUIRED_DATAPRODUCTS_REQUEST: "GET_REQUIRED_DATAPRODUCTS_REQUEST",
  GET_REQUIRED_DATAPRODUCTS_SUCCESS: "GET_REQUIRED_DATAPRODUCTS_SUCCESS",
  GET_REQUIRED_DATAPRODUCTS_FAILURE: "GET_REQUIRED_DATAPRODUCTS_FAILURE",
};
