import { ROLES_CONST } from "./actionTypes";

export const getAllRoles = () => {
  return {
    type: ROLES_CONST.GET_ALL_ROLES_REQUEST,
    payload: {},
  };
};
export const getAllRolesSuccess = (response) => {
  return {
    type: ROLES_CONST.GET_ALL_ROLES_SUCCESS,
    payload: response,
  };
};
export const getAllRolesFailure = (error) => {
  return {
    type: ROLES_CONST.GET_ALL_ROLES_FAILURE,
    payload: error,
  };
};
