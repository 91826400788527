import React, { useEffect } from "react";
import { FaUserAlt } from "react-icons/fa";
import { Table, Row, Col } from "reactstrap";
import { getUserProfile } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { t } from "react-multi-lang";
import { useHistory } from "react-router-dom";

const MyAccount = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  const nextProps = useSelector((state) => ({
    myProfile:
      state.Users.getUserProfile?.isSuccess &&
      state.Users.getUserProfile.profileData,
  }));

  const handleClick = (team) => {
    history.push(`/dataproduct-details?dataproductId=${team.dataProductId}`);
  };

  return (
    <div
      className="global-top container-fluid"
      data-testid="myaccount-container"
    >
      <div className="text-align-center mt-4">
        <div className="d-block">
          <FaUserAlt className="user-icon-1" />
        </div>
        <span
          className="d-block mt-3 profile-name w-100"
          data-testid="profile-name"
        >
          {nextProps.myProfile && nextProps.myProfile.name
            ? nextProps.myProfile.name
            : ""}
        </span>
      </div>
      <div className="plus-dp-postion mt-5">
        <h3 className="label-section-profile">
          {t("MYPROFILE.PROFILE_DETAILS")}
        </h3>
        <Row className="profile-row">
          <Col className="p-3">
            <Table className="profile-table mb-0">
              <tbody>
                <tr>
                  <td className="profile-td1">{t("CREATE.NAME")}</td>
                  <td className="profile-td2">
                    <span className="pr-4">:</span>
                    <span data-testid="profile-name-value">
                      {nextProps.myProfile && nextProps.myProfile.name
                        ? nextProps.myProfile.name
                        : ""}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="profile-td1">{t("LOGIN.EMAIL")}</td>
                  <td className="profile-td2">
                    <span className="pr-4">:</span>
                    <span data-testid="profile-email-value">
                      {nextProps.myProfile && nextProps.myProfile.email
                        ? nextProps.myProfile.email
                        : ""}
                    </span>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <div className="plus-dp-postion mt-5">
        <h3 className="label-section-profile-1">{t("MYPROFILE.TEAM")}</h3>
        <Row className="profile-row">
          <Col className="p-3">
            <Table className="profile-table mb-0">
              <tbody data-testid="data-product-list">
                {nextProps.myProfile?.dataProductList?.map((team) =>
                  team.dataProductName && team.dataProductId ? (
                    <tr key={team.dataProductId}>
                      <td
                        onClick={() => handleClick(team)}
                        className="profile-td2 profile-clickable"
                        data-testid={`data-product-${team.dataProductId}`}
                      >
                        {team.dataProductName}
                      </td>
                    </tr>
                  ) : null
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default MyAccount;
