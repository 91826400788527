import React from "react";
import CustomModal from "./CustomModal";
import PropTypes from "prop-types";

import "./DataProductListModal.scss";

const DataProductListModal = ({ isOpen, onClose, dpList }) => {
  if (!isOpen) return null;
  return (
    <CustomModal
      data-testid="data-product-list-modal"
      displayState={isOpen}
      toggleState={onClose}
      title={`${dpList[0].username} is assigned to the following data product(s):`}
      closeButtonClass={"close-button-style"}
    >
      <div>
        {dpList && (
          <ul
            data-testid="data-product-list"
            aria-labelledby="data-product-list"
            style={{ columnCount: 2 }}
          >
            {dpList.map((item, index) => (
              <li key={index} style={{ fontSize: "16px" }}>
                <a href={`/dataproduct-details?dataproductId=${item.id}`}>
                  {item.dpName}
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
    </CustomModal>
  );
};

DataProductListModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  dpList: PropTypes.array,
};

export default DataProductListModal;
