import React, { useEffect, useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import MultipurposeAccordion from "../../components/Common/MultipurposeAccordion";
import { GlobalNotificationHandle } from "../../services/NotificationHandler";
import DataProductCard from "../../components/Common/DataProductCard";
import NoDataProducts from "../DataDomainDetails/NoDataProducts";
import MyDataProductSettings from "../../components/Common/MyDataProductSettings";
import Pagination from "../DataDomainDetails/Pagination";
import useDebounce from "../../helpers/CustomHooks/useDebounce";
import useMatchMedia from "../../helpers/CustomHooks/useMatchMedia";
import { socket } from "../../services/Socket";

function DataProductAccordion({ isExtended = false, teamSpaceId }) {
  const [dataProductsByDomain, setDataProductsByDomain] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [total, setTotal] = useState(0);
  const history = useHistory();

  const debouncedSearch = useDebounce(search, 1000);
  const isSmallerScreen = useMatchMedia(1367);

  const dataProductPerPage = useMemo(
    () => (isSmallerScreen ? 4 : 6),
    [isSmallerScreen]
  );

  const fetchData = useCallback(() => {
    if (teamSpaceId) {
      socket.emit("getDPsByTeamSpaceId", {
        page: currentPage,
        teamSpaceId,
        limit: dataProductPerPage,
        searchValue: debouncedSearch || undefined,
        type: selectedFilter,
        excludeSandbox: true,
      });

      socket.on("recieveDPByteamSpaceId", (data) => {
        setDataProductsByDomain(data.dataProductList);
        setTotal(data.total);
      });

      // Cleanup function to remove the listener
      return () => {
        socket.off("recieveDPByteamSpaceId");
      };
    }
  }, [
    currentPage,
    dataProductPerPage,
    teamSpaceId,
    debouncedSearch,
    selectedFilter,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const maxPage = Math.max(Math.floor((total - 1) / dataProductPerPage), 0);
    if (currentPage > maxPage) {
      setCurrentPage(maxPage);
    }
  }, [dataProductPerPage, total, currentPage]);

  const getExtended = useCallback(async () => {
    try {
      if (dataProductsByDomain.length > 0) {
        return;
      }
      setDataProductsByDomain(dataProductsByDomain);
    } catch (e) {
      GlobalNotificationHandle(400, e.toString(), false, false, true);
    }
  }, [dataProductsByDomain]);

  useEffect(() => {
    if (!isExtended) {
      getExtended();
    }
  }, [getExtended, isExtended]);

  const handleEditDataProduct = (dataProduct) => {
    history.push(
      `/dataproduct-details?dataproductId=${dataProduct.id}`,
      dataProduct
    );
  };

  return (
    <MultipurposeAccordion title="Data Products">
      <>
        <MyDataProductSettings
          list={dataProductsByDomain || []}
          setSearchValueExternal={setSearch}
          setSelectedValueExternal={setSelectedFilter}
        />
        {dataProductsByDomain.length ? (
          <>
            <div className="dataProductGrid">
              {dataProductsByDomain.map((item, id) => (
                <DataProductCard
                  key={id}
                  onClickEvent={() => handleEditDataProduct(item)}
                  dataProduct={item}
                  handleDataProduct={handleEditDataProduct}
                />
              ))}
            </div>
            <div className="pagination-center">
              <Pagination
                totalItems={total}
                itemsPerPage={dataProductPerPage}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                selectedFilter={selectedFilter}
                searchValue={search}
              />
            </div>
          </>
        ) : (
          <div>
            <NoDataProducts />
          </div>
        )}
      </>
    </MultipurposeAccordion>
  );
}

DataProductAccordion.propTypes = {
  selectedItems: PropTypes.array,
  isExtended: PropTypes.bool,
  teamSpaceId: PropTypes.string,
  searchValue: PropTypes.string,
};

export default DataProductAccordion;
