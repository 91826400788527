import { DATAPRODUCT_CONST } from "./actionTypes";

const initialState = {
  loading: false,
  addDataProductLinkData: null,
};
const DataProducts = (state, action) => {
  if (typeof state == "undefined") {
    state = initialState;
  }

  switch (action.type) {
    case DATAPRODUCT_CONST.GET_ALL_DATAPRODUCTS_REQUEST:
      return {
        ...state,
        isDataProductsLoading: true,
        allDataProducts: [],
      };
    case DATAPRODUCT_CONST.GET_ALL_DATAPRODUCTS_SUCCESS:
      return {
        ...state,
        isDataProductsLoading: false,
        allDataProducts: action.payload?.response?.dataProductList,
        totalDataProducts: action.payload?.response?.total,
        combinedDataProducts: action.payload?.response?.combinedDataProducts,
      };
    case DATAPRODUCT_CONST.GET_ALL_DATAPRODUCTS_FAILURE:
      return {
        ...state,
        isDataProductsLoading: false,
        allDataProducts: [],
      };
    case DATAPRODUCT_CONST.GET_DATAPRODUCTS_BY_DOMAIN_REQUEST:
    case DATAPRODUCT_CONST.GET_DATAPRODUCTS_BY_DOMAIN_FAILURE:
      return {
        ...state,
      };
    case DATAPRODUCT_CONST.GET_DATAPRODUCTS_BY_DOMAIN_SUCCESS:
      return {
        ...state,
        dataProductsByDomain: action.payload?.response?.data?.dataProductList,
        totalDataProductsByDomain: action.payload?.response?.data?.total,
      };
    case DATAPRODUCT_CONST.GET_COLLIBRA_DATAPRODUCTS_BY_DOMAIN_REQUEST:
    case DATAPRODUCT_CONST.GET_COLLIBRA_DATAPRODUCTS_BY_DOMAIN_FAILURE:
      return {
        ...state,
        dataProductsByDomain: {},
      };
    case DATAPRODUCT_CONST.GET_COLLIBRA_DATAPRODUCTS_BY_DOMAIN_SUCCESS:
      return {
        ...state,
        collibraDataProductsByDomain: action.payload?.response?.data,
      };
    case DATAPRODUCT_CONST.GET_DATAPRODUCTS_BY_CRITERIA_REQUEST:
      return {
        ...state,
        isDataProductsByCriteriaLoading: true,
        allDataProductsByCriteria: [],
      };
    case DATAPRODUCT_CONST.GET_DATAPRODUCTS_BY_CRITERIA_SUCCESS:
      return {
        ...state,
        isDataProductsByCriteriaLoading: false,
        allDataProductsByCriteria: action.payload?.response?.data?.finalResult,
      };
    case DATAPRODUCT_CONST.GET_DATAPRODUCTS_BY_CRITERIA_FAILURE:
      return {
        ...state,
        allDataProductsByCriteria: [],
        isDataProductsByCriteriaLoading: false,
      };
    case DATAPRODUCT_CONST.GET_MY_DATA_PRODUCT_REQUEST:
    case DATAPRODUCT_CONST.GET_MY_DATA_PRODUCT_FAILURE:
      return {
        ...state,
        myDataProducts: null,
      };
    case DATAPRODUCT_CONST.GET_MY_DATA_PRODUCT_SUCCESS:
      return {
        ...state,
        myDataProducts: action.payload?.response,
      };
    case DATAPRODUCT_CONST.CREATE_DATAPRODUCT_REQUEST:
      return {
        ...state,
        createDataProductData: null,
      };
    case DATAPRODUCT_CONST.CREATE_DATAPRODUCT_SUCCESS:
      return {
        ...state,
        createDataProductData: action.payload?.response,
      };
    case DATAPRODUCT_CONST.CREATE_DATAPRODUCT_FAILURE:
      return {
        ...state,
        createDataProductData: action.payload?.data,
      };

    case DATAPRODUCT_CONST.UPDATE_DATAPRODUCT_REQUEST:
    case DATAPRODUCT_CONST.UPDATE_DATAPRODUCT_FAILURE:
      return {
        ...state,
        updateDataProductData: null,
      };
    case DATAPRODUCT_CONST.UPDATE_DATAPRODUCT_SUCCESS:
      return {
        ...state,
        updateDataProductData: action.payload?.response,
      };
    case DATAPRODUCT_CONST.GET_DATAPRODUCTS_BY_ID_REQUEST:
    case DATAPRODUCT_CONST.GET_DATAPRODUCTS_BY_ID_FAILURE:
      return {
        ...state,
        DataProductDetails: null,
        DataProductNotFound: action.payload?.statusCode === 400 ? true : false,
      };
    case DATAPRODUCT_CONST.GET_DATAPRODUCTS_BY_ID_SUCCESS:
      return {
        ...state,
        DataProductDetails: action.payload?.response?.dataProduct,
        ConnectedUseCases: action.payload?.response?.connectedUseCases,
      };
    case DATAPRODUCT_CONST.GET_ALL_FAVORITES_DATAPRODUCT_REQUEST:
    case DATAPRODUCT_CONST.GET_ALL_FAVORITES_DATAPRODUCT_FAILURE:
      return {
        ...state,
        getAllFavoritesDataProductsData: [],
      };
    case DATAPRODUCT_CONST.GET_ALL_FAVORITES_DATAPRODUCT_SUCCESS:
      return {
        ...state,
        getAllFavoritesDataProductsData: action.payload?.response?.data,
      };
    case DATAPRODUCT_CONST.GET_REQUIRED_DATAPRODUCTS_REQUEST:
    case DATAPRODUCT_CONST.GET_REQUIRED_DATAPRODUCTS_FAILURE:
      return {
        ...state,
        getRequiredDataProducts: [],
      };
    case DATAPRODUCT_CONST.GET_REQUIRED_DATAPRODUCTS_SUCCESS:
      return {
        ...state,
        getRequiredDataProducts: action.payload?.response,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default DataProducts;
