/* eslint-disable max-lines */
import React, {
  useCallback,
  useState,
  useRef,
  useMemo,
  useEffect,
} from "react";
import ReactFlow, { useNodesState, useEdgesState, isNode } from "reactflow";
import PropTypes from "prop-types";
import "reactflow/dist/style.css";
import { useLocation } from "react-router-dom";
import DiagramItem from "./DiagramItem";
import {
  AXIOS_INSTANCE,
  LEAN_VALUE_TREE_API,
  MEASURE_OF_SUCCESS_API,
} from "../../../store/apiUtils/config";
import { GlobalNotificationHandle } from "../../../services/NotificationHandler";
import AddMosModal from "./AddMosModal";
import {
  DIAGRAM_ORDER,
  LVT_ITEM,
  generateInitialNodes,
} from "./LVTDiagramSettings";
import LVTSection from "./LVTSection";
import useModal from "../../../helpers/CustomHooks/useModal";
import useStatus from "../../../helpers/CustomHooks/useStatus";
import { socket } from "../../../services/Socket";
import useLVTNodeCopy from "../../../helpers/CustomHooks/useLVTNodeCopy";

const LVTDiagram = ({
  initialNodes,
  initialEdges,
  userEditAccess,
  reactFlowInstance,
  setReactFlowInstance,
  refreshUseCases,
  fetchLVTStatus,
}) => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [isDraggable, setIsDraggable] = useState(true);
  const [autoEnableMOSField, setAutoEnableMOSField] = useState(false);
  const reactFlowWrapper = useRef(null);
  const { addMos, addMosToggle } = useModal();
  const [selectedLvtId, setSelectedLvtId] = useState("");
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const domainId = searchParams.get("domainId");
  const teamSpaceId = searchParams.get("teamSpaceId");
  const currentNodeId = teamSpaceId ? teamSpaceId : domainId;
  const roomName = `STATUS-LVT-${teamSpaceId ? teamSpaceId : domainId}`;
  const { checkStatus } = useStatus(roomName, currentNodeId, {
    check: "checkLVTStatus",
    update: "updateLVTStatus",
  });
  const isTeamSpace = teamSpaceId ? true : false;
  useLVTNodeCopy(
    setNodes,
    setEdges,
    nodes,
    edges,
    domainId,
    teamSpaceId,
    socket.lastJoinedRoom,
    fetchLVTStatus
  );

  useEffect(() => {
    setNodes([...generateInitialNodes(initialNodes), ...initialNodes]);
    setEdges(initialEdges);
  }, [initialNodes, initialEdges, setNodes, setEdges]);

  socket.on("syncLVTNodes", (nodes) => {
    if (nodes !== undefined && nodes.length > 0) {
      setNodes(nodes);
    }
  });

  useEffect(() => {
    refreshUseCases();
    sessionStorage.setItem("nodesCopy", JSON.stringify(nodes));
  }, [nodes, refreshUseCases]);

  useEffect(() => {
    sessionStorage.setItem("edgesCopy", JSON.stringify(edges));
  }, [edges]);

  socket.on("syncLVTEdges", (edges) => {
    if (edges !== undefined && edges.length > 0) {
      setEdges(edges);
    }
  });

  socket.on("syncLVTNodePosition", (nodes) => {
    if (nodes !== undefined && nodes.length > 0) {
      setNodes(nodes);
    }
  });

  const onNodesRemove = async (LVTId) => {
    await setNodes((nds) => {
      const finalNodesArray = nds.filter((node) => {
        return node.id !== LVTId;
      });
      socket.emit(
        "updateLVTNodes",
        finalNodesArray,
        currentNodeId,
        isTeamSpace,
        socket.lastJoinedRoom
      );
      return finalNodesArray;
    });
    await deleteLVT(LVTId);
  };

  const onEdgesRemove = async (removedElements) => {
    const finalEdgesArray = edges.filter((edge) => {
      return removedElements[0].id !== edge.id;
    });
    socket.emit(
      "updateLVTEdges",
      finalEdgesArray,
      currentNodeId,
      isTeamSpace,
      socket.lastJoinedRoom
    );
    await deleteLVTEdge(removedElements[0].id, "deleteHasLvtConnection");
    return removedElements;
  };

  const setNodesData = useCallback(
    (id, newData, shouldUpdateApi, setText, isDirty) => {
      setNodes((nds) => {
        if (
          nds.find(
            (node) =>
              node.data.label === newData.label && node.data.step === "useCase"
          ) &&
          nds.find((node) => node.id === id)?.data?.step === "useCase" &&
          isDirty
        ) {
          GlobalNotificationHandle(
            500,
            "This label is already added",
            false,
            true,
            true
          );
          setText(nds.find((node) => node.id === id)?.data?.label || "");
          return nds;
        }
        const finalNodesArray = nds.map((node) => {
          if (node.id === id) {
            node.data = { ...node.data, ...newData };
            if (shouldUpdateApi) {
              updateLvtApi(id, node, newData);
            }
          }
          return node;
        });
        socket.emit(
          "updateLVTNodes",
          finalNodesArray,
          currentNodeId,
          isTeamSpace,
          socket.lastJoinedRoom
        );
        return finalNodesArray;
      });
    },
    // eslint-disable-next-line
    [setNodes]
  );

  const handleSectionSizeChange = (id, updatedHeight) => {
    setNodes((prevNodes) => {
      const eventOnSection = DIAGRAM_ORDER.indexOf(id);
      const updatedNodes = [...prevNodes];
      updatedNodes.forEach((node, index) => {
        if (node.id === id) {
          const prevStyle = node.style;
          updatedNodes[index] = {
            ...node,
            style: {
              ...prevStyle,
              height: updatedHeight,
            },
          };
        } else {
          if (!DIAGRAM_ORDER.includes(node.id)) {
            if (node?.parentNode === id) {
              const currentNodePosition = node.position;
              const temp = updatedHeight - (node?.height || 140);
              if (temp <= 0) {
                currentNodePosition.y = 10;
              } else {
                currentNodePosition.y =
                  currentNodePosition.y > temp ? temp : currentNodePosition.y;
              }
              updatedNodes[index] = {
                ...node,
                position: {
                  ...currentNodePosition,
                },
              };
            }
          } else {
            if (DIAGRAM_ORDER.indexOf(node.id) > eventOnSection) {
              const currentSectionPosition = node.position;
              updatedNodes[index] = {
                ...node,
                position: {
                  ...currentSectionPosition,
                  y:
                    updatedNodes[index - 1].position?.y +
                    updatedNodes[index - 1].style?.height,
                },
              };
            }
          }
        }
      });
      socket.emit(
        "updateLVTNodes",
        updatedNodes,
        currentNodeId,
        isTeamSpace,
        socket.lastJoinedRoom
      );
      return updatedNodes;
    });
  };

  const selectNodeForCopy = (nodeId) => {
    setNodes((prevNodes) => {
      const updatedNodes = prevNodes.map((node) => {
        if (node.id === nodeId) {
          const nodeData = node?.data;
          const prevValueForCopySelection =
            node?.data?.nodeSelectedForCopy || false;
          const tempNode = {
            ...node,
            data: {
              ...nodeData,
              nodeSelectedForCopy: !prevValueForCopySelection,
            },
          };
          return tempNode;
        }
        return node;
      });
      return updatedNodes;
    });
  };

  const checkNodeForRelations = async(nodeId) => {
    // Check if basic connection have been made
    fetchLVTStatus({ domainId, teamSpaceId });
    const finalEdgesArray = await fetchEdges({ domainId, teamSpaceId });
    return finalEdgesArray.some(edge=> edge.source===nodeId || edge.target === nodeId)
    }

  const nodeTypes = useMemo(    
    () => ({
      [LVT_ITEM]: (props) => (
        <DiagramItem
          setNodesData={setNodesData}
          editAccess={userEditAccess}
          onNodesRemove={onNodesRemove}
          handleMosClick={handleMosClick}
          setSelectedLvtId={setSelectedLvtId}
          setIsDraggable={setIsDraggable}
          onConnect={onConnect}
          selectNodeForCopy={selectNodeForCopy}
          checkNodeForRelations={checkNodeForRelations}
          {...props}
        />
      ),
      section: (props) => (
        <LVTSection
          handleSectionSizeChange={handleSectionSizeChange}
          {...props}
        />
      ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setNodesData, userEditAccess]
  );

  const handleMosClick = () => {
    setAutoEnableMOSField(false);
    addMosToggle();
  };

  const addEdgeConnection = async (params, endpoint) => {
    try {
      await AXIOS_INSTANCE.post(`${LEAN_VALUE_TREE_API}/${endpoint}`, {
        sourceId: params.source,
        targetId: params.target,
        domainId,
        teamSpaceId,
      });
      fetchLVTStatus({ domainId, teamSpaceId });
    } catch (e) {
      GlobalNotificationHandle(
        e.response.status,
        e.response.data.errorDescription,
        false,
        true
      );
    }
  };
  const fetchEdges = useCallback(
    async ({ domainId, teamSpaceId }) => {
      try {
        let data;
        if (domainId) {
          const results = await AXIOS_INSTANCE.get(
            `${LEAN_VALUE_TREE_API}/getAllHasLvtConnectionsByDomainId`,
            { params: { domainId } }
          );
          data = results.data;
        } else if (teamSpaceId) {
          const results = await AXIOS_INSTANCE.get(
            `${LEAN_VALUE_TREE_API}/getAllHasLvtConnectionsByTeamSpaceId`,
            { params: { teamSpaceId } }
          );
          data = results.data;
        }
        const finalData = [...(data?.data?.data || [])];

        setEdges(finalData);
        return finalData;
      } catch (e) {
        GlobalNotificationHandle(
          e.response.status,
          e.response.data.errorDescription,
          false,
          true
        );
      }
    },
    [setEdges]
  );
  const onConnect = async (params) => {
    if (!canConnect(params.source, params.target)) {
      return;
    }

    await addEdgeConnection(params, "createHasLVTConnection");
    checkStatus(currentNodeId);
    // Check if basic connection have been made
    fetchLVTStatus({ domainId, teamSpaceId });
    const finalEdgesArray = fetchEdges({ domainId, teamSpaceId });
    socket.emit(
      "updateLVTEdges",
      finalEdgesArray,
      currentNodeId,
      isTeamSpace,
      socket.lastJoinedRoom
    );
    return finalEdgesArray;
  };

  const canConnect = (sourceId, targetId) => {
    let sourceNode, targetNode;
    setNodes((existingNodes) => {
      sourceNode = existingNodes.find((node) => node.id === sourceId);
      targetNode = existingNodes.find((node) => node.id === targetId);
      return existingNodes;
    });
    const sourceNodeType = sourceNode?.data.step;
    const targetNodeType = targetNode?.data.step;
    const sourceNodeLabel = sourceNode?.data.label?.trim();
    const targetNodeLabel = targetNode?.data.label?.trim();
    if (!(Boolean(sourceNodeLabel) && Boolean(targetNodeLabel))) {
      GlobalNotificationHandle(
        500,
        "Please add text to your card before linking it",
        false,
        true,
        true
      );
      return false;
    } else if (
      Math.abs(
        DIAGRAM_ORDER.indexOf(sourceNodeType) -
          DIAGRAM_ORDER.indexOf(targetNodeType)
      ) !== 1
    ) {
      GlobalNotificationHandle(
        500,
        "Cards can only be linked in a top-down order Vision > Business Outcome > Hypothesis > Use Case",
        false,
        true,
        true
      );
      return false;
    } else return true;
  };

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const edgeCustomStyle = { strokeWidth: "2px", stroke: "#7d0096" };

  const onNodeMouseEnter = (event, object) => {
    if (object.type === "section") {
      return;
    }

    setEdges((prevEdges) => {
      const duplicateEdges = [...prevEdges];
      return duplicateEdges.map((el) => {
        if (object.id === el.source || object.id === el.target) {
          return { ...el, style: edgeCustomStyle };
        } else {
          return { ...el, style: {} };
        }
      });
    });
  };

  const onNodeMouseLeave = (event, node) => {
    if (node.type === "section") {
      return;
    }

    setEdges((prevEdges) => {
      const duplicateEdges = [...prevEdges];
      return duplicateEdges.map((el) => {
        return { ...el, style: {} };
      });
    });
  };

  const onDrop = (event) => {
    event.preventDefault();

    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const type = event.dataTransfer.getData("application/reactflow");
    const parentNode = event.dataTransfer.getData("parentNode");

    if (typeof type === "undefined" || !type) {
      return;
    }

    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });

    if (type === LVT_ITEM) {
      addItemNode(position, type, parentNode);
    } else {
      addMoSNode(position);
    }
  };

  const addItemNode = (position, type, parentNode) => {
    const realPos = droppedOnNode(position, "item").position;

    let newNode = {
      type,
      position: {
        x: Math.abs(position.x - realPos.x),
        y: Math.abs(position.y - realPos.y - 10),
      },
      parentNode,
      extent: "parent",
      connectable: true,
      data: { label: "", step: parentNode },
    };
    createNewLvtApiItem(
      parentNode,
      newNode.position.x,
      newNode.position.y
    ).then((targetId) => {
      newNode = { ...newNode, id: targetId };
      setNodes((nds) => {
        const finalNodesArray = nds.concat(newNode);
        socket.emit(
          "updateLVTNodes",
          finalNodesArray,
          currentNodeId,
          isTeamSpace,
          socket.lastJoinedRoom
        );
        return finalNodesArray;
      });
      updateLvtApi(targetId, newNode, newNode);
    });
  };

  const addMoSNode = (position) => {
    const droppedNode = droppedOnNode(position, "mos");
    if (droppedNode) {
      setAutoEnableMOSField(true);
      addMosToggle();
      setSelectedLvtId(droppedNode.id);
    }
  };

  const droppedOnNode = (position, type) => {
    return nodes.find((element) => {
      if (
        isNode(element) &&
        (type === "mos"
          ? element.type !== "section"
          : element.type === "section")
      ) {
        const realPosY =
          element.type !== "section"
            ? nodes.find((el) => el.id === element.parentNode)?.position.y
            : 0;
        const realPosX =
          element.type !== "section"
            ? nodes.find((el) => el.id === element.parentNode)?.position.x
            : 0;

        const x1 = element.position.x + realPosX;
        const y1 = element.position.y + realPosY;
        const htmlElement = document.querySelector(
          `div.react-flow__node[data-id="${element.id}"]`
        );
        const x2 = x1 + htmlElement.offsetWidth;
        const y2 = y1 + htmlElement.offsetHeight;
        return (
          position.x > x1 &&
          position.x < x2 &&
          position.y > y1 &&
          position.y < y2
        );
      }
      return undefined;
    });
  };

  const mosSubmitHanlder = async (params) => {
    if (params.mosArray && params.mosArray.length > 0) {
      const prevMosData = await fetchMosApi(selectedLvtId);
      let formData = params.mosArray;
      await formData.forEach((data) => {
        prevMosData.forEach((obj) => {
          if (obj.id === data.id && obj.label !== data.label) {
            updateMosApi(data.id, data.label);
          }
        });
        if (data.id === undefined && data.label) {
          createMosApi(selectedLvtId, data.label);
        }
      });
      addMosIcon(selectedLvtId);
      if (userEditAccess) GlobalNotificationHandle(200, "Saved", true, true);
      await addMosToggle();
    }
  };

  const updateMosApi = async (mosId, description) => {
    try {
      await AXIOS_INSTANCE.put(`${MEASURE_OF_SUCCESS_API}/update/${mosId}`, {
        description: description,
      });
    } catch (e) {
      let status = e.response?.status ? e.response.status : 501;
      let message = e.response?.message ? e.response.message : "Failed";
      GlobalNotificationHandle(status, message, false, true);
    }
  };

  const createMosApi = async (lvtId, description) => {
    try {
      await AXIOS_INSTANCE.post(`${MEASURE_OF_SUCCESS_API}/create`, {
        leanValueTreeId: lvtId,
        description: description,
      });
    } catch (e) {
      let status = e.response?.status ? e.response.status : 501;
      let message = e.response?.message ? e.response.message : "Failed";
      GlobalNotificationHandle(status, message, false, true);
    }
  };

  const fetchMosApi = async (id) => {
    try {
      const finalData = await AXIOS_INSTANCE.get(
        `${MEASURE_OF_SUCCESS_API}/getByLeanValueTreeId`,
        { params: { id } }
      );
      let data = finalData.data.data.data;
      return data;
    } catch (e) {
      let status = e.response?.status ? e.response.status : 501;
      let message = e.response?.message ? e.response.message : "Failed";
      GlobalNotificationHandle(status, message, false, true);
    }
  };

  const deleteMosApi = async (mosId) => {
    try {
      if (mosId) {
        const response = await AXIOS_INSTANCE.delete(
          `${MEASURE_OF_SUCCESS_API}/delete/${mosId}`
        );
        if (response.data.data.isSuccess) {
          GlobalNotificationHandle(
            200,
            "Measure of Success Deleted",
            true,
            true
          );
          if (response.data.data.finalMosDeleted) {
            addMosToggle();
            removeMosIcon(selectedLvtId);
          }
        }
      } else {
        addMosToggle();
        removeMosIcon(selectedLvtId);
      }
    } catch (e) {
      let status = e.response?.status ? e.response.status : 501;
      let message = e.response?.message ? e.response.message : "Failed";
      GlobalNotificationHandle(status, message, false, true);
    }
  };

  const removeMosIcon = (LVTid) => {
    setNodes((nds) => {
      const finalNodesArray = nds.map((node) => {
        if (node.id === LVTid) {
          node.data = {
            ...node.data,
            hasMoS: false,
          };
        }
        return node;
      });
      socket.emit(
        "updateLVTNodes",
        finalNodesArray,
        currentNodeId,
        isTeamSpace,
        socket.lastJoinedRoom
      );
      return finalNodesArray;
    });
  };

  const addMosIcon = (LVTid) => {
    setNodes((nds) => {
      const finalNodesArray = nds.map((node) => {
        if (node.id === LVTid) {
          node.data = {
            ...node.data,
            hasMoS: true,
          };
        }
        return node;
      });
      socket.emit(
        "updateLVTNodes",
        finalNodesArray,
        currentNodeId,
        isTeamSpace,
        socket.lastJoinedRoom
      );
      return finalNodesArray;
    });
  };

  /* create LVT Item endpoint API */

  const createNewLvtApiItem = async (step, coordinateX, coordinateY) => {
    let targetIdValue;
    try {
      const { data } = await AXIOS_INSTANCE.post(
        `${LEAN_VALUE_TREE_API}/create`,
        {
          step,
          coordinateX,
          coordinateY,
          domainId,
          teamSpaceId,
          label: "",
        }
      );
      const targetId = await data.data.data.id; // uniquie id from creation
      targetIdValue = targetId;
      fetchLVTStatus({ domainId, teamSpaceId });
    } catch (e) {
      GlobalNotificationHandle(
        e.response.status,
        e.response.data.errorDescription,
        false,
        true
      );
    }
    return targetIdValue;
  };

  /* update LVT Item API */

  const updateLvtApi = async (id, prevData, newData) => {
    try {
      const label = newData.label
        ? newData.label
        : prevData.data.label
        ? prevData.data.label
        : "";
      const mos = newData.mos
        ? newData.mos
        : prevData.data.mos
        ? prevData.data.mos
        : "";
      await AXIOS_INSTANCE.put(`${LEAN_VALUE_TREE_API}/update/${id}`, {
        position: prevData.position,
        data: { step: prevData.data.step, label: label, mos: mos },
        domainId,
        teamSpaceId,
      });
      fetchLVTStatus({ domainId, teamSpaceId });
    } catch (e) {
      GlobalNotificationHandle(
        e.response.status,
        e.response.data.errorDescription,
        false,
        true
      );
    }
  };

  const deleteLVTEdge = async (LVTId, endpoint) => {
    try {
      await AXIOS_INSTANCE.delete(
        `${LEAN_VALUE_TREE_API}/${endpoint}/${LVTId}/${currentNodeId}`
      );
      fetchLVTStatus({ domainId, teamSpaceId });
    } catch (e) {
      GlobalNotificationHandle(
        e.response.status,
        e.response.data.errorDescription,
        false,
        true
      );
    }
  };

  const deleteLVT = async (LVTId) => {
    try {
      await AXIOS_INSTANCE.delete(
        `${LEAN_VALUE_TREE_API}/delete/${LVTId}/${currentNodeId}`
      );
      fetchLVTStatus({ domainId, teamSpaceId });
    } catch (e) {
      GlobalNotificationHandle(
        e.response.status,
        e.response.data.errorDescription,
        false,
        true
      );
    }
  };

  const handleNodeDragStop = (event, node) => {
    if (node.position.y < 0) {
      node.position.y = 0;
    }

    updateLvtApi(node.id, node, node);

    socket.emit("updateLVTNodePosition", nodes, `LVT-${currentNodeId}`);
  };
  return (
    <div className="h-100" ref={reactFlowWrapper}>
      <ReactFlow
        style={{ background: "rgba(244, 244, 244, 0.74)" }}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onNodesDelete={onNodesRemove}
        onEdgesDelete={onEdgesRemove}
        deleteKeyCode={userEditAccess ? ["Backspace", "Delete"] : null}
        elementsSelectable={userEditAccess}
        nodesConnectable={userEditAccess}
        nodesDraggable={userEditAccess}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        onInit={(instance) => {
          setReactFlowInstance(instance);
          setTimeout(() => {
            instance.setViewport({ x: 0, y: 200, zoom: 1 });
            localStorage.setItem("hideLoader", true);
          }, 1000);
        }}
        onDrop={onDrop}
        onNodeDragStop={handleNodeDragStop}
        onDragOver={onDragOver}
        disableKeyboardA11y={true}
        onNodeMouseEnter={onNodeMouseEnter}
        onNodeMouseLeave={onNodeMouseLeave}
        panOnDrag={isDraggable}
        zoomOnDoubleClick={false}
      ></ReactFlow>

      {addMos && (
        <AddMosModal
          id={selectedLvtId}
          addMos={addMos}
          autoEnableMOSField={autoEnableMOSField}
          addMosToggle={addMosToggle}
          mosSubmitHanlder={mosSubmitHanlder}
          fetchMosApi={fetchMosApi}
          deleteMosApi={deleteMosApi}
          userEditAccess={userEditAccess}
        />
      )}
    </div>
  );
};

LVTDiagram.propTypes = {
  initialNodes: PropTypes.array,
  initialEdges: PropTypes.array,
  userEditAccess: PropTypes.bool.isRequired,
  reactFlowInstance: PropTypes.object,
  setReactFlowInstance: PropTypes.func,
  refreshUseCases: PropTypes.func,
  fetchLVTStatus: PropTypes.func,
  autoEnableMOSField: PropTypes.bool,
};

export default LVTDiagram;
