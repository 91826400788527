import React from "react";
import Layout from "../../components/Common/Layout";
import Breadcrumb from "../../components/Common/BreadCrumb";
import PropTypes from "prop-types";
import MultipurposeAccordion from "../../components/Common/MultipurposeAccordion";
import { Container } from "@mui/material";
import "./index.scss";

const Releases = ({ versionLogs }) => {
  const { releaseLog } = versionLogs;

  return (
    <div className="global-top-1 releases-page">
      <Layout>
        <div>
          <Breadcrumb
            styles={{ marginBottom: 30 }}
            steps={[
              { name: "Home Page", href: "/home" },
              { name: "Releases", active: true, href: "#" },
            ]}
          />
          <Container>
            {releaseLog.map((item, index1) => (
              <MultipurposeAccordion
                title={`Version: ${item.version}, Release date: ${item.releaseDate}`}
                key={index1}
              >
                <div className="mt-2">
                  {item.newFeatures.map((feature, index2) =>
                    feature.description !== "" ? (
                      <MultipurposeAccordion
                        title={feature.title}
                        key={`${index1}-${index2}`}
                        data-testid={`#${index1}-${index2}#`}
                      >
                        <p className="fs-6"> {feature.description}</p>
                      </MultipurposeAccordion>
                    ) : (
                      <p
                        className="fs-6 mx-3"
                        key={`${index1}-${index2}`}
                        data-testid={`#${index1}-${index2}#`}
                      >
                        {" "}
                        {feature.title}
                      </p>
                    )
                  )}
                </div>
              </MultipurposeAccordion>
            ))}
          </Container>
        </div>
      </Layout>
    </div>
  );
};

Releases.propTypes = {
  versionLogs: PropTypes.object.isRequired,
};

export default Releases;
