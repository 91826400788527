import classNames from "classnames";
import React, { useState, useRef, useEffect } from "react";
import { Handle } from "reactflow";
import PropTypes from "prop-types";
import CodeEditor from "@uiw/react-textarea-code-editor";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import DiagramItemMenu from "./DiagramItemMenu";
import { GlobalNotificationHandle } from "../../../services/NotificationHandler";
import "./DiagramItem.scss";

const DiagramItem = ({
  data,
  isConnectable,
  setNodesData,
  id,
  editAccess,
  onNodesRemove,
  setSelectedLvtId,
  handleMosClick,
  setIsDraggable,
  onConnect,
  selectNodeForCopy,
  checkNodeForRelations,
}) => {
  const { label, nodeSelectedForCopy } = data;
  const labelRef = useRef();
  const [text, setText] = useState(label || "");
  const [isDirty, setIsDirty] = useState(false);
  const [linkAnchorEl, setLinkAnchorEl] = useState(null);
  const [isLinkMenuOpen, setIsLinkMenuOpen] = useState(false);
  const [isHighlighted, setisHighlighted] = useState(false);

  useEffect(() => {
    setText(label);
  }, [label]);

  const handleClick = (event) => {
    setLinkAnchorEl(event.currentTarget);
    setIsLinkMenuOpen(true);
  };

  const handleNodeClick = () => {
    labelRef.current.focus();
    setIsDraggable(false);
  };

  const handleOnBlur = () => {
    setIsDraggable(true);
    setNodesData(id, { label: text }, true, setText, isDirty);
    setIsDirty(false);
  };

  const handleOnChange = async (evn) => {
    setText(evn.target.value);
    setIsDirty(true);
    if (evn.target.value === "") {
      const hasLVTConnection = await checkNodeForRelations(id);
      setisHighlighted(hasLVTConnection);
      if (hasLVTConnection) {
        GlobalNotificationHandle(
          400,
          "Connected card can not be empty. Please delete or update the card",
          false,
          true,
          true
        );
      }
    } else setisHighlighted(false);
  };

  const onMosClickHandler = () => {
    setSelectedLvtId(id);
    handleMosClick();
  };

  const handleNodeDoubleClick = (id) => {
    labelRef.current.blur();
    selectNodeForCopy(id);
  };

  const handleKeyDown = (event) => {
    if (!editAccess) {
      event.preventDefault();
    }
  };

  return (
    <div className="diagram-item">
      <Handle
        type="target"
        position="top"
        style={{ background: "#555", marginTop: 10 }}
        className="diagram-item-handle-dot"
        isConnectable={isConnectable}
      />
      <div
        className={classNames("diagram-item-container", data.step)}
        onClick={handleNodeClick}
        onDoubleClick={() => handleNodeDoubleClick(id)}
      >
        <div
          className={classNames("diagram-item-copied-nodes", {
            "not-copied":
              nodeSelectedForCopy !== undefined ? !nodeSelectedForCopy : true,
          })}
        >
          <CheckRoundedIcon />
        </div>
        <CodeEditor
          className={
            isHighlighted ? "highlighted-editor" : "diagram-item-code-editor"
          }
          value={text}
          id={id}
          ref={labelRef}
          placeholder="Start Type"
          onChange={handleOnChange}
          onKeyDown={handleKeyDown}
          onBlur={handleOnBlur}
          padding={15}
        />
      </div>
      {data.hasMoS ? (
        <div className="diagram-item-mos" onClick={onMosClickHandler}>
          <span className="diagram-item-text">MoS</span>
        </div>
      ) : null}
      <div
        className={classNames("diagram-item-delete", data.step)}
        onClick={handleClick}
      >
        <MoreVertIcon sx={{ fontSize: "14px" }} />
      </div>
      <DiagramItemMenu
        data={data}
        id={id}
        linkAnchorEl={linkAnchorEl}
        setLinkAnchorEl={setLinkAnchorEl}
        isLinkMenuOpen={isLinkMenuOpen}
        setIsLinkMenuOpen={setIsLinkMenuOpen}
        onNodesRemove={onNodesRemove}
        editAccess={editAccess}
        onConnect={onConnect}
      />
      {data.step !== "useCase" ? (
        <Handle
          type="source"
          position="bottom"
          id="a"
          style={{ background: "#555", marginBottom: 15 }}
          className="diagram-item-handle-dot"
          isConnectable={isConnectable}
        />
      ) : null}
    </div>
  );
};

DiagramItem.propTypes = {
  data: PropTypes.object.isRequired,
  isConnectable: PropTypes.bool,
  setNodesData: PropTypes.func,
  id: PropTypes.string,
  editAccess: PropTypes.bool.isRequired,
  onNodesRemove: PropTypes.func,
  handleMosClick: PropTypes.func,
  setSelectedLvtId: PropTypes.func,
  setIsDraggable: PropTypes.func,
  onConnect: PropTypes.func,
  selectNodeForCopy: PropTypes.func,
  checkNodeForRelations: PropTypes.func,
};

export default DiagramItem;
