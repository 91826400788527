import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

const MultipleSelectCheckMarks = ({
  input,
  name,
  options,
  defaultItems = [],
  meta: { touched, error, warning },
}) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (defaultItems.length) {
      setItems(defaultItems);
      input.onChange(defaultItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event) => {
    const value = event.target.value;
    setItems(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    input.onChange(typeof value === "string" ? value.split(",") : value);
  };

  const renderSelectedItems = (selected) => {
    let renderArray = [];
    selected.forEach((eachValue) => {
      options.some((entry) => {
        if (entry.id === eachValue) {
          renderArray.push(entry.description);
        }
        return null;
      });
    });
    return renderArray.join(",");
  };

  return (
    <>
      <Select
        {...input}
        className="login-input form-control"
        data-testid="select"
        inputProps={{ "data-testid": "useCaseId-test" }}
        name={name}
        multiple
        value={items}
        onChange={handleChange}
        renderValue={(selected) => renderSelectedItems(selected)}
      >
        {options.map((option) => (
          <MenuItem
            className="create-dp-menu-item"
            disabled={defaultItems.includes(option.id)}
            key={option.id}
            value={option.id}
          >
            <Checkbox checked={items.indexOf(option.id) > -1} />
            <ListItemText primary={option.description} />
          </MenuItem>
        ))}
      </Select>
      {touched &&
        (error ? (
          <div className="form-feedback">{error}</div>
        ) : (
          warning && <div>{warning}</div>
        ))}
    </>
  );
};

MultipleSelectCheckMarks.propTypes = {
  input: PropTypes.object,
  options: PropTypes.any,
  name: PropTypes.string,
  meta: PropTypes.object,
  defaultItems: PropTypes.array,
};

export default MultipleSelectCheckMarks;
