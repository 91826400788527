import { combineReducers } from "redux";
//redux form
import { reducer as formReducer } from "redux-form";

// Front
import Layout from "./layout/reducer";
import Loader from "./layout/loader/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Domains from "./domains/reducer";
import DataProducts from "./dataProducts/reducer";
import DataproductTypes from "./dataProductTypes/reducer";
import Roles from "./role/reducer";
import Teams from "./team/reducer";
import Users from "./user/reducer";
import DataSource from "./dataSource/reducer";
import DataProductInteractionMap from "./dataProductInteractionMap/reducer";
import DataProductsRelations from "./dataProducts/relations/reducer";
import TeamSpace from "./teamSpaces/reducer";
import LineageFilter from "./dataProducts/LineageFilter/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Domains,
  Login,
  form: formReducer,
  Loader,
  DataProducts,
  DataproductTypes,
  Roles,
  Teams,
  Users,
  DataSource,
  DataProductsRelations,
  DataProductInteractionMap,
  TeamSpace,
  LineageFilter,
});

export default rootReducer;
