import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import CustomDataInputField from "../../components/Common/CustomDataInputField";
import CustomDataMultiSelectField from "../../components/Common/CustomDataMultiSelectField";
import DomainType from "../../components/Common/DomainType";
import { socket } from "../../services/Socket";
import useIsEdit from "../../helpers/CustomHooks/useIsEdit";
import "./DataDomainDetailsCard.scss";
import { getAllUsers } from "../../store/actions";
import { GO_TO_COLLIBRA_LINK_TEXT } from "../../constants";

const DataDomainDetailsCard = ({ selectedDomain }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [members, setMembers] = useState([]);
  const [owners, setOwners] = useState([]);
  const [selectableUsers, setSelectableUsers] = useState([]);
  const [domainName, setDomainName] = useState("");
  const [definition, setDefinition] = useState("");
  const { editable, handleEditState } = useIsEdit(socket.lastJoinedRoom);
  const dispatch = useDispatch();

  const userDetails = localStorage.getItem("loginInfo")
    ? JSON.parse(localStorage.getItem("loginInfo")).data?.userDetails
    : null;

  const { users } = useSelector((state) => ({
    users: state.Users?.allUsers?.userList,
  }));

  useEffect(() => {
    if (isEditMode) {
      dispatch(getAllUsers());
    }
  }, [isEditMode, dispatch]);

  useEffect(() => {
    setMembers(selectedDomain?.members);
    setOwners(selectedDomain?.owners);
    setDefinition(selectedDomain?.description || "");
    setDomainName(selectedDomain?.name || "");
  }, [selectedDomain]);

  useEffect(() => {
    if (!users || !members || !owners) {
      return;
    }

    const filteredUserList = users.filter((user) => {
      const isMember = members.find((member) => user.id === member.id);
      const isOwner = owners.find((owner) => user.id === owner.id);
      return !isMember && !isOwner;
    });

    setSelectableUsers(filteredUserList);
  }, [users, members, owners]);

  const handleSave = async () => {
    if (isOwner || isMember) {
      socket.emit(
        "updateDataDomain",
        {
          name: domainName,
          domainId: selectedDomain.id,
          description: definition,
          members,
          owners,
          isOwner,
          isMember,
        },
        socket.lastJoinedRoom
      );
    }
    setIsEditMode(false);
    handleEditState(true, socket.lastJoinedRoom);
  };

  const handleEdit = () => {
    setIsEditMode(true);
    handleEditState(false, socket.lastJoinedRoom);
  };

  const isCollibraDomain = selectedDomain?.isCollibra;

  const isOwner = !!selectedDomain?.owners?.find(
    (owner) => userDetails && owner.id === userDetails.id
  );

  const isMember = !!selectedDomain?.members?.find(
    (member) => userDetails && member.id === userDetails.id
  );

  return (
    <div
      className="data-domain-details-card"
      data-testid="data-domain-details-card"
    >
      <div
        className="data-domain-details-card-title"
        data-testid="domain-title"
      >
        <CustomDataInputField
          editState={isEditMode && !isCollibraDomain && isOwner}
          data={domainName}
          onChangeHandler={setDomainName}
          isMultiLine={false}
          textVariant="body2"
          textFieldClass="data-domain-details-card-title-text"
          textClass="data-domain-details-card-title-text"
          data-testid="domain-name"
        />
        <div className="data-domain-details-card-title-chip">
          <div>
            <DomainType domainType={selectedDomain?.domainType} />
          </div>
        </div>
        {isOwner || isMember ? (
          <Button
            className="data-domain-details-card-button"
            startIcon={<FaRegEdit />}
            variant={isEditMode ? "contained" : "outlined"}
            color="secondary"
            disabled={!editable && !isEditMode}
            onClick={isEditMode ? handleSave : handleEdit}
            data-testid="edit-save-button"
          >
            {isEditMode ? "Save" : "Edit"}
          </Button>
        ) : null}
      </div>
      <div className="data-domain-details-card-body" data-testid="domain-body">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              className="data-domain-details-card-body-title"
            >
              Definition:
            </Typography>
            <CustomDataInputField
              editState={isEditMode && !isCollibraDomain && isOwner}
              parseDataToHTML={isCollibraDomain}
              data={definition}
              onChangeHandler={setDefinition}
              isMultiLine={true}
              data-testid="domain-definition"
              textVariant="body2"
              textFieldClass="data-domain-details-card-body-definition"
              textClass="data-domain-details-card-body-definition"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              className="data-domain-details-card-body-title"
            >
              Data Domain Owner:
            </Typography>
            <CustomDataMultiSelectField
              domainId={selectedDomain.id}
              customEditClass="data-domain-details-card-members-edit"
              customTextClass="data-domain-details-card-members"
              data={owners}
              options={selectableUsers}
              isEdit={isEditMode && isOwner}
              setOptions={setOwners}
              customButtonText="Add new person"
              data-testid="domain-owners"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              className="data-domain-details-card-body-title"
            >
              Members:
            </Typography>
            <CustomDataMultiSelectField
              domainId={selectedDomain.id}
              customEditClass="data-domain-details-card-members-edit"
              customTextClass="data-domain-details-card-members"
              data={members}
              options={selectableUsers}
              isEdit={isEditMode}
              setOptions={setMembers}
              customButtonText="Add new person"
              data-testid="domain-members"
            />
          </Grid>
        </Grid>
      </div>
      {isCollibraDomain && (
        <div
          className="data-domain-details-card-footer"
          data-testid="collibra-link"
        >
          <Link
            to={{
              pathname:
                process.env.REACT_APP_COLLIBRA_LINK + selectedDomain?.id,
            }}
            target="_blank"
          >
            <Button
              variant="text"
              fontSize={16}
              className="data-domain-details-card-footer-button"
            >
              {GO_TO_COLLIBRA_LINK_TEXT}
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
};

DataDomainDetailsCard.propTypes = {
  selectedDomain: PropTypes.object,
};

export default DataDomainDetailsCard;
